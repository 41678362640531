import {AfterViewInit, Component, OnInit, ViewChild, Input} from '@angular/core';
import {ConfirmationService, LazyLoadEvent, MenuItem, Message, MessageService} from 'primeng/api';
import {ActivatedRoute, NavigationEnd, NavigationStart, Router} from '@angular/router';
import {EmployeeService} from '../../service/employee.service';
import {EmployeeClockingServiceV2} from '../../../employee-clocking/service/v2/employee.clocking.v2.service';
import {Table} from 'primeng/table';
import {Employee} from '../../model/employee.model';
import {DocumentsService} from '../../../shared/service/documents-upload/documents.service';
import {AuthService} from '../../../shared/service/auth/auth.service';
import {WindowRefService} from '../../../shared/service/window-ref/window-ref.service';
import {CrmCommService} from '../../../crm/service/crm-comm.service';
import {DropDownsService} from '../../../shared/service/drop-downs/drop-downs.service';
import {EmploymentAuthorizationService} from '../../service/employment-authorization.service';
import {RoleLevel} from '../../../layout/component/app-menu/role-level';
import {UtilsService} from '../../../shared/service/utils.service';
import {MonitoringDetailsService} from '../../../admin/process-monitor/service/monitoring-details.service';
import {RESPONSE_STATUS} from '../../../shared/model/constants';
import {Callout} from '../../model/callout.model';
import {AbsenceService} from '../../absence/service/absence.service';
import {Absence} from '../../absence/model/absence';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import * as _ from 'lodash';
import * as moment from 'moment';
import {Agency} from '../../../agency/model';
import {Company} from '../../../company/model/company.model';
import {FileUtility} from '../../../shared/utility/file.utility';
import {TranslatePipe} from '@ngx-translate/core';
import {EmployeePaymentHistoryService} from 'app/employee/service/employee-paymenthistory.service';
import {PrcoessMonitorLoginActivityService} from 'app/admin/process-monitor/service/process-monitor-login-activity.service';
import * as MOMENT from 'moment';
import {EmployeeClocking} from '../../../employee-clocking/model/employee-clocking.model';
import { ExportMonitorService } from 'app/admin/process-monitor/service/export-monitor.service';
import {DatePipe} from "@angular/common";


@Component({
    selector: 'app-employee-clocking-list',
    templateUrl: './employee-clocking-list.component.html',
    styleUrls: ['./employee-clocking-list.component.css'],
    providers: [EmployeeService, DocumentsService, AuthService,
        DropDownsService, ConfirmationService, EmployeePaymentHistoryService,
        MonitoringDetailsService, EmployeeClockingServiceV2]
})
export class EmployeeClockingListComponent implements OnInit, AfterViewInit {
    @Input() positionType: String;

    isShowDetailReport = false;
    employees: Employee[];
    selectedEmployee: Employee = new Employee();
    totalRecords: Number = 0;
    items: MenuItem[];
    msgs: Message[] = [];
    loading = true;
    types: Array<any> = [];
    showQRDialog: boolean;
    selectedQREmployee: any;
    @ViewChild('dt', {static: true}) table: Table;
    userPermission: Boolean;
    rowData: any;
    size: number;
    page: number;
    allCompanyList: any = [];
    allAgencyList: any = [];
    allCreatedByList: any = [];
    selectedCompany: any;
    selectedAgency: any;
    selectedCreatedBy: string;
    selectedType: string;
    selectedStatus: any;
    sortF: any = '';
    cols: any[] = [
        {field: 'employeeName', label: 'Name', sort: 'text', sortOptions: '' },
        {field: 'payrollWeek', label: 'Week', sort: 'number'},
        {field: 'typeEmployee', label: 'cols.type', sort: 'text', sortOptions: ''},
        {field: 'totalWorkTime', label: 'Worked', sort: 'number', sortOptions: ''},
        {field: 'totalLateTime', label: 'Latenesses', sort: 'number', sortOptions: ''},
        {field: 'overTime', label: 'Overtime', sort: 'number', sortOptions: ''},
        {field: 'balanceHour', label: 'Balance', sort: 'number', sortOptions: ''},
    ];
    selectedSortOption = '';
    selectedField = '';
    typeList = [
        {label: 'All', value: null},
        {label: 'Employee', value: 'employee'},
        {label: 'Intern (Paid)', value: 'intern_paid'},
        {label: 'Intern (Not Paid)', value: 'intern_unpaid'},
        {label: 'Volunteer', value: 'volunteer'},

    ];
    textSortOptions = [
        { name: 'Sort A To Z', value: 'ASC', img: 'arrow-down-a-z' },
        { name: 'Sort Z To A', value: 'DESC', img: 'arrow-up-z-a' }
    ];
    numberSortOptions = [
        { name: 'Sort 1 To 9', value: 'ASC', img: 'arrow-down-1-9' },
        { name: 'Sort 9 To 1', value: 'DESC', img: 'arrow-up-9-1' }
    ];
    dateSortOptions = [
        { name: 'Sort 1 To 12', value: 'ASC', img: 'arrow-down-1-9' },
        { name: 'Sort 12 To 1', value: 'DESC', img: 'arrow-up-9-1' }
    ];
    statusSortOptions = [
        { name: 'Inactive First', value: 'ASC', img: 'circle-xmark text-danger' },
        { name: 'Active First', value: 'DESC', img: 'circle-check text-success' }
    ];
    sortField: string;
    sortOrder: any;
    isCompanyDisabled: boolean;
    isAgencyDisabled: boolean;
    pageVisitTime: any = null;
    isSearchClicked = false;
    showCallout = false;
    calloutForm: FormGroup;
    callout: Callout = new Callout();
    listData: any = [];

    minDate: Date = new Date();
    empNames: any = [];
    empNamesSuggestion: { 'name': string; 'value': string; }[];
    anAbsence: Absence;
    dateToday: any;
    callOutDate: any;
    filterClocked: string;
    isDeleteAble = false;
    textSearch: string;
    filteredEmployees: Employee[];
    isAddEmployeeEnable: boolean = true;
    companyDisabled: boolean = false;
    agencyDisabled: boolean = false;
    isPlatformAdmin: boolean = false;
    showConfirmDialog: boolean;
    employeeSelected: Employee;
    display = false;
    ssnNumber = null;
    deskActivity: any[] = [];
    employeeId;
    overtimeList: any[];
    totalDay1: number;
    totalDay2: number;
    totalDay3: number;
    totalDay4: number;
    totalDay5: number;
    totalDay6: number;
    totalDay7: number;
    totalPotentialOT: number;
    totalRegular: number;
    totalShortPre: number;
    totalBalanceHour: number;
    totalOvertimeHours: number;
    totalAvoidOT: number;
    fromDate: Date;
    toDate: Date;
    filteredClockingMissingList = <any>[];
    filteredPunchHistoryList = <any>[];
    punchHistoryList: Array<any>;
    historyFilter: any = {
        employees: [],
        employeeIds: [],
        fromDate: null,
        toDate: null,
        ids: [],
        page: 0,
        size: 10,
        key: '',
        date: [new Date(), new Date()],
        lateEarlier: null,
        isMissing: false,
        withNoOvertime: false,
        type: 1
    };
    constructor(
        private employeeService: EmployeeService,
        private route: ActivatedRoute,
        private router: Router,
        private documentService: DocumentsService,
        private exportMonitorService: ExportMonitorService,
        private authService: AuthService,
        private windowRef: WindowRefService,
        private crmCommService: CrmCommService,
        private employmentAuthorizationService: EmploymentAuthorizationService,
        private confirmationService: ConfirmationService,
        private paymentHistoryService: EmployeePaymentHistoryService,
        private dropDownsService: DropDownsService,
        private utilsService: UtilsService,
        private monitoringDetailsService: MonitoringDetailsService,
        private absenceService: AbsenceService,
        private employeeClockingServiceV2: EmployeeClockingServiceV2,
        private messageService: MessageService,
        private translatePipe: TranslatePipe,
        private _employeeClockingService: EmployeeClockingServiceV2,
        private datePipe: DatePipe,
        private processMonitorLoginActivity: PrcoessMonitorLoginActivityService) {
        this.anAbsence = new Absence();
        this.route.queryParams.subscribe(params => {
            if (params['employeeId']) {
                this.employeeId = params['employeeId'];
            }
        });
        router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                if (this.router.url === '/app/employee/list') {
                    const userLoginTime = this.authService.getUserLogInTime();
                    if (userLoginTime && userLoginTime > 0) {
                        this.monitoringDetailsService.monitorNavigationProcess('Navigation After Login',
                            {'navigated_to_employee_list_after_login_by': this.authService.getCurrentLoggedInName()},
                            'Completed',
                            'Navigated after login', userLoginTime, true, false);
                    }
                    this.utilsService.visitedPageData.pageVisitStartTime = new Date();
                    this.pageVisitTime = this.utilsService.visitedPageData.pageVisitStartTime;
                    this.utilsService.visitedPageData.pageTitle = 'Employee List';
                    this.utilsService.visitedPageData.pageUrl = '/app/employee/list';
                }
            }
            if (event instanceof NavigationStart) {
                if (this.utilsService.visitedPageData.pageTitle === 'Employee List' && this.utilsService.visitedPageData.pageUrl === '/app/employee/list'
                    && this.authService.authenticateUser()) {
                    this.monitoringDetailsService.monitorNavigationProcess(this.utilsService.visitedPageData.pageTitle,
                        {'navigated_to_employee_list_page_by': this.authService.getCurrentLoggedInName()},
                        'Completed',
                        'Navigated to Employee List', this.pageVisitTime, false, false);
                }
            }

            this.route.queryParams.subscribe(params => {
                this.ssnNumber = params['ssn'];

            });
        });
    }


    ngOnInit() {
        this.isShowDetailReport = false;
        this.isPlatformAdmin = this.authService.isSuper() || this.authService.isSubSuper();
        this.selectedStatus = 1;
        this.textSearch = '';
        const date = new Date();
        this.fromDate = new Date(date.getFullYear(), date.getMonth(), 1);
        this.toDate = new Date();
        const menuList = this.authService.getLoggedInUserMenuList();
        const detailReportItem = menuList.find(i => i === '1100');
        if (detailReportItem) {
            this.isShowDetailReport = true;
        }
        // this.loadPage({ page: 0, size: this.table.rows });
        this.filterClocked = this.route.snapshot.queryParamMap.get('filter');
        // this.loadEmloyeesForCallout();
        const today = new Date();
        this.loadTimeHistoryLazy();
        this.items = [
            {label: 'View', icon: 'fa-solid fa-magnifying-glass', command: (event) => this.viewEmployee(this.selectedEmployee)}
        ];
        this.employees = [];
        this.types = [
            {label: 'Part Time', value: 'part_time'},
            {label: 'Full Time', value: 'full_time'},
            {label: 'Consultant', value: 'consultant'},
            {label: 'Volunteer', value: 'volunteer'}
        ];
        this.dateToday = new Date();
        this.showQRDialog = false;
        this.selectedQREmployee = {};
        this.userPermission = this.authService.adminRole();
        this.loadAllCompanyList();
        this.loadCompanyAndAgencyDropdown();
        this.utilsService.isLogoutTriggered$.subscribe((isLogout) => {
            if (isLogout && this.utilsService.isLogoutTriggerCompleted && this.authService.authenticateUser() &&
                this.utilsService.visitedPageData.pageTitle == 'Employee List' && this.utilsService.visitedPageData.pageUrl == '/app/employee/list') {
                this.monitoringDetailsService.monitorNavigationProcess('Employee List',
                    {'navigated_to_employee_list_by': this.authService.getCurrentLoggedInName()},
                    'Completed',
                    'Navigated to Employee List', this.pageVisitTime, false, true);
            }
        });
        this.isDeleteAble = this.authService.getLoggedInUserDeleteList().includes('21'); // get id from menu-list.ts
        this.isAddEmployeeEnable = this.checkAddEmployeeEnable();
    }

    getCreatedByList() {
        let params: any = {};
        if (!this.isPlatformAdmin) {
            params['companyId'] = (this.authService.getCurrentCompany()) ? this.authService.getCurrentCompany() : null;
            // params["agencyId"] = (this.authService.getCurrentAgency()) ? this.authService.getCurrentAgency().id : null;
        }
        this.employeeService.getCreatedByList(params).subscribe((e: any) => {
            if (e.status === RESPONSE_STATUS.SUCCESS) {
                e.data.forEach((item) => {
                    const emp = this.employees.filter(em => em.createdByUsr === item)[0];
                    if (item.toString().length > 0 && emp) {
                        this.allCreatedByList.push({label: item, value: item});
                    }
                });
            }
        });
    }

    loadCompanyAndAgencyDropdown() {
        this.utilsService.loadCompanyAndAgencyDropdown().then((res) => {
            let companyAgencyDropDown: any = res;
            this.companyDisabled = companyAgencyDropDown.companyDisabled;
            this.agencyDisabled = companyAgencyDropDown.agencyDisabled;
            if (companyAgencyDropDown.companyResponse) {
                if (companyAgencyDropDown.companyResponse.length == 1) {
                    this.allCompanyList = companyAgencyDropDown.companyResponse;
                    //this.selectedCompany = companyAgencyDropDown.companyResponse[0];
                } else {
                    this.allCompanyList = companyAgencyDropDown.companyResponse;
                }
            }
            if (companyAgencyDropDown.agencyResponse) {
                if (companyAgencyDropDown.agencyResponse.length == 1) {
                    this.allAgencyList = companyAgencyDropDown.agencyResponse;
                    this.selectedAgency = companyAgencyDropDown.agencyResponse[0];
                    // this.onAgencyChange(this.selectedAgencyId.id);
                } else {
                    //this.allCreatedByList = companyAgencyDropDown.agencyResponse;
                }
            }
        });
    }

    showCalloutModel() {
        this.callout = new Callout();
        this.callout.date = new Date();
        this.showCallout = !this.showCallout;
    }

    submitCallout() {
        this.msgs = [];
        this.messageService.clear();
        if (this.callout.employeeName && this.callout.note && this.callout.type && this.callOutDate) {
            if (!this.callout.employeeName.key) {
                this.messageService.add({
                    severity: 'error',
                    summary: this.translatePipe.transform('ServiceMessage'), detail: this.translatePipe.transform('Please select employee from the existing employees')
                });
                return;
            }
            this.anAbsence.employeeId = this.callout.employeeName.key;
            this.anAbsence.callOut = 'Yes';
            const calloutDate = this.callOutDate;
            calloutDate.setMinutes(calloutDate.getMinutes() + calloutDate.getTimezoneOffset());
            this.anAbsence.type = this.callout.type;
            this.anAbsence.timeStart = calloutDate;
            this.anAbsence.timeEnd = calloutDate;
            this.anAbsence.note = this.callout.note;
            this.anAbsence.ptoAmount = this.calculateWorkHours(this.callout.employeeName, this.anAbsence);
            this.anAbsence.companyId = this.callout.employeeName.companyId;
            this.absenceService.createAbsence(this.anAbsence).subscribe(
                (data: any) => {
                    if (data.status === 'SUCCESS') {
                        this.messageService.add({
                            severity: 'success',
                            summary: this.translatePipe.transform('ServiceMessage'), detail: this.translatePipe.transform('CallOutAdded')
                        });
                        this.showCalloutModel();
                    } else {
                        this.messageService.add({
                            severity: 'error',
                            summary: this.translatePipe.transform('ServiceMessage'), detail: data.message
                        });
                    }
                });
        } else {
            this.messageService.add({
                severity: 'error',
                summary: this.translatePipe.transform('ServiceMessage'),
                detail: this.translatePipe.transform('AllFieldsAreRequired')
            });
        }

    }

    calculateWorkHours(employee, absence) {
        const workdays = employee.workHours;
        const timeStart = new Date(absence.timeStart);
        const start = new Date(absence.timeStart).getDay();
        let s = start;
        if (start === 0) {
            s = 7;
        }
        let end = new Date(absence.timeEnd).getDay();
        if (end === 0) {
            end = 7;
        }
        let totalHours = 0;
        const time = absence.timeStart;

        while (s !== end) {
            workdays.forEach(element => {
                if (element.dayOfWeek === s) {
                    let hours = element.endTime - element.startTime;
                    if (hours / 3600000 > 8) {
                        hours = 8 * 3600000;
                    }
                    totalHours += hours;
                }
            });

            time.setDate(time.getDate() + 1);
            s = new Date(time).getDay();

            if (s === 0) {
                s = 7;
            }

        }

        workdays.forEach(element => {
            if (element.dayOfWeek === s) {
                let hours = element.endTime - element.startTime;
                if (hours / 3600000 > 8) {
                    hours = 8 * 3600000;
                }
                totalHours += hours;
            }
        });
        this.anAbsence.timeStart = timeStart;
        return (totalHours / 3600000);

    }

    searchEmp(event) {
        const search = event.query;
        this.empNamesSuggestion = this.empNames.filter(emp => emp.fullName && emp.fullName.toLowerCase().includes(search.toLowerCase()));
    }

    loadAllCompanyList() {
        const pafc = this;
        this.dropDownsService.getAllCompanyList().subscribe((res) => {
            const resObj: any = res;
            this.allCompanyList = [];
            if (resObj.status === 'SUCCESS') {
                this.allCompanyList = resObj.data;
                this.loadCompanyBasedOnAdminRole();}
        });
    }


    loadAgencyBasedOnCompanyId() {
        this.selectedAgency = null;
        const pafc = this;
        this.dropDownsService.getAllAgencyByCompanyId(this.selectedCompany.id).subscribe((res) => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                this.allAgencyList = resObj.data;
                this.loadAgencyBasedOnAdminRole();
            }
        });
    }

    hhmm2(minutes: number): string {
        const second = minutes * 60;
        const hours = Math.floor(second ? second / 3600 : 0);
        const minute = Math.floor((second ? second % 3600 : 0) / 60);

        return _.padStart(hours, 2, '0') + ':' + _.padStart(minute, 2, '0');
    }

    hhmm1(milisecond: number): string {
        const second = milisecond / 1000;
        const hours = Math.floor(second ? second / 3600 : 0);
        const minute = Math.floor((second ? second % 3600 : 0) / 60);

        return _.padStart(hours, 2, '0') + ':' + _.padStart(minute, 2, '0');
    }

    getTotalHour(totalHours: number, totalOvertime: number) {
        const result = totalHours / 60000 - totalOvertime;
        return this.hhmm3(Math.round(result));
    }

    hhmm3(minutes: number): string {
        const hours = Math.floor(minutes ? minutes / 60 : 0);
        const minute = minutes ? minutes % 60 : 0;
        if (!Number.isInteger(hours) || !Number.isInteger(minute)) {
            return minutes + '';
        }
        return _.padStart(hours, 1, '0') + ':' + _.padStart(minute, 2, '0');
    }

    getStatus(rowData) {
        if (rowData.status === 1) {
            return 'active';
        }
        if (rowData.status === 2 || rowData.status === 3) {
            return 'terminated';
        }
        if (rowData.status === 0) {
            return 'inactive';
        }
        if (rowData.status === 4) {
            return 'Blocked';
        }
    }

    isAdmin() {
        return this.authService.adminRole();
    }

    viewEmployee(d: Employee) {
        this.router.navigate(['app/employee', 'view', d.id]);
    }

    deleteEmployee(d: Employee) {
        this.showConfirmDialog = true;
        this.employeeSelected = d;
    }

    employmentAuthorization(employee: Employee) {
        this.crmCommService.executeCmd('employmentAuthorization', employee);
    }
    private voucherSourcetoPrint(source) {
        return '<html><head><script>function step1(){\n' +
            'setTimeout(\'step2()\', 10);}\n' +
            'function step2(){window.print();window.close()}\n' +
            '</scri' + 'pt></head><body onload=\'step1()\'>\n' +
            '<img src=\'' + source + '\' /></body></html>';
    }

    private voucherPrint(source) {
        const Pagelink = 'about:blank';
        const pwa = open(Pagelink, '_new');
        pwa.document.open();
        pwa.document.write(this.voucherSourcetoPrint(source));
        pwa.document.close();
    }

    printQR() {
        this.voucherPrint(this.selectedQREmployee.QRCode);
    }

    resetEmployeeSearchForm() {
        this.selectedCompany = null;
        this.selectedAgency = null;
        this.selectedCreatedBy = null;
        this.selectedType = null;
        this.textSearch = null;
        const date = new Date();
        this.fromDate = new Date(date.getFullYear(), date.getMonth(), 1);
        this.toDate = new Date();
        this.page = 0;
        this.table.reset();
        this.loadTimeHistoryLazy();
    }

    loadCompanyBasedOnAdminRole() {
        const loggedIn = this.authService.getUserInfo();
        if (loggedIn.role.level === RoleLevel.ROLE_COMPANY_ADMIN || loggedIn.role.level === RoleLevel.ROLE_AGENCY_ADMIN) {
            this.isCompanyDisabled = true;
            this.allCompanyList.map(company => {
                if (loggedIn.company && company.id === loggedIn.company.id) {
                    this.selectedCompany = company;
                    this.loadAgencyBasedOnCompanyId();
                }
            });
        }
    }

    loadAgencyBasedOnAdminRole() {
        const loggedIn = this.authService.getUserInfo();
        if (loggedIn.role.level === RoleLevel.ROLE_AGENCY_ADMIN) {
            this.isAgencyDisabled = true;
            this.allAgencyList.map(agency => {
                if (agency.id === loggedIn.agency.id) {
                    this.selectedAgency = agency;
                }
            });
        }
    }

    customSort(event) {
        if (this.sortF.length > 0) {
            if (event.data) {
                event.data.sort((data1, data2) => {
                    const value1 = data1[event.field];
                    const value2 = data2[event.field];
                    let result = null;

                    if (value1 == null && value2 != null) {
                        result = -1;
                    } else if (value1 != null && value2 == null) {
                        result = 1;
                    } else if (value1 == null && value2 == null) {
                        result = 0;
                    } else if (typeof value1 === 'string' && typeof value2 === 'string') {
                        result = value1.localeCompare(value2);
                    } else {
                        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
                    }

                    return (event.order * result);
                });
            }
            this.sortF = '';
        }
    }
    sortingClick(selectedSortOption) {
        if (this.selectedField === 'attachment') { this.selectedField = 'documentId'; }
        this.sortF = this.selectedField;
        const options = {
            field: this.selectedField,
            order: selectedSortOption === 'ASC' ? 1 : -1,
            mode: 'single',
            data: this.listData
        };
        this.customSort(options);
    }

    setSortOption(field, selectedSortOption?) {
        this.cols.forEach((item) => {
            if (field === item.field) {
                item.sortOptions = selectedSortOption;
                this.selectedSortOption = selectedSortOption;
            }
        });
    }

    sort(fieldName: string, order: number) {
        this.employees.sort((row1, row2) => {
            let val1 = row1[fieldName];
            let val2 = row2[fieldName];
            if (val1?.toLowerCase) {
                val1 = val1.toLowerCase().trim();
            }
            if (val2?.toLowerCase) {
                val2 = val2.toLowerCase().trim();
            }
            if (val1 === val2) {
                return 0;
            }
            let result = -1;
            if (val1 > val2) {
                result = 1;
            }
            if (order < 0) {
                result = -result;
            }
            return result;
        });
    }

    checkAddEmployeeEnable() {
        let menuList = this.authService.getLoggedInUserMenuList();
        return (menuList.indexOf('72') >= 0) ? true : false;
    }

    async exportEmployees() {
        this.table.first = 0;
        const options = <any>{};
        options.page = 0;
        options.size = 9999;
        let userInfo = this.authService.getUserInfo();
        let roleId = userInfo.role ? userInfo.role.id : null;
        if (!this.isPlatformAdmin) {
            options.companyId = this.authService.getCurrentCompanyId();
        }
        if (this.selectedCreatedBy) {
            options.createdBy = this.selectedCreatedBy;
        }
        if (this.selectedStatus) {
            options.status = this.selectedStatus;
        }
        this.employeeService.exportEmployee(options).subscribe(
            res => {
                const blob = FileUtility.b64toBlob(res.data, 'application/pdf');
                const blobUrl = URL.createObjectURL(blob);
                window.open(blobUrl, '_blank');
            }
        );
    }

    exportPdf() {
        if (this.listData && this.listData.length) {
            const data: any = {
                contentList: this.listData.map(value => {
                    return {
                        employeeName: value.employeeName,
                        payrollWeek: value.payrollWeek,
                        typeEmployee: this.getEmployeeType(value.typeEmployee),
                        totalWorkTime: value.totalWorkTime? this.hhmm1(value.totalWorkTime): '00:00',
                        lateness: value.totalLateTime?this.hhmm1(value.totalLateTime): '00:00',
                        overtime: value.overTime?this.hhmm1(value.overTime): '00:00',
                        balance: value.balanceHour?this.hhmm1(value.balanceHour): '00:00',
                    };
                })
            };
            data.companyId = !this.isPlatformAdmin? this.authService.getCurrentCompany(): (this.selectedCompany? this.selectedCompany.key: null);
            data.username = this.authService.getCurrentUsername();
            data.reportDate = this.datePipe.transform(new Date(), 'MM/dd/yyyy EEE hh:mm a');
            this._employeeClockingService.exportEmployeeTimeHistory(data).subscribe(res => {
                if (res.data) {
                    const blob = FileUtility.b64toBlob(res.data.body, 'application/pdf');
                    const blobUrl = URL.createObjectURL(blob);
                    window.open(blobUrl, '_blank');
                    let data = <any>{
                        featureName: 'Time History',
                        fileName: blobUrl,
                        fileSize: blob.size,
                        action: 'Export',
                        createdByUsr: this.authService.getCurrentUsername(),
                        companyId: this.authService.getCurrentCompanyId()
                    };
                    this.exportMonitorService.create(data).subscribe(()=>{
                    });
                } else {
                    this.messageService.add({severity: 'error', summary: this.translatePipe.transform('Export Error'), detail: this.translatePipe.transform('Error encountered while exporting PDF file, try again later. If this error persists, contact support.')});
                }
            });
        }
    }

    getEmployeeType(type) {
        switch (type) {
            case 'full_time':
                return 'Full Time';
            case 'part_time':
                return 'Part Time';
            case 'intern_paid':
                return 'Intern Paid';
            case 'intern_unpaid':
                return 'Intern Unpaid';
            case 'volunteer':
                return 'Volunteer';
            default:
                return type;
        }
    }

    sortAlphabet(alpha1: string, alpha2: string) {
        let alpha1ToUpperCase = alpha1 ? alpha1.toUpperCase() : '';
        let alpha2ToUpperCase = alpha2 ? alpha2.toUpperCase() : '';
        if (alpha1ToUpperCase > alpha2ToUpperCase) {
            return 1;
        } else {
            if (alpha1ToUpperCase === alpha2ToUpperCase) {
                return 0;
            }
            return -1;
        }
    }

    editDocument(rowData) {
        const employeeIds = _.map(this.employees, (e: Employee) => {
            return e.id;
        });
        this.crmCommService.setEmployeeList(employeeIds);
        this.crmCommService.executeCmd('edit_document', rowData);
    }

    ngAfterViewInit() {
        console.clear();
    }

    filterPunchHistory() {
        this.filteredPunchHistoryList = this.punchHistoryList.filter((punchHistory: EmployeeClocking) => {
            if (punchHistory.overTime > 0 &&  punchHistory.totalWorkTime === 0) {
                punchHistory.workHours = '00:00';
            } else if (punchHistory.overTime > 0 &&  punchHistory.totalWorkTime > 0) {
                punchHistory.workHours = punchHistory.totalWorkingStr;
            }
            punchHistory.workHourShadow = punchHistory.workHours;
            punchHistory.totalWorkTimeShadow = punchHistory.totalWorkTime;
            if (punchHistory.spreadHour && punchHistory.spreadHour > 0) {
                punchHistory.totalWorkTimeShadow = punchHistory.totalWorkTime - punchHistory.spreadHour;
                punchHistory.workHourShadow = this.hhmm(punchHistory.totalWorkTimeShadow / 60000);
                punchHistory.spreadHourText = 'Spread Hour';
            }
            return (!this.selectedCompany) || (this.selectedCompany && punchHistory.employee.companyId === this.selectedCompany.key);
        });
    }

    hhmm(minutes: number): string {
        const hours = Math.floor(minutes ? minutes / 60 : 0);
        const minute = minutes ? minutes % 60 : 0;
        if (!Number.isInteger(hours) || !Number.isInteger(minute)) {
            return minutes + '';
        }
        return _.padStart(hours, 1, '0') + ':' + _.padStart(minute, 2, '0');
    }

    async loadTimeHistoryLazy(event?: LazyLoadEvent) {
        this.table.reset();
        this.historyFilter.page = 0;
        this.historyFilter.size = 9999;
        if (event && event.sortField) {
            const sortOrder = <any>event['sortOrder'];
            const sortField = event['sortField'];
            this.historyFilter.sortOrder = sortOrder === 'ASC' || sortOrder === 1 ? 'ASC' : 'DESC';
            this.historyFilter.sortField = sortField;
        } else {
            this.historyFilter.sortField = 'createdAt';
            this.historyFilter.sortOrder = 'DESC';
        }
        this.historyFilter.listTypeEmployee = [];
        if (this.selectedType) {
            this.historyFilter.listTypeEmployee.push(this.selectedType);
        } else {
            this.historyFilter.listTypeEmployee = ['intern_paid', 'intern_unpaid', 'volunteer', 'employee'];
        }
        this.loading = true;
        this.route.queryParams.subscribe(params => {
            if (this.historyFilter.date) {
                if (this.historyFilter.date.startDate) {
                    this.historyFilter.fromDate = MOMENT(this.historyFilter.date.startDate).startOf('day').utc(true);;
                } else {
                    this.historyFilter.fromDate = MOMENT().startOf('day').utc(true);
                }
                if (this.historyFilter.date.endDate) {
                    this.historyFilter.toDate = MOMENT(this.historyFilter.date.endDate).endOf('day').utc(true);
                } else {
                    this.historyFilter.toDate = MOMENT().endOf('day').utc(true);
                }
            }
            this.historyFilter.isMissing = false;
            this.loadPage(this.historyFilter);
        });
    }

    async loadPage(options?: any) {
        if (this.fromDate) {
            this.historyFilter.fromDate = MOMENT(this.fromDate).startOf('day').utc(true);;
        }

        if (this.toDate) {
            this.historyFilter.toDate = MOMENT(this.toDate).endOf('day').utc(true);
        }
        if (this.textSearch) {
            this.historyFilter.key = this.textSearch;
        } else {
            this.historyFilter.key = null;
        }
        if (!options) {
            options = this.historyFilter;
        }
        if (!this.isPlatformAdmin) {
            options.companyId = this.authService.getCurrentCompany();
        } else {
            if (this.selectedCompany) {
                options.companyId = this.selectedCompany.key;
            } else {
                //options.companyId = this.authService.getCurrentCompany();
            }
        }

        this._employeeClockingService.getEmployeeTimeHistory(options).subscribe(
            resp => {
                this.listData = resp.data.content;
                this.filteredEmployees = resp.data.content;
                this.loading = false;
                this.totalRecords = resp.data.content.length;
                this.updateListData();
        });
    }

    filterEmployees() {
        this.table.reset();
        this.listData = this.filteredEmployees.filter((event: Employee) => {
            return (this.filterFirstName(event) || this.filterLastName(event)
                 || this.filterByFullName(event));
        });
        this.updateListData();
        this.totalRecords = this.listData.length;
    }
    filterByFullName(event) {
        return event.employeeName != null && !(event.employeeName.search(RegExp(this.textSearch, 'i')) === -1);
    }

    filterFirstName(event) {
        return event.firstName != null && !(event.firstName.search(RegExp(this.textSearch, 'i')) === -1);
    }

    filterLastName(event) {
        return event.lastName != null && !(event.lastName.search(RegExp(this.textSearch, 'i')) === -1);
    }

    updateListData() {
        if (this.listData) {
            let i = 0;
            for (let data of this.listData) {
                data.id = i;
                i++;
            }
        }
    }

    isDate(evt) {
        const charCode = (evt.which) ? evt.which : evt.keyCode;
        const val = evt.target?.value;
        if (val.length > 0) {
            if (val.length === 1) {
                if (48 <= charCode && charCode <= 57) {
                    return true;
                }
            } else if (val.length === 2 || val.length === 5) {
                if (charCode === 47) {
                    return true;
                }
            } else if (val.length === 3) {
                if (48 <= charCode && charCode <= 51) {
                    return true;
                }

            } else if (val.length === 4 || val.length === 7 || val.length === 8 || val.length === 9) {
                if (48 <= charCode && charCode <= 57) {
                    return true;
                }
            } else if (val.length === 6) {
                if (charCode === 49 || charCode === 50) {
                    return true;
                }
            } else {
                return false;
            }
        } else {
            if (charCode === 48 || charCode === 49) {
                return true;
            }
        }
        return false;
    }

}
