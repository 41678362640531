import { Component, OnDestroy, OnInit } from '@angular/core';
import {MenuItem, Message, MessageService} from 'primeng/api';
import { InternalRequest } from '../../../shared/model/internal-request';
import { CommService } from '../../../employee/performance/service/comm.service';
import { NavigationEnd, Router } from '@angular/router';
import { BreadcrumbService } from '../../../layout/service/breadcrumb.service';
import { takeUntil } from "rxjs/operators";
import { AuthService } from '../../../shared/service/auth/auth.service';
import { Subscription, Subject, interval } from 'rxjs';
import {TranslatePipe} from '@ngx-translate/core';

@Component({
    selector: 'app-transaction-type-layout',
    templateUrl: './transaction-type-layout.component.html',
    styleUrls: ['./transaction-type-layout.component.css']
})
export class TransactionTypeLayoutComponent implements OnInit, OnDestroy {
    loading = false;
    transactionTypeItems: MenuItem[] = [];
    subscription: Subscription;
    basicMenuItems = [];
    activeItem: MenuItem;
    private editTransactionTypeI = { label: 'EditTransactionType', routerLink: ['edit'], id: '0' };
    private editTransaction: MenuItem = { label: 'EditTransaction', icon: 'fa-pen-to-square', routerLink: ['edit'], id: '0' };
    defaultBreadCrumbs: MenuItem[] = [];
    private destroyed$ = new Subject<void>();
    messages: Message[] = [];
    initBreadCrumbs() {
        this.defaultBreadCrumbs = [];
        this.defaultBreadCrumbs.push({ label: 'Accounting' });
    }
    routerEventsSubscribe;
    constructor(private commService: CommService, private router: Router,
                private breadcrumbService: BreadcrumbService, private authService: AuthService,
                private messageService: MessageService,
                private translatePipe: TranslatePipe) {
        // this.router.events.subscribe(event => {
        //     if (event instanceof NavigationEnd) {
        //         // console.log(event.url);
        //         this.initBreadCrumbs();
        //         this.breadcrumbService.setItems(this.defaultBreadCrumbs);
        //     }
        // });
        const transactionTypeItem = { label: 'Transaction Types', id: 'transaction_type', routerLink: ['/', 'app', 'transaction-type', 'add'] };
        const transactionListItem = { label: 'Transactions', id: 'transaction_list', routerLink: ['/', 'app', 'transaction', 'list'] };
        const transactionAddItem = { label: 'AddTransaction', id: 'add_transaction', routerLink: ['/', 'app', 'transaction', 'add'] };
        const stateBalanceItem = { label: 'Statements & Balances', id: 'statement_balance', routerLink: ['/', 'app', 'statement-balance', 'list'] };
        const discountAndWaiveFee = { label: 'Discounts & Waivers', id: 'discount_fees_waived', routerLink: ['/', 'app', 'transaction', 'discount'] };
        const transactionGroup = { label: 'Add Group Transactions', id: 'transaction_group', routerLink: ['/', 'app', 'transaction', 'group'] };
        if (this.authService.getLoggedInUserMenuList().includes('83')) {
            this.transactionTypeItems.push(transactionListItem);
            this.basicMenuItems.push(transactionListItem);
        }
        if (this.authService.getLoggedInUserMenuList().includes('84')) {
            this.transactionTypeItems.push(transactionAddItem);
            this.basicMenuItems.push(transactionAddItem)
        }
        if (this.authService.getLoggedInUserMenuList().includes('82')) {
            this.transactionTypeItems.push(transactionTypeItem);
            this.basicMenuItems.push(transactionTypeItem);
        }
        if (this.authService.getLoggedInUserMenuList().includes('1672')) {
            this.transactionTypeItems.push(stateBalanceItem);
            this.basicMenuItems.push(stateBalanceItem);
        }
        if (this.authService.getLoggedInUserMenuList().includes('84')) {
            this.transactionTypeItems.push(discountAndWaiveFee);
            this.basicMenuItems.push(discountAndWaiveFee);
        }
        this.transactionTypeItems.push(transactionGroup);
        this.basicMenuItems.push(transactionGroup);
        
        this.activeItem = this.transactionTypeItems[0];
        this.routerEventsSubscribe = this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                if(event.url.indexOf('/app/transaction/list') >= 0) {
                    this.initBreadCrumbs();
                    this.defaultBreadCrumbs.push({label: 'Transactions'});
                } else if (event.url.indexOf('/app/transaction-type/edit') >= 0) {
                    const editTransactionType = { label: 'EditTransactionType', routerLink: ['edit'] };
                    this.transactionTypeItems = [];
                    this.transactionTypeItems.push(...this.basicMenuItems);
                    const obj = this.transactionTypeItems.find(emp => emp.label === editTransactionType.label);
                    // Check if this item is already edited
                    if (!obj) {
                        this.transactionTypeItems.push({ label: 'EditTransactionType', id: 'edit_transaction_type', routerLink: ['edit'] });
                    }
                } else if (event.url.indexOf('/app/transaction/list') >= 0) {
                    this.initBreadCrumbs();
                    this.defaultBreadCrumbs.push({ label: 'Transactions', routerLink: ['/', 'app', 'transaction', 'list'] });
                    this.transactionTypeItems = [];
                    this.transactionTypeItems.push(...this.basicMenuItems);

                }  else if (event.url.indexOf('/app/transaction/add') >= 0) {
                    this.initBreadCrumbs();
                    this.defaultBreadCrumbs.push({ label: 'AddTransaction', routerLink: ['/', 'app', 'transaction', 'add'] });
                    this.transactionTypeItems = [];
                    this.transactionTypeItems.push(...this.basicMenuItems);

                } else if (event.url.indexOf('/app/transaction-type/add') >= 0) {
                    this.initBreadCrumbs();
                    this.defaultBreadCrumbs.push({ label: 'Transaction Types', routerLink: ['/', 'app', 'transaction-type', 'add'] });
                    this.transactionTypeItems = [];
                    this.transactionTypeItems.push(...this.basicMenuItems);
                } else if (event.url.indexOf('/app/transaction/edit') >= 0) {
                    this.initBreadCrumbs();
                    this.defaultBreadCrumbs.push({ label: 'EditTransaction'});
                    this.transactionTypeItems = [];
                    this.transactionTypeItems.push(...this.basicMenuItems);
                    this.transactionTypeItems.push({ label: 'EditTransaction', id: 'edit_transaction', routerLink: ['edit'] });
                } else if (event.url.indexOf('/app/statement-balance/list') >= 0) {
                    this.initBreadCrumbs();
                    this.defaultBreadCrumbs.push({ label: 'Statements & Balances'});
                    this.transactionTypeItems = [];
                    this.transactionTypeItems.push(...this.basicMenuItems);
                } else if (event.url.indexOf('/app/transaction/discount') >= 0) {
                    this.initBreadCrumbs();
                    this.defaultBreadCrumbs.push({ label: 'Discounts & Waivers', routerLink: ['/', 'app', 'transaction', 'discount'] });
                    this.transactionTypeItems = [];
                    this.transactionTypeItems.push(...this.basicMenuItems);

                } else if (event.url.indexOf('/app/transaction/group') >= 0) {
                    this.initBreadCrumbs();
                    this.defaultBreadCrumbs.push({ label: 'Add Group Transaction', routerLink: ['/', 'app', 'transaction', 'group'] });
                    this.transactionTypeItems = [];
                    this.transactionTypeItems.push(...this.basicMenuItems);

                } else if (event.url.indexOf('/app/transaction/group/edit') >= 0) {
                    this.initBreadCrumbs();
                    this.defaultBreadCrumbs.push({ label: 'Edit Group Transaction', routerLink: ['/', 'app', 'transaction', 'group', 'edit'] });
                    this.transactionTypeItems = [];
                    this.transactionTypeItems.push(...this.basicMenuItems);

                } else {
                    this.initBreadCrumbs();
                    this.transactionTypeItems = [];
                    this.transactionTypeItems.push(...this.basicMenuItems);
                }

                setTimeout(() => {
                    this.breadcrumbService.setItems(this.defaultBreadCrumbs);
                }, 1000);
            }
        });
    }

    ngOnInit() {
        this.subscribeMessage();
        this.subscription = this.commService.announced$.subscribe(
            (data: InternalRequest) => {
                if (data.cmd === 'edit_transaction-type') {
                    // this.editTransactionType(data.cmd);
                } else if (data.cmd === 'show_spin') {
                    setTimeout(() => {
                        this.loading = true;
                    }, 0);
                } else if (data.cmd === 'hide_spin') {
                    setTimeout(() => {
                        this.loading = false;
                    }, 0);
                } else if (data.cmd === 'edit_transaction') {
                    this.editTransactionData(data.data);
                } else if (data.cmd === 'edit_transaction_group') {
                    this.editTransactionGroupData(data.data);
                }
            }
        );
    }

    editTransactionData(data) {
        // const editTransactions = { ...this.editTransaction, id: data.transactionId };
        // this.transactionTypeItems.push(editTransactions);
        if (!this.authService.getLoggedInUserMenuList().includes('84')) {
            this.messageService.add({ severity: 'error',
                summary: this.translatePipe.transform('error'),
                detail: this.translatePipe.transform('message.permission') });
            return;
        }
        this.router.navigate(['/app/transaction/edit', data.transactionId]);
    }

    editTransactionGroupData(data) {
        // const editTransactions = { ...this.editTransaction, id: data.transactionId };
        // this.transactionTypeItems.push(editTransactions);
        if (!this.authService.getLoggedInUserMenuList().includes('84')) {
            this.messageService.add({ severity: 'error',
                summary: this.translatePipe.transform('error'),
                detail: this.translatePipe.transform('message.permission') });
            return;
        }
        this.router.navigate(['/app/transaction/group/edit', data.transactionId]);
    }

    private subscribeMessage() {
        this.commService.message$
            .pipe(
                takeUntil(this.destroyed$)
            )
            .subscribe((message: Message) => {
                this.messages.push(message);
            });

        interval(5000)
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() => {
                if (this.messages.length) {
                    this.messages.shift();
                }
            });
    }
    editTransactionType(data) {
        const editEmployee = { ...this.editTransactionTypeI, id: data.id };
        const index = this.transactionTypeItems.map((emp) => emp.id).indexOf(data.id);
        // Check if this item is already edited
        if (index === -1) {
            this.transactionTypeItems.push(editEmployee);
        }

        this.router.navigate(['/app/transactionType/edit', data.id]);
    }
    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        if (this.routerEventsSubscribe) {
            this.routerEventsSubscribe.unsubscribe();
        }
    }

}
