<p-toast position="bottom-right">{{msgs}}</p-toast>
	<h1 *ngIf="formMode === 'create'" class="m-0 ml-2 my-4">{{ 'Holidays' | translate}}</h1>
	<h1 *ngIf="formMode === 'update'" class="m-0 ml-2 my-4">{{ 'Edit Holiday' | translate}}</h1>
	<p-panel [toggleable]="true">
		<p-header class="d-flex flex-grow-1 align-items-center justify-content-between">
			<span><i class="fa-solid fa-circle-plus text-primary mr-2"></i>{{formMode == 'create'? ('Add Holiday' | translate) : 'Edit Holiday' | translate}}</span>
			<span class="ci-history c-pointer cursor-pointer mr-4" *ngIf="this.formMode === 'update'" (click)="getHistory()"><i class="fa-solid fa-clock-rotate-left text-primary"></i> {{'history.title' | translate}}</span>
		</p-header>
		<div class="ui-g">	
			<div [formGroup]="searchForm" class="ui-g-6 ui-sm-12" *ngIf="isPlatformAdmin">
				<label>{{'Company' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-building"></i>
						</button>
					</div>
					<p-dropdown [options]="companyList" id="company" placeholder="{{'pleaseSelect' | translate}}" [filter]="true" formControlName="company" [(ngModel)]="companyId" (onChange)="changeCompany($event)">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>	
			</div>	
			<div [formGroup]="holidayForm" #f="ngForm" class="ui-g-6 ui-sm-12">
				<label for="holidayName">{{'Holiday' | translate}}:<span class="required-indicator">*</span></label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-quote-left"></i>
						</button>
					</div>
					<input maxlength="50" type="text" class="form-control" pInputText id="holidayName" formControlName="holidayName" placeholder="{{'Enter Text' | translate}}"/>
				</div>
				<div class="text-danger error" *ngIf="holidayForm.get('holidayName').invalid && holidayForm.get('holidayName').touched">
					<span>{{'This field is required' | translate}}</span>
				</div>
			</div>
			<div [formGroup]="holidayForm" #f="ngForm" class="ui-g-6 ui-sm-12">
				<label for="status">{{'Logic' | translate}}<span class="required-indicator">*</span></label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-info"></i>
						</button>
					</div>
					<p-dropdown id="holidayLogic" [options]="optionLogic" formControlName="holidayLogic" [autoDisplayFirst]="false" (onChange)="changeLogic($event)">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>
				<div class="text-danger error" *ngIf="holidayForm.get('holidayLogic').invalid && holidayForm.get('holidayLogic').touched">
					<span>{{'This field is required' | translate}}</span>
				</div>
			</div>

			<div [formGroup]="holidayForm" #f="ngForm" class="ui-g-6 ui-sm-12" *ngIf="holidayForm.get('holidayLogic').value === 'Day'">
				<label for="status">{{'Month' | translate}}<span class="required-indicator">*</span></label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-info"></i>
						</button>
					</div>
					<p-dropdown id="month" [options]="optionMonth" formControlName="month" [autoDisplayFirst]="false">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>
				<div class="text-danger error" *ngIf="holidayForm.get('month').invalid && holidayForm.get('month').touched">
					<span>{{'This field is required' | translate}}</span>
				</div>
			</div>
			<div [formGroup]="holidayForm" #f="ngForm" class="ui-g-6 ui-sm-12" *ngIf="holidayForm.get('holidayLogic').value === 'Day'">
				<label for="status">{{'Every' | translate}}<span class="required-indicator">*</span></label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-info"></i>
						</button>
					</div>
					<p-dropdown id="holiday" [options]="optionEvery" formControlName="day" [autoDisplayFirst]="false">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>
				<div class="text-danger error" *ngIf="holidayForm.get('day').invalid && holidayForm.get('day').touched">
					<span>{{'This field is required' | translate}}</span>
				</div>
			</div>

			<div [formGroup]="holidayForm" #f="ngForm" class="ui-g-6 ui-sm-12" *ngIf="holidayForm.get('holidayLogic').value === 'Day'">
				<label for="status">{{'Day Of Week' | translate}}<span class="required-indicator">*</span></label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-info"></i>
						</button>
					</div>
					<p-dropdown id="dayOfWeek" [options]="optionDayOfWeek" formControlName="dayOfWeek" [autoDisplayFirst]="false">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>
				<div class="text-danger error" *ngIf="holidayForm.get('dayOfWeek').invalid && holidayForm.get('dayOfWeek').touched">
					<span>{{'This field is required' | translate}}</span>
				</div>
			</div>
			<div [formGroup]="holidayForm" #f="ngForm" class="ui-g-6 ui-sm-12" *ngIf="holidayForm.get('holidayLogic').value === 'Month & Day'">
				<label for="date">{{'form.date' | translate}}<span class="required-indicator">*</span></label>
				<div class="input-group">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-calendar-day"></i>
						</button>
					</div>
					<mat-form-field class="w-100">
						<input class="w-100" matInput (focus)="picker.open()" [matDatepicker]="picker"  name="holidayDate" formControlName="holidayDate">
						<mat-datepicker #picker ></mat-datepicker>
					</mat-form-field>
				</div>
				<div class="text-danger error" *ngIf="holidayForm.get('holidayDate').invalid && holidayForm.get('holidayDate').touched">
					<span>{{'This field is required' | translate}}</span>
				</div>
			</div>

			<div [formGroup]="holidayForm" #f="ngForm" class="ui-g-6 ui-sm-12">
				<label for="status">{{'form.status' | translate}}<span class="required-indicator">*</span></label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-info"></i>
						</button>
					</div>
					<p-dropdown id="status" [options]="searchStatus" formControlName="status" placeholder="{{'placeholder.selectaStatus' | translate}}" [autoDisplayFirst]="false">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>
				<div class="text-danger error" *ngIf="holidayForm.get('status').invalid && holidayForm.get('status').touched">
					<span>{{'This field is required' | translate}}</span>
				</div>
			</div>
			<div class="ui-g-12 text-center py-4">
				<button *ngIf="formMode === 'create'" class="btn btn-danger mx-1" (click)="onCancel($event)" ><i class="fa-solid fa-xmark mr-2"></i>{{'button.reset' | translate}}</button>
				<button *ngIf="formMode === 'create'"class="btn btn-primary mx-1" (click)="onSave($event)" [disabled]="holidayForm.invalid || searchForm.invalid" ><i class="fa-solid fa-check mr-2"></i>{{'button.submit' | translate}}</button>
				<button *ngIf="formMode === 'update'" class="btn btn-danger mx-1" (click)="onCancel($event)" ><i class="fa-solid fa-xmark mr-2"></i>{{'button.cancel' | translate}}</button>
				<button *ngIf="formMode === 'update'" class="btn btn-primary mx-1" (click)="onSave($event)" [disabled]="holidayForm.invalid || searchForm.invalid" ><i class="fa-solid fa-check mr-2"></i>{{'Update' | translate}}</button>
			</div>
		</div>
	</p-panel>
	<div class="d-flex justify-content-between align-items-center flex-wrap py-4">
		<h1 class="m-0 ml-2">{{'Browse Holidays' | translate}}</h1>
		<button class="btn btn-primary" pTooltip="{{'Add' | translate}}" (click)="addHoliday()" [disabled]="formMode === 'update'" tooltipPosition="left"><i class="fa-solid fa-plus"></i></button>
	</div>
	<p-panel id="table-ppanel" [toggleable]="true">
		<p-header class="flex-grow-1">
			<span><i class="fa-solid fa-table text-primary mr-2"></i>{{'Holidays' | translate}} ({{table?._totalRecords || 0 | number}})</span>
		</p-header>
		<p-table #table [value]="holidayList" (onFilter)="onFilter($event, table)" [paginator]="true" [rowsPerPageOptions]="[10, 25, 50]" [totalRecords]="table?._totalRecords" (onLazyLoad)="loadAllHolidayList($event)" [lazy]="false" [responsive]="true" [rows]="10" dataKey="id"
				 [globalFilterFields]="['holidayDateStr', 'createdAtStr', 'id', 'holidayName', 'companyName', 'every']">
			<ng-template pTemplate="caption">
				<div class="d-flex align-items-center justify-content-between">
					<div class="d-flex align-items-center">
						<button type="button" (click)="exportPdf();" class="btn btn-primary mx-1">
							<i class="fa-solid fa-print mr-2"></i> {{'Export PDF' | translate}}
						</button>
					</div>
					<div class="input-group" style="max-width: 400px">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-filter"></i>
							</button>
						</div>
						<input type="text" [(ngModel)]="searchText" pInputText size="50" placeholder="{{'Filter' | translate}}" (input)="table.filterGlobal($event?.target?.value, 'contains')">
					</div>
				</div>
			</ng-template>
			<ng-template pTemplate="header" let-columns>
				<tr>
					<th class="adjust-padding" pResizableColumn *ngFor="let col of cols">
						<ng-container [ngSwitch]="col.field">
							<span *ngSwitchDefault>
								<span class="d-flex align-items-center justify-content-between w-100"> 
									<span class="one-liner" pTooltip="{{col.label | translate}}" tooltipPosition="left">{{col.label | translate}}</span>
									<span *ngIf="col.sort">
										<span (click)="col.sort == 'number'? op2.toggle($event) : op1.toggle($event); selectedField = col.field;">
											<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
										</span>
									</span>
								</span>
							</span>
							<span *ngSwitchCase="'createdAt'">
								<span class="d-flex align-items-center justify-content-between w-100"> 
									<span class="one-liner" pTooltip="{{col.label | translate}}" tooltipPosition="left">{{col.label | translate}}</span>
									<span *ngIf="col.sort">
										<span (click)="col.sort == 'number'? op3.toggle($event) : op1.toggle($event); selectedField = col.field;">
											<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
										</span>
									</span>
								</span>
							</span>
							<span *ngSwitchCase="'status'">
								<i class="fa-solid fa-info text-muted m-auto" pTooltip="{{'Status' | translate}}" tooltipPosition="left"></i>
							</span>
							<span *ngSwitchCase="'actions'">
								<i class="fa-solid fa-bars text-muted m-auto" pTooltip="{{'Actions' | translate}}" tooltipPosition="left"></i>
							</span>
						</ng-container>
					</th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-rowData>
				<tr [pSelectableRow]="rowData" >
					<td *ngFor="let col of cols">
						<ng-container [ngSwitch]="col.field">
							<span *ngSwitchDefault>
								<span class="one-liner">
									<span class="mobile-table-label">{{'ID' | translate}}: </span>
									<span *ngIf="this.rowData[col.field]" pTooltip="{{ rowData[col.field] }}">{{ rowData[col.field] }}</span>
									<span *ngIf="!this.rowData[col.field]" class="text-muted">{{ 'no data' | translate }}</span>
								</span>
							</span>
							<span *ngSwitchCase="'companyName'">
								<span class="one-liner">
									<span class="mobile-table-label">{{'Company' | translate}}: </span>
									<span *ngIf="this.rowData[col.field]" pTooltip="{{ rowData[col.field] }}">{{ rowData[col.field] }}</span>
									<span *ngIf="!this.rowData[col.field]" class="text-muted">{{ 'no data' | translate }}</span>
								</span>
							</span>
							<span *ngSwitchCase="'holidayName'">
								<span class="one-liner">
									<span class="mobile-table-label">{{'Holiday' | translate}}: </span>
									<span *ngIf="this.rowData[col.field]" pTooltip="{{ rowData[col.field] }}">{{ rowData[col.field] }}</span>
									<span *ngIf="!this.rowData[col.field]" class="text-muted">{{ 'no data' | translate }}</span>
								</span>
							</span>
							<span *ngSwitchCase="'holidayDate'">
								<span class="one-liner">
									<span class="mobile-table-label">{{'Date' | translate}}: </span>
									<!-- <span *ngIf="rowData.holidayDate" pTooltip="{{setDate(rowData.holidayDate) | date: 'MMMM d' }}">{{setDate(rowData.holidayDate) | date: 'MMMM d' }}</span> -->
									<span *ngIf="rowData.holidayDate" pTooltip="{{setDate(rowData.holidayDate) | date: 'yyyy MMMM d' }}">{{setDate(rowData.holidayDate) | date: 'yyyy MMMM d' }}</span>
									<span *ngIf="!rowData.holidayDate" class="text-muted">{{ 'no data' | translate }}</span>
								</span>
							</span>

							<span *ngSwitchCase="'every'">
								<span class="one-liner">
									<span class="mobile-table-label">{{'Date' | translate}}: </span>
									<span *ngIf="rowData.every" pTooltip="{{rowData.every}}">{{rowData.every}}</span>
									<span *ngIf="!rowData.every" class="text-muted">{{ 'no data' | translate }}</span>
								</span>
							</span>
							<span *ngSwitchCase="'createdAt'">
								<span class="one-liner">
									<span class="mobile-table-label">{{'Added Date' | translate}}: </span>
									<span *ngIf="rowData.createdAt" pTooltip="{{rowData.createdAt | date: 'MM/dd/yyyy hh:mm:ss a' }}">{{rowData.createdAt | date: 'MM/dd/yyyy hh:mm:ss a' }}</span>
									<span *ngIf="!rowData.createdAt" class="text-muted">{{ 'no data' | translate }}</span>
								</span>
							</span>
							<span *ngSwitchCase="'status'">
								<span class="one-liner">
									<span class="mobile-table-label">{{'Status' | translate}}: </span>
									<i *ngIf="rowData[col.field] == 'ACTIVE'" class="fa-solid fa-circle-check text-success" pTooltip="{{'Active' | translate}}"></i>
									<i *ngIf="rowData[col.field] != 'ACTIVE'" class="fa-solid fa-circle-xmark text-danger" pTooltip="{{'Inactive' | translate}}"></i>
								</span>
							</span>
							<span *ngSwitchCase="'actions'">
								<span class="one-liner">
									<span class="mobile-table-label">{{'Actions' | translate}}: </span>
									<i class="fa-solid text-primary fa-pen-to-square c-pointer mx-1" (click)="onEditHoliday($event, rowData)" pTooltip="{{'View/Edit' | translate}}"></i>
									<i class="fa-solid fa-xmark text-danger c-pointer mx-1" (click)="onDeleteHoliday($event, rowData)" pTooltip="{{'Delete' | translate}}"></i>
								</span>
							</span>
						</ng-container>
					</td>
				</tr>
			</ng-template>
			<ng-template pTemplate="emptymessage" let-columns>
				<tr *ngIf="!loading">
					<td [attr.colspan]="6" class="text-center py-5">
						<div class="text-muted my-5">{{'no data' | translate}}</div>
					</td>
				</tr>
				<tr *ngIf="loading">
					<td [attr.colspan]="6" class="text-center py-5">
						<div class="text-muted my-5"><i class="fa-solid fa-spinner mr-2 loadingHourglass"></i>{{'Loading...' | translate}}</div>
					</td>
				</tr>
			</ng-template>
		</p-table>
	</p-panel>
	<p-overlayPanel #op1 appendTo="body">
		<p-listbox [options]="textSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortingClick(selectedSortOption)">
			<ng-template let-col pTemplate="item">
				<div class="d-flex align-items-center">
					<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
					<span>{{col.name | translate}}</span>
				</div>
			</ng-template>
		</p-listbox>
	</p-overlayPanel>
	<p-overlayPanel #op2 appendTo="body">
		<p-listbox [options]="numberSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortingClick(selectedSortOption)">
			<ng-template let-col pTemplate="item">
				<div class="d-flex align-items-center">
					<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
					<span>{{col.name | translate}}</span>
				</div>
			</ng-template>
		</p-listbox>
	</p-overlayPanel>
	<p-overlayPanel #op3 appendTo="body">
		<p-listbox [options]="dateSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortingClick(selectedSortOption)">
			<ng-template let-col pTemplate="item">
				<div class="d-flex align-items-center">
					<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
					<span>{{col.name | translate}}</span>
				</div>
			</ng-template>
		</p-listbox>
	</p-overlayPanel>

<p-dialog *ngIf="addHolidayModal" id="addHolidayModal" [modal]="true" [(visible)]="addHolidayModal" [dismissableMask]="true" showEffect="fade" styleClass="w-75" appendTo="body" [style]="{'width':'90%', 'max-width':'500px'}">
	<p-header>
		<span><i class="fa-solid fa-circle-plus mr-2"></i>{{'Add Holiday' | translate}}</span>
	</p-header>
	<div class="ui-g pb-3">	
		<div [formGroup]="searchForm" class="ui-g-6 ui-sm-12" *ngIf="isPlatformAdmin && !hide">
			<label>{{'Company' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-building"></i>
					</button>
				</div>
				<p-dropdown [options]="companyList" id="company" placeholder="{{'pleaseSelect' | translate}}" [filter]="true" formControlName="company" [(ngModel)]="companyId" (onChange)="changeCompany($event)">
					<ng-template pTemplate="item" let-item>
						<span class="text-truncate">{{item.label | translate}}</span>
					</ng-template>
					<ng-template let-item pTemplate="selectedItem">
						<span class="one-liner">{{item.label | translate}}</span>
					</ng-template>		
				</p-dropdown>
			</div>
		</div>	
		<div [formGroup]="holidayForm" #f="ngForm" class="ui-g-6 ui-sm-12">
			<label for="holidayName">{{'Holiday' | translate}}<span class="required-indicator">*</span></label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-snowman"></i>
					</button>
				</div>
				<input pInputText type="text" class="form-control" placeholder="{{'Enter Text' | translate}}" id="holidayName" formControlName="holidayName" />
			</div>
			<div class="text-danger error" *ngIf="holidayForm.get('holidayName').invalid && holidayForm.get('holidayName').touched">
				<span>{{'This field is required' | translate}}</span>
			</div>
		</div>
		<div [formGroup]="holidayForm" #f="ngForm" class="ui-g-6 ui-sm-12">
			<label for="date">{{'form.date' | translate}}<span class="required-indicator">*</span></label>
			<div class="input-group">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-calendar-day"></i>
					</button>
				</div>
				<mat-form-field class="w-100">
					<input type="text" class="w-100" matInput (focus)="picker.open()" [matDatepicker]="picker" name="holidayDate" formControlName="holidayDate">
					<mat-datepicker #picker></mat-datepicker>
				</mat-form-field>
			</div>
			<div class="text-danger error" *ngIf="holidayForm.get('holidayDate').invalid && holidayForm.get('holidayDate').touched">
				<span>{{'This field is required' | translate}}</span>
			</div>
		</div>
		<div [formGroup]="holidayForm" #f="ngForm" class="ui-g-6 ui-sm-12">
			<label for="status">{{'form.status' | translate}}<span class="required-indicator">*</span></label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-info"></i>
					</button>
				</div>
				<p-dropdown id="status" [options]="searchStatus" formControlName="status" placeholder="{{'placeholder.selectaStatus' | translate}}">
					<ng-template pTemplate="item" let-item>
						<span class="text-truncate">{{item.label | translate}}</span>
					</ng-template>
					<ng-template let-item pTemplate="selectedItem">
						<span class="one-liner">{{item.label | translate}}</span>
					</ng-template>	
				</p-dropdown>
			</div>
			<div class="text-danger error" *ngIf="holidayForm.get('status').invalid && holidayForm.get('status').touched">
				<span>{{'This field is required' | translate}}</span>
			</div>
		</div>
	</div>
	<p-footer class="d-flex justify-content-between">
		<button class="btn btn-danger" (click)="closeHoliday()"><i class="fa-solid fa-check mr-2"></i>{{'Cancel' | translate}}</button>
		<button class="btn btn-primary mx-1" (click)="onSave($event)" [disabled]="holidayForm.invalid || searchForm.invalid" ><i class="fa-solid fa-check mr-2"></i>{{'button.submit' | translate}}</button>
	</p-footer>
</p-dialog>

<p-dialog *ngIf="historyShow" [modal]="true" [(visible)]="historyShow" [dismissableMask]="true" showEffect="fade" styleClass="w-75" appendTo="body">
	<p-header>
		<span><i class="fa-solid fa-clock-rotate-left mr-2"></i>{{'Edit History' | translate}}</span>
	</p-header>
	<app-holiday-history [holidayId]="edittingHoliday.id"></app-holiday-history>
	<p-footer>
		<button class="btn btn-primary" (click)="historyShow = false"><i class="fa-solid fa-check mr-2"></i>{{'Done' | translate}}</button>
	</p-footer>
</p-dialog>

<p-dialog [(visible)]="displayDialog" [modal]="true" [style]="{'width':'90%', 'max-width':'500px'}">
	<p-header>
		<span><i class="fa-solid fa-triangle-exclamation mr-2"></i>{{'Delete Item' | translate}}</span>
	</p-header>
   <div class="ui-g">
		<div class="ui-g-12">
			<span style="font-size: 16px;">{{'Are you sure you want to delete this item?' | translate}}</span>
		</div>
	</div>
    <p-footer class="d-flex align-items-center justify-content-between">
		<button type="button" (click)="onReject()" class="btn btn-danger"><i class="fa-solid fa-xmark mr-2"></i>{{'Cancel' | translate}}</button>
		<button type="button" (click)="onConfirm()" class="btn btn-primary"><i class="fa-solid fa-check mr-2"></i>{{'Confirm' | translate}}</button>
    </p-footer>
</p-dialog>
