import { DatePipe, registerLocaleData } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { LazyLoadEvent, MenuItem, Message, SelectItem, MessageService } from 'primeng/api';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Table } from 'primeng/table';
import { DepartmentService } from '../../../department/service/department.service';
import { DepartmentServicev2 } from '../../../department/service/v2/department.v2.service';
import { Employee } from '../../../employee/model/employee.model';
import { AuthService } from '../../../shared/service/auth/auth.service';
import { EmployeeClockingService } from '../../service/employee-clocking.service';
import { EmployeeClocking } from '../../model/employee-clocking.model';
import { EmployeeClockingV2, EmployeePunchRequest, EmployeePunchResponse } from '../../model/v2/employee.clocking';
import { WindowRefService } from '../../../shared/service/window-ref/window-ref.service';
import * as MOMENT from 'moment';
import * as moment from 'moment';
import { saveAs as importedSaveAs } from 'file-saver';
import { PayrollSettingService } from '../../../payroll-setting/service/payroll-setting.service';
import { CompanyService } from '../../../company/service/company.service';
import { AgencyService } from '../../../agency/service';
import { first } from 'rxjs/operators';
import * as _ from 'lodash';
import { NoteHistoryService } from '../../../crm/service/note-history.service';
import { Constants } from '../../../shared/model/constants';
import { EditedClockingBeyondSettings } from '../../model/edited.clocking-beyond-settings.model';
import { EmployeeService } from '../../../employee/service/employee.service';
import { UtilsService } from '../../../shared/service/utils.service';
import { NotificationType, NotificationService } from '../../../crm/service/notification.service';
import { EmployeeClockingServiceV2 } from '../../service/v2/employee.clocking.v2.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { TranslatePipe } from '@ngx-translate/core';
import { PayrollSetting } from 'app/payroll-setting/model/payroll-setting.model';
import { MonitoringDetailsService } from 'app/admin/process-monitor/service/monitoring-details.service';
import { EmployeeClockingTrackingServiceV2 } from '../../service/v2/employee.clocking.tracking.v2.service';
import { PunchType } from '../../model/v2/punch.enum';
import { FileUtility } from '../../../shared/utility/file.utility';

declare let $: any;

@Component({
    selector: 'app-punch-in-out-tracking',
    templateUrl: './punch-in-out-tracking.component.html',
    styleUrls: ['./punch-in-out-tracking.component.scss'],
    providers: [EmployeeClockingService, EmployeeClockingServiceV2, WindowRefService, PayrollSettingService, MessageService]
})
export class PunchInOutTrackingComponent implements OnInit, OnDestroy {

    constructor(private router: Router, private route: ActivatedRoute,
        private employeeService: EmployeeService,
        private employeeClockingService: EmployeeClockingService,
        private payrollSettingService: PayrollSettingService,
        private noteHistoryService: NoteHistoryService,
        private windowRef: WindowRefService,
        private companyService: CompanyService,
        private messageService: MessageService,
        private agencyService: AgencyService,
        private datePipe: DatePipe,
        private notificationService: NotificationService,
        private utilsService: UtilsService,
        private departmentService: DepartmentService,
        private departmentServiceV2: DepartmentServicev2,
        private _employeeClockingService: EmployeeClockingServiceV2,
        private employeeClockingTrackingServiceV2: EmployeeClockingTrackingServiceV2,
        private authService: AuthService,
        private translatePipe: TranslatePipe) {
    }
    fromDate: any;
    toDate: any;
    punchHistoryList: Array<any>;
    selectedPunchHistory: EmployeeClockingV2 = new EmployeeClockingV2();
    employeeClocking: EmployeeClockingV2 = new EmployeeClockingV2();
    totalRecords: Number = 0;
    items: MenuItem[];
    msgs: Message[] = [];
    loading = false;
    historyData = [];
    payrollSetting: PayrollSetting;
    add = 'fa-solid fa-plus';
    size: number;
    page: number;
    companyId: number;
    fromTimestamp: number;
    toTimestamp: number;
    rangeDates: any = {
        fromDate: null,
        toDate: null,
    };
    show = false;
    eventTemp: any;
    selectedFieldDisplay;
    fieldsName: any = [];
    types: Array<any> = [];
    employeeList: SelectItem[] = [];
    filteredClockingMissingList = <any>[];
    @ViewChild('dt', { static: true }) table: Table;
    @ViewChild('op1', { static: true }) op1: OverlayPanel;
    @ViewChild('op99', { static: true }) op99: OverlayPanel;
    sortField: string = 'createdAt';
    sortOrder: number = -1;
    reasonHeader: string;
    reasonNote: string;
    historyFilter: any = {
        employees: [],
        employeeIds: [],
        fromDate: null,
        toDate: null,
        page: 0,
        size: 10,
        key: '',
        date: [],
        clockAction: null,
        status: null,
    };
    rowData: any;
    selected: any;
    companies = [];
    allAgencies = [];
    agencies = [];
    selectedCompany: any;
    selectedAgency: any;
    selectedEmployee: any;
    selectedTimeClock: any = null;
    selectedWorkStatus: any = null;
    selectedDepartment: any;
    isEmployee: boolean;
    isPlatformAdmin = false;
    uniqueDataForLunchCalculation = <any>[];
    dataForCheckOut = <any>[];
    employeeAndDateWiseLunchTime = <any>[];
    clockActions = <any>[];
    timeClock = [];
    workStatus = [];
    departments: any;
    adminData: any;
    disableSendEmployee = true;
    textSortOptionsClient = [
        { name: 'Sort A To Z', value: 'ASC', img: 'arrow-down-a-z' },
        { name: 'Sort Z To A', value: 'DESC', img: 'arrow-up-z-a' }
    ];
    numberSortOptionsClient = [
        { name: 'Sort 1 To 9', value: 'ASC', img: 'arrow-down-1-9' },
        { name: 'Sort 9 To 1', value: 'DESC', img: 'arrow-up-9-1' }
    ];
    dateSortOptions = [
        { name: 'Sort 1 To 12', value: 'ASC', img: 'arrow-down-1-9' },
        { name: 'Sort 12 To 1', value: 'DESC', img: 'arrow-up-9-1' }
    ];
    selectedSortOption = '';
    selectedField = '';
    isEditAble = false;
    totalHours = 0;
    companyDisabled = false;
    agencyDisabled = false;
    emplAbsent: string;
    emplClockedIn: string;
    emplOutForLunch: string;
    emplEndWork: string;
    employeeClockingOutForLunchs: EmployeeClocking[] = [];
    employeeAbsents: any;
    totalClockedIn: any;
    textSearch: any;
    ranges: any = {
        today: [MOMENT(), MOMENT()],
        Yesterday: [MOMENT().subtract(1, 'days'), MOMENT().subtract(1, 'days')],
        Last7Days: [MOMENT().subtract(6, 'days'), MOMENT()],
        Last30Days: [MOMENT().subtract(29, 'days'), MOMENT()],
        ThisMonth: [MOMENT().startOf('month'), MOMENT().endOf('month')],
        LastMonth: [MOMENT().subtract(1, 'month').startOf('month'), MOMENT().subtract(1, 'month').endOf('month')],
    };
    invalidDates: MOMENT.Moment[] = [MOMENT().add(2, 'days'), MOMENT().add(3, 'days'), MOMENT().add(5, 'days')];

    timeSpent = new Date();
    exportingPDF = false;
    private routerSub = Subscription.EMPTY;

    showViewFaceImg = false;
    confirmingFaceImg = false;
    faceData: any = {};

    showViewDetectionFaceImg = false;
    detectionFaceImg;

    // cols = [
    //     {field: 'id', label: 'ID'},
    //     {field: 'employee.fullName', label: 'Full Name', sortOptions: '', sort: 'text'},
    //     {field: 'accountUsed', label: 'Username', sortOptions: '', sort: 'text'},
    //     {field: 'pin', label: 'PIN/FR', sortOptions: '', sort: 'text'},
    //     {field: 'details', label: 'Details', sortOptions: '', sort: 'text'},
    //     {field: 'locationName', label: 'Location', sortOptions: '', sort: 'text'},
    //     {field: 'action', label: 'Punch', sortOptions: '', sort: 'text'},
    //     {field: 'createdAt', label: 'Timestamp', sortOptions: '', sort: 'number'},
    //     {field: 'status', label: 'Status', sortOptions: '', sort: 'number'},
    //     {field: 'confirmationDate', label: 'Confirmation Date', sortOptions: '', sort: 'number'}
    // ];

    isInvalidDate = (m: MOMENT.Moment) => {
        return this.invalidDates.some(d => d.isSame(m, 'day'));
    }

    ngOnInit() {
        this.clockActions = [
            { label: 'All', value: null },
            { label: 'Start Work', value: PunchType.CLOCK_IN },
            { label: 'Out For Lunch', value: PunchType.LUNCH_OUT },
            { label: 'Back For Lunch', value: PunchType.LUNCH_IN },
            { label: 'End Work', value: PunchType.CLOCK_OUT },
            { label: 'Wrong Pin', value: PunchType.WRONG_PIN }
        ];
        this.selectedTimeClock = this.clockActions[0].value;
        this.workStatus = [{ label: 'ALL', value: 'all' },
        { label: 'Success', value: 'Success' },
        { label: 'Failed', value: 'Error' }];
        this.punchHistoryList = [];

        this.fromDate = new Date();
        this.fromDate.setDate(this.fromDate.getDate() - 7);
        this.toDate = new Date();
        this.isPlatformAdmin = this.authService.isSuper() || this.authService.isSubSuper();
        this.isEmployee = this.authService.isEmployeeRole();

        this.loadPunchHistoryLazy();
        this.loadCompanyAndAgencyDropdown();
        this.loadEmployeeList(null);
    }

    ngOnDestroy(): void {
        setTimeout(() => {
            this.routerSub.unsubscribe();
        }, 1000);
    }

    changeCompany(company) {
        if (company) {
            this.loadEmployeeList(company.key);
            this.historyFilter.companyId = company.key;
        }
        this.loadStat({});
    }

    translateRange() {
        const object: any = {};
        object[this.translatePipe.transform('today')] = [MOMENT(), MOMENT()];
        object[this.translatePipe.transform('Yesterday')] = [MOMENT().subtract(1, 'days'), MOMENT().subtract(1, 'days')];
        object[this.translatePipe.transform('Last7Days')] = [MOMENT().subtract(6, 'days'), MOMENT()];
        object[this.translatePipe.transform('Last30Days')] = [MOMENT().subtract(29, 'days'), MOMENT()];
        object[this.translatePipe.transform('ThisMonth')] = [MOMENT().startOf('month'), MOMENT().endOf('month')];
        object[this.translatePipe.transform('LastMonth')] = [MOMENT().subtract(1, 'month').startOf('month'), MOMENT().subtract(1, 'month').endOf('month')];
        return object;
    }

    loadCompanyAndAgencyDropdown() {
        this.utilsService.loadCompanyAndAgencyDropdown().then((res) => {
            const companyAgencyDropDown: any = res;
            this.companyDisabled = companyAgencyDropDown.companyDisabled;
            if (companyAgencyDropDown.companyResponse) {
                if (companyAgencyDropDown.companyResponse.length === 1) {
                    this.companies = companyAgencyDropDown.companyResponse;
                    this.selectedCompany = {};
                    this.selectedCompany.key = companyAgencyDropDown.companyResponse[0].id;
                    this.selectedCompany.value = companyAgencyDropDown.companyResponse[0].name;
                } else {
                    this.companies = companyAgencyDropDown.companyResponse;
                }
            }
            if (companyAgencyDropDown.companyResponse) {
                if (companyAgencyDropDown.companyResponse.length === 1) {
                    this.loadEmployeeList(companyAgencyDropDown.companyResponse[0].id);
                }
            }
        });
    }

    loadStat(option?: any) {
        option = option ? option : this.historyFilter;
        if (this.selectedCompany === undefined || this.selectedCompany == null) {
            if (this.isSuperAdmin()) {
                option.companyId = 0;
            } else {
                option.companyId = this.authService.getCurrentCompanyId();
            }
        } else {
            option.companyId = this.selectedCompany.key;
        }
        this.employeeClockingService.punchStatistic(option).subscribe(res => {
            if (res.data) {
                const statisticData: any = res.data;
                this.emplClockedIn = statisticData.totalClockedIn;
                this.emplAbsent = statisticData.totalAbsent > 0 ? statisticData.totalAbsent : 0;
                this.emplOutForLunch = statisticData.totalOutForLunch;
                this.emplEndWork = statisticData.totalEndWork;
                this.employeeClockingOutForLunchs = res.data.totalOutForLunchEmployeeList;
                this.employeeAbsents = res.data.employeeAbsent;
                this.totalClockedIn = res.data.totalClockedIn;
            }
        }, (_error) => {
            console.log(_error);
        });
    }

    timeClockChange(event) {
        if (event.value === 'all') {
            this.historyFilter.lateEarlier = null;
        } else {
            this.historyFilter.lateEarlier = event.value === 'onTime' ? '1' : '2';
        }
    }

    workStatusChange(event) {
        if (event.value === 'all') {
            this.historyFilter.typeAbsence = null;
        } else {
            this.historyFilter.typeAbsence = event.value;
        }
    }


    searchByDepartment(event: any) {
        if (event.value) {
            this.historyFilter.department = event.value;
            this.loadPage();
        }
    }

    async loadPunchHistoryLazy(event?: LazyLoadEvent) {
        this.size = event && event.rows ? event.rows : (this.size ? this.size : 25);
        this.page = event && typeof event.first !== 'undefined' && event.rows ? (event.first / event.rows) : (this.page ? this.page : 0);
        this.sortField = this.sortField ? this.sortField : event && event.sortField ? event.sortField : 'createdAt';
        this.sortOrder = this.sortOrder ? this.sortOrder : event && event.sortOrder ? event.sortOrder : -1;
        const order = this.sortOrder === 1 ? 'ASC' : 'DESC';
        this.historyFilter.page = this.page;
        this.historyFilter.size = this.size;
        this.historyFilter.sortField = this.sortField;
        this.historyFilter.sortOrder = order;
        this.loadPage(this.historyFilter);
    }

    async loadPage(options?: any) {
        this.loading = true;
        if (!options) {
            options = this.historyFilter;
        }
        options.size = 9999;
        options = this.buildOptionSearch(options);
        const resObj: any = await this.employeeClockingTrackingServiceV2.search(options).toPromise();
        if (resObj.status === 'SUCCESS') {
            resObj.data.content = _.compact(resObj.data.content);
            this.totalRecords = resObj.data.totalElements;
            this.punchHistoryList = resObj.data.content;
            this.punchHistoryList.forEach(item => {
                item.applicationName = item.userClockFrom === 'Computer'? 'Computer (web portal)': item.userClockFrom;
            });
            // this.punchHistoryList.forEach(item => {
            //     item.createdAtStr = this.datePipe.transform(item.createdAt, 'MM/dd/yyyy hh:mm a');
            //     // if (PunchType.CLOCK_IN === item.clockAction) {
            //     //     item.action = 'Start Work';
            //     // }
            //     // if (PunchType.LUNCH_OUT === item.clockAction) {
            //     //     item.action = 'Start Break';
            //     // }
            //     // if (PunchType.LUNCH_IN === item.clockAction) {
            //     //     item.action = 'End Break';
            //     // }
            //     // if (PunchType.CLOCK_OUT === item.clockAction) {
            //     //     item.action = 'End Work';
            //     // }
            //     // if (PunchType.WRONG_PIN === item.clockAction) {
            //     //     item.action = 'Wrong PIN';
            //     // }
            // });
            this.loading = false;
        }
    }

    getReasonTooltip(text: any) {
        if (text !== null && text.length > 0) {
            return text.substring(text.indexOf('-') + 2, text.length);
        }
        return '';
    }

    getHeaderReasonTooltip(text: any) {
        let data = '';
        if (text !== null && text.length > 0) {
            const type = text.substring(0, text.indexOf('-')).trim();
            switch (type) {
                case '1':
                    data = 'User forgot/refused to punch.';
                    break;
                case '2':
                    data = 'User not able to punch.';
                    break;
                case '3':
                    data = 'No location data provided.';
                    break;
                default:
                    data = 'Other';
            }
        }
        return data;
    }


    setLunchTime() {
        const arrayObj = _.uniqWith(this.uniqueDataForLunchCalculation, _.isEqual);
        this.employeeClockingService.getLunchHoursForAllEmployees(arrayObj).subscribe((res) => {
            this.employeeAndDateWiseLunchTime = res.valueOf();
        });
    }

    checkUpdateClockInPunchHistory(rowData: any) {
        return !this.authService.isEmployeeRole() && rowData.isAdminUpdateCheckIn;
    }

    checkUpdateClockOutPunchHistory(rowData: any) {
        return !this.authService.isEmployeeRole() && rowData.isAdminUpdateCheckOut;
    }

    checkUpdateOutForLunchPunchHistory(rowData: any) {
        return !this.authService.isEmployeeRole() && rowData.isAdminUpdateLunchOut;
    }

    checkUpdateLunchInPunchHistory(rowData: any) {
        return !this.authService.isEmployeeRole() && rowData.isAdminUpdateLunchIn;
    }

    hhmm(minutes: number): string {
        const hours = Math.floor(minutes ? minutes / 60 : 0);
        const minute = minutes ? minutes % 60 : 0;
        if (!Number.isInteger(hours) || !Number.isInteger(minute)) {
            return minutes + '';
        }
        return _.padStart(hours, 2, '0') + ':' + _.padStart(minute, 2, '0');
    }


    getTotalDay(punchHistoryList: Array<any>) {
        if (punchHistoryList) {
            return punchHistoryList.length.toString() + this.translatePipe.transform('Record');
        }
        return '0 ' + this.translatePipe.transform('Record');
    }

    mappingEmployeeCloking(employee: Employee, employeeClockings: EmployeeClocking[]) {
        const clockings: EmployeeClocking[] = [];
        employee.employeeClockings = [];
        employeeClockings.forEach(cloking => {
            if (cloking.employee.id === employee.id) {
                clockings.push(cloking);
            }
        });
        employee.employeeClockings = clockings;
    }

    getTotalHour(punchHistoryList: Array<any>) {
        let totalHours = 0;
        let totalOvertime = 0;
        _.forEach(punchHistoryList, (x) => {
            totalHours += x.totalWorkTime;
            totalOvertime += x.overTime;
        });
        const result = totalHours / 60000 - totalOvertime;
        return this.hhmm(Math.round(result));
    }


    getTotalOverrtime(punchHistoryList: Array<any>) {
        let totalHours = 0;
        _.forEach(punchHistoryList, (x) => {
            totalHours += x.overTime;
        });
        return this.hhmm(Math.round(totalHours));
    }

    setList(punchHistoryList: Array<any>, rowData: EmployeeClocking) {
        const list: EmployeeClocking[] = [];
        this.punchHistoryList.forEach(function (obj) {
            if (obj.employee.id === rowData.employee.id) {
                list.push(obj);
            }
        });
        rowData.list = list;
    }

    search(event) {
        const wordSearch = event.target.value;
        setTimeout(() => {
            if (wordSearch === event.target.value) {
                this.eventTemp = event;
                this.loading = true;
                this.table.first = 0;
                this.historyFilter.key = event.target.value;
                this.historyFilter.page = 0;
                this.historyFilter.size = this.table.rows;
                if (this.historyFilter.key) {
                    this.loadPage(this.historyFilter);
                } else {
                    this.historyFilter.key = '';
                    this.loadPage(this.historyFilter);
                }
            }
        }, 1000);
    }

    filterMissing() {
        this.historyFilter.isMissing = true;
        this.historyFilter.page = 0;
        this.historyFilter.ids = this.filteredClockingMissingList;
        this.loadPage(this.historyFilter);
    }

    searchBySelectedDate(date) {
        if (date.startDate && date.endDate) {
            this.table.first = 0;
            this.historyFilter.page = 0;
            this.historyFilter.size = this.table.rows;
            this.historyFilter.fromDate = date.startDate;
            this.historyFilter.toDate = MOMENT(date.endDate).endOf('day').utc(true);

            const empIds: number[] = [];

        }
    }

    showHour(date: Date) {
        if (date === null) {
            return 'Missing';
        }
        return MOMENT.utc(date).format('HH:mm');
    }

    showCheckOutTime(data: EmployeeClocking) {
        const tempData = [];
        let firstDate;
        let secondDate;
        let returnTime;
        if (data.clockOutTime === null) {
            returnTime = 'Missing';
        } else if (data.clockInTime != null) {
            this.dataForCheckOut.forEach((i) => {
                if (MOMENT(data.clockInTime).format('YYYY-MM-DD') === MOMENT(i.clockInTime).format('YYYY-MM-DD')) {
                    tempData.push(i);
                }
            });
            if (tempData[0].clockOutTime != null && tempData[1]) {
                firstDate = moment(tempData[0].clockOutTime);
                secondDate = moment(tempData[1].clockOutTime);
                if (secondDate > firstDate) {
                    returnTime = moment(secondDate).format('HH:mm');
                }
            }

        }
        return returnTime;
    }

    showDate(date: Date) {
        return MOMENT.utc(date).format('D/MM/YYYY ddd');
    }



    mapToDropdown(label, val) {
        return {
            label: label,
            value: val
        };
    }



    loadEmployeeList(company) {
        this.employeeList = [];
        const searchOption = <any>{};
        if (company != null) {
            searchOption.companyId = company;
        } else {
            searchOption.companyId = this.authService.getCurrentCompanyId();
        }
        searchOption.status = 1;
        this.employeeService.getAllsDropdown(searchOption).subscribe(resEmp => {
            const empObj: any = resEmp;
            const tempList: any[] = [];
            if (empObj.status === 'SUCCESS') {
                empObj.data.forEach(element => {
                    tempList.push({ label: element.fullName, value: element.key });
                });
            }
            this.employeeList = tempList;
        });

    }


    disableCompany(): boolean {
        return !(this.authService.isSuper() || this.authService.isSubSuper());
    }

    disableAgency(): boolean {
        return this.disableCompany() && !(this.authService.isCompanyAd() || this.authService.isSubCompanyAd());
    }

    disableEmployee(): boolean {
        return this.authService.isEmployeeRole();
    }

    dateToShowInTwelveHoursFormat(date) {
        if (date) {
            return moment(date).format('hh:mm a');
        } else {
            return '';
        }
    }

    sortingClick(selectedSortOption) {
        const options = {
            sortOrder: selectedSortOption,
            sortField: this.selectedField
        };
        this.sortField = this.selectedField;
        this.sortOrder = selectedSortOption === 'ASC' ? 1 : 0;
        //this.op1.hide();
        this.loadPunchHistoryLazy(options);
    }

    search2(event?: Event) {
        this.page = 0;
        // this.sortField = null;
        // this.sortOrder = null;
        if (!!this.historyFilter.sort) {
            delete this.historyFilter.sort;
        }
        if (!!this.historyFilter.order) {
            delete this.historyFilter.order;
        }
        this.loadPage(this.historyFilter);
    }

    reset() {
        const today = new Date();
        today.setMinutes(today.getMinutes() + today.getTimezoneOffset());
        this.fromDate = new Date();
        this.fromDate.setDate(this.fromDate.getDate() - 7);
        this.toDate = today;
        this.textSearch = null;
        this.table.reset();
        if (this.authService.isAgencyAd() || this.authService.isSubAgencyAd()) {
            this.selectedEmployee = null;
            this.selectedTimeClock = null;
            this.selectedWorkStatus = null;
            this.selectedDepartment = null;
            this.historyFilter = {
                employees: [],
                employeeIds: [],
                fromDate: null,
                toDate: null,
                ids: [],
                page: 0,
                size: 10,
                key: '',
                date: [moment().toDate(), moment().toDate()],
                lateEarlier: null,
                isMissing: false,
                type: 1,
                sortField: 'createdAt',
                sortOrder: 'DESC'
            };
        } else if (this.authService.isCompanyAd() || this.authService.isSubCompanyAd()) {
            // this.selectedCompany = null;
            this.selectedAgency = null;
            this.selectedEmployee = null;
            this.selectedTimeClock = null;
            this.selectedWorkStatus = null;
            this.selectedDepartment = null;
            this.historyFilter = {
                employees: [],
                employeeIds: [],
                fromDate: null,
                toDate: null,
                ids: [],
                page: 0,
                size: 10,
                key: '',
                date: [moment().toDate(), moment().toDate()],
                lateEarlier: null,
                isMissing: false,
                type: 1,
                sortField: 'createdAt',
                sortOrder: 'DESC'
            };
        } else if (this.authService.isEmployeeRole()) {
            this.selectedCompany = null;
            this.selectedAgency = null;
            this.selectedTimeClock = null;
            this.selectedWorkStatus = null;
            this.selectedDepartment = null;
            this.historyFilter = {
                employees: [],
                employeeIds: [],
                fromDate: null,
                toDate: null,
                ids: [],
                page: 0,
                size: 10,
                key: '',
                date: [moment().toDate(), moment().toDate()],
                lateEarlier: null,
                isMissing: false,
                type: 1,
                sortField: 'createdAt',
                sortOrder: 'DESC'
            };
            this.historyFilter.employeeIds.push(this.authService.getCurrentEmployee());

        } else {
            this.selectedCompany = null;
            this.selectedWorkStatus = null;
            this.selectedAgency = null;
            this.selectedTimeClock = null;
            this.selectedDepartment = null;
            this.authService.getCurrentEmployee();
            this.historyFilter = {
                employees: [],
                employeeIds: [],
                fromDate: null,
                toDate: null,
                ids: [],
                page: 0,
                size: 10,
                key: '',
                date: [moment().toDate(), moment().toDate()],
                lateEarlier: null,
                isMissing: false,
                type: 1,
                sortField: 'createdAt',
                sortOrder: 'DESC'
            };
        }
        this.historyFilter.fromDate = today;
        this.historyFilter.toDate = today;
        this.historyFilter.date.startDate = moment().toDate();
        this.historyFilter.date.endDate = moment().toDate();
        this.loadPage(this.historyFilter);
    }

    isSuperAdmin() {
        return this.authService.isSuper() || this.authService.isSubSuper();
    }

    isCompanyAdmin() {
        return this.authService.isCompanyAd() || this.authService.isSubCompanyAd();
    }

    isAgencyAdmin() {
        return this.authService.isAgencyAd() || this.authService.isSubAgencyAd();
    }

    checkSelectedEmployee() {
        if (this.historyFilter.employeeIds.length > 0) {
            this.disableSendEmployee = false;
        } else {
            this.disableSendEmployee = true;
        }
    }

    buildOptionSearch(options?: any) {
        if (!this.isPlatformAdmin) {
            options.companyId = this.authService.getCurrentCompanyId();
        }
        if (this.isEmployee) {
            options.employeeIds = [];
            options.employeeIds.push(this.authService.getCurrentEmployee());
            options.companyId = this.authService.getUserInfo().companyId;
        }
        options.key = this.textSearch;

        // if (this.fromDate && this.toDate) {
        //     options.fromDate = this.fromDate;
        //     options.toDate = this.toDate;
        // }
        if (this.fromDate) {
            // options.fromDate = moment(this.fromDate).utc(true).startOf('day').toDate();
            options.fromDate = MOMENT(this.fromDate).startOf('day').utc(true);
        }
        if (this.toDate) {
            // options.toDate = moment(this.toDate).utc(true).endOf('day').toDate();
            options.toDate = MOMENT(this.toDate).endOf('day').utc(true);
        }
        options.status = (this.selectedWorkStatus && this.selectedWorkStatus !== 'all') ? this.selectedWorkStatus : null;
        options.clockAction = (this.selectedTimeClock || null);
        return options;
    }

    async exportPdf() {
        this.exportingPDF = true;
        let options = <any>{
            page: 0,
            size: 9999,
            sortField: this.sortField ? this.sortField : 'createdAt',
            sortOrder: this.sortOrder === 1 ? 'ASC' : 'DESC',

        };
        options = this.buildOptionSearch(options);
        //this.companyService.getCompanyByCompanyId(this.authService.getCurrentCompanyId()).subscribe(async (res: any) => {
            const resObj: any = await this.employeeClockingTrackingServiceV2.search(options).toPromise();
            if (resObj.status === 'SUCCESS') {
                resObj.data.content = _.compact(resObj.data.content);
                const punchHistoryList = resObj.data.content;
                // punchHistoryList.forEach(item => {
                //     if (PunchType.CLOCK_IN === item.clockAction) {
                //         item.action = 'Start Work';
                //     }
                //     if (PunchType.LUNCH_OUT === item.clockAction) {
                //         item.action = 'Start Break';
                //     }
                //     if (PunchType.LUNCH_IN === item.clockAction) {
                //         item.action = 'End Break';
                //     }
                //     if (PunchType.CLOCK_OUT === item.clockAction) {
                //         item.action = 'End Work';
                //     }
                //     if (PunchType.WRONG_PIN === item.clockAction) {
                //         item.action = 'Wrong PIN';
                //     }
                // });
                const data = <any>{
                    dataList: punchHistoryList.map(value => {
                        return {
                            id: value.id,
                            employeeName: value.employee ? value.employee.fullName : this.translatePipe.transform('no data'),
                            accountUsed: value.accountUsed || this.translatePipe.transform('no data'),
                            pin: value.pin || this.translatePipe.transform('no data'),
                            details: value.details || this.translatePipe.transform('no data'),
                            locationName: value.locationName ? this.translatePipe.transform(value.locationName): this.translatePipe.transform('no data'),
                            clockActionStr: value.clockActionName ? this.translatePipe.transform(value.clockActionName) : this.translatePipe.transform('no data'),
                            createdAtStr: value.createdAt ? this.datePipe.transform(value.createdAt, 'MM/dd/yyyy hh:mm a') : this.translatePipe.transform('no data'),
                            status: value.status,
                            confirmationDateStr: value.confirmationDate ? this.datePipe.transform(value.confirmationDate, 'MM/dd/yyyy hh:mm a') : this.translatePipe.transform('no data'),
                            landscape: value.userClockFrom === 'Computer' ? 'Computer (web portal)' : value.userClockFrom
                        };
                    })
                };
                data.companyId = this.authService.getCurrentCompanyId();
                data.printedBy = this.authService.getCurrentUsername();
                data.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                data.reportDate = this.datePipe.transform(new Date(), 'MM/dd/yyyy EEE hh:mm a');
                let headers = [
                    'ID',
                    'Full Name',
                    'Username',
                    'Method',
                    'Details',
                    'Location',
                    'Punch',
                    'Timestamp',
                    'Status',
                    'Confirmed',
                    'Application'
                ];
                headers = headers.map(item => this.translatePipe.transform(item));
                data.headers = headers;
                this.employeeClockingTrackingServiceV2.exportPdf(data).subscribe(response => {
                    const blob = FileUtility.b64toBlob(response.data.body, 'application/pdf');
                    const blobUrl = URL.createObjectURL(blob);
                    window.open(blobUrl, '_blank');
                });
            }
            this.exportingPDF = false;
            this.messageService.add({
                severity: 'info',
                summary: this.translatePipe.transform('Please Wait'),
                detail: this.translatePipe.transform('The PDF file has been compiled and is being opened...')
            });
        }


    viewFaceImg(data: any) {
        this.faceData.name = data.employee?.fullName;
        this.faceData.img = data.faceDetectionImage;
        this.showViewDetectionFaceImg = true;
    }

    viewFaceImgConfirm(data: any) {
        this.faceData.id = data.clockingId;
        this.faceData.name = data.employee?.fullName;
        this.faceData.img = data.faceImage;
        this.faceData.type = data.clockAction;
        this.faceData.date = data.createdAt;
        this.faceData.status = data.faceImageStatus;
        this.faceData.lunchHistoryId = data.lunchHistoryId;
        this.showViewFaceImg = true;
    }

    acceptFaceImg() {
        if (this.confirmingFaceImg) {
            return;
        }
        this.confirmingFaceImg = true;
        this._employeeClockingService.acceptFaceImg(this.faceData.id, this.faceData.type, this.faceData.lunchHistoryId).subscribe(
            (data: any) => {
                this.confirmingFaceImg = false;
                if (data.status === 'SUCCESS') {
                    this.showViewFaceImg = false;
                    this.messageService.add({ severity: 'success',
                        summary: this.translatePipe.transform('ServiceMessage'), detail: this.translatePipe.transform('UpdatedSuccess') });
                    this.search2();
                } else {
                    this.messageService.add({ severity: 'error',
                        summary: this.translatePipe.transform('ServiceMessage'), detail: this.translatePipe.transform('UpdatedFail') });
                }
            }, () => {
                this.messageService.add({ severity: 'error',
                    summary: this.translatePipe.transform('ServiceMessage'), detail: this.translatePipe.transform('UpdatedFail') });
                this.confirmingFaceImg = false;
            }
        );
    }

    rejectFaceImg() {
        if (this.confirmingFaceImg) {
            return;
        }
        this.confirmingFaceImg = true;
        this._employeeClockingService.rejectFaceImg(this.faceData.id, this.faceData.type, this.faceData.lunchHistoryId).subscribe(
            (data: any) => {
                this.confirmingFaceImg = false;
                if (data.status === 'SUCCESS') {
                    this.showViewFaceImg = false;
                    this.messageService.add({ severity: 'success',
                        summary: this.translatePipe.transform('ServiceMessage'), detail: this.translatePipe.transform('UpdatedSuccess') });
                    this.search2();
                } else {
                    this.messageService.add({ severity: 'error',
                        summary: this.translatePipe.transform('ServiceMessage'), detail: this.translatePipe.transform('UpdatedFail') });
                }
            }, () => {
                this.messageService.add({ severity: 'error',
                    summary: this.translatePipe.transform('ServiceMessage'), detail: this.translatePipe.transform('UpdatedFail') });
                this.confirmingFaceImg = false;
            }
        );  
    }
    onFilter(event, dt) {
        this.totalRecords = event.filteredValue.length;
    }
}
