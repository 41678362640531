import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractService } from '../../shared/service/abstract.service';
import { CrmEvent } from '../model/crmEvent';
import { Constants } from '../../shared/model/constants';
import { environment } from '../../../environments/environment';
import { Email } from '../../layout/model/email';
import { Page } from "../../shared/model/page.model";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ListResponse } from '../../shared/model/list.response';

@Injectable({ providedIn: 'root' })
export class EventService extends AbstractService<CrmEvent> {

    constructor(private http: HttpClient) {
        super(http, Constants.ROUTES.CRM.EVENT, '');
    }

    getEventsBackWeek(): Observable<CrmEvent[]> {
        return this._http.get<CrmEvent[]>(this.baseUrl + 'findAllBackWeek').pipe(map(resp => resp));
    }

    getUpComing(params?: any): Observable<CrmEvent[]> {
        return this._http.get<CrmEvent[]>(this.baseUrl + 'upcoming', { params: params }).pipe(map(resp => resp));
    }

    filter(options: any): Observable<Page<CrmEvent>> {
        // options.toDate = moment(options.toDate).toISOString(true);
        // options.fromDate = moment(options.fromDate).toISOString(true);
        const params: any = { page: options.page, size: options.size };
        if (options.sort) {
            params.sort = options.sort;
        }


        return this._http.post<Page<CrmEvent>>(`${this.baseUrlV2}search`, options).pipe(map(resp => resp));
    }

    exportPdf(options: any): Observable<any> {
        //return this._http.post<any>(`http://localhost:8010/api/v2/crm/events/pdf`, options).pipe(map(resp => resp));
        return this._http.post<any>(`${this.baseUrlV2}pdf`, options).pipe(map(resp => resp));
    }

    listSimple(options: any): Observable<any> {
        // options.toDate = moment(options.toDate).toISOString(true);
        // options.fromDate = moment(options.fromDate).toISOString(true);
        const params: any = { page: options.page, size: options.size };
        if (options.sort) {
            params.sort = options.sort;
        }
        if (options.sort) {
            params.sort = options.sort;
        }

        return this._http.post<any>(`${this.baseUrl}simple`, options, { params: params }).pipe(map(resp => resp));
    }

    sendEmailOfEvent(id: number, options?: any): Observable<any> {
        // const params = this.createParamsPrivately(options);
        return this._http.get(`${this.baseUrl}sendEmail/${id}`, { params: options }).pipe(map(resp => resp));
    }


    sendEmailFromOperr(mail: Email): Observable<any> {
        return this._http.post(`${this.baseUrl}sendEmailFromOperr`, mail).pipe(map(resp => resp));
    }

    saveNotification(notificationData: any): Observable<any> {
        return this._http.post<any>(`${environment.server_ip}/${Constants.ROUTES.NOTIFCATIONSETTING}`, notificationData).pipe(map(resp => resp));
    }


    getNotificationByType(type: any): Observable<any> {
        return this._http.get<any>(`${environment.server_ip}/${Constants.ROUTES.NOTIFCATIONSETTING}/getNotificationByType`, { params: { type: type } }).pipe(map(resp => resp));
    }

    deleteMultipleNotification(options?: any): Observable<any> {
        return this._http.delete(`${environment.server_ip}/${Constants.ROUTES.NOTIFCATIONSETTING}/multipleDelete?ids=${options}`).pipe(map(resp => resp));
    }

    getMessateNotification(username: any): Observable<any> {
        return null;
        //return this._http.get<any>(`${environment.chat_api_url}/conversation/count/unread/user/${username}`, { params: { app_key: environment.chat_api_key } }).pipe(map(resp => resp));;
    }

    getEventsPopup(entity: any) {
        return this._http.post<Page<CrmEvent>>(`${this.baseUrlV2}popup`, entity).pipe(map(resp => resp));
    }

    getDistance(fromLat, fromLong, toLat, toLong) {
        const query = `fromLat=${fromLat}&fromLong=${fromLong}&toLat=${toLat}&toLong=${toLong}`;
        const url = this.baseUrl;
        return this._http.get<CrmEvent[]>(`${environment.v2_server_ip}/${Constants.ROUTES.CRM.EVENT}/distance_matrix?${query}`).pipe(map(resp => resp));
    }

    getDropdownCreatedBy(searchParams?: any): Observable<ListResponse<any>> {
        const params = this.createParams(searchParams);
        return this._http.get<ListResponse<any>>(this.baseUrlV2 + 'dropdownCreatedBy', { params: params }).pipe(map(resp => resp));
    }

    updateStatus(options: any): Observable<any> {
        return this._http.put<any>(`${this.baseUrlV2}update-status`, options).pipe(map(resp => resp));
    }

    getCrmEventHistory(entity) {
        return this._http.post(`${this.baseUrlV2}search-history`, entity).pipe(map(res => res));
    }
}
