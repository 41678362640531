<h1 class="m-0 ml-2 my-4">{{'Card Activity' | translate}}</h1>
<p-panel [toggleable]="true">
	<p-header class="flex-grow-1">
		<span><i class="fa-solid fa-magnifying-glass text-primary mr-2"></i>{{'Find Card Activity' | translate}}</span>
	</p-header>
	<div class="ui-g">
		<div class="ui-g-6 ui-sm-12" *ngIf="isSuperAdmin">
			<label>{{'companyLabel' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-building"></i>
					</button>
				</div>
				<p-dropdown [filter]="true" [options]="companies" [(ngModel)]="selectedCompany" [autoDisplayFirst]="false" placeholder="{{'Select One' | translate}}">
					<ng-template pTemplate="item" let-item>
						<span class="text-truncate">{{item.label | translate}}</span>
					</ng-template>
					<ng-template let-item pTemplate="selectedItem">
						<span class="one-liner">{{item.label | translate}}</span>
					</ng-template>
				</p-dropdown>
			</div>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<label>{{'Board' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-chalkboard-user"></i>
					</button>
				</div>
				<p-dropdown [options]="allProject" [(ngModel)]="boardSelected"  filter="true" [autoDisplayFirst]="false" placeholder="{{'Select One' | translate}}">
					<ng-template pTemplate="item" let-item>
						<span class="text-truncate">{{item.label | translate}}</span>
					</ng-template>
					<ng-template let-item pTemplate="selectedItem">
						<span class="one-liner">{{item.label | translate}}</span>
					</ng-template>
				</p-dropdown>
			</div>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<label>{{'Action' | translate}} </label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-mouse-pointer"></i>
					</button>
				</div>
				<p-dropdown [options]="actions" [(ngModel)]="action" [autoDisplayFirst]="false" placeholder="{{'Select One' | translate}}">
					<ng-template pTemplate="item" let-item>
						<span class="text-truncate">{{item.label | translate}}</span>
					</ng-template>
					<ng-template let-item pTemplate="selectedItem">
						<span class="one-liner">{{item.label | translate}}</span>
					</ng-template>
				</p-dropdown>
			</div>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<label>{{'dateRange' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-calendar-week"></i>
					</button>
				</div>
				<mat-form-field class="w-100">
					<mat-date-range-input [rangePicker]="picker">
						<input matStartDate matInput placeholder="{{'StartDate' | translate}}" [(ngModel)]="filterDate[0]"
							name="Startdate" (focus)="picker.open()">
						<input matEndDate matInput placeholder="{{'EndDate' | translate}}" [(ngModel)]="filterDate[1]"
							name="Enddate" (focus)="picker.open()">
					</mat-date-range-input>
					<mat-date-range-picker #picker></mat-date-range-picker>
				</mat-form-field>
			</div>
		</div>
		<div class="ui-g-12 py-4 text-center">
			<button class="btn btn-danger mx-1" (click)="reset()"><i class="fa-solid fa-xmark mr-2"></i>{{'button.reset' | translate}}</button>
			<button class="btn btn-primary mx-1" (click)="search()"><i class="fa-solid fa-check mr-2"></i>{{'button.search' | translate}}</button>
		</div>
	</div>
</p-panel>
<h1 class="m-0 ml-2 my-4">{{'Browse Activity' | translate}}</h1>
<p-panel [toggleable]="true" id="table-ppanel">
	<p-header class="flex-grow-1">
		<span><i class="fa-solid fa-table text-primary mr-2"></i>{{'Card Activity' | translate}} ({{totalRecords || 0 | number}})</span>
	</p-header>
	<p-table #dt [value]="tickets" selectionMode="single" dataKey="id" [totalRecords]="totalRecords" [lazy]="false" [paginator]="true" [rowsPerPageOptions]="[10, 25, 50]" [rows]="10" [columns]="cols" [responsive]="true" [(first)]="first" (onLazyLoad)="loadTicketsLazy($event)">
		<ng-template pTemplate="caption">
			<div class="d-flex align-items-center justify-content-between">
				<button type="button" (click)="exportData()" [disabled]="!tickets?.length" class="btn btn-primary mx-1">
					<span style="white-space:nowrap"><i class="fa-solid fa-print mr-2"></i> {{'Export PDF' | translate}}</span>
				</button>
				<div class="input-group w-100 mr-2" style="max-width: 450px">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-filter"></i>
						</button>
					</div>
					<input type="text" pInputText placeholder="{{'Filter by Keyword' | translate}}" [(ngModel)]="textSearchStr" (keyup)="searchTickets()">
				</div>
			</div>
		</ng-template>
		<ng-template pTemplate="header" let-columns>
			<tr>
				<th *ngFor="let col of columns;let i = index" [style.width]="col.width">
					<div class="d-flex align-items-center justify-content-between w-100">
						<span class="one-liner" pTooltip="{{col.label | translate}}" tooltipPosition="left">{{col.label | translate}}</span>
						<span *ngIf="i+1 !== 1" (click)="col.sort == 'number'? op2.toggle($event) : op1.toggle($event); selectedField = col.field; setSortOption(col.field, col.sortOptions)">
							<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
						</span>
					</div>
				</th>
			</tr>
		</ng-template>
		<ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" >
			<tr >
				<td>{{rowIndex + 1}}</td>
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Board' | translate}}: </span>
						<span *ngIf="rowData.boardName" pTooltip="{{rowData.boardName}}">{{rowData.boardName}}</span>
						<span *ngIf="!rowData.boardName" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Creator' | translate}}: </span>
						<span *ngIf="rowData.createdByUsr" pTooltip="{{rowData.createdByUsr}}">{{rowData.createdByUsr}}</span>
						<span *ngIf="!rowData.createdByUsr" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Created' | translate}}: </span>
						<span *ngIf="rowData.createdAt" pTooltip="{{rowData.createdAt | date : 'MM/dd/yyyy hh:mm a'}}">{{rowData.createdAt | date : 'MM/dd/yyyy hh:mm a'}}</span>
						<span *ngIf="!rowData.createdAt" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
				<td>
					<div class="d-flex align-items-center justify-content-between">
						<span class="one-liner">
							<span class="mobile-table-label">{{'Ticket' | translate}}: </span>
							<span *ngIf="rowData.ticketLink" pTooltip="{{rowData.ticketLink}}"><a class="text-primary" href="{{rowData.ticketLink}}" id="{{'textToCopy-' + i}}">{{rowData.ticketLink}}</a></span>
							<span *ngIf="!rowData.ticketLink" class="text-muted">{{'no data' | translate}}</span>
						</span>
						<i *ngIf="rowData.ticketLink" class="fa-solid fa-copy text-muted" (click)="copyToClipboard(rowData.ticketLink)" pTooltip="{{'Copy URL' | translate}}" tooltipPosition="left"></i>
					</div>
				</td>
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Action' | translate}}: </span>
						<span *ngIf="rowData.taskAction" pTooltip="{{rowData.taskAction}}">{{rowData.taskAction}}</span>
						<span *ngIf="!rowData.taskAction" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
			</tr>
		</ng-template>
		<ng-template pTemplate="emptymessage" let-columns>
			<tr *ngIf="!loading">
				<td [attr.colspan]="columns?.length" class="text-center py-5">
					<div class="text-muted my-5">{{'no data' | translate}}</div>
				</td>
			</tr>
			<tr *ngIf="loading">
				<td [attr.colspan]="columns?.length" class="text-center py-5">
					<div class="text-muted my-5"><i class="fa-solid fa-spinner mr-2 loadingHourglass"></i>{{'Loading...' | translate}}</div>
				</td>
			</tr>
		</ng-template>
	</p-table>
</p-panel>

<p-overlayPanel #op1 appendTo="body" [style]="{'padding':'0px'}" style="padding: 0px !important">
	<p-listbox [options]="textSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortingClick(selectedSortOption)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span> {{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>
<p-overlayPanel #op2 appendTo="body" style="padding: 0px !important">
	<p-listbox [options]="numberSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortingClick(selectedSortOption)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span> {{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>
<p-overlayPanel #op3 appendTo="body" style="padding: 0px !important">
	<p-listbox [options]="dateSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortingClick(selectedSortOption)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span> {{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>
