import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, Message, MessageService } from 'primeng/api';
import { Company } from '../../../company/model/company.model';
import { CompanyService } from '../../../company/service/company.service';
import { AuthService } from '../../../shared/service/auth/auth.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import * as MOMENT from 'moment';
import { WindowRefService } from '../../../shared/service/window-ref/window-ref.service';
import { DropDownsService } from '../../../shared/service/drop-downs/drop-downs.service';
import { TransactionTypeService } from '../../service/transaction-type.service';
import { TransactionType } from '../../model/transaction-type.model';
import { TransactionDetailsService } from '../../service/transaction-details.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import { CommService } from '../../../employee/performance/service/comm.service';
import { FileUtility } from '../../../shared/utility/file.utility';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { TranslatePipe } from '@ngx-translate/core';
import { ProjectService } from 'app/project/service/project.service';
import { NavigationEnd, Router } from '@angular/router';
import { CompanyAdminService } from 'app/admin/company-admin/service/company-admin.service';
import { MonitoringDetailsService } from 'app/admin/process-monitor/service/monitoring-details.service';
import { Subscription } from 'rxjs';
import { DocumentsServicev2 } from 'app/shared/service/documents-upload/document.v2.service';
import { DocumentsService } from 'app/shared/service/documents-upload/documents.service';
import { TransactionDetailsHistoryService } from '../../service/transaction-details-history.service';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { UIChart } from 'primeng/chart';
import { AccountService } from 'app/account/service/account.service';
import { PaymentInvoiceService } from '../../../payment/service/payment-invoice.service';
import {CommonService} from '../../../shared/service/common.service';
import {CurrencyPipe, DatePipe} from '@angular/common';
import {Table} from "primeng/table";

declare var google: any;

@Component({
  selector: 'app-transaction-list',
  templateUrl: './transaction-list.component.html',
  styleUrls: ['./transaction-list.component.scss'],
  providers: [WindowRefService, TransactionTypeService, TransactionDetailsService, ConfirmationService,
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS }]

})
export class TransactionListComponent implements OnInit, OnDestroy {
  companies: any = [];
  allAgencies = [];
  agencies = [];
  searchParticipant: { companyId: number, agencyId: number };
  selectedCreatedBy: string;
  msgs: Message[] = [];
  allProject: any = [];
  allExpenses: any = [];
  selectedTransactionType: number;
  selectedProject: number;
  cols = [];
  transactions = [];
  totalRecords: Number;
  selectedDateRange: any;
  loading = true;
  page: number;
  size: number;
  allCreatedBy: any[];
  textSearch: string;
  searchStatus: any[] = [
    { label: 'Active', value: 1 },
    { label: 'Inactive', value: 0 }
  ];
  requestFilter: any;
  allCreatedByList: any = [];
  selectedStatus: number;
  textSortOptions = [
    { name: 'Sort A To Z', value: 'ASC', img: 'arrow-down-a-z' },
    { name: 'Sort Z To A', value: 'DESC', img: 'arrow-up-z-a' }
  ];
  numberSortOptions = [
    { name: 'Sort 1 To 9', value: 'ASC', img: 'arrow-down-1-9' },
    { name: 'Sort 9 To 1', value: 'DESC', img: 'arrow-up-9-1' }
  ];
  dateSortOptions = [
    { name: 'Sort 1 To 12', value: 'ASC', img: 'arrow-down-1-9' },
    { name: 'Sort 12 To 1', value: 'DESC', img: 'arrow-up-9-1' }
  ];
  @ViewChild('op5') op5: OverlayPanel;
  downloadOptions = [
    { name: 'PDF File', value: 'PDF' },
    { name: 'XLS File', value: 'EXCEL' }
  ];
  showpanel = true;
  selectedDownload = '';
  selectedSortOption = '';
  selectedField = '';
  textSearchStr: string;
  filteredTransaction = [];
  isPlatformAdmin: Boolean = false;
  transactionTypes: TransactionType[];
  allTransactionTypes: any[];
  availableBalance = 0;
  presentBalance = 0;
  currentBalanceLnumber = 0;
  netCashInOut = 0;
  previousDayBalance = 0;
  difference = 0;
  isDeleteAble = false;
  isEditable = false;
  pieChart: any;
  barChart: any;
  allAdminNameList: any[] = [];
  allProjects: any[] = [];
  allCompanyList: any = [];
  selectedCompany: any;
  fromDate: Date;
  toDate: Date;
  sortF: any = '';
  showConfirmDialog = false;
  selectedTransaction: any;
  clientList: any[];
  selectedClient: any;
  allCreatedByUser: any = [];
  selectedByCreatedByUser: any;
  timeSpent = new Date();
  private _routerSub = Subscription.EMPTY;
  deleteTime: Date;
  transactionSum: any;
  totalSum: any = {};
  showViewGraphDialog = false;

  statementBalanceList = [];
  searchData: any;

  statisticsCols = [
    { field: 'first30', label: '0 - 30' },
    { field: 'first60', label: '31 - 60' },
    { field: 'first90', label: '61 - 90' },
    { field: 'first120', label: '91 - 120' },
    { field: 'last120', label: '120+' },
  ];
  statementBalanceStatistics = [{
    first30: 0,
    first60: 0,
    first90: 0,
    first120: 0,
    last120: 0
  }];
  totalBalanceStatistics = 0;

  attachment = 'fa-solid fa-paperclip text-muted';
  attachment1 = 'fa-solid fa-paperclip text-success';
  noFileOptions = [
    { label: 'Upload', value: 'Upload' },
    { label: 'History', value: 'History' }
  ];
  hasFileOptions = [
    { label: 'View', value: 'View' },
    { label: 'Change', value: 'Change' },
    { label: 'Remove', value: 'Remove' },
    { label: 'History', value: 'History' }
  ];
  fileUrl: string;
  showViewFileDialog = false;
  selectedTransactionDetail: any;
  historyFor: any;
  showHistoryUploadDialog = false;
  historySize: number;
  historyPage: number;
  historyFromDate: Date;
  historyToDate: Date;
  historySelectedField: string;
  displayColumns: any[] = [
    { field: 'updatedDate', label: 'Updated', sortable: false },
    { field: 'userName', label: 'User', sortable: false },
    { field: 'oldValue', label: 'Before', sortable: false },
    { field: 'newValue', label: 'After', sortable: false },
    { field: 'action', label: 'Action', sortable: false }
  ];
  dataSourceSubject = new BehaviorSubject(null);
  dataSource$ = this.dataSourceSubject.asObservable();
  fieldsName = [];
  actionHistoryFile = 'Updated';
  viewGraphData: any;
  viewGraphOptions: any;
  viewGraphDataLabels: any = [];
  textColor = '#495057';
  textColorSecondary = '#6c757d';
  surfaceBorder = '#dfe7ef';
  currentDay = new Date();
  @ViewChild('viewGraphChart') viewGraphChart: UIChart;
  viewGraphDatasets: any = [];
  viewGraphLoading = false;
  showConfirmationPopup = false;
  confirmationId = '';
  confirmationType = '';

  billingAccounts: any[] = [];
  selectedBillingAccount;

  sortField: string;
  sortOrder: number = 0;
  listFilterOptions = [
    { label: 'Invoice #', value: 'invoice' },
    { label: 'Client', value: 'client' }
  ];
  selectedShowOptions = null;

  @ViewChild('op3', { static: true }) op3: OverlayPanel;
  exportOptions = [
    { name: 'View Detail', value: 'view' },
    { name: 'Download invoice', value: 'download' },
  ];
  invoiceRef: any = {};
  exportAs: any;

  verifyOptions = [
    { label: '1st Confirmation', value: '1st Confirmation' },
    { label: '2nd Confirmation', value: '2nd Confirmation' },
    { label: 'No Confirmation', value: 'No Confirmation' }
  ];
  selectedVerify = [];
  attachmentOptions = [
    { label: 'With Attachment', value: 'With Attachment' },
    { label: 'No Attachment', value: 'No Attachment' }
  ];
  selectedAttachments = [];

  adminList: any[];
  userConfig: any;

  exportLoading = false;
  dataExportList = [];

  invoiceType: any
  invoiceId: any
  allInvoicesDropdownData: any = []
  selectedPaymentMethods = []
  invoiceTypes = [
    { label: 'Billing', value: 'Billing' },
    { label: 'Dispatch', value: 'Dispatch' },
    { label: 'Advertisement', value: 'Advertisement' },
    { label: 'Dispatching', value: 'Dispatching' },
    { label: 'OperrTel', value: 'OperrTel' },
  ]

  paymentMethods = [
    { label: 'Cash', value: 'cash' },
    { label: 'Credit/Debit', value: 'credit/Debit' },
    { label: 'Zelle', value: 'zelle' },
    { label: 'Check', value: 'check' },
    { label: 'Bank Transfer', value: 'BANK_TRANSFER' },
    { label: 'Other', value: 'other' }
  ]

  constructor(
    private messageService: MessageService,
    private companyService: CompanyService,
    private authService: AuthService,
    private dropDownsService: DropDownsService,
    private transactionTypeService: TransactionTypeService,
    private transactionDetailsService: TransactionDetailsService,
    private commService: CommService,
    private translatePipe: TranslatePipe,
    private router: Router,
    private projectService: ProjectService,
    private companyAdminService: CompanyAdminService,
    private monitoringDetailsService: MonitoringDetailsService,
    private documentsService: DocumentsService,
    private documentServicev2: DocumentsServicev2,
    private transactionDetailsHistoryService: TransactionDetailsHistoryService,
    private accountService: AccountService,
    private contentService: PaymentInvoiceService,
    private commonService: CommonService,
    private datePipe: DatePipe,
    private currencyPipe: CurrencyPipe,
  ) {
    this._routerSub = router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.url !== '/app/transaction/list') {
          this.monitoringDetailsService.monitorAction(
            `Navigated to Transaction List`,
            this.timeSpent,
            {
              navigated_to_transaction_list_by: this.authService.getCurrentLoggedInName()
            },
            'complete',
            'Navigated to Transaction List',
            0
          );
        }
      }
    });
  }

  ngOnInit() {
    google.charts.load('current', { 'packages': ['corechart'] });
    this.isPlatformAdmin = this.authService.isSuper() || this.authService.isSubSuper();
    this.searchParticipant = {
      companyId: this.authService.getCurrentCompany() ? this.authService.getCurrentCompany().id : 0,
      agencyId: this.authService.getCurrentAgency() ? this.authService.getCurrentAgency().id : 0
    };

    const options: any = {};
    if (!this.isPlatformAdmin) {
      options.companyId = this.authService.getCurrentCompanyId();
    } else {
      this.isDeleteAble = true;
    }
    this.transactionTypeService.getDropdown(options).subscribe(res => {
      const resObj: any = res;
      this.transactionTypes = resObj.data;
      this.allTransactionTypes = [];
      const allTransactionTypes = [];

      if (resObj.status === 'SUCCESS') {
        resObj.data.forEach(transactionType => {
          allTransactionTypes.push({ label: transactionType.value, value: transactionType.key });
        });

        this.allTransactionTypes = _.sortBy(allTransactionTypes, 'label');
      }
    });

    this.cols = [
      { field: 'date', label: 'Created Date', sortOptions: '', sort: 'number' },
      { field: 'transactionTypeName', label: 'Method', sortOptions: '', sort: 'text', width: '13%' },
      { field: 'cashIn', label: 'In', sortOptions: '', sort: 'number', width: '12%' },
      { field: 'cashKept', label: 'Kept', sortOptions: '', sort: 'number' },
      { field: 'cashOut', label: 'Out', sortOptions: '', sort: 'number' },
      { field: 'netCashInOut', label: 'Net', sortOptions: '', sort: 'number', width: '15%' },
      { field: 'createdByUsr', label: 'Creator', sortOptions: '', sort: 'text', width: '12%' },
      { field: 'transaction.transactionDate', label: 'Transaction Date', sortOptions: '', sort: 'number' },
      { field: 'document', label: 'Upload' },
      { field: 'verification1st', label: 'Verify 1', sortOptions: '', sort: 'text', width: '12%' },
      { field: 'verification2st', label: 'Verify 2', sortOptions: '', sort: 'text', width: '12%' },
      { field: 'actions', label: 'Actions' }
    ];
    this.textSearch = '';

    this.requestFilter = {
      fromDate: '',
      toDate: '',
      page: 0,
      size: 10,
      searchText: '',
      transactionTypeId: 0,
      project: '',
      username: '',
      sortOrder: '',
      sortField: ''
    };

    this.searchData = {
      fromDate: '',
      toDate: '',
      page: 0,
      size: 10,
      searchText: '',
      sortOrder: '',
      sortField: ''
    };
    // this.loadLazy();
    this.getBalanceInfo();
    this.loadAllCreatedByUser();
    this.loadAllAdminNameList();
    this.loadAllCompanies();
    this.isEditable = this.authService.isEmployeeRole() ?
      this.authService.getLoggedInUserMenuList().includes('83') :
      (this.authService.getLoggedInUserEditList().includes('83') || this.authService.getLoggedInUserEditList().includes('84'));
    // this.isDeleteAble = this.authService.getLoggedInUserDeleteList().includes('83'); // get id from menu-list.ts
    this.loadProjectList();
    this.getClientList();
    this.getAccountList();
    this.getUserConfig();

    const strSearch = sessionStorage.getItem('TRANSACTION_LIST_SEARCH');
    if (strSearch) {
      const jsonSearch = JSON.parse(strSearch);
      this.selectedCompany = jsonSearch.selectedCompany;
      this.selectedTransactionType = jsonSearch.selectedTransactionType;
      this.fromDate = jsonSearch.fromDate;
      this.toDate = jsonSearch.toDate;
      this.selectedProject = jsonSearch.selectedProject;
      this.selectedCreatedBy = jsonSearch.selectedCreatedBy;
      this.selectedClient = jsonSearch.selectedClient;
      this.selectedByCreatedByUser = jsonSearch.selectedByCreatedByUser;
      this.selectedBillingAccount = jsonSearch.selectedBillingAccount;
      this.selectedVerify = jsonSearch.selectedVerify;
      this.selectedAttachments = jsonSearch.selectedAttachments;
      this.selectedSortOption = jsonSearch.selectedSortOption;
      this.selectedField = jsonSearch.selectedField;
      this.selectedShowOptions = jsonSearch.selectedShowOptions;

      this.searchList();
      sessionStorage.removeItem('TRANSACTION_LIST_SEARCH');
    } else {
      this.searchList();
    }
  }

  ngOnDestroy(): void {
    setTimeout(() => {
      this._routerSub.unsubscribe();
    }, 1000);
  }

  loadProjectList() {
    const options: any = {};
    if (this.selectedCompany) {
      options.companyId = this.selectedCompany.key;
    }
    if (!this.isPlatformAdmin) {
      options.companyId = this.authService.getCurrentCompanyId();
    }
    const allProjects = [];
    this.projectService.getProjectDropdown(options).subscribe(res => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        resObj.data.forEach(ele => {
          allProjects.push({ label: ele.projectName, value: ele.id });
        });
        this.allProjects = allProjects;
      }
    });
  }

  search() {
    if (this.textSearchStr) {
      this.filterEmployees();
    } else {
      this.transactions = this.filteredTransaction;
    }
  }

  filterEmployees() {

  }

  createdByUsr(event) {
    return event.createdByUsr != null && !(event.createdByUsr.search(RegExp(this.textSearchStr, 'i')) === -1);
  }

  reset() {
    this.selectedTransactionType = null;
    this.selectedCreatedBy = null;
    this.selectedDateRange = null;
    this.selectedProject = null;
    this.selectedCompany = null;
    this.fromDate = null;
    this.toDate = null;
    this.selectedByCreatedByUser = null;
    this.selectedClient = null;
    this.selectedBillingAccount = null;
    this.selectedVerify = [];
    this.selectedAttachments = [];
    this.requestFilter = {
      fromDate: '',
      toDate: '',
      page: 0,
      size: 10,
      searchText: '',
      transactionTypeId: 0,
      projectId: '',
      username: '',
      sortOrder: '',
      sortField: ''
    };
    this.availableBalance = 0;
    this.presentBalance = 0;
    this.previousDayBalance = 0;
    this.difference = 0;
    this.textSearchStr = null;
    this.invoiceId = null
    this.invoiceType = null
    this.selectedPaymentMethods = []
    this.loadLazy(1);
    // this.getPaymentInfo();
    // this.getBalanceInfo();
  }

  getBalanceInfo() {
    const options: any = {};
    if (this.textSearchStr) {
      options.searchText = this.textSearchStr;
    }
    if (this.selectedTransactionType) {
      options.transactionTypeId = this.selectedTransactionType;
    }
    if (this.fromDate) {
      options.fromDate = MOMENT(this.fromDate).startOf('day').utc(true);
    }
    if (this.toDate) {
      options.toDate = MOMENT(this.toDate).endOf('day').utc(true);
    }

    if (this.selectedCreatedBy) {
      options.username = this.selectedCreatedBy;
    }

    if (!this.isPlatformAdmin) {
      options.companyId = this.authService.getCurrentCompany();
    }
    if (this.selectedProject) {
      options.projectId = this.selectedProject;
    }
    if (this.selectedCreatedBy) {
      options.createdByUsr = this.selectedCreatedBy;
    }
    if (this.isPlatformAdmin && this.selectedCompany) {
      options.companyId = this.selectedCompany.key;
    }
    if (this.selectedClient) {
      options.crmClientId = this.selectedClient.key;
    }
    if (this.selectedBillingAccount) {
      options.billingAccountId = this.selectedBillingAccount;
    }
    if (this.selectedByCreatedByUser) {
      const addBy = this.allCreatedByUser.find(i => i.value === this.selectedByCreatedByUser);
      const listString = addBy.contractorInvoiceId.split(',');
      const listLong = listString.map(i => parseInt(i, 10));
      options.contractorInvoiceIds = listLong;
    }
    options.verifyList = this.selectedVerify;
    options.attachmentSelections = this.selectedAttachments;
    this.transactionDetailsService.loadBalanceInfo(options).subscribe(res => {
      const resObj: any = res;
      this.previousDayBalance = resObj.data.previousDayBalance;
      this.availableBalance = resObj.data.availableBalance;
      this.presentBalance = resObj.data.presentBalance;
      this.difference = resObj.data.difference;
      this.totalSum = {
        cashInSum: resObj.data.totalCashIn || 0,
        cashKeptSum: resObj.data.totalCashKept || 0,
        cashOutSum: resObj.data.totalCashOut || 0,
        netAmtSum: resObj.data.totalCashInOut || 0
      };
    });
  }

  async loadLazy(type: any, event?: any) {
    console.log("loadLazy type: ", type);
    console.log("loadLazy event: ", event);
    const options: any = {};
    // this.size = 9999;
    // this.page = 0;

    // this.size = event && event.rows ? event.rows : (this.searchData.size ? this.searchData.size : 25);
    // this.page = event && event.first >= 0 && event.rows >= 0 ?
    //   (event.first / event.rows) : (this.searchData.page ? this.searchData.page : 0);

    // options.page = this.page;
    // options.size = this.size;
    if (this.textSearchStr) {
      options.searchText = this.textSearchStr;
    }
    if (this.selectedTransactionType) {
      options.transactionTypeId = this.selectedTransactionType;
    }
    if (this.fromDate) {
      options.fromDate = MOMENT(this.fromDate).startOf('day').utc(true);
    }
    if (this.toDate) {
      options.toDate = MOMENT(this.toDate).endOf('day').utc(true);
    }

    if (this.selectedCreatedBy) {
      options.username = this.selectedCreatedBy;
    }
    options.sortField = this.selectedField || 'id';
    options.sortOrder = this.selectedSortOption || 'DESC';

    // if (event && event.sortField) {
    //   const sortOrder = <any>event['sortOrder'];
    //   const sortField = event['sortField'];
    //   options.sort = sortField;
    //   options.order = sortOrder === 'ASC' || sortOrder === 1 ? 'ASC' : 'DESC';
    // }
    if (!this.isPlatformAdmin) {
      options.companyId = this.authService.getCurrentCompany();
    }
    if (this.selectedProject) {
      options.projectId = this.selectedProject;
    }
    if (this.selectedCreatedBy) {
      options.createdByUsr = this.selectedCreatedBy;
    }
    if (this.isPlatformAdmin && this.selectedCompany) {
      options.companyId = this.selectedCompany.key;
    }
    if (this.selectedClient) {
      options.crmClientId = this.selectedClient.key;
    }
    if (this.selectedBillingAccount) {
      options.billingAccountId = this.selectedBillingAccount;
    }
    options.verifyList = this.selectedVerify;
    options.attachmentSelections = this.selectedAttachments;
    options.invoiceId = this.invoiceId
    options.invoiceType = this.invoiceType
    options.paymentMethods = this.selectedPaymentMethods

    // search text so need to update total records  
    if (type === 2) {

      this.loading = true;
      this.transactions = [];
      if (this.selectedByCreatedByUser) {
        const addBy = this.allCreatedByUser.find(i => i.value === this.selectedByCreatedByUser);
        const listString = addBy.contractorInvoiceId.split(',');
        const listLong = listString.map(i => parseInt(i, 10));
        options.contractorInvoiceIds = listLong;
      }
      this.searchData = Object.assign({}, options);
      const p1 = new Promise((resolve, reject) => this.transactionDetailsService.loadLists(this.searchData).subscribe(res => {
        resolve(res.data);
      }));

      const p2 = new Promise((resolve, reject) => this.transactionDetailsService.loadBalanceInfo(options).subscribe(res => {
        resolve(res);
      }));
      await Promise.all([p1, p2]).then((values: any[]) => {
        console.log("values[0].content: ", values[0].content);
        this.filteredTransaction = values[0].content;
        this.filteredTransaction.forEach(ele => {
          ele.showHideActions = (moment(moment().toDate()).diff(ele.date, 'minutes') >= 1440);
        });
        this.transactions = this.filteredTransaction;
        this.netCashInOut = 0;
        this.transactions.forEach(ele => {
          ele.date = new Date(ele.date);
          ele.dateStr = this.datePipe.transform(ele.date, 'M/d/yyyy h:mm:ss a');

          
          if (ele?.transaction?.transactionDate) {
            ele.transaction.transactionDate = new Date(ele.transaction.transactionDate)
          }

          if (ele.transaction && ele.transaction.transactionDate) {
            ele.transactionDateStr = this.datePipe.transform(ele.transaction.transactionDate, 'MM/dd/yyyy');
          }

          ele.transactionTypeName = ele.transactionType ? ele.transactionType.transactionType : ele.paymentMethod;
          if (ele.transactionTypeName === 'cash') {
            ele.transactionTypeName = 'Cash';
          }
          if (ele.transactionTypeName === 'credit/Debit') {
            ele.transactionTypeName = 'Credit/Debit';
          }

          if (ele.transactionTypeName === 'zelle') {
            ele.transactionTypeName = 'Zelle';
          }

          if (ele.transactionTypeName === 'check') {
            ele.transactionTypeName = 'Check';
          }

          if (ele.transactionTypeName === 'other') {
            ele.transactionTypeName = 'Other';
          }
          this.netCashInOut = this.netCashInOut + ele.netCashInOut;

          if (ele.cashIn) {
            ele.cashInStr = this.currencyPipe.transform(ele.cashIn, 'USD', 'symbol', '1.2-2');;
          }
          if (ele.cashKept) {
            ele.cashKeptStr = this.currencyPipe.transform(ele.cashKept, 'USD', 'symbol', '1.2-2');;
          }
          if (ele.cashOut) {
            ele.cashOutStr = this.currencyPipe.transform(ele.cashOut, 'USD', 'symbol', '1.2-2');;
          }
          if (ele.cashOut) {
            ele.cashOutStr = this.currencyPipe.transform(ele.cashOut, 'USD', 'symbol', '1.2-2');;
          }
          if (ele.netCashInOut) {
            ele.netCashInOutStr = this.currencyPipe.transform(ele.netCashInOut, 'USD', 'symbol', '1.2-2');;
          }
        });
        this.filteredValues = this.transactions;

        this.totalRecords = values[0].totalElements;
        this.reloadBalanceStatistic();
        this.loading = false;

        // get payment info
        const resObjPayment = values[1];
        this.previousDayBalance = resObjPayment.data.previousDayBalance;
        this.availableBalance = resObjPayment.data.availableBalance;
        this.presentBalance = resObjPayment.data.presentBalance;
        this.difference = resObjPayment.data.difference;
        this.totalSum = {
          cashInSum: resObjPayment.data.totalCashIn || 0,
          cashKeptSum: resObjPayment.data.totalCashKept || 0,
          cashOutSum: resObjPayment.data.totalCashOut || 0,
          netAmtSum: resObjPayment.data.totalCashInOut || 0
        };
        this.changeCols();
        this.getClientBase();
      });
    } else {
      this.loadTransaction(options, event);
    }
  }

  getTransction(rowData) {
    if (this.transactionTypes) {
      const transctionType = this.transactionTypes.find(tracsType => tracsType.id === rowData.transactionTypeId);
      if (transctionType) {
        return transctionType.transactionType;
      }
    }

    return null;
  }

  loadTransaction(options: any, event?) {
    this.loading = true;
    this.transactions = [];
    if (this.selectedByCreatedByUser) {
      const addBy = this.allCreatedByUser.find(i => i.value === this.selectedByCreatedByUser);
      const listString = addBy.contractorInvoiceId.split(',');
      const listLong = listString.map(i => parseInt(i, 10));
      options.contractorInvoiceIds = listLong;
    }
    this.searchData = Object.assign({}, options);
    this.transactionDetailsService.loadLists(this.searchData).subscribe(res => {
      // const resObj: any = res;
      // if (resObj.status === 'SUCCESS') {
      this.filteredTransaction = res.data.content;
      this.filteredTransaction.forEach(ele => {
        ele.showHideActions = (moment(moment().toDate()).diff(ele.date, 'minutes') >= 1440);
      });
      this.transactions = this.filteredTransaction;
      this.netCashInOut = 0;
      this.transactions.forEach(ele => {
        ele.date = new Date(ele.date);
        ele.transactionTypeName = ele.transactionType ? ele.transactionType.transactionType : ele.paymentMethod;
        if (ele.transactionTypeName === 'cash') {
          ele.transactionTypeName = 'Cash';
        }
        if (ele.transactionTypeName === 'credit/Debit') {
          ele.transactionTypeName = 'Credit/Debit';
        }

        if (ele.transactionTypeName === 'zelle') {
          ele.transactionTypeName = 'Zelle';
        }

        if (ele.transactionTypeName === 'check') {
          ele.transactionTypeName = 'Check';
        }

        if (ele.transactionTypeName === 'other') {
          ele.transactionTypeName = 'Other';
        }
        this.netCashInOut = this.netCashInOut + ele.netCashInOut;
      });
      this.totalRecords = res.data.totalElements;
      this.changeCols();
      this.reloadBalanceStatistic();
      this.getClientBase();
      this.loading = false;
      // } else this.loading = false;
      // this.checkPage();
    });
  }

  searchText1() {

  }

  mapToDropdown(label, val) {
    return {
      label: label,
      value: val
    };
  }

  sortingClick(selectedSortOption) {
    this.sortF = this.selectedField;
    const options = {
      field: this.selectedField,
      order: selectedSortOption === 'ASC' ? 1 : -1,
      mode: 'single',
      data: this.transactions
    };

    // this.setSortOption(this.selectedField, selectedSortOption);
    this.customSort(options);
    // const sortForStatus = selectedSortOption;
    // if (this.selectedField === 'status') {
    //     selectedSortOption = (selectedSortOption === 'ASC') ? 'DESC' : (selectedSortOption === 'DESC' ? 'ASC' : selectedSortOption);
    // }
    // selectedSortOption = selectedSortOption === 'ASC' ? 1 : -1;
    // this.sort(this.selectedField, selectedSortOption);
    this.sortField = this.selectedField;
    this.sortOrder = selectedSortOption === 'ASC' ? 1 : 0;
  }

  customSort(event) {
    if (this.sortF.length > 0) {
      if (event.data) {
        event.data.sort((data1, data2) => {
          const value1 = data1[event.field];
          const value2 = data2[event.field];
          let result = null;

          if (value1 == null && value2 != null) {
            result = -1;
          } else if (value1 != null && value2 == null) {
            result = 1;
          } else if (value1 == null && value2 == null) {
            result = 0;
          } else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
          } else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
          }

          return (event.order * result);
        });
      }
      this.sortF = '';
    }
  }

  sort(fieldName: string, order: number) {
    this.transactions.sort((row1, row2) => {
      const val1 = fieldName === 'transactionType' ? row1[fieldName].transactionType : row1[fieldName];
      const val2 = fieldName === 'transactionType' ? row2[fieldName].transactionType : row2[fieldName];
      if (val1 === val2) {
        return 0;
      }
      let result = -1;
      if (val1 > val2) {
        result = 1;
      }
      if (order < 0) {
        result = -result;
      }
      return result;
    });
  }

  setSortOption(field, selectedSortOption?) {
    this.cols.forEach((item) => {
      if (field === item.field) {
        item.sort = selectedSortOption;
        this.selectedSortOption = selectedSortOption;
      }
    });
  }

  viewTransaction(transation: any) {
    this.msgs = [];
    if (this.isEditable) {
      const searchFormData = {
        selectedCompany: this.selectedCompany,
        selectedTransactionType: this.selectedTransactionType,
        fromDate: this.fromDate,
        toDate: this.toDate,
        selectedProject: this.selectedProject,
        selectedCreatedBy: this.selectedCreatedBy,
        selectedClient: this.selectedClient,
        selectedByCreatedByUser: this.selectedByCreatedByUser,
        selectedBillingAccount: this.selectedBillingAccount,
        selectedVerify: this.selectedVerify,
        selectedAttachments: this.selectedAttachments,
        selectedSortOption: this.selectedSortOption,
        selectedField: this.selectedField,
        selectedShowOptions: this.selectedShowOptions,
      }
      sessionStorage.setItem('TRANSACTION_LIST_SEARCH', JSON.stringify(searchFormData));

      this.commService.executeCmd('edit_transaction', transation);
    } else {
      this.msgs.push({ severity: 'error', summary: 'Error', detail: `You don't have permissions!` });
    }
  }

  viewTransactionGroup(transation: any) {
    this.msgs = [];
    if (this.isEditable) {
      const searchFormData = {
        selectedCompany: this.selectedCompany,
        selectedTransactionType: this.selectedTransactionType,
        fromDate: this.fromDate,
        toDate: this.toDate,
        selectedProject: this.selectedProject,
        selectedCreatedBy: this.selectedCreatedBy,
        selectedClient: this.selectedClient,
        selectedByCreatedByUser: this.selectedByCreatedByUser,
        selectedBillingAccount: this.selectedBillingAccount,
        selectedVerify: this.selectedVerify,
        selectedAttachments: this.selectedAttachments,
        selectedSortOption: this.selectedSortOption,
        selectedField: this.selectedField,
        selectedShowOptions: this.selectedShowOptions,
      }
      sessionStorage.setItem('TRANSACTION_LIST_SEARCH', JSON.stringify(searchFormData));

      this.commService.executeCmd('edit_transaction_group', transation);
    } else {
      this.msgs.push({ severity: 'error', summary: 'Error', detail: `You don't have permissions!` });
    }
  }

  addTransaction() {
    this.msgs = [];
    const isAddAble = this.authService.isEmployeeRole() ?
      this.authService.getLoggedInUserMenuList().includes('83') :
      (this.authService.getLoggedInUserMenuList().includes('84'));
    if (isAddAble) {
      this.router.navigate(['/app/transaction/add']);
    } else {
      this.msgs.push({ severity: 'error', summary: 'Error', detail: `You don't have permissions!` });
    }
  }

  deleteTransaction(transation: any) {
    if (!this.isDeleteAble) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: `You don't have permission!` });
      return;
    }
    this.showConfirmDialog = true;
    this.selectedTransaction = transation;
    this.deleteTime = new Date();
  }

  accept() {
    this.msgs = [];
    const hours = moment().diff(moment(this.selectedTransaction.date), 'hours');
    this.transactionDetailsService.deleteTransaction(this.selectedTransaction.id).subscribe((_response) => {
      this.messageService.add({
        severity: 'success',
        summary: this.translatePipe.transform('Deleted'),
        detail: this.translatePipe.transform('Transactiondeleted')
      });
      this.showConfirmDialog = false;
      this.loadLazy(1);
      this.monitoringDetailsService.monitorAction(
        `Transaction Deleted`,
        this.deleteTime,
        {
          transaction_deleted_by: this.authService.getCurrentLoggedInName(),
          transaction_id: this.selectedTransaction.id
        },
        'complete',
        'Transaction Deleted',
        0
      );
    }, (error) => {
      console.log(error);
    });
  }

  reject() {
    this.showConfirmDialog = false;
  }

  pushNewMessage(message: Message) {
    this.msgs = [];
    this.msgs.push(message);
  }

  onMessageClose(event: any) {
    this.msgs = [];
  }

  clickDownladFile() {
    this.showpanel = false;
    if (this.selectedDownload === 'PDF') {
      this.createPieChart();
    } else if (this.selectedDownload === 'EXCEL') {
      // this.downloadExcel();
      this.downloadExcelNew();
    }
    setTimeout(() => {
      this.showpanel = true;
    }, 500);

  }

  downloadPdf() {
    const options: any = {};
    if (this.textSearchStr) {
      options.searchText = this.textSearchStr;
    }
    if (this.selectedTransactionType) {
      options.transactionTypeId = this.selectedTransactionType;
    }
    if (this.fromDate) {
      options.fromDate = moment(this.fromDate).format('YYYY-MM-DD');
    }
    if (this.toDate) {
      options.toDate = MOMENT(this.toDate).endOf('day').utc(true);
    }
    if (this.selectedCreatedBy) {
      options.createdByUsr = this.selectedCreatedBy;
    }
    if (this.selectedProject) {
      options.projectName = this.selectedProject;
    }
    options.pieChart = this.pieChart;
    options.graph = this.barChart;

    if (this.isPlatformAdmin) {
      options.companyName = this.selectedCompany ? this.selectedCompany.value : '';
    } else {
      options.companyName = this.authService.getCurrentCompany().name;
    }

    if (!this.isPlatformAdmin) {
      options.companyId = this.authService.getCurrentCompany();
    }

    if (this.isPlatformAdmin && this.selectedCompany) {
      options.companyId = this.selectedCompany.key;
    }

    if (this.selectedClient) {
      options.crmClientId = this.selectedClient.key;
    }
    if (this.selectedBillingAccount) {
      options.billingAccountId = this.selectedBillingAccount;
    }
    options.verifyList = this.selectedVerify;
    options.attachmentSelections = this.selectedAttachments;
    options.sortField = this.sortField;
    options.sortOrder = this.sortOrder === 1 ? 'ASC' : 'DESC';
    options.selectedShowOptions = this.selectedShowOptions;
    options.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    options.exportBy = this.authService.getCurrentUsername()
    options.exportDate = this.datePipe.transform(new Date(), 'MM/dd/yyyy EEE hh:mm a')
    options.transactionDetailList = this.filteredValues;
    options.transactionBalanceDTO = {
      totalCashIn: this.totalSum.cashInSum.toFixed(2),
      totalCashKept: this.totalSum.cashKeptSum.toFixed(2),
      totalCashOut: this.totalSum.cashOutSum.toFixed(2),
      totalCashInOut: this.totalSum.netAmtSum.toFixed(2)
    }

    this.exportLoading = true;
    this.transactionDetailsService.generatePdfFile(options).subscribe(res => {
      this.exportLoading = false;
      const blob = FileUtility.b64toBlob(res.data.body, 'application/pdf');
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl, '_blank');
    }, () => {
      this.exportLoading = false;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Export pdf fail!' });
    });
    this.selectedDownload = null;
  }

  downloadExcel() {
    let options: any = {};
    options = JSON.parse(JSON.stringify(this.requestFilter));
    options.page = 0;
    options.size = this.totalRecords;
    // this.transactionDetailsService.loadLists(options).subscribe(res => {
    //     const resObj: any = res;
    this.converDataToExcelFormat(this.transactions);
    // });
    this.selectedDownload = null;
  }

  converDataToExcelFormat(data: any[]) {
    const excelData = [];
    for (let i = 0; i < data.length; i++) {
      const trans = data[i];
      const excelTrans: any = {};
      excelTrans['Date'] = new Date(trans.date);
      excelTrans['Transaction'] = '';
      if (trans.transactionTypeId && this.allTransactionTypes) {
        const transctionType = this.allTransactionTypes.find(tracsType => tracsType.value === trans.transactionTypeId);
        if (transctionType) {
          excelTrans['Transaction'] = transctionType.label;
        }
      }
      excelTrans['Cash In'] = trans.cashIn ? trans.cashIn.toFixed(2) : '';
      excelTrans['Cash Kept'] = trans.cashKept ? trans.cashKept.toFixed(2) : '';
      excelTrans['Cash Out'] = trans.cashOut ? trans.cashOut.toFixed(2) : '';
      excelTrans['Net Cash in/out'] = trans.netCashInOut ? trans.netCashInOut.toFixed(2) : '';
      excelData.push(excelTrans);
    }
    this.transactionDetailsService.exportAsExcelFile(excelData, 'Transactions');
  }

  createPieChart() {
    if (this.availableBalance <= 0) {
      this.availableBalance = 0;
    }
    if (this.presentBalance <= 0) {
      this.presentBalance = 0;
    }
    if (this.previousDayBalance <= 0) {
      this.previousDayBalance = 0;
    }
    if (this.difference <= 0) {
      this.difference = 0;
    }

    const data = google.visualization.arrayToDataTable([
      ['Task', 'Hours per Day'],
      ['Accountant Balance', this.availableBalance],
      ['Present Balance', this.presentBalance],
      ['Previous Balance', this.previousDayBalance],
      ['Difference', this.difference]
    ]);

    const options = { 'title': ' ', 'width': 450, 'height': 300 };
    const chart = new google.visualization.PieChart(document.getElementById('piechart'));
    chart.draw(data, options);
    this.pieChart = chart.getImageURI();
    this.createbarChart();
  }

  createbarChart() {
    document.getElementById('barChar').style.display = 'block';
    google.charts.load('current', { packages: ['corechart'] });
    const data = google.visualization.arrayToDataTable([
      ['Year', '', { role: 'style' }],
      ['2013', this.availableBalance, '#c9372c'],
      ['2014', this.presentBalance, '#7ecca9'],
      ['2015', this.previousDayBalance, '#dbcd72'],
      ['2016', this.difference, '#5283bf']
    ]);
    const view = new google.visualization.DataView(data);
    view.setColumns([0, 1,
      {
        calc: 'stringify',
        sourceColumn: 1,
        type: 'string',
        role: 'annotation'
      },
      2]);

    const options = { 'title': 'Insurance Payments in Fires', 'width': 600, 'height': 300 };
    const chart = new google.visualization.ColumnChart(document.getElementById('barChar'));
    chart.draw(view, options);
    this.barChart = chart.getImageURI();
    document.getElementById('barChar').style.display = 'none';
    this.downloadPdf();
  }

  loadAllAdminNameList() {
    const pafc = this;
    const params: any = {};
    if (!this.isPlatformAdmin) {
      params.companyId = this.authService.getCurrentCompanyId();
    }
    this.companyAdminService.getAllAdminList(params).subscribe((res) => {
      const resObj: any = res;
      this.adminList = resObj.data;
      this.allAdminNameList = [];
      const allAdminNameList = [];
      if (resObj.status === 'SUCCESS') {
        resObj.data.forEach((admin) => {
          allAdminNameList.push({ label: admin.userName, value: admin.userName });
        });
      }
      this.allAdminNameList = _.sortBy(allAdminNameList, 'label');
      this.allAdminNameList = this.allAdminNameList.filter((val) => !val.label.includes('_')).map((val) => {
        return val;
      });
    });
  }

  loadAllCompanies() {
    this.companyService.getCompaniesDropdown({}).subscribe(res => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        this.allCompanyList = resObj.data;
        this.allCompanyList = _.sortBy(resObj.data, 'value');
        this.getAllInvoices()
      }
    });
  }

  getClientList() {
    const options: any = { status: 1 };
    if (this.selectedCompany) {
      options.companyId = this.selectedCompany.key;
    }
    if (!this.isPlatformAdmin) {
      options.companyId = this.authService.getCurrentCompanyId();
    }
    this.dropDownsService.getAllClientList(options).subscribe(res => {
      const resObj: any = res;
      this.clientList = [];
      if (resObj.status === 'SUCCESS') {
        this.clientList = resObj.data;
        this.clientList = _.sortBy(resObj.data, 'value');
      }
    });
  }

  changeCompany() {
    if (this.selectedCompany) {
      this.getClientList();
      this.loadAllCreatedByUser();
    }
  }

  loadAllCreatedByUser() {
    let companyId = this.authService.getCurrentCompanyId();
    if (!this.isPlatformAdmin) {
      companyId = this.authService.getCurrentCompany();
    }
    if (this.isPlatformAdmin && this.selectedCompany) {
      companyId = this.selectedCompany.key;
    }
    this.transactionDetailsService.findAllCreatedByContractorInvoiceByCompanyId(companyId).subscribe((res) => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS' && resObj.data.length > 0) {
        for (const user of resObj.data) {
          this.allCreatedByUser.push({
            label: user.fullName + ', (' + user.value + ')',
            contractorInvoiceId: user.contractorInvoiceId,
            value: user.key, username: user.value
          });
        }
      }
    });
  }

  searchList() {
    this.loadLazy(2);
    this.monitoringDetailsService.monitorAction(
      `Transaction Search`,
      this.timeSpent,
      {
        transaction_searched_by: this.authService.getCurrentLoggedInName()
      },
      'complete',
      'Transaction Search',
      0
    );
  }

  formatDate(date: any) {
    if (date) {
      // return moment(date).format('MM/DD/YYYY HH:mm');
      return moment(date).format('MM/DD/YYYY hh:mm:ss a');
    }
    return null;
  }

  checkPage() {
    this.totalSum = {
      cashInSum: 0,
      cashKeptSum: 0,
      cashOutSum: 0,
      netAmtSum: 0
    };
    this.transactions?.forEach(ele => {
      this.totalSum.cashInSum = parseFloat(this.totalSum.cashInSum + ele.cashIn);
      this.totalSum.cashKeptSum = parseFloat(this.totalSum.cashKeptSum + ele.cashKept);
      this.totalSum.cashOutSum = parseFloat(this.totalSum.cashOutSum + ele.cashOut);
      this.totalSum.netAmtSum = parseFloat(this.totalSum.netAmtSum + ele.netCashInOut);
    });
  }

  reloadBalanceStatistic() {
    this.totalBalanceStatistics = 0;
    const statistics: any = {
      first30: 0,
      first60: 0,
      first90: 0,
      first120: 0,
      last120: 0
    };
    this.transactions.forEach(t => {
      const diff = moment().diff(moment(t.date), 'd');
      if (diff >= 0) {
        this.totalBalanceStatistics += t.netCashInOut;
        if (diff < 30) {
          statistics.first30 += t.netCashInOut;
        } else if (diff < 60) {
          statistics.first60 += t.netCashInOut;
        } else if (diff < 90) {
          statistics.first90 += t.netCashInOut;
        } else if (diff < 120) {
          statistics.first120 += t.netCashInOut;
        } else {
          statistics.last120 += t.netCashInOut;
        }
      }
    });
    this.statementBalanceStatistics = [];
    this.statementBalanceStatistics.push(statistics);
  }

  onChangeUploadOption(event, data) {
    this.selectedTransactionDetail = data;
    if (event.value.label === 'View') {
      this.onViewDocument();
    }
    if (event.value.label === 'Upload' || event.value.label === 'Change') {
      if (event.value.label === 'Change') {
        this.actionHistoryFile = 'Changed';
      } else {
        this.actionHistoryFile = 'Updated';
      }
      this.selectFile();
    }
    if (event.value.label === 'Remove') {
      this.actionHistoryFile = 'Removed';
      this.onRemoveDocument();
    }
    if (event.value.label === 'History') {
      this.openViewHistory();
    }
    data.uploadOption = null;
    this.selectedTransactionDetail.uploadOption = {};
  }

  openViewHistory() {
    this.historyFor = {};
    this.showHistoryUploadDialog = true;
    this.historySelectedField = 'fileUrl';
    this.fieldsName = [
      { label: 'Document', value: 'fileUrl' },
    ];
    this.loadDataSource();
  }

  loadDataSource(event?: any) {
    this.historySize = event && event.rows ? event.rows : (this.historySize ? this.historySize : 25);
    this.historyPage = event && event.first && event.rows ? (event.first / event.rows) : (this.historyPage ? this.historyPage : 0);
    const options: any = {
      size: this.historySize,
      page: this.historyPage,
    };
    options.fromDate = this.historyFromDate ? new Date(this.historyFromDate) : null;
    options.toDate = this.historyToDate ? new Date(this.historyToDate) : null;
    options.transactionDetailsId = this.selectedTransactionDetail.id;
    options.fieldName = this.historySelectedField;
    if (!options.transactionDetailsId || !this.historySelectedField) {
      this.dataSourceSubject.next([]);
      return;
    }
    this.transactionDetailsHistoryService.getTransactionDetailsHistory(options).subscribe(
      (data: any) => {
        if (data.data.content) {
          data.data.content.forEach(ele => {
            if (ele.fieldName === 'fileUrl') {
              if (ele.oldValue) {
                this.documentsService.getUploadedFile(ele.oldValue).subscribe((res: any) => {
                  ele.oldValue = res.data.fileUrl;
                });
              }
              if (ele.newValue) {
                this.documentsService.getUploadedFile(ele.newValue).subscribe((res: any) => {
                  ele.newValue = res.data.fileUrl;
                });
              }
            }
          });
          this.dataSourceSubject.next(data.data);
        }
      }
    );
  }

  onViewDocument() {
    if (this.selectedTransactionDetail.fileUrl.includes('.pdf')) {
      window.open(this.selectedTransactionDetail.fileUrl);
    } else {
      if (this.selectedTransactionDetail.fileUrl.includes('http')) {
        this.fileUrl = this.selectedTransactionDetail.fileUrl;
        this.showViewFileDialog = true;
      } else {
        this.documentsService.getUploadedFile(this.selectedTransactionDetail.fileUrl).subscribe(res => {
          const resObj: any = res;
          if (resObj.status === 'SUCCESS') {
            if (resObj.data.fileType === 'pdf') {
              window.open(resObj.data.fileUrl);
            } else {
              this.fileUrl = resObj.data.fileUrl;
              this.showViewFileDialog = true;
            }
          }
        }, error => {
          // error
        });
      }
    }

  }

  selectFile() {
    document.getElementById('fileUrl').click();
  }

  closeViewFileDialog() {
    this.showViewFileDialog = false;
  }

  onSelectDocument(event) {
    if (event.target.files.length === 0) {
      return;
    }
    if (event.target.files[0].size > 1048576) {
      this.messageService.add({
        severity: 'error',
        summary: this.translatePipe.transform('Invalid File Size'),
        detail: this.translatePipe.transform('File size is too big. Note: File must be 1000 KB in size or smaller')
      });
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    this.documentServicev2.uploadFile(event.target.files[0], 'transaction_file', this.selectedCompany ? this.selectedCompany.key : 1, 'Company')
      .subscribe(res => {
        const resObj: any = res;
        if (resObj.status === 'SUCCESS') {
          this.selectedTransactionDetail.fileUrl = resObj.data.id;
          this.selectedTransactionDetail.action = this.actionHistoryFile;
          this.transactionDetailsService.updateTransactionDetail(this.selectedTransactionDetail, this.selectedTransactionDetail.id).subscribe((resChild: any) => {
            const resObjChild: any = resChild;
            if (resObjChild.status === 'SUCCESS') {
              this.messageService.add({ severity: 'success', summary: this.translatePipe.transform('Created'), detail: this.translatePipe.transform('SuccessfullyUploaded') });
            }
          });
        }
      });
  }

  onRemoveDocument() {
    if (this.selectedTransactionDetail.fileUrl) {
      this.selectedTransactionDetail.fileUrl = null;
      this.selectedTransactionDetail.action = 'Removed';
      this.transactionDetailsService.updateTransactionDetail(this.selectedTransactionDetail, this.selectedTransactionDetail.id).subscribe((res: any) => {
        const resObj: any = res;
        if (resObj.status === 'SUCCESS') {
          this.messageService.add({ severity: 'info', summary: this.translatePipe.transform('File Delete'), detail: this.translatePipe.transform('The attached file has been removed') });
        }
      });
    }
  }

  checkType(value, fieldName) {
    if (fieldName === 'date') {
      return moment(parseInt(value)).format('MM/DD/yyyy');
    } else if (fieldName === 'status') {
      return value === '1' ? 'Active' : 'Inactive';
    } else {
      return value;
    }
  }
  viewFullFile(url) {
    if (url.endsWith('.pdf')) {
      window.open(url);
    } else if (url.endsWith('.xlsx')) {
      window.location.href = url;
    } else {
      this.fileUrl = url;
      this.showViewFileDialog = true;
    }
  }

  openViewGraphDialog() {
    this.showViewGraphDialog = true;
    this.getWeekDays();
    console.log('viewGraphDataLabels: ', this.viewGraphDataLabels);
  }
  closeViewGraphDialog() {
    this.resetDataViewGraph();
    this.showViewGraphDialog = false;
  }

  async getWeekDays() {
    this.viewGraphLoading = true;
    // Lấy ngày hiện tại
    const today = this.currentDay;

    // Lấy chỉ số ngày trong tuần (0 - 6)
    const dayOfWeek = today.getDay();

    // Tính toán ngày đầu tiên trong tuần (Thứ 2)
    const monday = new Date(today);
    monday.setDate(today.getDate() - dayOfWeek + 1);

    // Tạo một mảng chứa các ngày trong tuần
    const weekDays = [];
    for (let i = 0; i < 7; i++) {
      const date = new Date(monday);
      date.setDate(monday.getDate() + i);
      const dateS = moment(date).format('MM/DD/yyyy');
      weekDays.push(dateS);
    }
    this.viewGraphDataLabels = weekDays;
    const getViewGraphRes = await this.transactionDetailsService.getViewGraph({ listDate: this.viewGraphDataLabels }).toPromise();
    if (getViewGraphRes.status === 'SUCCESS') {
      if (getViewGraphRes.data) {
        if (getViewGraphRes.data.code === '00') {
          this.viewGraphDatasets = getViewGraphRes.data.data;
        } else {
          this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('Error'), detail: getViewGraphRes.data.message });
        }
      }
    }
    this.getViewGraphData(this.viewGraphDataLabels, this.viewGraphDatasets);
    // this.viewGraphChart.reinit();

    this.viewGraphLoading = false;
  }

  async previousWeekDays() {
    this.viewGraphLoading = true;

    const day = new Date(this.currentDay);
    day.setDate(this.currentDay.getDate() - 7);

    // Lấy chỉ số ngày trong tuần (0 - 6)
    const dayOfWeek = day.getDay();

    // Tính toán ngày đầu tiên trong tuần (Thứ 2)
    const monday = new Date(day);
    day.setDate(day.getDate() - dayOfWeek + 1);

    // Tạo một mảng chứa các ngày trong tuần
    const weekDays = [];
    for (let i = 0; i < 7; i++) {
      const date = new Date(monday);
      date.setDate(monday.getDate() + i);
      const dateS = moment(date).format('MM/DD/yyyy');
      weekDays.push(dateS);
    }
    this.viewGraphDataLabels = weekDays;
    this.apiGetViewGraph();

    const getViewGraphRes = await this.transactionDetailsService.getViewGraph({ listDate: this.viewGraphDataLabels }).toPromise();
    if (getViewGraphRes.status === 'SUCCESS') {
      if (getViewGraphRes.data) {
        if (getViewGraphRes.data.code === '00') {
          this.viewGraphDatasets = getViewGraphRes.data.data;
        } else {
          this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('Error'), detail: getViewGraphRes.data.message });
        }
      }
    }
    this.getViewGraphData(this.viewGraphDataLabels, this.viewGraphDatasets);
    // this.viewGraphChart.reinit();

    this.viewGraphLoading = false;
  }

  getViewGraphData(labels: any[], datasets: any[]) {
    this.viewGraphData = {
      labels: labels,
      datasets: datasets
    };
    this.viewGraphOptions = {
      tooltips: {
        callbacks: {
          label: function (t, data) {
            const dataset: any = data.datasets[t.datasetIndex];
            const currentValue = dataset.data[t.index];
            return 'Daily payment' + ': ' + (currentValue && currentValue > 0 ? '$' + (currentValue).toFixed(2) : '0');
          }
        }
      },
      responsive: false,
      maintainAspectRatio: false,
      aspectRatio: 0.8,
      plugins: {
        legend: {
          labels: {
            color: this.textColor,
          },
        },
      },
      scales: {
        xAxes: [{
          stacked: true,
          ticks: {
            color: this.textColorSecondary
          },
          grid: {
            color: this.surfaceBorder,
            drawBorder: false
          }
        }],
        yAxes: [{
          stacked: true,
          ticks: {
            color: this.textColorSecondary,
            callback: (label, index, labels) => {
              return (label && label > 0 ? '$' + (label).toFixed(2) : '0');
            }
          },
          grid: {
            color: this.surfaceBorder,
            drawBorder: false
          }
        }],
      },
    };
  }

  resetDataViewGraph() {
    this.viewGraphData = {};
    this.viewGraphOptions = {};
  }

  apiGetViewGraph() {

    this.transactionDetailsService.getViewGraph({ listDate: this.viewGraphDataLabels }).subscribe(res => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        if (resObj.data) {
          if (resObj.data.code === '00') {
            console.log('getViewGraph: ', resObj.data.data);
            this.viewGraphDatasets = resObj.data.data;
          } else {
            this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('Error'), detail: resObj.data.message });
          }
        }
      }
    });
  }

  openConfirmationPopup(type?, id?) {
    this.confirmationId = id;
    this.confirmationType = type;
    this.showConfirmationPopup = (type === '1' || type === '2');
  }

  confirmTransaction() {
    if (this.confirmationId && this.confirmationType) {
      const verification1st = this.confirmationType === '1' ? this.authService.getCurrentUsername() : '';
      const verification2st = this.confirmationType === '2' ? this.authService.getCurrentUsername() : '';
      this.transactionDetailsService.updateTransactionConfirm({ id: this.confirmationId, verification1st, verification2st }).subscribe(res => {
        const resObj: any = res;
        if (resObj.status === 'SUCCESS') {
          this.messageService.add({
            severity: 'success',
            summary: this.translatePipe.transform('Success'),
            detail: this.translatePipe.transform('Transaction confirm successfully')
          });
        } else {
          this.messageService.add({
            severity: 'error',
            summary: this.translatePipe.transform('Error'),
            detail: this.translatePipe.transform('Transaction confirm failure')
          });
        }
        this.loadLazy(1);
        this.openConfirmationPopup();
      }, (error) => {
        this.loadLazy(1);
        this.openConfirmationPopup();
        this.messageService.add({
          severity: 'error',
          summary: this.translatePipe.transform('Error'),
          detail: error.error.message
        });
      })
    }
  }

  getAccountList() {
    const options: any = {};
    this.billingAccounts = [];
    this.accountService.getAccoundDropdown(options).subscribe(res => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        resObj.data.forEach(ele => {
          this.billingAccounts.push({ label: ele.account + ' (' + ele.beneficiaryName + ')', value: ele.id });
        });
      }

    });
  }

  async getClientBase() {
    if (this.transactions && this.transactions.length > 0 && this.selectedShowOptions) {
      let invoiceIds: number[] = [];
      this.transactions.forEach(t => {
        if (t.invoiceLst)
          invoiceIds.push(...t.invoiceLst.split(',').map(Number))
        else
          invoiceIds.push(Number(t.invoiceId))
      });

      let options: any = {
        page: 0,
        size: 999,
        invoiceIds: invoiceIds
      };

      this.contentService.exportInvoices(options).subscribe(res => {
        const resObj: any = res;
        const contentList = resObj.data.content;
        this.transactions.forEach(data => {

          data.nameClient = this.clientList.find(c => c.key === data.transaction.crmClientId)?.fullName;

          if (data?.type == 'group' && data?.invoiceLst) {
            const invoices = data.invoiceLst.split(',');
            data.invoiceNumber = contentList.filter(c => invoices.includes(c.id)).map(i => i.invoiceNumber)?.join(',')
          } else {
            data.invoiceNumber = contentList.find(c => data.invoiceId === c.id)?.invoiceNumber;
          }
        });
      });
    }
  }

  clickInvoiceRef(event: any, rowData) {
    console.log('clickInvoiceRef: ', rowData);
    this.op3.toggle(event);
    this.invoiceRef = rowData;
  }

  invoiceRefAction(type: string) {
    switch (type) {
      case 'view': this.exportPdf(this.invoiceRef);
        break;
      case 'download': this.downloadInvoiceRefPdf();
        break;
    }
  }

  exportPdf(invoice) {
    this.op3.hide();
    if (invoice.pdfLink) {
      window.open(invoice.pdfLink, '_blank');
    } else {
      this.contentService.exportPdf(invoice.invoiceId).subscribe(res => {
        if (res.data) {
          const blob = FileUtility.b64toBlob(res.data.body, 'application/pdf');
          const blobUrl = URL.createObjectURL(blob);
          window.open(blobUrl, '_blank');
        }
      });
    }
  }
  downloadInvoiceRefPdf() {
    this.op3.hide();
    this.contentService.exportPdfWithName(this.invoiceRef.invoiceId).subscribe(res => {
      if (res.data) {
        const blob = FileUtility.b64toBlob(res.data.blob.body, 'application/pdf');
        const blobUrl = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = blobUrl;
        a.target = '_blank';
        let fileName = 'Dummy.pdf';
        if (res.data.fileName && res.data.fileName.length > 0) {
          fileName = res.data.fileName;
        }
        a.download = fileName;
        a.click();
        const args = {
          ticket_invoice_download_by: this.authService.getCurrentLoggedInName(),
          Ticket_id: this.invoiceRef.invoiceId,
        };
      }
    });
  }

  sortingClickNew(selectedSortOption) {
    this.selectedSortOption = selectedSortOption;
    this.loadLazy('1');
  }

  getAdminName(username) {
    if (username) {
      const admin = this.adminList.find(el => el.userName === username);
      if (admin) { return admin.name; }
    }
    return username;
  }

  onSelectColumn(event?: any) {
    if (!this.userConfig)
      this.userConfig = {};

    this.userConfig.userId = this.authService.getUserInfo().adminId;
    this.userConfig.page = 'TRANSACTION';
    this.userConfig.jsonData = JSON.stringify({selectedShowOptions: this.selectedShowOptions});

    this.commonService.save(this.userConfig).subscribe(() => {
      this.changeCols();
      this.getClientBase();
    });
  }

  getUserConfig() {
    const req = {
      userId: this.authService.getUserInfo().adminId,
      page: 'TRANSACTION',
    }

    this.commonService.getByUserIdAndPage(req).subscribe(res => {
      this.userConfig = res.data;
      if (this.userConfig) {
        this.selectedShowOptions = JSON.parse(this.userConfig.jsonData)?.selectedShowOptions
        this.changeCols();
        const interval = setInterval(() => {
          this.getClientBase();

          if (interval)
            clearInterval(interval)
        }, 1000);
      }
    });
  }

  changeCols() {
    if (this.selectedShowOptions && this.selectedShowOptions.length === 1 && this.selectedShowOptions[0] === 'invoice') {
      this.cols = [
        { field: 'date', label: 'Created Date', sortOptions: '', sort: 'number' },
        { field: 'transactionTypeName', label: 'Transaction', sortOptions: '', sort: 'text', width: '13%' },
        { field: 'cashIn', label: 'Cashin', sortOptions: '', sort: 'number', width: '12%' },
        { field: 'cashKept', label: 'Cashkept', sortOptions: '', sort: 'number' },
        { field: 'cashOut', label: 'CashOut', sortOptions: '', sort: 'number' },
        { field: 'netCashInOut', label: 'netCashInOut', sortOptions: '', sort: 'number', width: '15%' },
        { field: 'createdByUsr', label: 'createdBy', sortOptions: '', sort: 'text', width: '12%' },
        { field: 'invoiceNumber', label: 'Invoice #', sortOptions: '', sort: 'text', width: '12%' },
        { field: 'transaction.transactionDate', label: 'Transaction Date', sortOptions: '', sort: 'number' },
        { field: 'document', label: 'Upload' },
        { field: 'verification1st', label: '1st Verification', sortOptions: '', sort: 'text', width: '12%' },
        { field: 'verification2st', label: '2nd Verification', sortOptions: '', sort: 'text', width: '12%' },
        { field: 'actions', label: 'Actions' }
      ];
    } else if (this.selectedShowOptions && this.selectedShowOptions.length === 1 && this.selectedShowOptions[0] === 'client') {
      this.cols = [
        { field: 'date', label: 'Created Date', sortOptions: '', sort: 'number' },
        { field: 'transactionTypeName', label: 'Transaction', sortOptions: '', sort: 'text', width: '13%' },
        { field: 'cashIn', label: 'Cashin', sortOptions: '', sort: 'number', width: '12%' },
        { field: 'cashKept', label: 'Cashkept', sortOptions: '', sort: 'number' },
        { field: 'cashOut', label: 'CashOut', sortOptions: '', sort: 'number' },
        { field: 'netCashInOut', label: 'netCashInOut', sortOptions: '', sort: 'number', width: '15%' },
        { field: 'createdByUsr', label: 'createdBy', sortOptions: '', sort: 'text', width: '12%' },
        { field: 'nameClient', label: 'Client', sortOptions: '', sort: 'text', width: '12%' },
        { field: 'transaction.transactionDate', label: 'Transaction Date', sortOptions: '', sort: 'number' },
        { field: 'document', label: 'Upload' },
        { field: 'verification1st', label: '1st Verification', sortOptions: '', sort: 'text', width: '12%' },
        { field: 'verification2st', label: '2nd Verification', sortOptions: '', sort: 'text', width: '12%' },
        { field: 'actions', label: 'Actions' }
      ];
    } else if (this.selectedShowOptions && this.selectedShowOptions.length === 2) {
      this.cols = [
        { field: 'date', label: 'Created Date', sortOptions: '', sort: 'number' },
        { field: 'transactionTypeName', label: 'Transaction', sortOptions: '', sort: 'text', width: '13%' },
        { field: 'cashIn', label: 'Cashin', sortOptions: '', sort: 'number', width: '12%' },
        { field: 'cashKept', label: 'Cashkept', sortOptions: '', sort: 'number' },
        { field: 'cashOut', label: 'CashOut', sortOptions: '', sort: 'number' },
        { field: 'netCashInOut', label: 'netCashInOut', sortOptions: '', sort: 'number', width: '15%' },
        { field: 'createdByUsr', label: 'createdBy', sortOptions: '', sort: 'text', width: '12%' },
        { field: 'invoiceNumber', label: 'Invoice #', sortOptions: '', sort: 'text', width: '12%' },
        { field: 'nameClient', label: 'Client', sortOptions: '', sort: 'text', width: '12%' },
        { field: 'transaction.transactionDate', label: 'Transaction Date', sortOptions: '', sort: 'number' },
        { field: 'document', label: 'Upload' },
        { field: 'verification1st', label: '1st Verification', sortOptions: '', sort: 'text', width: '12%' },
        { field: 'verification2st', label: '2nd Verification', sortOptions: '', sort: 'text', width: '12%' },
        { field: 'actions', label: 'Actions' }
      ];
    } else {
      this.cols = [
        { field: 'date', label: 'Created Date', sortOptions: '', sort: 'number' },
        { field: 'transactionTypeName', label: 'Transaction', sortOptions: '', sort: 'text', width: '13%' },
        { field: 'cashIn', label: 'Cashin', sortOptions: '', sort: 'number', width: '12%' },
        { field: 'cashKept', label: 'Cashkept', sortOptions: '', sort: 'number' },
        { field: 'cashOut', label: 'CashOut', sortOptions: '', sort: 'number' },
        { field: 'netCashInOut', label: 'netCashInOut', sortOptions: '', sort: 'number', width: '15%' },
        { field: 'createdByUsr', label: 'createdBy', sortOptions: '', sort: 'text', width: '12%' },
        { field: 'transaction.transactionDate', label: 'Transaction Date', sortOptions: '', sort: 'number' },
        { field: 'document', label: 'Upload' },
        { field: 'verification1st', label: '1st Verification', sortOptions: '', sort: 'text', width: '12%' },
        { field: 'verification2st', label: '2nd Verification', sortOptions: '', sort: 'text', width: '12%' },
        { field: 'actions', label: 'Actions' }
      ];
    }
  }

  async downloadExcelNew() {
    const options: any = {};
    this.dataExportList = [];
    if (this.textSearchStr) {
      options.searchText = this.textSearchStr;
    }
    if (this.selectedTransactionType) {
      options.transactionTypeId = this.selectedTransactionType;
    }
    if (this.fromDate) {
      options.fromDate = MOMENT(this.fromDate).startOf('day').utc(true);
    }
    if (this.toDate) {
      options.toDate = MOMENT(this.toDate).endOf('day').utc(true);
    }

    if (this.selectedCreatedBy) {
      options.username = this.selectedCreatedBy;
    }
    options.sortField = this.selectedField || 'id';
    options.sortOrder = this.selectedSortOption || 'DESC';

    if (!this.isPlatformAdmin) {
      options.companyId = this.authService.getCurrentCompany();
    }
    if (this.selectedProject) {
      options.projectId = this.selectedProject;
    }
    if (this.selectedCreatedBy) {
      options.createdByUsr = this.selectedCreatedBy;
    }
    if (this.isPlatformAdmin && this.selectedCompany) {
      options.companyId = this.selectedCompany.key;
    }
    if (this.selectedClient) {
      options.crmClientId = this.selectedClient.key;
    }
    if (this.selectedBillingAccount) {
      options.billingAccountId = this.selectedBillingAccount;
    }
    options.verifyList = this.selectedVerify;
    options.attachmentSelections = this.selectedAttachments;


    if (this.selectedByCreatedByUser) {
      const addBy = this.allCreatedByUser.find(i => i.value === this.selectedByCreatedByUser);
      const listString = addBy.contractorInvoiceId.split(',');
      const listLong = listString.map(i => parseInt(i, 10));
      options.contractorInvoiceIds = listLong;
    }

    this.exportLoading = true;
    const p1 = new Promise((resolve, reject) => this.transactionDetailsService.loadLists(options).subscribe(res => {
      resolve(res.data);
    }, () => {
      this.exportLoading = false;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Export excel fail!' });
      return;
    }));

    await Promise.all([p1]).then((values: any[]) => {
      console.log('downloadExcelNew data: ', values[0].content);
      this.dataExportList = values[0].content;

      const excelData = [];
      for (let i = 0; i < this.dataExportList.length; i++) {
        const trans = this.dataExportList[i];
        const excelTrans: any = {};
        excelTrans['Date'] = new Date(trans.date);
        excelTrans['Transaction'] = '';
        if (trans.transactionTypeId && this.allTransactionTypes) {
          const transctionType = this.allTransactionTypes.find(tracsType => tracsType.value === trans.transactionTypeId);
          if (transctionType) {
            excelTrans['Transaction'] = transctionType.label;
          }
        }
        excelTrans['Cash In'] = trans.cashIn ? trans.cashIn.toFixed(2) : '';
        excelTrans['Cash Kept'] = trans.cashKept ? trans.cashKept.toFixed(2) : '';
        excelTrans['Cash Out'] = trans.cashOut ? trans.cashOut.toFixed(2) : '';
        excelTrans['Net Cash in/out'] = trans.netCashInOut ? trans.netCashInOut.toFixed(2) : '';
        excelData.push(excelTrans);
      }
      this.transactionDetailsService.exportAsExcelFile(excelData, 'Transactions');
      this.exportLoading = false;
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Export Excel Success!' });
    }, ()=> {
      this.exportLoading = false;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Export excel fail!' });
      return;
    });
  }

  getAllInvoices() {
    const option = {
      companyId: !this.isPlatformAdmin ? this.authService.getCurrentCompanyId() : null
    }
    this.contentService.getAllInvoicesDropdown(option).subscribe(res => {

      this.allInvoicesDropdownData = (res?.data || []).filter(i => i.invoiceNumber).map(i => {
        const companyName = this.allCompanyList.find(c => c.key === i.companyId)?.value
        return {
          label: i.invoiceNumber + (companyName ? ' (' + companyName + ')' : ''),
          value: i.id
        }
      })
    })
  }
  
  @ViewChild('dt', {static: true}) table: Table;
  filteredValues = [];
  onFilter(event, dt) {
      this.filteredValues = event.filteredValue;
      this.totalRecords = this.filteredValues?.length || 0;
    this.totalSum = {
      cashInSum: (this.filteredValues || []).reduce((total, i) => total += (i.cashIn || 0), 0),
      cashKeptSum: (this.filteredValues || []).reduce((total, i) => total += (i.cashKept || 0), 0),
      cashOutSum: (this.filteredValues || []).reduce((total, i) => total += (i.cashOut || 0), 0),
      netAmtSum: (this.filteredValues || []).reduce((total, i) => total += (i.netCashInOut || 0), 0)
    };
  }
}
