import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ForgotPasswordService} from '../../service/forgot-password.service';
import { EmployeeForgotPasswordService } from '../../service/employee-forgot-password.service';
import * as Crypto from 'crypto-js';
import { ContractorForgotPasswordService } from 'app/forgot-password/service/contractor-forgot-password.service';
import { GuestForgotPasswordService } from 'app/forgot-password/service/guest-forgot-password.service';
import { ClientForgotPasswordService } from 'app/forgot-password/service/client-forgot-password.service';
@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
    providers: [ForgotPasswordService, EmployeeForgotPasswordService]
})
export class ResetPasswordComponent implements OnInit {

    configCaptcha:any = {
        type:1,
        length:6,
        cssClass:'custom',
        back: {
          stroke:"#2F9688",
          solid:"#f2efd2"
        } ,
        font:{
          color:"#000000",
          size:"35px",
          family: "Knewave"
        }
    };
    captch_input: any = null;
    code: any = null;
    rsCodeCaptcha: any = null;

    resetPassworReq: any = {
        password: String,
        rePassword: String
    };
    isEmployeeType = false;
    isCompanyType = false;
    loading = true;
    token: string;
    message: string;
    isShowPassword = false;
    isShowRePassword = false;
    successMsg: string;
    isContractorType: boolean = false;
    isClientType: boolean = false;
    isGuestType: boolean = false;
    maxLimit: boolean;
    expiredTokenMsg: string;
    constructor(private activeRouter: ActivatedRoute,
                private router: Router,
                public employeeForgotPasswordService: EmployeeForgotPasswordService,
                private forgotService: ForgotPasswordService,
                private contractorFgtPassService:ContractorForgotPasswordService,
                private guestForgotPasswordService:GuestForgotPasswordService,
                private clientForgotPasswordService:ClientForgotPasswordService) { }

    ngOnInit() {
        this.resetPassworReq.password = '';
        this.resetPassworReq.rePassword = '';

        this.activeRouter.params.subscribe(params => {
            this.token = params['secret'];
            console.log('Params', params);

            this.forgotService.checkValidToken(this.token).subscribe(
                data => {
                    this.loading = false;
                    this.isCompanyType = true;
                    this.successMsg = data.message;

                    if (data.status === 'CODE_EXPIRED') {
                        this.expiredTokenMsg = data.message;
                    }
                },
                error => {
                    this.loading = false;
                    // this.message = 'Unknowed Error';
                }
            );

            this.employeeForgotPasswordService.checkValidToken(this.token).subscribe(
                data => {
                    this.loading = false;
                    this.isEmployeeType = true;
                    this.successMsg = data.message;

                    if (data.status === 'CODE_EXPIRED') {
                        this.expiredTokenMsg = data.message;
                    }
                },
                error => {
                    this.loading = false;
                    // this.message = 'Unknowed Error';
                }
            );

            this.contractorFgtPassService.checkValidToken(this.token).subscribe(data => {
                    this.loading = false;
                    this.isContractorType = true;
                    this.successMsg = data.message;

                    if (data.status === 'CODE_EXPIRED') {
                        this.expiredTokenMsg = data.message;
                    }
                },
                error => { this.loading = false; }
            );
            this.clientForgotPasswordService.checkValidToken(this.token).subscribe(data => {
                    this.loading = false;
                    this.isClientType = true;
                    this.successMsg = data.message;

                    if (data.status === 'CODE_EXPIRED') {
                        this.expiredTokenMsg = data.message;
                    }
                },
                error => {this.loading = false; }
            );
            this.guestForgotPasswordService.checkValidToken(this.token).subscribe(data => {
                    this.loading = false;
                    this.isGuestType = true;
                    this.successMsg = data.message;

                    if (data.status === 'CODE_EXPIRED') {
                        this.expiredTokenMsg = data.message;
                    }
                },
                error => { this.loading = false; }
            );
        });
        this.createCaptcha();
    }

    resetPassword() {
        if (!this.checkCaptcha()) {
            return;
        }
        this.successMsg = '';
        this.loading = true;
        const pattern = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{10,})')
        if(!pattern.test(this.resetPassworReq.password)){
            this.loading = false;
            this.message = 'password should be 10 characters long with an upper case, a lower case , a number and a special character';
            return;
        }
        if (this.resetPassworReq.password !== this.resetPassworReq.rePassword) {
            this.loading = false;
            this.message = 'password not matched';
            return;
        }

        const req = {
            password: Crypto.MD5(this.resetPassworReq.password).toString(),
            rePassword: Crypto.MD5(this.resetPassworReq.rePassword).toString()
        };
        this.message = '';
        if (this.isCompanyType) {
            this.forgotService.resetPassword(this.token, req).subscribe(
                data => {
                    if (data.status === 'SUCCESS') {
                        this.successMsg = data.message;
                    } else {
                        this.message = data.message;
                    }
                    this.loading = false;
                },
                error => {},
                () => {
                    setTimeout(() => {
                        this.router.navigate(['/', 'login']);
                    }, 500);
                }
            );
        } else if (this.isEmployeeType) {
            this.employeeForgotPasswordService.resetPassword(this.token, req).subscribe(
                data => {
                    if (data.status === 'SUCCESS') {
                        this.successMsg = data.message;
                    } else {
                        this.message = data.message;
                    }
                    this.loading = false;
                },
                error => {},
                () => {
                    setTimeout(() => {
                        this.router.navigate(['/', 'login']);
                    }, 500);
                }
            );
        }else if (this.isContractorType) {
            this.contractorFgtPassService.resetPassword(this.token, req).subscribe(data => {
                    if (data.status === 'SUCCESS') {
                        this.successMsg = data.message;
                    } else {
                        this.message = data.message;
                    }
                    this.loading = false;
                },
                error => {},
                () => {
                   this.redirectUrl()
                }
            );
        }else if (this.isClientType) {
            this.clientForgotPasswordService.resetPassword(this.token, req).subscribe(data => {
                    if (data.status === 'SUCCESS') {
                        this.successMsg = data.message;
                    } else {
                        this.message = data.message;
                    }
                    this.loading = false;
                },
                error => {},
                () => {
                   this.redirectUrl()
                }
            );
        }else if (this.isGuestType) {
            this.guestForgotPasswordService.resetPassword(this.token, req).subscribe(data => {
                    if (data.status === 'SUCCESS') {
                        this.successMsg = data.message;
                    } else {
                        this.message = data.message;
                    }
                    this.loading = false;
                },
                error => {},
                () => {
                   this.redirectUrl()
                }
            );
        }
       
    }

    showPassword(){
        this.isShowPassword = !this.isShowPassword;
    }

    showRePassword(){
        this.isShowRePassword = !this.isShowRePassword;
    }

    redirectUrl(){
        setTimeout(() => {
            this.router.navigate(['/', 'login']);
        }, 500); 
    }

    checkInput(event){
        this.message = '';
        this.successMsg = '';
        if (event.target.value.length === 30) {
           this.maxLimit = true;
        } else {
            this.maxLimit = false;
        }
    }

    createCaptcha() {
        switch (this.configCaptcha.type) {
            case 1: // only alpha numaric degits to type
            let char =
                Math.random().toString(24).substring(2, this.configCaptcha.length) +
                Math.random().toString(24).substring(2, 4);
            this.code = this.rsCodeCaptcha = char.toUpperCase();
            break;
            case 2: // solve the calculation
            let num1 = Math.floor(Math.random() * 99);
            let num2 = Math.floor(Math.random() * 9);
            let operators = ['+', '-'];
            let operator = operators[Math.floor(Math.random() * operators.length)];
            this.code = num1 + operator + num2 + '=?';
            this.rsCodeCaptcha = operator == '+' ? num1 + num2 : num1 - num2;
            break;
        }

        setTimeout(() => {
            let captcahCanvas: any = document.getElementById('captcahCanvas');
            var ctx = captcahCanvas.getContext('2d');
            ctx.fillStyle = this.configCaptcha.back.solid;
            ctx.fillRect(0, 0, captcahCanvas.width, captcahCanvas.height);

            ctx.beginPath();

            captcahCanvas.style.letterSpacing = 12 + 'px';
            ctx.font = this.configCaptcha.font.size + ' Knewave';
            ctx.fillStyle = this.configCaptcha.font.color;
            ctx.textBaseline = 'middle';
            //ctx.textAlign = 'center';
            ctx.fillText(this.code, 40, 50);
            if (this.configCaptcha.back.stroke) {
                ctx.strokeStyle = this.configCaptcha.back.stroke;
                for (var i = 0; i < 150; i++) {
                    ctx.moveTo(Math.random() * 300, Math.random() * 300);
                    ctx.lineTo(Math.random() * 300, Math.random() * 300);
                }
                ctx.stroke();
            }
        }, 100);
    }
    
    playCaptcha() {
        var msg = new SpeechSynthesisUtterance(this.code.split('').join(' '));
        msg.pitch = 0.1;
        window.speechSynthesis.speak(msg);
    }
    
    checkCaptcha() {
        if (this.captch_input != this.rsCodeCaptcha) {
            this.guestForgotPasswordService.setCaptchaStatus(false);
            this.message = 'Captcha not matched. Please try again!';
            return false;
        } else {
            this.guestForgotPasswordService.setCaptchaStatus(true);
            this.message = '';
            return true;
        }
    }
	backToLogin() {
        this.router.navigate(['/', 'login']);
    }
}
