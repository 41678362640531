<p-toast position="bottom-right" class="concern"></p-toast>
<div class="layout-wrapper" (click)="onWrapperClick()" [ngClass]="{'layout-wrapper-static': layoutMode === 'static',
                'layout-wrapper-active': mobileMenuActive,
                'layout-menu-horizontal': layoutMode === 'horizontal',
                'layout-rtl': isRTL}" *ngIf="(isCheckURL && !isQuotePreviewPage) || (isCheckURL && isResetPasswordUrl)">


    <app-menu [reset]="resetMenu" *ngIf="(isAuthorized8 | async) && !isResetPasswordUrl && !isSignInOut"></app-menu>

    <div class="layout-main" [ngClass]="{'layout-punch-inout': (isAuthorized8 | async) === false}">
        <app-topbar *ngIf="(isAuthorized8 | async) && !isResetPasswordUrl && !isSignInOut"></app-topbar>
		<app-breadcrumb [hidden]="!((isAuthorized | async) && !isResetPasswordUrl && !isSignInOut && !isADashboard && !isComplianceDashboard && !isCEODashboard)"></app-breadcrumb>
	  
        <div class="layout-content" [ngClass]="(this.isADashboard || this.isComplianceDashboard || this.isCEODashboard) ? 'isDashboard' : ''">
            <div class="ui-g" *ngIf="vioLateTheLawDisplay">
                <div class="ui-g-12 text-center">
                    <b style="color:red">Your are on Violation of the labor department law.</b>
                    <br />
                    When the employee works for MORE than 6 hours, it's necessary to take a rest break of at least 30
                    minutes.
                </div>
            </div>
            <div class="ui-g" *ngIf="invoiceWarningMessage">
				<div class="ui-g-12 p-0">
					<div class="alert alert-danger alert-dismissible" role="alert">
						<div class="d-flex align-items-center justify-content-between">
							<h4 class="alert-heading">{{'Invoice Due' | translate}}</h4>
							<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
						</div>
						<p *ngIf="invoiceWarningMessage !== 'INVOICE_DUE_MESSAGE'">{{invoiceWarningMessage}}</p>
                        <div *ngIf="invoiceWarningMessage === 'INVOICE_DUE_MESSAGE'">
                            <p>Your Account is Currently Suspended due to non-payment.</p>
                            <p class="font-weight-bold">Balance Due: ${{totalUnPaidAmount}}</p>
                            <p>We recommended one time payment amount to help avoid any additional charge to any invoice that is not due yet.</p>
                            <p>You will only be able to access the billing section until a payment is made. If you have any questions or comments, please contact us atsupport@operr.com.</p>
                            <p class="font-weight-bold">Our billing department is available Monday-Friday 9:00 AM to 5:30PM.</p>
                        </div>
						<hr>
						<p class="mb-0">
                            <span *ngIf="invoiceWarningCountdown">
                                <countdown [config]="{ leftTime: invoiceWarningCountdown }" (event)="handleCountdownEvent($event)"></countdown>
                            </span>
                            <a *ngIf="isLockedTrustPayment" class="btn btn-white" (click)="openOutstandingBalancePayDialog()">{{'I Already Paid'}}</a>
                        </p>
					</div>
				</div>
            </div>
            <router-outlet></router-outlet>
        </div>
        <app-footer class="custom-footer" *ngIf="(isAuthorized | async) && !isResetPasswordUrl"></app-footer>
        <div class="layout-main-mask" *ngIf="mobileMenuActive"></div>
        <label style="display: none" id="logoutHiddenLabel" (click)="hideAllDialog()"></label>
    </div>
</div>
<div class="layout-wrapper" *ngIf="isCheckURL && isQuotePreviewPage">
    <div class="layout-content" style="height: 100vh;">
        <app-quote-preview [id]="quotePreviewId"></app-quote-preview>
    </div>
</div>
<div class="layout-wrapper" *ngIf="isCheckURL && isPayrollPreviewPage">
    <div class="layout-content" style="height: 100vh;">
        <app-payroll-journal-report-preview [id]="payrollPreviewId"></app-payroll-journal-report-preview>
    </div>
</div>
<div class="layout-wrapper" *ngIf="isCheckURL && isDispatchPotentialClientPage">
    <div class="layout-content" style="height: 100vh;">
        <app-dispatch-potential-client [id]="potentialClientId"></app-dispatch-potential-client>
    </div>
</div>
<div class="layout-wrapper" *ngIf="isCheckURL && isFidelisPotentialClientPage">
    <div class="layout-content" style="height: 100vh;">
        <app-fidelis-potential-client [id]="potentialClientId"></app-fidelis-potential-client>
    </div>
</div>
<div class="layout-wrapper" *ngIf="isCheckURL && isFidelisLookbackPotentialClientPage">
    <div class="layout-content" style="height: 100vh;">
        <app-fidelis-lookback-potential-client [id]="potentialClientId"></app-fidelis-lookback-potential-client>
    </div>
</div>
<div class="layout-wrapper" *ngIf="isCheckURL && isMasPotentialClientPage">
    <div class="layout-content" style="height: 100vh;">
        <app-mas-potential-client [id]="potentialClientId"></app-mas-potential-client>
    </div>
</div>

<div *ngIf="!isCheckURL" class="container">
    <div class="row justify-content-md-center align-self-center" style="text-align: center; display: inline;">
        <div class="col-md-auto" style="margin-top: 100px">
            <img src="../../../assets/images/warning-icon-svg-11.jpg" style="width: 100px;height: auto;"><br>
            <h3 translate>Sorry, you do not have permission to access this page.</h3>
        </div>
    </div>
</div>

<p-dialog header="{{'Confirmation' | translate}}" [(visible)]="employeeService.showConfirmAutoPayReminderDialog"
    class="modal_in" [modal]='true'>
    <div class="ui-g">
        {{'confirmAutoPayReminder' | translate}}
    </div>
    <p-footer>
        <div class="ui-g">
            <div class="ui-g-12">
                <button type="button" pButton label="{{'yes' | translate}}" (click)="acceptAutoPay()"></button>
                <button type="button" pButton label="{{'no' | translate}}" (click)="rejectAutoPay()"></button>
            </div>
        </div>
    </p-footer>
</p-dialog>

<p-dialog header="{{'Confirmation' | translate}}" [(visible)]="employeeService.payUnPaidInvoiceDialog" class="modal_in"
    [modal]='true' (onHide)="employeeService.payUnPaidInvoiceDialog = false">
    <div class="ui-g">
        Would you like to pay your current balance of {{totalUnPaidAmount | currency}}
    </div>
    <p-footer>
        <div class="ui-g">
            <div class="ui-g-12">
                <button type="button" pButton label="{{'yes' | translate}}" (click)="acceptPayUnPaidInvoice()"
                    [disabled]="autoPayPending"></button>
                <button type="button" pButton label="{{'no' | translate}}"
                    (click)="employeeService.payUnPaidInvoiceDialog = false"></button>
            </div>
        </div>
    </p-footer>
</p-dialog>

<p-dialog width="600px" [(visible)]="employeeService.invoiceWarningDialog" class="modal_in"
    (onHide)="checkDefaultPayment(unpaidInvoices, true)" [modal]='true' [closable]="!restrictedAccess">
    <div class="ui-g confirm p-2">
        <span>Sorry your account has been restricted due to your past Due outstanding balance</span>
    </div>
    <p-footer>
        <div class="ui-g">
            <div class="ui-g-12">
                <button type="button" pButton label="{{'ok' | translate}}" (click)="invoiceWarningClick()"></button>
            </div>
        </div>
    </p-footer>
</p-dialog>

<p-dialog width="600px" [(visible)]="employeeService.invoiceWarningDialog" class="modal_in"
    (onHide)="checkDefaultPayment(unpaidInvoices, true)" [modal]='true' [closable]="!restrictedAccess">
    <div class="ui-g confirm p-2">
        <span>Sorry your account has been restricted due to your past Due outstanding balance</span>
    </div>
    <p-footer>
        <div class="ui-g">
            <div class="ui-g-12">
                <button type="button" pButton label="{{'ok' | translate}}" (click)="invoiceWarningClick()"></button>
            </div>
        </div>
    </p-footer>
</p-dialog>

<p-dialog header="Register Child Client Account" [showHeader]="true" showEffect="fade" styleClass="w-50"
    [closable]="true" *ngIf="employeeService.showRegisterChildAccountDialog"
    [(visible)]="employeeService.showRegisterChildAccountDialog" modal="modal" [dismissableMask]="true">
    <form [formGroup]="childClientForm">
        <div class="row mx-0 mb-2 align-items-center">
            <div class="ui-g-12">
                <label>{{'Email' | translate}}<span class="required-indicator">*</span>:</label>
                <input type="text" pInputText formControlName="email" [(ngModel)]="childClient.email"
                    placeholder="{{'Email' | translate}}">
                <div class="text-danger"
                    *ngIf="childClientForm.controls['email'].invalid && childClientForm.controls['email'].touched && childClientForm.controls['email'].hasError('required')">
                    <span>
                        {{'validate.required' | translate:{value: 'Email' | translate} }}
                    </span>
                </div>
            </div>
        </div>
        <div class="row mx-0 mb-2 align-items-center">
            <div class="ui-g-12">
                <label>{{'username' | translate}}<span class="required-indicator">*</span>:</label>
                <input type="text" pInputText formControlName="clientChildUsername"
                    [(ngModel)]="childClient.childClientUsername" autocomplete="off"
                    placeholder="{{'username' | translate}}" (keyup)="isCharacter($event)" minlength="6"
                    (keypress)="isWhiteSpace($event)">
                <div class="text-danger">
                    <span *ngIf="childClientForm.controls['clientChildUsername'].hasError('required') && childClientForm.controls['clientChildUsername'].touched && !showErr ">
                        {{'validate.required' | translate:{value: 'username' | translate} }}
                    </span>
                    <span *ngIf="childClientForm.get('clientChildUsername').hasError('minlength') && !showErr">
                        Username should have minimum 6 characters
                    </span>
                    <span *ngIf="showErr" style="color: Red;">
                        Sorry, only letters (A-Z), numbers (0-9), and periods (.) are allowed
                    </span>
                </div>
            </div>
        </div>
        <div class="row mx-0 mb-2 align-items-center">
            <div class="ui-g-12">
                <label>{{'password' | translate}}<span class="required-indicator">*</span>:</label>
                <div *ngIf="showPwd" class="position-relative">
                    <input pInputText type="text" id="childClientPassword" formControlName="childClientPassword"
                        [(ngModel)]="childClient.childClientPassword" autocomplete="new-password"
                        name="childClientPassword" placeholder="Password" class="w-100"
                        (keypress)="isWhiteSpace($event)" />
                    <i class="fa-solid fa-eye-slash position-absolute"
                        style="right: 10px; top: 50%; margin-top: -0.5em !important;" (click)="showPassword()"></i>
                </div>
                <div *ngIf="!showPwd" class="position-relative">
                    <input pInputText type="password" id="childClientPassword" formControlName="childClientPassword"
                        [(ngModel)]="childClient.childClientPassword" autocomplete="new-password"
                        name="childClientPassword" placeholder="Password" class="w-100"
                        (keypress)="isWhiteSpace($event)">
                    <i class="fa-solid fa-eye position-absolute"
                        style="right: 10px; top: 50%; margin-top: -0.5em !important;" (click)="showPassword()"></i>
                </div>

                <div class="text-danger"
                    *ngIf="(childClientForm.controls['childClientPassword'].invalid)  && childClientForm.controls['childClientPassword'].touched && (childClientForm.controls['childClientPassword'].hasError('required'))">
                    <span>
                        {{'validate.required' | translate:{value: 'password' | translate} }}
                    </span>
                </div>
            </div>
        </div>

    </form>
    <p-footer>
        <div class="ui-g">
            <div class="ui-g-12 p-1" align="center">
                <button type="button" pButton label="Save" (click)="accept()"></button>
                <button type="button" pButton label="No" (click)="reject()"></button>
            </div>
        </div>
    </p-footer>
</p-dialog>

<p-dialog header="Outstanding Balance Payment" [showHeader]="true" showEffect="fade" styleClass="w-50" [closable]="true"
    *ngIf="employeeService.showOutstandingBalancePayDialog"
    [(visible)]="employeeService.showOutstandingBalancePayDialog" modal="modal" [dismissableMask]="true">
    <form [formGroup]="outstandingBalancePayForm" #outstandingBalancePayFormData [name]="outstandingBalancePayFormData">
        <div class="row mx-0 mb-2 align-items-center">
            <div class="ui-g-12">
                <label>{{'Payment Method' | translate}}<span class="required-indicator">*</span>:</label>
                <p-dropdown [options]="paymentMethod" [style]="{'width':'100%'}"
                    placeholder="{{'pleaseSelect' | translate}}" formControlName="paymentMethod"
                    [(ngModel)]="outstandingBalancePay.paymentMethod" [filter]="true">
                </p-dropdown>
                <div class="text-danger"
                    *ngIf="outstandingBalancePayForm.controls['paymentMethod'].invalid && outstandingBalancePayForm.controls['paymentMethod'].dirty && outstandingBalancePayForm.controls['paymentMethod'].hasError('required')">
                    <span>
                        {{'validate.required' | translate:{value: 'Payment Method' | translate} }}
                    </span>
                </div>
            </div>
        </div>
        <div class="row mx-0 mb-2 align-items-center">
            <div class="ui-g-12">
                <label>{{'Invoice Number' | translate}}<span class="required-indicator">*</span>:</label>
                <p-multiSelect style="width: 100%;" [options]="unpaidInvoices" formControlName="invoiceNumber"
                    (onChange)="outstandingBalanceInvoiceChange($event)" [(ngModel)]="outstandingBalancePay.invoices"
                    optionLabel="invoiceNumber" id="id_invoices_to_selected_label"></p-multiSelect>
                <div class="text-danger"
                    *ngIf="outstandingBalancePayForm.controls['invoiceNumber'].invalid && outstandingBalancePayForm.controls['invoiceNumber'].dirty && outstandingBalancePayForm.controls['invoiceNumber'].hasError('required')">
                    <span>
                        {{'validate.required' | translate:{value: 'Invoice Number' | translate} }}
                    </span>
                </div>
            </div>
        </div>

        <div class="row mx-0 mb-2 align-items-center">
            <div class="ui-g-12">
                <label>{{'Amount' | translate}}<span class="required-indicator">*</span>:</label>
                <input type="number" pInputText formControlName="amount" [(ngModel)]="outstandingBalancePay.amount"
                    (keyup)="calculateBalance()" (blur)="formatCurrency(outstandingBalancePay.amount)"
                    placeholder="{{'Amount' | translate}}">
                <div class="text-danger"
                    *ngIf="outstandingBalancePayForm.controls['amount'].invalid && outstandingBalancePayForm.controls['amount'].dirty && outstandingBalancePayForm.controls['amount'].hasError('required')">
                    <span>
                        {{'validate.required' | translate:{value: 'Amount' | translate} }}
                    </span>
                </div>
            </div>
        </div>

        <div class="row mx-0 mb-2 align-items-center">
            <div class="ui-g-12">
                <label>{{'Payment Date' | translate}}<span class="required-indicator">*</span>:</label>
                <mat-form-field class="w-100">
                    <input matInput (focus)="picker1.open()" [matDatepicker]="picker1" name="paymentDate"
                        [disabled]="readOnly" formControlName="paymentDate"
                        [(ngModel)]="outstandingBalancePay.paymentDate" [max]="maxPaymentDate">
                    <mat-datepicker-toggle matSuffix [for]="picker1">
                    </mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>
                <div class="text-danger"
                    *ngIf="outstandingBalancePayForm.controls['paymentDate'].invalid && outstandingBalancePayForm.controls['paymentDate'].dirty && outstandingBalancePayForm.controls['paymentDate'].hasError('required')">
                    <span>
                        {{'validate.required' | translate:{value: 'Payment Date' | translate} }}
                    </span>
                </div>
            </div>
        </div>

        <div class="row mx-0 mb-2 align-items-center">
            <div class="ui-g-12">
                <label>{{'Note' | translate}}<span class="required-indicator"
                        *ngIf="outstandingBalancePay.paymentMethod === 'other'">*</span>:</label>
                <textarea pInputTextarea style="width:100%" [rows]="7" class="acceptance-inputs" autoResize="true"
                    maxlength="250" placeholder="{{'Maximum length of notes is 250' | translate}}"
                    formControlName="note" [(ngModel)]="outstandingBalancePay.note">
                </textarea>
                <div class="text-danger"
                    *ngIf="outstandingBalancePayForm.controls['note'].dirty && outstandingBalancePay.paymentMethod === 'other' && (!outstandingBalancePay.note || outstandingBalancePay.note === '')">
                    <span>
                        {{'validate.required' | translate:{value: 'Note' | translate} }}
                    </span>
                </div>
            </div>
        </div>

        <div class="row mx-0 mb-2 align-items-center">
            <div class="ui-g-12">
                <div class="ui-g-12 p-4 text-center">
                    <div class="ui-g-12 pb-4 pt-4" *ngIf="outstandingBalancePay.documentUrl">
                        <div style="position: absolute; right:150px"><span class="material-icons"
                                (click)="openDialogDelete()" style="margin-top: -30px;">cancel </span></div>
                        <img alt="{{'profilePicture' | translate}}"
                            (click)="previewImage = outstandingBalancePay.documentUrl; visibleImage = true" width="250"
                            height="250" style="max-width: 200px;max-height: 200px;"
                            src="{{outstandingBalancePay.documentUrl}}" class="align-self-center profile-picture" />
                    </div>
                    <div class="ui-g-12 pt-0 pb-0" *ngIf="!outstandingBalancePay.documentUrl">
                        <img alt="{{'profilePicture' | translate}}" width="250" height="250"
                            src="assets/layout/images/default-avatar.png" class="align-self-center profile-picture" />
                    </div>
                </div>
                <div class="ui-g-12">
                    <p-fileUpload mode="basic" #documentImg name="documentImg" url="./upload.php" customUpload="true"
                        accept="image/*" (uploadHandler)="uploadDocumentImg($event, documentImg)" [disabled]="readOnly"
                        chooseLabel="Upload" auto="auto" [disabled]="uploadingImage">
                    </p-fileUpload>
                </div>
            </div>
        </div>

    </form>

    <p-footer>
        <div class="ui-g">
            <div class="ui-g-12 p-1" align="center">
                <button type="button" pButton label="Submit" (click)="validateOutstandingBalancePay()"
                    [disabled]="outstandingBalancePayForm.invalid || (outstandingBalancePay.paymentMethod === 'other' && (!outstandingBalancePay.note || outstandingBalancePay.note === ''))"></button>
                <button type="button" pButton label="Close" (click)="rejectOutstandingBalancePay()"></button>
            </div>
        </div>
    </p-footer>
</p-dialog>

<p-dialog header="Confirmation" [style]="{'width':'30%'}" [(visible)]="employeeService.balanceWaringDialog"
    modal='modal' [modal]='true'>
    <div class="ui confirm">
        You cannot add Pay more than the Invoice amount
    </div>
    <p-footer>
        <div class="ui-g-12">
            <button type="button" pButton label="{{'ok' | translate}}"
                (click)="employeeService.balanceWaringDialog = false"></button>
        </div>
    </p-footer>
</p-dialog>

<p-dialog class="show-image" [showHeader]="false" [(visible)]="visibleImage" [modal]="true" [dismissableMask]="true"
    [resizable]="true" [style]="{width: '40vw'}">
    <div><img [src]="previewImage" style="width: 100%;" /></div>
    <div class="close" (click)="visibleImage=false">X</div>
</p-dialog>

<p-dialog header="{{'Confirmation' | translate}}" width="500px" [(visible)]="showConfirmDialogImage" class="modal_in"
    [modal]='true'>
    <div class="ui-g confirm">
        {{'deleteConfirm' | translate}}
    </div>
    <p-footer>
        <div class="ui-g">
            <div class="ui-g-12" align="right">
                <button type="button" pButton label="{{'Yes' | translate}}" (click)="acceptDelete()"></button>
                <button type="button" pButton label="{{'No' | translate}}" (click)="closeDeleteDialog()"></button>
            </div>
        </div>
    </p-footer>
</p-dialog>

<p-dialog header="{{'Confirmation' | translate}}" width="500px" [(visible)]="showConfirmDialogImage" class="modal_in"
    [modal]='true'>
    <div class="ui-g confirm">
        {{'deleteConfirm' | translate}}
    </div>
    <p-footer>
        <div class="ui-g">
            <div class="ui-g-12" align="right">
                <button type="button" pButton label="{{'Yes' | translate}}" (click)="acceptDelete()"></button>
                <button type="button" pButton label="{{'No' | translate}}" (click)="closeDeleteDialog()"></button>
            </div>
        </div>
    </p-footer>
</p-dialog>


<p-dialog header="{{'Confirmation' | translate}}" width="400px" [(visible)]="showConfirmPaymentDialog" class="modal_in"
    [modal]='true'>
    <div class="text-center">
        <div>
            {{'Are you sure you your payment already submitted?' | translate}}
        </div>
        
        <div>
            <b>Note</b>: If for any reason your payment was not submitted and cannot find any proof of payment, this access will be remove to your account.
        </div>
    </div>
    <p-footer>
        <div class="ui-g">
            <div class="ui-g-12" align="right">
                <button type="button" pButton label="{{'Yes' | translate}}" (click)="acceptConfirmPayment()"></button>
                <button type="button" pButton label="{{'No' | translate}}" (click)="closeConfirmPayment()"></button>
            </div>
        </div>
    </p-footer>
</p-dialog>

<p-dialog header="{{'Confirmation' | translate}}" width="500px" [(visible)]="uploadProofOfPaymentDialog" class="modal_in"
    [modal]='true'>
    <div class="text-center">
        <div>
            {{'Please Upload Proof of payment for faster approval' | translate}}
        </div>
    </div>
    <p-footer>
        <div class="ui-g">
            <div class="ui-g-12 d-flex justify-content-end" align="right">
                <p-fileUpload mode="basic" #proofOfPaymentImg name="proofOfPaymentImg" url="./upload.php" customUpload="true"
                    accept="image/*" (uploadHandler)="uploadDocumentImg($event, proofOfPaymentImg, true)" [disabled]="readOnly"
                    chooseLabel="Upload" auto="auto" [disabled]="uploadingImage"> 
                </p-fileUpload>
                <button type="button" pButton label="{{'Send without upload' | translate}}" (click)="uploadProofOfPaymentDialog = false; showConfirmPaymentDialog = true"></button>
            </div>
        </div>
    </p-footer>
</p-dialog>
