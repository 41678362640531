import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, ElementRef, Input, OnInit, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslatePipe } from '@ngx-translate/core';
import { AccountService } from 'app/account/service/account.service';
import { MonitoringDetailsService } from 'app/admin/process-monitor/service/monitoring-details.service';
import { ContractorInvoiceService } from 'app/contractor/service/contractor-invoice.service';
import { ClientService } from 'app/crm/service/client.service';
import { PaymentInvoiceService } from 'app/payment/service/payment-invoice.service';
import { PaymentNotificationService } from 'app/payment/service/payment-notification.service';
import { ProjectService } from 'app/project/service/project.service';
import { DocumentsServicev2 } from 'app/shared/service/documents-upload/document.v2.service';
import { DocumentsService } from 'app/shared/service/documents-upload/documents.service';
import { TransactionDetailsService } from 'app/transaction-type/service/transaction-details.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import { MessageService } from 'primeng/api';
import { OverlayPanel } from 'primeng/primeng';
import { Table } from 'primeng/table';
import { Company } from '../../../company/model/company.model';
import { CompanyService } from '../../../company/service/company.service';
import { AuthService } from '../../../shared/service/auth/auth.service';
import { DropDownsService } from '../../../shared/service/drop-downs/drop-downs.service';
import { FormValidatorService } from '../../../shared/service/form-validator/form-validator.service';
import { TransactionDetail } from '../../model/transaction-detail.model';
import { Transaction } from '../../model/transaction.model';
import { TransactionTypeService } from '../../service/transaction-type.service';
import { TransactionService } from '../../service/transaction.service';
import { PayrollSettingService } from 'app/payroll-setting/service/payroll-setting.service';
import { CompanySettingServiceV2 } from 'app/company/service/company-setting.v2.service';
import { Dropdown } from 'primeng/dropdown';
import { ProjectModel } from 'app/project/model/project.model';
import { ExpenseService } from 'app/expense/service/expense.service';
import { VoterListService } from 'app/electoral-campaign/voter-list/service/voter-list.service';

@Component({
    selector: 'app-transaction-group-form',
    templateUrl: './transaction-group-form.component.html',
    styleUrls: ['./transaction-group-form.component.scss'],
    providers: [TransactionService, TransactionTypeService, DropDownsService, TransactionDetailsService, ContractorInvoiceService,
        FormValidatorService, AuthService, CurrencyPipe, PaymentInvoiceService, PayrollSettingService, VoterListService],
})
export class TransactionGroupFormComponent implements OnInit {
    @ViewChildren('upload') uploadFileRef: QueryList<ElementRef>;
    @Input() mode: String;
    @Input() readOnly: Boolean = false;
    @Input() transactionId: number;
    @Input() guestDataForm: ElementRef;
    @ViewChild('fdt', { static: true }) table: Table;
    msgs = [];
    transactionForm: UntypedFormGroup;
    transaction: Transaction;
    transactionDetails: TransactionDetail[] = [];
    transactionDetailsBkp: TransactionDetail[] = [];
    userPermission: boolean;
    statusTypes: Array<any> = [];
    allProjects = [];
    allExpenses: any = [];
    outStandingBalance: any;
    outStandingBalanceWithDiscount: any = '0.00';
    availableCredit = 0;
    availableCreditStr = '0.00';
    expenseCurrency = [];
    selectedCompany: Company;
    transactionTypes: any[] = [];
    allTransactionTypes: any[] = [];
    historyShow = false;
    easyPass: any[];
    amountFormat: string;
    addTollFee: any[] = [];
    signaturePadConfig: Object = {
        'minWidth': 1,
        'canvasWidth': 220,
        'canvasHeight': 110,
        'canvasBorder': 'none',
        'penColor': '#4a87f3'
    };
    disableToll: boolean;
    showLicenceeSign = true;
    canEditSign = true;
    considerToll = true;
    isPlatformAdmin: Boolean = false;
    disableAll = false;
    disableSave = false;
    allCompanyList: Company[] = [];
    selectedTransactionDetail: any;
    fileUrl: string;
    showViewFileDialog = false;
    allClientList: any[];
    selectedClient: any;
    allContractorInvoiceList: any[];
    selectedBillingInvoice: any;
    allBillingInvoice: any [];
    selectedContractorInvoice: any;
    totalCashOut = 0;
    showClient = true;
    showContractorInvoice = false;
    allProjectsList: any[];
    projectSelected: any;
    transactionDate = new Date();
    accounts: any;
    paymentMethod = [
        {label: 'Cash', value: 'cash'},
        {label: 'Credit/Debit', value: 'credit/Debit'},
        {label: 'Zelle', value: 'zelle'},
        {label: 'Check', value: 'check'},
        {label: 'Stripe', value: 'stripe'},
        {label: 'AHC', value: 'ahc'},
        {label: 'Other', value: 'other'}
    ];
    showConfirmDialog = false;
    showConfirmApplyCreditDialog = false;
    applyCreditDisable = true;
    timeSpent = new Date();
    isDisable: boolean = false;
    applyTime: Date;
    disableCash: boolean = false;
    selectedServiceBureauProject;

    allDiscountType = [
        { label: 'Amount', value: 'Amount' },
        { label: 'Percent %', value: 'Percent' },
        { label: 'NA', value: 'NA' }
    ];

    discountType;
    discountValue;
    showConfirmApplyDiscountDialog = false;
    lateFee = 0;
    totalLateFee = 0;
    totalLateFeeTxt = '0.00';
    showConfirmWaiveFeeDialog = false;
    amountAvailableSelectedAccount = 0;
    lastSelectedBillingAccount;
    selectedTransactionDetailsIndex;
    
    checkApiUsageFee = false;
    checkLateFee = false;

    apiUsageFee = 0;
    totalApiUsageFee = 0;
    totalApiUsageFeeTxt = '0.00';

    showFileHistoryDialog: boolean = false;
    transactionDetailId: any;
    transactionFileHistories: any;
    transactionFileIndex: any;

    deleteTransactionDetailsPopup = false;
    selectedTransactionDetailsId;
    selectedTransactionDetailsIdx;
    selectedExpenses;
    showExpenses = false;

    requestExpense: any = {};
    loadingExpense = false;
    optAllExpenses = [];

    @ViewChild('projectsDropdown') projectsDropdown: Dropdown;

    constructor(
        private documentServicev2: DocumentsServicev2,
        private renderer: Renderer2,
        private messageService: MessageService,
        private transactionService: TransactionService,
        private companyService: CompanyService,
        private transactionTypeService: TransactionTypeService,
        private router: Router,
        private formValidator: FormValidatorService,
        private authService: AuthService,
        private translatePipe: TranslatePipe,
        private contentService: PaymentInvoiceService,
        private contractorInvoiceService: ContractorInvoiceService,
        private dropDownsService: DropDownsService,
        private transactionDetailsService: TransactionDetailsService,
        private projectService: ProjectService,
        private accountService: AccountService,
        private monitoringDetailsService: MonitoringDetailsService,
        private clientService: ClientService,
        private paymentNotificationService: PaymentNotificationService,
        private payrollSettingService: PayrollSettingService,
        private companySettingService: CompanySettingServiceV2,
        private voterListService: VoterListService,
        private expenseService: ExpenseService,
        private currencyPipe: CurrencyPipe,
        private datePipe: DatePipe
        ) {
    }

    ngOnInit() {
        this.readOnly = this.mode === 'view' ? true : false;
        this.accountList();
        this.loadExpenses();
        this.transaction = new Transaction();
        this.isPlatformAdmin = this.authService.isSuper() || this.authService.isSubSuper();
        // this.selectedCompany = this.authService.getCurrentCompany() ? this.authService.getCurrentCompany() : null;
        this.allProjects = [
            { label: 'Service Bureau', value: 'Service Bureau' },
        ];

        this.allProjects = _.sortBy(this.allProjects, 'label');
        this.expenseCurrency = [{ label: 'USD', value: 'USD' }];
        this.transactionForm = new UntypedFormGroup({
            companyId: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            expenseCurrency: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            projectId: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            note: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            checkLateFee: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            checkApiUsageFee: new UntypedFormControl({ value: '', disabled: this.readOnly })
        });
        if (this.transactionId) {
            this.transactionService.getTransaction(this.transactionId).subscribe(res => {
                const resObj: any = res;
                if (resObj.data) {
                    console.log("Load edit", resObj.data?.invoiceLst?.split(",").map(Number))
                    this.transaction = Object.assign({}, resObj.data);
                    this.selectedBillingInvoice = resObj.data?.invoiceLst?.split(",").map(Number); 
                    this.transaction.projectId = resObj.data.projectId;
                    this.transaction.expenseCurrency = resObj.data.expenseCurrency;
                    this.transaction.note = resObj.data.note;
                    this.transaction.id = resObj.data.id;
                    this.transaction.createdAt = resObj.data.createdAt;
                    this.transaction.createdByUsr = resObj.data.createdByUsr;
                    this.transaction.updatedAt = resObj.data.updatedAt;
                    this.transaction.lastModifiedBy = resObj.data.lastModifiedBy;
                    this.transaction.status = 1;
                    this.transaction.companyId = resObj.data.companyId;
                    this.transaction.showHideActions = (moment(moment().toDate()).diff(resObj.data.createdAt, 'minutes') >= 1440);
                    this.transaction.crmClientId = resObj.data.crmClientId;
                    this.transaction.checkLateFee = resObj.data.checkLateFee;
                    this.transaction.checkApiUsageFee = resObj.data.checkApiUsageFee;
                    this.checkLateFee = this.transaction.checkLateFee;
                    this.checkApiUsageFee = this.transaction.checkApiUsageFee;

                    this.transactionDate = resObj.data.createdAt ? new Date(resObj.data.createdAt) : new Date();
                    resObj.data.transactionDetails.forEach(element => {
                        const ts = new Date().getTime();
                        if ((element.date + (24 * 3600000) < ts)) {
                            this.disableAll = true;
                            this.transactionForm.controls['note'].disable();
                        }
                        element.date = element.date;
                        element.cashIn = element.cashIn ? element.cashIn.toFixed(2) : '0.00';
                        element.cashKept = element.cashKept ? element.cashKept.toFixed(2) : '0.00';
                        element.cashOut = element.cashOut ? element.cashOut.toFixed(2) : '0.00';
                        element.netCashInOut = element.netCashInOut ? element.netCashInOut.toFixed(2) : '0.00';
                        element.billingAccountId = element.billingAccountId;
                        this.transactionDetails.push(element);
                    });
                    this.loadAllCompanies();
                    this.loadProjectList();
                    this.billingAccountChange();
                }
            });
        } else {
            this.loadAllCompanies();
            this.loadProjectList();
            this.transaction = new Transaction();
            this.discountType = 'NA';
            this.transaction.status = 1;
        }
        this.userPermission = this.authService.adminRole();
        if (!this.transactionId) {
            this.addThreeDetailsAsDefault();
        }

        this.transaction.expenseCurrency = 'USD';

        this.payrollSettingService.getPayrollSettings(this.authService.getCurrentCompanyId(), { companyId: this.authService.getCurrentCompanyId() }).subscribe((res: any) => {
            if (res.status === 'SUCCESS') {
                if (res.data.lateFee) {
                    this.lateFee = Number(res.data.lateFee) ;
                }

            }
        });
    }

    addThreeDetailsAsDefault() {
        for (let i = 0; i < 1; i++) {
            this.addDetails();
        }

    }

    changeCompany(type?: any) {

        let options: any = {};
        options = {
            page: 0,
            size: 9999
        };
        if (this.selectedCompany) {
            options.companyId = this.selectedCompany.key;
        }
        if (!this.isPlatformAdmin) {
            options.companyId = this.authService.getCurrentCompany();
        }
        if (type) {
            this.loadAllClient();
            this.loadProjectList();
            this.loadAllContractorInvoice();
        }
        this.transactionTypeService.findAllTransactionType(options).subscribe(res => {
            const resObj: any = res;
            let transactionTypeActiveList;
            this.transactionTypes = resObj.data.content;
            transactionTypeActiveList = this.transactionTypes.filter(item => item.status === 'Active');
            this.allTransactionTypes = [];
            if (resObj.status === 'SUCCESS') {
                transactionTypeActiveList.forEach(transactionType => {
                    this.allTransactionTypes.push({ label: transactionType.transactionType, value: transactionType.id });
                });
            }
        });
        // this.transactionTypeService.getDropdown().subscribe(res => {
        //     const resObj: any = res;
        //     let transactionTypeActiveList;
        //     this.transactionTypes = resObj.data;
        //     transactionTypeActiveList = this.transactionTypes;
        //     this.allTransactionTypes = [];
        //     if (resObj.status === 'SUCCESS') {
        //         transactionTypeActiveList.forEach(transactionType => {
        //             this.allTransactionTypes.push({ label: transactionType.value, value: transactionType.key });
        //         });
        //     }
        // });
    }

    async changeClientBase() {
        let options: any = {};
        options = {
            page: 0,
            size: 9999
        };
        if (this.selectedClient != null) {
            options.clientId = this.selectedClient.key;
            options.crmClientIds = [this.selectedClient.key];
        }

        let sumFee = 0, sumCashIn = 0, totalBalance = 0, fee = 0, discountedFee = 0;
        options.sortField = 'id';
        options.sortOrder = 'DESC';
        options.oldInvoiceOnly = false;
        options.ignoreInvoiceDetails = true;
        const content = await this.contentService.filter(options).toPromise();
        const transactionDetail = await this.transactionDetailsService.loadLists(options).toPromise();
        const contentList = content.data.content;
        console.log('content.data.content', content.data.content);
        console.log('this.transactionDetails', this.transactionDetails);
        console.log('content.data. detail', this.transactionDetails[0]);
        this.allBillingInvoice = [];
        contentList.forEach(invoice => {
            if (invoice.status === 'Pending') {
                return;
            }
            if (invoice.status === 'Paid') {
                return;
            }
            fee += invoice.fee;
            discountedFee += invoice.totalFee;
            sumFee += invoice.paidFee > 0 ? invoice.paidFee : 0;
            totalBalance += invoice.balance;
            // if (invoice.appliedCredit && invoice.paymentCreditHistory && invoice.paymentCreditHistory.length > 0 && invoice.appliedTransaction === false) {
            //     for ( let i = 0; i < invoice.paymentCreditHistory.length; i++ ) {
            //         if (invoice.paymentCreditHistory[i].creditAmount) {
            //             sumFee += invoice.paymentCreditHistory[i].creditAmount;
            //         }
            //     }
            // } else {
            //     const paidFee = invoice.paidFee ? invoice.paidFee : 0;
            //     totalBalance = paidFee >= invoice.totalFee ? invoice.totalFee : paidFee;
            //     sumFee += totalBalance;
            // }

            const balance = invoice.balance;
            this.allBillingInvoice.push({
                label: invoice.invoiceNumber,
                value: invoice.id,
                discountType: invoice.discountType, discountValue: invoice.discountValue,
                fee: invoice.fee, paidFee: invoice.fee, discountedFee: invoice.totalFee,
                waiveLateFee: invoice.waiveLateFee
            });
        });
        this.selectedBillingInvoice = [];
        this.outStandingBalanceWithDiscount = '0.00';
        this.selectedBillingInvoice = this.allBillingInvoice.find(b => {
            const invoiceLstIds = this.transactionDetails[0].invoiceLst.split(",");
            return invoiceLstIds.includes(b.id.toString());
        });

        if (this.selectedBillingInvoice) {
            const balance = this.selectedBillingInvoice.balance;
            const balanceDiscounted = this.selectedBillingInvoice.balance;

            this.discountType = this.selectedBillingInvoice.discountType;
            this.discountValue = this.selectedBillingInvoice.discountValue;
            this.outStandingBalance = balance.toFixed(2);
            this.outStandingBalanceWithDiscount = balanceDiscounted.toFixed(2);
        } else {
            this.outStandingBalance = totalBalance.toFixed(2);
            this.outStandingBalanceWithDiscount = totalBalance.toFixed(2);
        }
        const filteredTransaction = transactionDetail.data.content;
        filteredTransaction.forEach(ele => {
            sumCashIn += ele.cashIn;
        });
        const availableCredit = sumCashIn - sumFee;
        this.availableCreditStr = '0.00';
        if (availableCredit > 0) {
            this.availableCreditStr = availableCredit.toFixed(2);
            this.availableCredit = availableCredit;
        }
    }

    changeContractorInvoice() {
        if (this.selectedContractorInvoice) {
            this.getTransactionOutstanding();
        }
    }

    formatData(data, index, type) {
        let value;
        if (Number(data)) {
            value = Number(data).toFixed(2);
        } else {
            value = '0.00';
        }
        if (type === 'cashIn') {
            this.transactionDetails[index].cashIn = value;
        }
        if (type === 'cashKept') {
            this.transactionDetails[index].cashKept = value;
        }
        if (type === 'cashOut') {
            this.transactionDetails[index].cashOut = value;
        }
        if (type === 'netCashInOut') {
            this.transactionDetails[index].netCashInOut = value;
        }

        this.calculateNetCashInOut();

    }
    numberOnly(event): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode === 190) {
            return false;
        }
        return true;
    }

    changeHistoryHandler(transactionId, mergedObject) {
        const admin = this.authService.getUserInfo();
        // console.log(mergedObject);
        const historyData = [];
        const fieldsName = ['project'];
        const fieldsDetails = ['transactionType', 'cashIn', 'cashKept', 'cashOut', 'netCashInOut'];
        fieldsName.forEach(element => {
            if (mergedObject[element] !== null && mergedObject[element] !== '') {
                const dataObj: any = {};
                dataObj.fieldName = element;
                dataObj.oldValue = '';
                dataObj.newValue = mergedObject[element];
                dataObj.action = 'Created';
                dataObj.userId = transactionId;
                dataObj.transactionId = transactionId;
                dataObj.userName = admin.username;
                historyData.push(dataObj);
            }
        });

        // console.log(historyData);
        this.transactionService.saveHistoryData(historyData).subscribe((item: any) => {
            // console.log(item);
        });
    }

    changeHistoryHandlerEdit(newClientData) {
        const admin = this.authService.getUserInfo();
        // console.log(newClientData);
        this.transactionService.get(newClientData.id).subscribe((item: any) => {
            const oldData = item.data;
            // console.log(oldData);
            const historyData = [];
            const fieldsName = ['project'];
            const fieldsDetails = ['transactionType', 'cashIn', 'cashKept', 'cashOut', 'netCashInOut'];
            fieldsName.forEach(element => {
                if (newClientData[element] !== oldData[element]) {
                    const dataObj: any = {};
                    dataObj.fieldName = element;
                    if (element === 'date') {
                        dataObj.oldValue = oldData[element];
                        dataObj.newValue = new Date(newClientData[element]).getTime();
                    } else {
                        dataObj.oldValue = oldData[element];
                        dataObj.newValue = newClientData[element];
                    }

                    if (dataObj.newValue.length === 0) {
                        dataObj.action = 'Deleted';
                    } else {
                        dataObj.action = 'Updated';
                    }

                    dataObj.transactionId = newClientData.id;
                    dataObj.userName = admin.username;
                    historyData.push(dataObj);
                }
            });

            this.transactionService.saveHistoryData(historyData).subscribe(() => {
            });
        });
    }

    addDetails() {
        const transactionDetail = new TransactionDetail();
        transactionDetail.status = 1;
        transactionDetail.date = new Date();
        transactionDetail.cashKept = 0;
        transactionDetail.cashOut = 0;
        transactionDetail.netCashInOut = 0;
        transactionDetail.transactionFileHistories = [];
        this.transactionDetails.push(transactionDetail);
    }

    removeDetail(id: number, i: number) {
        if (id) {
            this.deleteTransactionDetailsPopup = true;
            this.selectedTransactionDetailsId = id;
            this.selectedTransactionDetailsIdx = i;
            return;
        }
        if (this.selectedTransactionDetailsIndex === i) {
            this.selectedTransactionDetailsIndex = null;
        }
        this.transactionDetails.splice(i, 1);
        this.billingAccountChange();
    }

    deleteTransactionDetails() {
        this.transactionDetailsService.deleteTransaction(this.selectedTransactionDetailsId).subscribe(() => {
            this.messageService.add({ severity: 'success', summary: this.translatePipe.transform('success'), detail: this.translatePipe.transform('Deleted!') });
            if (this.selectedTransactionDetailsIndex === this.selectedTransactionDetailsIdx) {
                this.selectedTransactionDetailsIndex = null;
            }
            this.transactionDetails.splice(this.selectedTransactionDetailsIdx, 1);
            this.billingAccountChange();
            this.selectedTransactionDetailsId = null;
            this.selectedTransactionDetailsIdx = null;
            this.deleteTransactionDetailsPopup = false;
        });
    }

    caculateTotal() {
    }

    async saveTransaction(transactionDataForm) {
        if (this.transactionId) {
            this.updateTransaction(transactionDataForm, true);
        } else {
            this.saveToDatabase(transactionDataForm, true);
        }
    }

    sendNotificationEmail(paymentMethod, invoiceId, invoiceNumber, totalCashIn: number) {
        if (this.selectedClient && this.selectedClient.key) {
            this.clientService.getClientById(this.selectedClient.key).subscribe(c => {
                this.contentService.generatePdfLink(invoiceId).subscribe(res => {
                    this.paymentNotificationService.sendEmail({
                        to: c.data.email,
                        emailCompanyId: c.data.companyId,
                        emailFullName: c.data.name,
                        paymentMethod: paymentMethod,
                        invoiceNumber: invoiceNumber,
                        invoicePdfLink: res.data,
                        totalCashIn: totalCashIn.toFixed(2),
                        invoiceAmount: totalCashIn.toFixed(2),
                        transferFee: (0).toFixed(2)
                    }).toPromise();
                });
            })
        }
    }

    saveToDatabase(transactionDataForm, sendEmail?) {
        this.reject();
        if (!this.isPlatformAdmin) {
            this.transactionForm.patchValue({
                companyId: this.selectedCompany
            });
        }
        if (this.formValidator.validateForm(this.transactionForm, transactionDataForm)) {
            this.transaction.companyId = this.selectedCompany.key;
            this.transaction.transactionDetails = [];
            this.transaction.transactionDetails = this.transactionDetails;
            this.transaction.createdByUsr = this.authService.getCurrentUsername();
            this.transaction.crmClientId = this.selectedClient ? this.selectedClient.key : null;
            this.transaction.projectId = this.projectSelected.value;

            this.transaction.checkLateFee = this.checkLateFee;
            this.transaction.checkApiUsageFee = this.checkApiUsageFee;
            this.transaction.type = 'group';

            if (this.transactionId && this.transactionDetailsBkp && this.transactionDetailsBkp.length > 0) {
                this.transaction.transactionDetails = this.transaction.transactionDetails.concat(this.transactionDetailsBkp);
            }

            const isInvalidNetAmount = this.transaction.transactionDetails.find(i => i.netCashInOut === '00.00' || i.netCashInOut === 0);
            if (isInvalidNetAmount) {
                this.messageService.add({ severity: 'error',
                    summary: this.translatePipe.transform('Error'),
                    detail: this.translatePipe.transform(`The net cash cannot not be zero!`) });
                return;
            }

            if ( this.transaction.crmClientId && this.selectedBillingInvoice === undefined ) {
                this.messageService.add({ severity: 'error',
                    summary: this.translatePipe.transform('Error'),
                    detail: this.translatePipe.transform(`The Billing invoice is required`) });
                return;
            }
            let totalCashIn = 0;
            let paymentMethod = '';
            this.transaction.transactionDetails.forEach(transactionDetail => {
                transactionDetail.createdByUsr = this.authService.getCurrentUsername();
                if (transactionDetail.cashOut) {
                    this.totalCashOut += parseFloat(transactionDetail.cashOut.toString());
                }
                if (transactionDetail.cashIn) {
                    totalCashIn += parseFloat(transactionDetail.cashIn.toString());
                }
                if (this.selectedContractorInvoice) {
                    transactionDetail.contractorInvoiceId = this.selectedContractorInvoice.key;
                }

                if (this.selectedBillingInvoice) {
                    transactionDetail.invoiceId = this.selectedBillingInvoice[0];
                    transactionDetail.invoiceLst = this.selectedBillingInvoice.join(',');
                    transactionDetail.type = 'group';                }
                if (paymentMethod === '') {
                    const method = this.paymentMethod.find(p => p.value === transactionDetail.paymentMethod);
                    if (method) {
                        paymentMethod = method.label;
                    }
                }
            });

            if (this.selectedBillingInvoice) {
                console.log("this.selectedBillingInvoice");
                console.log(this.selectedBillingInvoice);
                this.transaction.invoiceLst = this.selectedBillingInvoice.join(',');
                this.transaction.type = 'group';
                this.transaction.appliedTransaction = true;
            }

            if (this.selectedExpenses) {
                this.transaction.selectedExpense = this.selectedExpenses;
            }
            this.disableSave = true;

            console.log('createTransaction transaction: ', this.transaction);
            this.transactionService.createTransaction(this.transaction).subscribe(res => {
                const resObj: any = res;
                if (resObj.status === 'SUCCESS') {
                // handle to send payment confirmation email
                    this.messageService.add({ severity: 'success', summary: this.translatePipe.transform('success'), detail: this.translatePipe.transform('TransactionCreated') });
                    this.monitoringDetailsService.monitorAction(
                        'Transaction Added',
                        this.timeSpent,
                        {
                            transaction_added_by: this.authService.getCurrentLoggedInName(),
                            transaction_id: resObj.data.id
                        },
                        'complete',
                        'Transaction Added',
                        0
                    );
                } else {
                    this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('InvalidData'), detail: this.translatePipe.transform('InvalidData') });
                }
                
                // if (sendEmail && this.selectedBillingInvoice) {
                //     this.sendNotificationEmail(paymentMethod, this.selectedBillingInvoice.key, this.selectedBillingInvoice.value, totalCashIn);
                // }
                setTimeout(() => this.router.navigate(['app/transaction/list']), 2000);
            }, err => {
                this.messageService.add({ severity: 'error',
                    summary: this.translatePipe.transform('Error'),
                    detail: this.translatePipe.transform('Something went wrong. Please try again!') });
                this.disableSave = false;
            });
        } else {
            const invalid = [];
            for (const name in this.transactionForm.controls) {
                if (this.transactionForm.controls[name].invalid) {
                    invalid.push(name);
                }
            }
            this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('InvalidData'), detail: this.translatePipe.transform('PleaseEnterRequiredFields') });
        }
    }

    updateTransaction(transactionDataForm, sendEmail?) {
        this.msgs = [];
        this.reject();
        if (this.formValidator.validateForm(this.transactionForm, transactionDataForm)) {
            this.disableSave = true;
            this.transaction.companyId = this.selectedCompany.key;
            this.transaction.transactionDetails = [];
            this.transaction.transactionDetails = this.transactionDetails;
            this.transaction.crmClientId = this.selectedClient ? this.selectedClient.key : null;
            this.transaction.projectId = this.projectSelected.value;
            this.transaction.type = 'group';
            if (this.transactionId && this.transactionDetailsBkp && this.transactionDetailsBkp.length > 0) {
                this.transaction.transactionDetails = this.transaction.transactionDetails.concat(this.transactionDetailsBkp);
            }
            if (this.selectedBillingInvoice) {
                this.transaction.invoiceLst = this.selectedBillingInvoice.join(',');
                this.transaction.appliedTransaction = true;
            }

            if ( this.transaction.crmClientId && this.selectedBillingInvoice === undefined ) {
                this.messageService.add({ severity: 'error',
                    summary: this.translatePipe.transform('Error'),
                    detail: this.translatePipe.transform(`The Billing invoice is required`) });
                return;
            }

            const isInvalidNetAmount = this.transaction.transactionDetails.find(i => i.netCashInOut === '00.00' || i.netCashInOut === 0);
            if (isInvalidNetAmount) {
                this.messageService.add({ severity: 'error',
                    summary: this.translatePipe.transform('Error'),
                    detail: this.translatePipe.transform(`The net cash cannot not be zero!`) });
                return;
            }

            let totalCashIn = 0;
            let paymentMethod = '';
            this.transaction.transactionDetails.forEach(transactionDetail => {
                if (this.selectedBillingInvoice) {
                    transactionDetail.invoiceId = this.selectedBillingInvoice[0];
                    transactionDetail.invoiceLst = this.selectedBillingInvoice.join(',');
                    transactionDetail.type = 'group';
                }
                if (transactionDetail.cashIn) {
                    totalCashIn += parseFloat(transactionDetail.cashIn.toString());
                }
                if (paymentMethod === '') {
                    const method = this.paymentMethod.find(p => p.value === transactionDetail.paymentMethod);
                    if (method) {
                        paymentMethod = method.label;
                    }
                }
            });

            this.transactionService.updateTransaction(this.transaction, this.transaction.id).subscribe(res => {
                const resObj: any = res;
                if (resObj.status === 'SUCCESS') {
                    this.messageService.add({ severity: 'info', summary: this.translatePipe.transform('success'), detail: this.translatePipe.transform('TransactionUpdated') });
                    this.monitoringDetailsService.monitorAction(
                        'Transaction Updated',
                        this.timeSpent,
                        {
                            transaction_updated_by: this.authService.getCurrentLoggedInName(),
                            transaction_id: this.transaction.id.toString()
                        },
                        'complete',
                        'Transaction Updated',
                        0
                    );
                } else {
                    this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('InvalidData'), detail: this.translatePipe.transform('InvalidData') });
                }
                setTimeout(() => this.router.navigate(['app/transaction/list']), 2000);
            }, err => {
                this.messageService.add({ severity: 'error',
                    summary: this.translatePipe.transform('Error'),
                    detail: this.translatePipe.transform('Something went wrong. Please try again!') });
                this.disableSave = false;
            });
        } else {
            const invalid = [];
            for (const name in this.transactionForm.controls) {
                if (this.transactionForm.controls[name].invalid) {
                    invalid.push(name);
                }
            }
            this.messageService.add({ severity: 'error',
                summary: this.translatePipe.transform('Invalid data'),
                detail: this.translatePipe.transform('Please enter required fields!') });
        }
    }

    private dataURItoBlob(dataURI) {
        const byteString = atob(dataURI.split(',')[1]);
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], {
            type: mimeString
        });
    }

    onCancel() {
        this.router.navigate(['app/transaction/list']);
    }

    uploadFile(event) {
        if (event.target.files.length === 0) {
            return;
        }

        const mimeType = event.target.files[0].type;
        if (mimeType.match(/image\/*/) == null) {
            if (mimeType.match(/pdf\/*/) == null) {
                return;
            }
        }

        const reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        this.documentServicev2.uploadFile(event.target.files[0], 'transaction_file', this.selectedCompany ? this.selectedCompany.key : 1, 'Company')
            .subscribe(res => {
                const resObj: any = res;
                this.selectedTransactionDetail.fileUrl = resObj.data.fileUrl;

                if (this.transactionDetails[this.transactionFileIndex].transactionFileHistories) {
                    this.transactionDetails[this.transactionFileIndex].transactionFileHistories.map(t => t.status = 'INACTIVE');
                } else {
                    this.transactionDetails[this.transactionFileIndex].transactionFileHistories = [];
                }
                this.transactionDetails[this.transactionFileIndex].transactionFileHistories.push({
                    fileId: resObj.data.id,
                    fileUrl: resObj.data.fileUrl,
                    fileName: resObj.data.fileName,
                    status: 'ACTIVE',
                    action: 'Uploaded',
                    createdAt: new Date(),
                    createdByUsr: this.authService.getCurrentUsername(),
                });

                this.messageService.add({ severity: 'success', summary: this.translatePipe.transform('Created'), detail: this.translatePipe.transform('SuccessfullyUploaded') });

            });
    }

    calculateNetCashInOut() {
        this.transactionDetails.forEach((el: any) => {
            if (!el.cashIn) { el.cashIn = '0.00'; }
            el.netCashInOut = (parseFloat(el.cashIn) + parseFloat(el.cashKept)) - parseFloat(el.cashOut);
            if (Number(el.netCashInOut).toFixed(2).split('.')[0].length < 2) {
                el.netCashInOut = '0' + Number(el.netCashInOut).toFixed(2);
            } else {
                el.netCashInOut = Number(el.netCashInOut).toFixed(2);
            }
        });
    }

    loadAllCompanies() {
        this.companyService.getCompaniesDropdown({}).subscribe(res => {
            const resObj: any = res;
            console.log(resObj);
            if (resObj.status === 'SUCCESS') {
                this.allCompanyList = _.sortBy(resObj.data, 'value');
                if (this.transaction.companyId) {
                    this.selectedCompany = this.allCompanyList.find(company => company.key === this.transaction.companyId);
                }
                if (!this.isPlatformAdmin) {
                    const companyId: any = this.authService.getCurrentCompany();
                    this.selectedCompany = this.allCompanyList.find(company => company.key === companyId);
                }
            }
            this.changeCompany();
            this.loadAllClient();
            this.loadAllContractorInvoice();
        });
    }

    onSeletedTransaction(transaction, index) {
        this.selectedTransactionDetail = transaction;
        this.selectedTransactionDetail.index = index;
        this.transactionFileIndex = index;
    }

    onViewDocument(uploadFilePanel: OverlayPanel, transactionDetail) {
        const file = transactionDetail.transactionFileHistories?.find(t => t.status === 'ACTIVE');
        if (file) {
            if (file.fileUrl.endsWith('pdf')) {
                window.open(file.fileUrl);
            } else {
                this.fileUrl = file.fileUrl;
                this.showViewFileDialog = true;
            }
        } else {
            if (this.selectedTransactionDetail.fileUrl && this.selectedTransactionDetail.fileUrl.includes('http')) {
                if (this.selectedTransactionDetail.fileUrl.endsWith('pdf')) {
                    window.open(this.selectedTransactionDetail.fileUrl);
                } else {
                    this.fileUrl = this.selectedTransactionDetail.fileUrl;
                    this.showViewFileDialog = true;
                }
            }
        }
        uploadFilePanel.hide();
        // this.documentsService.getUploadedFile(this.selectedTransactionDetail.fileUrl).subscribe(res => {
        //     const resObj: any = res;
        //     if (resObj.status === 'SUCCESS') {
        //         if (resObj.data.fileType === 'pdf') {
        //             window.open(resObj.data.fileUrl);
        //         } else {
        //             this.fileUrl = resObj.data.fileUrl;
        //             this.showViewFileDialog = true;
        //         }
        //         uploadFilePanel.hide();
        //     }
        // }, error => {
        //     // error
        // });
    }

    closeViewFileDialog() {
        this.showViewFileDialog = false;
    }

    onUploadDocument(uploadFilePanel: OverlayPanel) {
        this.uploadFileRef.toArray()[+this.selectedTransactionDetail.index].nativeElement.click();
        uploadFilePanel.hide();
    }

    onChangeDocument(uploadFilePanel: OverlayPanel) {
        this.uploadFileRef.toArray()[+this.selectedTransactionDetail.index].nativeElement.click();
        uploadFilePanel.hide();
    }

    onRemoveDocument(uploadFilePanel: OverlayPanel) {
        if (this.selectedTransactionDetail.fileUrl) {
            this.selectedTransactionDetail.fileUrl = null;
            uploadFilePanel.hide();
        }

        this.transactionDetails[this.transactionFileIndex].transactionFileHistories.map(t => {
            t.status = 'INACTIVE';
            t.action = 'Removed';
        });
    }

    loadAllClient() {
        const options: any = {};
        if (this.selectedCompany) {
            options.companyId = this.selectedCompany.key;
        }
        if (!this.isPlatformAdmin) {
            options.companyId = this.authService.getCurrentCompanyId();
        }
        this.dropDownsService.getAllClientList(options).subscribe((res) => {
            const resObj: any = res;
            this.allClientList = [];
            if (resObj.status === 'SUCCESS') {
                this.allClientList = resObj.data;
            }
            this.allClientList = this.allClientList.sort((a, b) => a.value !== b.value ? a.value < b.value ? -1 : 1 : 0);
            if (this.transaction.crmClientId) {
                this.selectedClient = this.allClientList.find(client => client.key === this.transaction.crmClientId);
                this.changeClientBase();
            }
        });
    }

    loadAllContractorInvoice() {
        const options: any = {};
        if (this.selectedCompany) {
            options.companyId = this.selectedCompany.key;
        }
        if (!this.isPlatformAdmin) {
            options.companyId = this.authService.getCurrentCompanyId();
        }
        options.status = 'Approved';
        options.fromDate = null;
        options.toDate = null;
        options.page = 0;
        options.size = 9999;
        this.contractorInvoiceService.filter(options).subscribe(res => {
            const resObj: any = res;
            this.allContractorInvoiceList = [];
            if (resObj.status === 'SUCCESS' && resObj.data.content.length > 0) {
                resObj.data.content.forEach(i => {
                    const item = {
                        key: i.id,
                        value: i.invoiceNumber + ', (' + i.freelancer.fullName + ')',
                        fee: i.totalAmount,
                        invoiceNumber: i.invoiceNumber
                    };
                    this.allContractorInvoiceList.push(item);
                });
                if (this.transactionDetails && this.transactionDetails.length > 0) {
                    const contractorInvoiceId = this.transactionDetails[0].contractorInvoiceId;
                    this.selectedContractorInvoice = this.allContractorInvoiceList.find(i => i.key === contractorInvoiceId);
                    this.selectProject();
                }
            }
          });
    }

    selectProject() {
        this.showExpenses = false;
        if (this.isServiceBureau()) {
            this.showClient = false;
            this.showContractorInvoice = false;
            this.isDisable = true;
            this.disableCash = false;
            this.selectedServiceBureauProject = true;
        } else if (this.isExpense()) {
            this.showExpenses = true;
            this.showClient = false;
            this.showContractorInvoice = true;
            this.isDisable = true;
            this.selectedServiceBureauProject = false;
            this.discountType = 'NA';
            this.discountValue = null;
            if (this.allExpenses.length == 0 && !this.loadingExpense) {
                this.loadExpenses();
            } 
        } else {
            this.showClient = true;
            this.selectedClient = null;
            this.showContractorInvoice = true;
            this.isDisable = false;
            // this.disableCash = true;
            this.selectedServiceBureauProject = false;
            this.discountType = 'NA';
            this.discountValue = null;
            this.calculateBalanceWithDisCount();
        }
        this.removeHighlight('projectSelected', true);
    }

    loadProjectList() {
        const options: any = {
            status: 1
        };
        if (this.selectedCompany) { options.companyId = this.selectedCompany.key; }
        if (!this.isPlatformAdmin) { options.companyId = this.authService.getCurrentCompanyId(); }
        const allProjects = [];
        this.projectService.getProjectDropdown(options).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                resObj.data.forEach(ele => {
                    allProjects.push({ label: ele.projectName, value: ele.id });
                });
                this.allProjectsList = allProjects;
            }
            this.projectSelected = this.getServiceBureau();
        });
    }

    accountList() {
        const options: any = {
            companyId: this.isPlatformAdmin ? null : this.authService.getCurrentCompanyId()
        };
        this.accounts = [];
        this.accountService.getAccoundDropdown(options).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                resObj.data.forEach(ele => {
                    this.accounts.push({ label: ele.account + ' (' + ele.beneficiaryName + ')', value: ele.id });
                });
                this.accounts.push({ label: 'Other', value: 0 });
            }

        });
    }

    invalid() {
        if (!this.transaction.note || !this.projectSelected || !this.transaction.expenseCurrency) {
            return true;
        }
        return false;
    }

    showLength(val) {
        if (val.value.length >= 250) {
            this.messageService.add({ severity: 'info',
                summary: this.translatePipe.transform('Info'),
                detail: this.translatePipe.transform('User can add max 250 character') });
            return;
        }
    }

    enbaleTransaction() {
        if (this.mode === 'view') {
            this.selectedTransactionDetailsIndex = null;
            this.mode = 'edit';
            this.transactionForm.enable();
            this.readOnly = false;
            this.monitoringDetailsService.monitorAction(
                'Transaction Edited',
                this.timeSpent,
                {
                    transaction_viewed_by: this.authService.getCurrentLoggedInName(),
                    transaction_id: this.transactionId.toString()
                },
                'complete',
                'Transaction Edited',
                0
            );
        }
    }

    cancelEnableTransaction() {
        if (this.mode === 'edit') {
            this.selectedTransactionDetailsIndex = null;
            this.mode = 'view';
            this.transactionForm.disable();
            this.readOnly = true;
            this.monitoringDetailsService.monitorAction(
                'Transaction Edit Canceled',
                this.timeSpent,
                {
                    transaction_viewed_by: this.authService.getCurrentLoggedInName(),
                    transaction_id: this.transactionId.toString()
                },
                'complete',
                'Transaction Edit Canceled',
                0
            );
        }
    }


    getTransactionOutstanding() {
        const option: any = {};
        option.page = 0;
        option.size = 9999;
        option.contractorInvoiceId = this.selectedContractorInvoice.key;
        option.sort = 'id';
        let totalIncome = 0;
        this.transactionDetailsService.loadLists(option).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS' && resObj.data.content.length > 0) {
                resObj.data.content.forEach(i => {
                    if (i.cashOut) {
                        totalIncome += parseFloat(i.cashOut);
                    }
                });
            }
            const balance = parseFloat(this.selectedContractorInvoice.fee) - totalIncome;
            this.outStandingBalance = balance > 0 ? balance.toFixed(2) : '0.00';
        });
    }

    openConfirmation() {
        this.msgs = [];
        let invalid = false;
        for (let i = 0; i < this.transactionDetails.length; i++) {
            if (!this.transactionDetails[i].transactionTypeId) {
                this.highlightInvalid('transactionTypeSelected' + i, true);
                this.messageService.clear();
                this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('InvalidData'), detail: this.translatePipe.transform('TransactionTypeIsRequired') });
                invalid = true;
                // return;
            } else {
                this.removeHighlight('transactionTypeSelected' + i, true);
            }
            if (!(this.transactionDetails[i].billingAccountId >= 0)) {
                this.highlightInvalid('acctSelected' + i, true);
                this.messageService.clear();
                this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('InvalidData'), detail: this.translatePipe.transform('AccountRequired') });
                invalid = true;
                // return;
            } else {
                this.removeHighlight('acctSelected' + i, true);
            }

            if (!this.transactionDetails[i].paymentMethod) {
                this.highlightInvalid('methodSelected' + i, true);
                this.messageService.clear();
                this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('InvalidData'), detail: this.translatePipe.transform('PaymentMethodRequired') });
                invalid = true;
                // return;
            } else {
                this.removeHighlight('methodSelected' + i, true);
            }
        }
        if (!this.projectSelected) {
            this.highlightInvalid('projectSelected', true);
            this.messageService.clear();
            this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('InvalidData'), detail: this.translatePipe.transform('Project is required') });
            invalid = true;
            // return;
        } else {
            this.removeHighlight('projectSelected', true);
        }
        if (!this.transaction.note) {
            this.highlightInvalid('transactionNotesSelected');
            this.messageService.clear();
            this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('InvalidData'), detail: this.translatePipe.transform('Transaction Notes is required') });
            invalid = true;
            // return;
        } else {
            this.removeHighlight('transactionNotesSelected');
        }
        if (!this.transaction.expenseCurrency) {
            this.highlightInvalid('expenseCurrencySelected');
            this.messageService.clear();
            this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('InvalidData'), detail: this.translatePipe.transform('Expense Currency is required') });
            invalid = true;
            // return;
        } else {
            this.removeHighlight('expenseCurrencySelected');
        }
        if (!this.isPlatformAdmin && !this.showContractorInvoice && (!this.selectedBillingInvoice || this.selectedBillingInvoice.length <= 0)) {
            this.highlightInvalid('billingInvoiceSelected');
            this.messageService.clear();
            this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('InvalidData'), detail: this.translatePipe.transform('Billing Invoice is required') });
            invalid = true;
        } else {
            this.removeHighlight('billingInvoiceSelected');
        }
        if (invalid) {
            return;
        }
        this.showConfirmDialog = true;
    }

    highlightInvalid(elementId: string, isDropdown?) {
        const element = document.getElementById(elementId);
        if (element) {
            if (isDropdown) {
                element.classList.add('requiredField');
              } else {
                element.style.border = '2px solid red';
              } 
        }
    }

    removeHighlight(elementId: string, isDropdown?) {
        const element = document.getElementById(elementId);
        if (element) {
            if (isDropdown) {
                element.classList.remove('requiredField');
            } else {
                element.style.border = '';
            }
        }
    }

    scrollAndHighlight(elementId: string, isDropdown?) {
        const element = document.getElementById(elementId);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'center' });
          if (isDropdown) {
            element.classList.add('requiredField');
          } else {
            element.style.border = '2px solid red';
          }

          setTimeout(() => {
            if (isDropdown) {
                element.classList.remove('requiredField');
            } else {
                element.style.border = '';
            }
          }, 10000);
        }
    }

    reject() {
        this.showConfirmDialog = false;
        this.showConfirmApplyCreditDialog = false;
    }

    applyCredit() {
        if (this.selectedBillingInvoice) {
            this.showConfirmApplyCreditDialog = false;
            const paymentCredit: any = {};
            paymentCredit.invoiceId = this.selectedBillingInvoice.key;
            paymentCredit.createdByUsr = this.authService.getCurrentUsername();
            paymentCredit.availableCredit = this.availableCredit;
            paymentCredit.clientId =  this.selectedClient ? this.selectedClient.key : null;
            this.contentService.updateDateApplyCredit(paymentCredit).subscribe(res => {
                if (res.status === 'SUCCESS') {
                    this.changeClientBase();
                    this.messageService.add({ severity: 'info',
                        summary: this.translatePipe.transform('Info'),
                        detail: this.translatePipe.transform('The invoice has been applied the credit amount', {
                            value: this.selectedBillingInvoice.value
                        })
                    });
                    const args = {
                        credit_applied_to_the_invoice_by:this.authService.isClientRole() ? this.authService.getUserInfo()?.name : this.authService.getCurrentLoggedInName(),
                        invoice_number:this.selectedBillingInvoice.value
                    }
                    this.monitorDetails(args, 'Credit Applied')
                } else {
                    const args = {
                        credit_applied_failed_to_the_invoice_by:this.authService.isClientRole() ? this.authService.getUserInfo()?.name : this.authService.getCurrentLoggedInName(),
                        invoice_number:this.selectedBillingInvoice.value
                    }
                    this.monitorDetails(args, 'Credit Applied Failed')
                    this.messageService.add({ severity: 'error',
                        summary: this.translatePipe.transform('Info'),
                        detail: this.translatePipe.transform('Cannot apply the credit amount to the invoice', {
                            value: this.selectedBillingInvoice.value
                        })
                    });
                }
          }, err => {
            const args = {
                credit_applied_failed_to_the_invoice_by:this.authService.isClientRole() ? this.authService.getUserInfo()?.name : this.authService.getCurrentLoggedInName(),
                invoice_number:this.selectedBillingInvoice.value
            }
            this.monitorDetails(args, 'Credit Applied Failed')
          });
        }
    }

    changeBillingInvoice() {
        this.applyCreditDisable = true;
        if (this.availableCredit > 0 && this.selectedBillingInvoice) {
            this.applyCreditDisable = false;
        }
        this.loadInvoiceBalance();
        console.log("Change Billing")
        console.log(this.selectedBillingInvoice)
    }

    openConfirmationApplyCredit() {
        this.showConfirmApplyCreditDialog = true;
        this.applyTime = new Date();
    }

    loadInvoiceBalance() {
        this.totalLateFee = 0;
        this.totalLateFeeTxt = '0.00';
        this.totalApiUsageFee = 0
        this.totalApiUsageFeeTxt = '0.00';
        const options: any = {};
        this.outStandingBalance = '0.00';
        this.outStandingBalanceWithDiscount = '0.00';
        options.oldInvoiceOnly = false;
        options.companyId = this.authService.getCurrentCompanyId();
        options.clientId = this.selectedClient.key;
        options.sortField = 'id';
        options.sortOrder = 'DESC';
        this.contentService.searchByPost(options).subscribe(res => {
          const resObj: any = res;
          if (resObj.status === 'SUCCESS' && resObj.data.content.length > 0) {
            // const invoice = resObj.data.content.find( i => i.invoiceNumber === this.selectedBillingInvoice.value);
            let balance = 0.00;
            resObj.data.content.forEach(b => {
                this.selectedBillingInvoice.forEach(c => {
                    if (b.id === c) {
                        balance += b.balance;
                    }
                })
            })
            this.outStandingBalanceWithDiscount = balance.toFixed(2);

            // console.log('loadInvoiceBalance selectedBillingInvoice: ', this.selectedBillingInvoice)
            // console.log('loadInvoiceBalance invoice: ', invoice)
            // if (invoice) {
            //     const balance = invoice.balance;
            //     const balanceDiscounted = invoice.balance;
            //     // this.discountType = invoice.discountType;
            //     // this.discountValue = invoice.discountValue;
            //     // this.outStandingBalance = balance.toFixed(2);
            //     // if (!invoice.waiveLateFee) {
            //     //     const dueDate = moment(invoice.dueDate).startOf('d');
            //     //     const now = moment().startOf('d');
            //     //     const diff = dueDate.diff(now, 'd', true);
            //     //     if (diff < 0) {
            //     //         this.totalLateFee = invoice.fee * this.lateFee / 100;
            //     //         this.totalLateFeeTxt = this.totalLateFee.toFixed(2);
            //     //     }
            //     // }

            //     // if (invoice.apiUsageCost && invoice.apiUsageCost > 0 && !invoice.waiveApiUsageFee) {
            //     //     this.totalApiUsageFee = invoice.apiUsageCost;
            //     //     this.totalApiUsageFeeTxt = this.totalApiUsageFee.toFixed(2);
            //     // }

            // }
          }
        });
    }

    monitorDetails(args, action) {
        this.monitoringDetailsService.monitorAction(
           action,
           this.applyTime,
            args,
            "complete",
           action,
            0
        );
    }

    disableField(type){
        if(type){
            if(this.disableAll || this.readOnly || this.disableCash ) return true;
            return false;
        } else {
            if(this.disableAll || this.readOnly || this.isDisable || this.disableCash ) return true;
            return false;
        }
    }

    isNumber(evt) {
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) return false;
        if(evt.target.value.indexOf(".")>-1 && (evt.target.value.split('.')[1].length > 1))	return false;
        if (evt.target.value.indexOf('.') >= 0 && charCode == 46) return false;
        return true;
    }

    changeDiscountType() {
        this.discountValue = null;
        this.calculateBalanceWithDisCount();
    }

    calculateBalanceWithDisCount() {
        const balance = this.outStandingBalance ? Number(this.outStandingBalance) : 0;
        let discountAmount = 0;
        if (!this.discountValue) {
            this.outStandingBalanceWithDiscount = balance;
        } else if (this.discountType === 'Amount') {
            const discountValue = Number(this.discountValue);
            if (discountValue > balance) {
                this.discountValue = balance;
            }
            discountAmount = discountValue;
            this.outStandingBalanceWithDiscount = balance - discountValue;
            return;
        } else if (this.discountType === 'Percent') {
            const discountValue = Number(this.discountValue);
            if (discountValue > 100) {
                this.discountValue = 100;
            }
            discountAmount = balance * discountValue / 100;
            this.outStandingBalanceWithDiscount = balance - discountAmount;
        } else {
            discountAmount = null;
            this.outStandingBalanceWithDiscount = balance;
        }
        this.outStandingBalanceWithDiscount = this.outStandingBalanceWithDiscount.toFixed(2);
    }

    isServiceBureau() {
        const string = ['Operr Service Bureau', 'Service Bureau', 'Operr Services Bureau', 'Services Bureau', 'Income'];
        const project = this.projectSelected?.label.toLowerCase();
        return (project === string[0].toLowerCase() || project === string[1].toLowerCase() || project === string[2].toLowerCase()
        || project === string[3].toLowerCase());
    }

    isExpense() {
        const string = ['Expense','expenses'];
        const project = this.projectSelected?.label.toLowerCase();
        return project === string[0].toLowerCase() || project === string[1].toLowerCase();
    }
    getServiceBureau() {
        const string = ['Operr Service Bureau', 'Service Bureau', 'Operr Services Bureau', 'Services Bureau'];
        return this.allProjectsList.find(p => string.find(s => s === p.label));
    }

    isDisabledAppyDiscount() {
        return this.readOnly || !this.discountValue || !this.discountType 
        || this.discountType === 'NA' || !this.isServiceBureau() || !this.selectedBillingInvoice || (this.selectedBillingInvoice.discountType && this.selectedBillingInvoice.discountValue);
    }

    openConfirmationAppyDiscount() {
        this.showConfirmApplyDiscountDialog = true;
    }

    rejectDiscount() {
        this.showConfirmApplyDiscountDialog = false;
    }

    billingAccountChange() {
        if (!this.transactionDetails || this.transactionDetails.length === 0) {
            this.amountAvailableSelectedAccount = 0;
            return;
        }
        const details = this.transactionDetails.filter(t => t.billingAccountId > 0);
        if (!details || details.length === 0) {
            this.amountAvailableSelectedAccount = 0;
            return;
        }
        const lastBillingAccount = details[details.length - 1].billingAccountId;
        if (lastBillingAccount !== this.lastSelectedBillingAccount) {
            this.transactionDetailsService.getAmountAvailableBillingAccount(lastBillingAccount).subscribe((res) => {
                this.lastSelectedBillingAccount = lastBillingAccount;
                this.amountAvailableSelectedAccount = res.data;
            });
        }
    }

    clickTransactionRow(index) {
        if (!(this.disableAll || this.readOnly)) {
            return;
        }
        if (this.selectedTransactionDetailsIndex === index) {
            this.selectedTransactionDetailsIndex = null;
            this.billingAccountChange();
            return;
        }
        this.selectedTransactionDetailsIndex = index;
        const selectedTransactionDetailsRow = this.transactionDetails[index];
        if (!selectedTransactionDetailsRow.billingAccountId) {
            this.lastSelectedBillingAccount = null;
            this.amountAvailableSelectedAccount = 0;
        } else {
            this.transactionDetailsService.getAmountAvailableBillingAccount(selectedTransactionDetailsRow.billingAccountId).subscribe((res) => {
                this.lastSelectedBillingAccount = selectedTransactionDetailsRow.billingAccountId;
                this.amountAvailableSelectedAccount = res.data;
            });
        }
    }

    isEnableDiscount() {
        return this.authService.getLoggedInUserEditList().includes('1676');
    }

    checkApiUsageFeeChange(event) {
        console.log('checkApiUsageFeeChange event: ', event);
        this.checkApiUsageFee = event.checked;
    }
    checkLateFeeChange(event) {
        console.log('checkLateFeeChange event: ', event);
        this.checkLateFee = event.checked;
    }

    showFileHistoryPopup(transactionDetailId, FileHistories) {
        this.showFileHistoryDialog = true;
        this.transactionFileHistories = FileHistories;
        this.transactionDetailId = transactionDetailId;
    }

    hideFileHistoryDialog() {
        this.showFileHistoryDialog = false;
        this.transactionDetailId = null;
        this.transactionFileHistories = null;
    }

    projectsDropdownShow() {
        if (this.projectsDropdown.filterViewChild) {
            const filterElement = this.projectsDropdown.filterViewChild.nativeElement;
            let emptyMessageEvent = false;
            filterElement.addEventListener('input', () => {
                if ((!this.projectsDropdown.optionsToDisplay || (this.projectsDropdown.optionsToDisplay && this.projectsDropdown.optionsToDisplay.length === 0))) {
                    this.projectsDropdown.emptyFilterMessage = `Add ${this.projectsDropdown.filterValue} as new Project`;
                    const emptyMessage = this.projectsDropdown.containerViewChild.nativeElement.querySelector('.ui-dropdown-empty-message');
                    if (emptyMessage && !emptyMessageEvent) {
                        emptyMessageEvent = true;
                        this.renderer.listen(emptyMessage, 'click', () => {
                            if (this.projectsDropdown.filterValue) {
                                this.addProject(this.projectsDropdown.filterValue);
                            }
                        });
                    }
                }
            });
        }
    }

    addProject(projectName) {
        if ((projectName || '').trim().length  > 60) {
            this.messageService.add({ severity: 'error',
                summary: this.translatePipe.transform('error'),
                detail: this.translatePipe.transform('Max Character limit of name is 60.') });
            return;
        }
        this.projectService.create({
            projectName: projectName,
            status: 1,
            companyId: this.authService.getCurrentCompanyId(),
            createdByUsr: this.authService.getCurrentUsername()
        } as ProjectModel).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                this.allProjectsList.push({ label: resObj.data.projectName, value: resObj.data.id });
                this.messageService.add({ severity: 'success', summary: this.translatePipe.transform('Created'), detail: this.translatePipe.transform('Project created successfully') });
                this.projectsDropdown.options = this.allProjectsList;
            } else {
                this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('error'), detail: this.translatePipe.transform(resObj.data) });
            }
        }, err => {
            this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('error'), detail: this.translatePipe.transform(err) });
        });
    }

    transactionTypeDropdownShow(transactionTypeDropdown: Dropdown) {
        if (transactionTypeDropdown.filterViewChild) {
            const filterElement = transactionTypeDropdown.filterViewChild.nativeElement;
            let emptyMessageEvent = false;
            filterElement.addEventListener('input', () => {
                if ((!transactionTypeDropdown.optionsToDisplay || (transactionTypeDropdown.optionsToDisplay && transactionTypeDropdown.optionsToDisplay.length === 0))) {
                    transactionTypeDropdown.emptyFilterMessage = `Add ${transactionTypeDropdown.filterValue} as new Type`;
                    const emptyMessage = transactionTypeDropdown.containerViewChild.nativeElement.querySelector('.ui-dropdown-empty-message');
                    if (emptyMessage && !emptyMessageEvent) {
                        emptyMessageEvent = true;
                        this.renderer.listen(emptyMessage, 'click', () => {
                            if (transactionTypeDropdown.filterValue) {
                                this.createTransactionType(transactionTypeDropdown.filterValue);
                            }
                        });
                    }
                }
            });
        }
        
    }

    createTransactionType(transactionType) {
        if (this.allTransactionTypes.find(t => t.label === transactionType)) {
            return this.messageService.add({
              severity: 'error',
              summary: this.translatePipe.transform('Error'),
              detail: this.translatePipe.transform(`This Type already Exist, please add different name!`)
            });
        }
        this.transactionTypeService.createTransactionType({
            companyId: (this.isPlatformAdmin) ? this.selectedCompany.key : this.authService.getCurrentCompanyId(),
            createdBy: this.authService.getCurrentLoggedInId(),
            creatorName: this.authService.getCurrentLoggedInName(),
            status: 'Active',
            transactionType: transactionType
        }).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                this.allTransactionTypes.push({ label: resObj.data.transactionType, value: resObj.data.id });
                this.messageService.add({ severity: 'success',
                    summary: this.translatePipe.transform('success'), detail: this.translatePipe.transform('TransactionTypeCreated') });
            } else {
                this.messageService.add({ detail: 'Transaction Type Created Fail!', severity: 'error', summary: 'Error' });
            }
        }, () => {
            this.messageService.add({ detail: 'Transaction Type Created Fail!', severity: 'error', summary: 'Error' });
        });
    }

    async loadExpenses() {
        
        this.requestExpense.status = 'Pending';
        this.requestExpense.page = 0;
        this.requestExpense.size = 999;
        this.requestExpense.agencyId = 0;
        this.requestExpense.sortField = "id";
        this.requestExpense.sortOrder = "DESC";
        this.requestExpense.key = "";
        this.requestExpense.username = "";
        this.requestExpense.companyId = this.authService.getCurrentCompanyId();
        this.requestExpense.from = "";
        this.requestExpense.to = "";
    
        this.requestExpense.sort = 'id' + ',' + 'DESC';
        this.expenseService.loadExpenses(this.requestExpense).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                this.allExpenses = resObj.data.content;
                console.log("All Expenses")
                console.log(this.allExpenses)
                this.loadingExpense = true;
                for (const e of this.allExpenses) {
                    if (e.samount != e?.amountPaid && e.samount != "0.00") {
                        if ((Number(e.samount) - Number(e.amountPaid)) > 0) {
                            let label = e?.username + " - " + this.currencyPipe.transform((Number(e.samount) - Number(e.amountPaid)) > 0 ? (Number(e.samount) - Number(e.amountPaid)) : '00.0' , 'USD', true, "1.2-2") + " - " + this.datePipe.transform(e?.date, 'dd/MM/yyyy');
                            this.optAllExpenses.push({
                                label : label,
                                value : e?.id,
                            })
                        }
                    }
                }
            } else {
                if (this.allExpenses?.length == 0) {
                    this.loadExpenses();
                }
            }
        });
    }

    allExpensesDropDownChange(event) {
        if (event.value.length === this.allExpenses.length) {
            let x = document.getElementById('id_expenseOptions_to_selected_label').getElementsByClassName('ui-multiselect-label').item(0);
            x.textContent = 'All';
        } else {
            let x = document.getElementById('id_expenseOptions_to_selected_label').getElementsByClassName('ui-multiselect-label').item(0);
            x.textContent = `${event.value.length} items selected`;
        }
        console.log("Selected")
        console.log(this.selectedExpenses)
        console.log(event)
    }
}

