import * as moment from 'moment';

export interface TimeDifferenceReponse {
    minutes: number;
    hours: number;
    days: number;
    seconds: number;
}

export class TimeUtility {
    public static checkTime(i) {
        if (i < 10) { i = '0' + i; }  // add zero in front of numbers < 10
        return i;
    }

    public static getMilisecondsTimeDifferenceMoment(startTime: number, endTime: number): TimeDifferenceReponse {
        const startDate = moment(startTime);
        const now = moment(endTime);
        const differenceTime = now.diff(startDate);
        const duration = moment.duration(differenceTime);
        const minutes = duration.minutes();
        const hours = Math.trunc(duration.hours());
        const days = Math.trunc(duration.asDays());
        const seconds = duration.seconds();
        return {
            minutes,
            hours,
            days,
            seconds
        };
    }

    public static getMilisecondsTimeDifference(startTime: number, endTime: number, startDifference?: number): TimeDifferenceReponse {
        if (!startDifference) {
            startDifference = 0;
        }

        // Calculate difference in milliseconds
        const diff = startDifference + endTime - startTime;
        const hoursInDay = 24;
        const minutesInHour = 60;
        const secondsInMinute = 60;
        const millisecondsInSecond = 1000;
        // Calculate difference in days
        const diffDays = Math.trunc(diff / (hoursInDay * minutesInHour * secondsInMinute * millisecondsInSecond));

        // Calculate difference in hours and subtract diff in days as hours
        const diffHours = Math.trunc((diff / (minutesInHour * secondsInMinute * millisecondsInSecond)) - (diffDays * hoursInDay));

        // Calculate difference in minutes and subtract diff in hours as minutes
        const hoursToMinutes = (diffHours * minutesInHour);
        const daysToMinutes = (diffDays * hoursInDay * minutesInHour);
        const diffMinutes =  Math.trunc((diff / (secondsInMinute * millisecondsInSecond)) - (hoursToMinutes + daysToMinutes));

        // Calculate difference in seconds and subtract diff in minutes as seconds
        const hoursToSeconds = (diffHours * minutesInHour * secondsInMinute);
        const daysToSeconds = (diffDays * hoursInDay * minutesInHour * secondsInMinute);
        const minutesToSeconds = (diffMinutes * secondsInMinute);
        const diffSeconds = Math.trunc((diff / millisecondsInSecond) - (hoursToSeconds + daysToSeconds + minutesToSeconds));

        return {
            seconds: diffSeconds,
            minutes: diffMinutes,
            hours: diffHours,
            days: diffDays
        };
    }

    public static getMilisecondsTimeDifferenceFormattedToDaysFormat(startTime: number, endTime: number, startDifference?: number) {
        if (!startTime || !endTime) {
            return '';
        }
        const difference = this.getMilisecondsTimeDifference(startTime, endTime, startDifference);

        const days = difference.days;
        const daysDesc = days === 1 ? 'day' : 'days';
        const formattedTime = `${days} ${daysDesc} ${this.checkTime(difference.hours)}:${this.checkTime(difference.minutes)}`;
        return formattedTime;
    }

    public static getMilisecondsTimeDifferenceFormattedToDaysMinSecFormat(startTime: number, endTime: number, startDifference?: number) {
        if (!startTime || !endTime) {
            return '';
        }
        const difference = this.getMilisecondsTimeDifference(startTime, endTime, startDifference);

        const days = difference.days;
        const daysDesc = days === 1 ? 'day' : 'days';
        const formattedTime = `${days} ${daysDesc} ${this.checkTime(difference.hours)}:${this.checkTime(difference.minutes)}:${this.checkTime(difference.seconds)}`;
        return formattedTime;
    }

    public static getMilisecondsTimeDiff(startTime: number, endTime: number, startDifference) {
        return startDifference + endTime - startTime;
    }
}

