import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import {NavigationEnd, Router} from '@angular/router';
import { MonitoringDetailsService } from 'app/admin/process-monitor/service/monitoring-details.service';
import { CompanyService } from 'app/company/service/company.service';
import { ClientService } from 'app/crm/service/client.service';
import { OperatorService } from 'app/employee/service/v2/operator.v2.service';
import { FollowUpBillingInvoice } from 'app/payment/model/follow-up-billing-invoice.model';
import { FollowUpBillingInvoiceService } from 'app/payment/service/follow-up-billing.service';
import { PaymentInvoiceService } from 'app/payment/service/payment-invoice.service';
import { DATE_FORMATS } from 'app/shared/data/config-common';
import { BrowserNotification } from 'app/shared/model/browser-notification.model';
import { AuthService } from 'app/shared/service/auth/auth.service';
import { BrowserNotificationService } from 'app/shared/service/browser-notification/browser-notification.service';
import { DropDownsService } from 'app/shared/service/drop-downs/drop-downs.service';
import { FileUtility } from 'app/shared/utility/file.utility';
import { Message } from 'aws-sdk/clients/cloudwatch';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import * as _ from 'lodash';
import moment from 'moment';
import { MessageService, OverlayPanel } from 'primeng';
import {TranslatePipe} from "@ngx-translate/core";
import { DocumentsServicev2 } from 'app/shared/service/documents-upload/document.v2.service';
import {GeneralInvoiceTypeService} from '../../../general-invoice/service/general-invoice-type.service';
import {Table} from 'primeng/table';
import {Subject} from 'rxjs';
import {__assign} from 'tslib';

@Component({
  selector: 'app-statement-balance',
  templateUrl: './statement-balance.component.html',
  styleUrls: ['./statement-balance.component.scss'],
  providers: [PaymentInvoiceService, DropDownsService, FollowUpBillingInvoiceService,
    MonitoringDetailsService, OperatorService, BrowserNotificationService,
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS }]
})
export class StatementBalanceComponent implements OnInit, OnDestroy {

  @ViewChild('op3', { static: true }) op3: OverlayPanel;
  @ViewChild('dt2', { static: true }) table: Table;

  isPlatformAdmin = false;
  isClientRole = false;

  allCompanyList: any[] = [];
  selectedCompany: any;
  allClientList: any[] = [];
  clientList: any[] = [];
  selectedClient: any;

  fromDate: Date;
  toDate: Date;

  loading = false;
  statementBalanceList = [];
  statementBalanceStatistics = [{
    first30: 0,
    first60: 0,
    first90: 0,
    first120: 0,
    last120: 0
  }];
  totalBalanceStatistics = 0;

  size: number;
  page: number;
  rows = 10;
  sortField: string;
  sortOrder: number = 0;
  totalRecords = 0;

  cols = [
    { field: 'invoiceNumber', label: 'Invoice', sortOptions: '', sort: 'number' },
    { field: 'invoiceDate', label: 'Date', sortOptions: '', sort: 'number' },
    { field: 'clientName', label: 'Client', sortOptions: '', sort: 'text' },
    { field: 'referenceNo', label: 'Ref#', sortOptions: '' },
    { field: 'dueDate', label: 'Due', sortOptions: '', sort: 'number' },
    { field: 'paidFee', label: 'Paid', sortOptions: '', sort: 'number' },
    { field: 'balance', label: 'Balance', sortOptions: '', sort: 'number' },
    { field: 'updatedAt', label: 'Updated', sort: 'number' },
    { field: 'numOfFollowUpNote', label: 'Actions' }
  ];

  statisticsCols = [
    { field: 'first30', label: '0 - 30' },
    { field: 'first60', label: '31 - 60' },
    { field: 'first90', label: '61 - 90' },
    { field: 'first120', label: '91 - 120' },
    { field: 'last120', label: '121+' },
  ];

  exportOptions = [
    { name: 'View Details', value: 'view' },
    { name: 'Download Invoice', value: 'download' },
  ];
  invoiceRef: any = {};
  exportAs: any;
  now = new Date();
  paymentInvoiceData: any = {};
  isNew = false;
  followUpDate: any;
  followUpNote: any;
  headerNote = 'Create Balance Notification';
  selectedCollectionPresentative: any;
  selectedContact = '';
  charactersLength = 0;
  isOpenCollectionNote = false;
  timespent = new Date();
  contacts = [
    { label: 'Phone Call', value: 'Phone Call' },
    { label: 'Text Message', value: 'Text Message' },
    { label: 'Email', value: 'Email' },
    { label: 'In Person Contact', value: 'In Person Contact' }
  ];
  assignedToEmployeeList: any = [];
  assignedToEmployeeMentionList: any = [];
  employeeList: any = [];
  msgs: Message[] = [];
  concernEditor: any;
  MAX_LENGTH = 500;
  @ViewChild('notesEditor', { static: true })
  followUpNoteEditor: any;
  mentionConfig: any = {};
  companyInfo;
  allRecords: any[] = [];
  exceededMaxLength = false;
  minLength = false;
  lastStatisticOption;
  statusTypes: any[] = [
    { label: 'Unpaid', value: 'Unpaid' },
    { label: 'Partially Paid', value: 'Partial Paid' },
    { label: 'Paid', value: 'Paid' }
  ];
  selectedStatuses;
  textSortOptions = [
    { name: 'Sort A To Z', value: 'ASC', img: 'arrow-down-a-z' },
    { name: 'Sort Z To A', value: 'DESC', img: 'arrow-up-z-a' }
  ];
  numberSortOptions = [
    { name: 'Sort 1 To 9', value: 'ASC', img: 'arrow-down-1-9' },
    { name: 'Sort 9 To 1', value: 'DESC', img: 'arrow-up-9-1' }
  ];
  dateSortOptions = [
      { name: 'Sort 1 To 12', value: 'ASC', img: 'arrow-down-1-9' },
      { name: 'Sort 12 To 1', value: 'DESC', img: 'arrow-up-9-1' }
    ];
  selectedSortOption = '';
  selectedField = '';
  showPopupEmailStatement = false;
  selectedClientEmail: any;
  statementBalanceDays: any = [];
  statementBalanceDaysTmp: any = {
    first30: [],
    first60: [],
    first90: [],
    first120: [],
    last120: []
  };
  totalBalanceDays: number = 0;
  showStatementBalanceDays: boolean = false;
  statementBalanceDaysCols: any = [
    { field: 'clientName', label: 'Client', sortOptions: '', sort: 'text' },
    { field: 'invoiceNumber', label: 'Invoice', sortOptions: '', sort: 'text' },
    { field: 'balance', label: 'Balance', sortOptions: '', sort: 'text' },
  ];
  textSearch: any = '';
  typeList: any = [];
  selectedType: any;
  exportingPDF = false;
  filterText: any
  private filterSubject: Subject<string> = new Subject();
  routerEventsSubscribe;
  exportRecords: any = [];

  constructor(private authService: AuthService,
    private companyService: CompanyService,
    private dropDownsService: DropDownsService,
    private paymentInvoiceService: PaymentInvoiceService,
    private router: Router,
    private messageService: MessageService,
    private followUpBillingInvoiceService: FollowUpBillingInvoiceService,
    private monitoringDetailsService: MonitoringDetailsService,
    private operatorService: OperatorService,
    private browserNotificationService: BrowserNotificationService,
    private translatePipe: TranslatePipe,
    private clientService: ClientService,
    private documentServicev2: DocumentsServicev2,
    private generalInvoiceTypeService: GeneralInvoiceTypeService,
  ) {
    this.routerEventsSubscribe = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (event.url.includes('app/statement-balance/list')) {
          this.monitoringDetailsService.monitorAction(
              `Navigated to Statement & Balance`,
              this.timespent,
              {
                navigated_to_statement_balance_by: this.authService.getCurrentLoggedInName()
              },
              'complete',
              `Navigated to Statement & Balance`,
              0
          );
        }
      }
    });
  }

  ngOnInit(): void {
    this.isClientRole = this.authService.isClientRole();
    this.isPlatformAdmin = this.authService.isSuper() || this.authService.isSubSuper();
    if (!this.isPlatformAdmin) {
      this.companyService.getCompanyByCompanyId(this.authService.getCurrentCompanyId()).subscribe(res => {
        this.companyInfo = res.data;
      });
    }
    this.loadAllCompanies();
    this.loadAllClient();
    this.loadEmployeeDropDown();
    this.getInvoiceTypes();
    // this.search();
  }

  loadAllCompanies() {
    this.dropDownsService.getAllCompanyList().subscribe((res) => {
      const resObj: any = res;
      this.allCompanyList = [];
      if (resObj.status === 'SUCCESS') {
        this.allCompanyList = _.sortBy(resObj.data, 'value');
        if (!this.isPlatformAdmin) {
          this.selectedCompany = this.allCompanyList.find(company => company.key === this.authService.getCurrentCompanyId()).key;
        }
      }
    });
  }

  loadAllClient() {
    const options: any = { size: 99999, page: 0, moduleName: 'billing' };
    if (this.selectedCompany) {
      options.companyId = this.selectedCompany;
    }
    if (!this.isPlatformAdmin) options.companyId = this.authService.getCurrentCompanyId();
    // options.status = 1;
    this.dropDownsService.getAllClientList(options).subscribe((res) => {
      const resObj: any = res;
      this.allClientList = [];
      this.clientList = [];
      if (resObj.status === 'SUCCESS') {
//console.log('getAllClientList resObj: ', resObj)
        let data = resObj.data.sort((a, b) => a.value.localeCompare(b.value));
        this.allClientList = data.map(c => ({ value: c.key, label: c.value, email: c.email }));
        this.clientList = data;
      }
    });
  }

  changeCompany() {
    if (this.selectedCompany) {
      this.loadAllClient();
    }
  }

  search(event?: any) {
    this.loading = true;
    const options = this.buildOptions(event);
    this.loadPage(options).then(() => {
      this.loading = false;
    });
  }

  searchData(event?: any) {
    this.loading = true;
    this.table?.reset();
    this.monitoringDetailsService.monitorAction(
        `Searched Statement & Balance`,
        this.timespent,
        {
          search_to_statement_balance_by: this.authService.getCurrentLoggedInName()
        },
        'complete',
        `Searched Statement & Balance`,
        0
    );
    const options = this.buildOptions(event);
    this.loadPage(options).then(() => {
      this.loading = false;
    });
  }

  buildOptions(event?: any, page?, size?) {
    this.loading = true;
    this.size = event ? event.rows : this.size ? this.size : 10;
    this.page = event && event.first >= 0 && event.rows > 0 ? event.first / event.rows : 0;
    const options = <any>{
      page: page ? page : this.page,
      size: size ? size : this.size
    };
    this.sortField = this.sortField ? this.sortField : event && event.sortField ? event.sortField : 'id';
    this.sortOrder = this.sortOrder ? this.sortOrder : event && event.sortOrder ? event.sortOrder : 0;
    if (this.sortField === 'id') {
      this.sortOrder = 0;
    }

    options.sortField = this.sortField;
    options.sortOrder = this.sortOrder === 1 ? 'ASC' : 'DESC';

    if (this.selectedCompany != null) {
      options.companyId = this.selectedCompany;
    }

    if (this.selectedClient != null) {
      options.clientId = this.selectedClient.key;
    }

    if (this.fromDate) {
      options.fromDate = moment(this.fromDate).utc(true).startOf('day').toDate();
      options.startDate = options.fromDate;
    }
    if (this.toDate) {
      options.toDate = moment(this.toDate).utc(true).endOf('day').toDate();
      options.endDate = options.toDate;
    }
    if (this.selectedStatuses && this.selectedStatuses.length > 0 && this.selectedStatuses.length !== this.statusTypes.length) {
      options.listStatus = this.selectedStatuses;
    } else {
      options.listStatus = null;
    }
    options.oldInvoiceOnly = false;
    options.ignoreInvoiceDetails = true;
    options.invoiceType = this.selectedType;
    options.searchText = this.filterText;

    const client = this.allClientList.find(com => com.label.toLowerCase() === this.filterText?.toLowerCase())
    if (this.filterText && client) {
      options.searchText = client.value;
    }
    return options;
  }

  async loadPage(options) {
    if (!this.isPlatformAdmin) {
      options.companyId = this.authService.getCurrentCompany();
    }
    if (this.selectedCompany) {
      options.companyId = this.selectedCompany;
    }
    await this.paymentInvoiceService.filter(options).toPromise().then(res => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        this.statementBalanceList = resObj.data.content;
        this.totalRecords = resObj.data.totalElements;
        for (const invoice of this.statementBalanceList) {
          const company = this.allCompanyList.find(com => com.value === invoice.companyId);
          const client = this.allClientList.find(com => com.value === invoice.clientId);
          if (company) {
            invoice.companyName = company.label;
          }
          if (client) {
            invoice.clientName = client.label;
          }
          const invoiceDate = new Date(invoice.invoiceDate);
          invoiceDate.setMinutes(invoiceDate.getMinutes() + invoiceDate.getTimezoneOffset());
          invoice.invoiceDate = invoiceDate;
          if (invoice.paymentMethod === 'cash') {
            invoice.paymentMethod = 'Cash';
          }

          if (invoice.paymentMethod === 'credit/Debit') {
            invoice.paymentMethod = 'Credit/Debit';
          }

          if (invoice.paymentMethod === 'zelle') {
            invoice.paymentMethod = 'Zelle';
          }

          if (invoice.paymentMethod === 'check') {
            invoice.paymentMethod = 'Check';
          }

          if (invoice.paymentMethod === 'other') {
            invoice.paymentMethod = 'Other';
          }
          if (invoice.totalAmount) {
            invoice.totalAmountTxt = invoice.totalAmount.toFixed(2);
          } else {
            invoice.totalAmountTxt = '0.00';
          }

          if (invoice.fee) {
            invoice.feeTxt = invoice.fee.toFixed(2);
          } else {
            invoice.feeTxt = '0.00';
          }

          if (invoice.paidFee) {
            invoice.paidFeeTxt = invoice.paidFee.toFixed(2);
          } else {
            invoice.paidFeeTxt = '0.00';
          }

          if (invoice.balance) {
            invoice.balanceTxt = invoice.balance.toFixed(2);
          } else {
            invoice.balanceTxt = '0.00';
          }

          const length = invoice.followUpBillingInvoices ? invoice.followUpBillingInvoices.length : 0;
          if (invoice.followUpBillingInvoices && length > 0) {
            invoice.followUpNoteDate = invoice.followUpBillingInvoices[length - 1].createdAt;
            invoice.numOfFollowUpNote = length;
          }
        }
      } else {
        this.statementBalanceList = [];
        this.loading = false;
        this.totalRecords = 0;
      }
    }, () => {
      this.loading = false;
      this.statementBalanceList = [];
      this.totalRecords = 0;
    });


    const statisticsOption = Object.assign({}, options);
    statisticsOption.page = 0;
    statisticsOption.size = 99999;
    if (!this.lastStatisticOption || this.lastStatisticOption.companyId !== statisticsOption.companyId 
      || this.lastStatisticOption.clientId !== statisticsOption.clientId
      || this.lastStatisticOption.fromDate !== statisticsOption.fromDate
      || this.lastStatisticOption.toDate !== statisticsOption.toDate
      || this.lastStatisticOption.listStatus !== statisticsOption.listStatus
      || this.lastStatisticOption.sortField !== statisticsOption.sortField
      || this.lastStatisticOption.sortOrder !== statisticsOption.sortOrder
    ) {
        this.loading = true;
        this.allRecords = [];
        statisticsOption.oldInvoiceOnly = false;
        statisticsOption.ignoreInvoiceDetails = true;
        statisticsOption.isAll = true;

        this.paymentInvoiceService.filter(statisticsOption).subscribe(res => {
          this.loading = false;
          const resObj: any = res;
          const statistics: any = {
            first30: 0,
            first60: 0,
            first90: 0,
            first120: 0,
            last120: 0
          };
          if (resObj.status === 'SUCCESS') {
            this.lastStatisticOption = Object.assign({}, statisticsOption);
            this.allRecords = resObj.data.content;
            this.totalBalanceStatistics = 0;
            for (const invoice of this.allRecords) {

              const company = this.allCompanyList.find(com => com.value === invoice.companyId);
              const client = this.allClientList.find(com => com.value === invoice.clientId);
              if (company) {
                invoice.companyName = company.label;
              }
              if (client) {
                invoice.clientName = client.label;
              }

              if (!invoice.balance) {
                continue;
              }
              const diff = moment().diff(moment(invoice.invoiceDate), 'd');
              if (diff >= 0) {
                this.totalBalanceStatistics += invoice.balance;
                if (diff < 30) {
                  statistics.first30 += invoice.balance;
                  this.statementBalanceDaysTmp.first30.push(invoice);
                } else if (diff < 60) { 
                  statistics.first60 += invoice.balance;
                  this.statementBalanceDaysTmp.first60.push(invoice);
                } else if (diff < 90) {
                  statistics.first90 += invoice.balance;
                  this.statementBalanceDaysTmp.first90.push(invoice);
                } else if (diff < 120) {
                  statistics.first120 += invoice.balance;
                  this.statementBalanceDaysTmp.first120.push(invoice);
                } else {
                  statistics.last120 += invoice.balance;
                  this.statementBalanceDaysTmp.last120.push(invoice);
                }
              }
            }
            this.statementBalanceStatistics = [];
            this.statementBalanceStatistics.push(statistics);
          }
        }, () => {
          this.loading = false;
        });
    } 
  }

  reset() {
    if (this.isPlatformAdmin) {
      this.selectedCompany = null;
    }
    this.selectedClient = null;
    this.selectedStatuses = null;
    this.fromDate = null;
    this.toDate = null;
    this.sortField = null;
    this.sortOrder = null;
    this.page = 0;
    this.size = 10;
    if (this.table) {
      this.table.first = 0;
      this.table.rows = 10;
      this.table.reset();
    }
    this.search();
  }

  addStatementBalance() {

  }

  formatDate(date: any) {
    if (date) {
      return moment.utc(moment(date).format('MM/DD/YYYY hh:mm a')).local().format('MM/DD/YYYY hh:mm a');
    }
    return null;
  }

  clickInvoiceRef(event: any, rowData) {
//    console.log('clickInvoiceRef: ', rowData)
    this.op3.toggle(event);
    this.invoiceRef = rowData;
  }

  invoiceRefAction(type: string) {
    switch (type) {
      case 'view': this.exportPdf(this.invoiceRef);
        break;
      case 'download': this.downloadInvoiceRefPdf();
        break;
    }
  }

  exportPdf(invoice) {
    this.exportingPDF = true;
    this.op3.hide();
    if (invoice.pdfLink) {
      window.open(invoice.pdfLink, '_blank');
    } else {
      this.paymentInvoiceService.exportPdf(invoice.id).subscribe(res => {
        if (res.data) {
            this.exportingPDF = false;
          const blob = FileUtility.b64toBlob(res.data.body, 'application/pdf');
          const blobUrl = URL.createObjectURL(blob);
          window.open(blobUrl, '_blank');
        }
      })
    }
  }

  downloadInvoiceRefPdf() {
    this.op3.hide();
    this.paymentInvoiceService.exportPdfWithName(this.invoiceRef.id).subscribe(res => {
      if (res.data) {
        const blob = FileUtility.b64toBlob(res.data.blob.body, 'application/pdf');
        const blobUrl = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = blobUrl;
        a.target = '_blank';
        let fileName = 'Dummy.pdf';
        if (res.data.fileName && res.data.fileName.length > 0) {
          fileName = res.data.fileName;
        }
        a.download = fileName;
        a.click();
        const args = {
          ticket_invoice_download_by: this.authService.getCurrentLoggedInName(),
          Ticket_id: this.invoiceRef.invoiceId,
        };
      }
    });
  }

  redirectToFollowUptUrl(rowData: any) {
    localStorage.removeItem('follow-up-note-number');
    localStorage.setItem('follow-up-note-number', rowData.invoiceNumber);
    this.router.navigate(['/app/payments/follow-up-note']);
  }

  openDialog(data?: any, event?: any, isNew?: any) {
    this.now = new Date();
    this.paymentInvoiceData = data;
    this.isNew = isNew;
    this.followUpDate = this.now;
    this.headerNote = 'Collection Note - Create';
    if (!isNew) {
      this.headerNote = 'Collection Note - Update';
    }
    if (data.followUpBillingInvoices && data.followUpBillingInvoices.length > 0 && !isNew) {
      const length = data.followUpBillingInvoices.length;
      this.selectedCollectionPresentative = data.followUpBillingInvoices[length - 1].collectionRepresentative;
      this.selectedContact = data.followUpBillingInvoices[length - 1].methodContact;
      this.followUpNote = data.followUpBillingInvoices[length - 1].note;
      this.followUpDate = data.followUpBillingInvoices[length - 1].createdAt;
    } else {
      if (this.authService.isEmployeeRole()) {
        this.selectedCollectionPresentative = this.authService.getCurrentUsername();
      } else {
        this.selectedCollectionPresentative = null;
      }
      this.selectedContact = '';
      this.followUpNote = '';
    }

    this.charactersLength = this.followUpNote ? this.followUpNote.replace(/<\/?[^>]+(>|$)/g, '').length : 0;
    this.isOpenCollectionNote = true;
    this.timespent = new Date();

  }

  async loadEmployeeDropDown() {
    const options: any = {};
    options.companyId = this.authService.getCurrentCompanyId();
    options.status = 1;
    this.employeeList = [];
    const employee = await this.dropDownsService.getAllEmployeeList(options).toPromise();
    this.assignedToEmployeeList = [];
    this.assignedToEmployeeMentionList = [];
    if (employee.data.length > 0) {
      this.employeeList = employee.data;
      employee.data.forEach((item) => {
        const nm = item.value;
        this.assignedToEmployeeList.push({
          label: nm,
          value: nm
        });
      });

      this.assignedToEmployeeMentionList = Object.assign({}, this.assignedToEmployeeList);
    }
  }

  textChanged1() {
    this.msgs = [];
    this.messageService.clear();
    if (this.concernEditor.getLength() < 25) {
      this.charactersLength = this.concernEditor.getLength() - 1;
      this.exceededMaxLength = false;
      return;
    } else {
      this.charactersLength = this.concernEditor.getLength() - 1;
      this.exceededMaxLength = false;
    }
    if (this.concernEditor.getLength() > this.MAX_LENGTH) {
      this.exceededMaxLength = true;
      this.concernEditor.deleteText(this.MAX_LENGTH, this.concernEditor.getLength());
      this.messageService.add({ severity: 'error', summary: 'Error', detail: `You have exceeded the maximum text limit!` });
    }
    this.charactersLength = this.concernEditor.getLength() - 1;
    this.exceededMaxLength = false;
  }

  setEditor(event: any) {
    event.editor.root.focus();
    this.concernEditor = event.editor;
  }

  boldMention() {
    setTimeout(() => {
      const regexTag = /(@)([@.a-zA-Z0-9_-]*)(<\/p>)/g;
      const regexTag2 = /(@)([@.a-zA-Z0-9_-]*)( +)/g;
      this.followUpNote = this.followUpNote.replace(regexTag, '<strong>$1$2</strong>&nbsp;$3');
      this.followUpNote = this.followUpNote.replace(regexTag2, '<strong>$1$2</strong>$3');
      setTimeout(() => {
        this.followUpNoteEditor.getQuill().setSelection(this.followUpNoteEditor.value.length);
      }, 100);
    }, 500);
  }

  saveCollectionNote(data?: any, event?: any) {
    const followUpBilling = new FollowUpBillingInvoice();
    followUpBilling.collectionRepresentative = this.selectedCollectionPresentative;
    followUpBilling.methodContact = this.selectedContact;
    followUpBilling.note = this.followUpNote;
    followUpBilling.invoiceId = this.paymentInvoiceData.id;
    followUpBilling.invoiceNumber = this.paymentInvoiceData.invoiceNumber;
    followUpBilling.clientName = this.paymentInvoiceData.clientName;
    followUpBilling.companyId = this.paymentInvoiceData.companyId;
    this.msgs = [];
    if (this.concernEditor.getLength() < 25) {
      this.messageService.add({ severity: 'error',
          summary: this.translatePipe.transform('Error'),
          detail: this.translatePipe.transform(`Please add at least 25 characters`) });
      return;
    }
    if (this.concernEditor.getLength() > this.MAX_LENGTH) {
      this.messageService.add({ severity: 'error',
          summary: this.translatePipe.transform('Error'),
          detail: this.translatePipe.transform(`You have exceeded the 500 character text limit`) });
      this.exceededMaxLength = true;
      return;
    }
    if (this.paymentInvoiceData.followUpBillingInvoices && this.paymentInvoiceData.followUpBillingInvoices.length > 0 && !this.isNew) {
      const length = this.paymentInvoiceData.followUpBillingInvoices.length;
      followUpBilling.id = this.paymentInvoiceData.followUpBillingInvoices[length - 1].id;
      followUpBilling.createdAt = this.paymentInvoiceData.followUpBillingInvoices[length - 1].createdAt;
      followUpBilling.createdByUsr = this.paymentInvoiceData.followUpBillingInvoices[length - 1].createdByUsr;
      followUpBilling.lastModifiedBy = this.authService.getCurrentLoggedInName();
      this.followUpBillingInvoiceService.update(followUpBilling, this.paymentInvoiceData.followUpBillingInvoices[length - 1].id).subscribe(res => {
        const resObj: any = res;
        this.isOpenCollectionNote = false;
        if (resObj.status === 'SUCCESS') {
          this.messageService.add({
            severity: 'info',
            summary: this.translatePipe.transform('Update Follow Up Note'),
            detail: this.translatePipe.transform('Follow Up Note has been updated successfully!')
          });

          this.statementBalanceList.find(i => i.id === this.paymentInvoiceData.id).followUpBillingInvoices[length - 1] = followUpBilling
          this.notifyMentionEmployee(this.followUpNote, resObj.data.id, followUpBilling);
        }
        this.monitoringDetailsService.monitorAction(
          'Follow Up Note Updated',
          this.timespent,
          {
            follow_up_note_updated_by: this.authService.getCurrentLoggedInName(),
            follow_up_note_id: resObj.data.id
          },
          'complete',
          'Follow Up Note Updated',
          0
        );

      });
    } else {
      followUpBilling.createdByUsr = this.authService.getCurrentLoggedInName();
      this.followUpBillingInvoiceService.create(followUpBilling).subscribe(res => {
        const resObj: any = res;
        this.isOpenCollectionNote = false;
        if (resObj.status === 'SUCCESS') {
          this.statementBalanceList.forEach(element => {
            if (element.id === this.paymentInvoiceData.id) {
              element.followUpBillingInvoices.push(resObj.data);
              const length = element.followUpBillingInvoices ? element.followUpBillingInvoices.length : 0;
              element.followUpNoteDate = element.followUpBillingInvoices[length - 1].createdAt,
                element.numOfFollowUpNote = '(' + length + ')';
            }
          });

          this.statementBalanceList.find(i => i.id === this.paymentInvoiceData.id).followUpBillingInvoices[length - 1] = [];
          this.statementBalanceList.find(i => i.id === this.paymentInvoiceData.id).followUpBillingInvoices[length - 1].push(followUpBilling);

          this.notifyMentionEmployee(this.followUpNote, resObj.data.id, followUpBilling);
          this.messageService.add({
            severity: 'info',
            summary: this.translatePipe.transform('Add Follow Up Note'),
            detail: this.translatePipe.transform('Follow Up Note has been created successfully!')
          });
        }
        this.monitoringDetailsService.monitorAction(
          'Follow Up Note Added',
          this.timespent,
          {
            follow_up_note_added_by: this.authService.getCurrentLoggedInName(),
            follow_up_note_id: resObj.data.id
          },
          'complete',
          'Follow Up Note Added',
          0
        );
        // this.search();
      });
    }
  }

  async notifyMentionEmployee(details: string, followNoteId: number, followUpBilling: any) {
    const employeeList = details.match(/(@)([@.a-zA-Z0-9_-]*)/g);
    if (!employeeList || employeeList.length < 1) return
    for (const employee of employeeList) {
      const key = this.employeeList.find(e => '@' + e.value === employee)?.key;
      if (key != null) {
        const notification: BrowserNotification = new BrowserNotification();
        notification.entity = 'employee';
        const regex = new RegExp(employee + '(.{0,30})');
        notification.content = this.authService.getCurrentUsername() + ' mentioned you: ' + this.stripHtml(details).match(regex)[0] + '...';
        notification.logo = 'https://livestore.operrwork.com/operrwork/2021_2_11__2_10_35__1__2021_1_23__13_42_4__1__m6qxrYbF_400x400.jpg';
        notification.url = document.location.origin + '/#/app/payments/follow-up-note/' + followNoteId;
        notification.status = 'New';
        notification.entityId = key;
        await this.browserNotificationService.save(notification).toPromise();
        // add the bell notification
        const bellNotification: any = {};
        bellNotification.sourceId = followNoteId;
        bellNotification.targetTableName = 'employee';
        bellNotification.targetRoleId = 7;
        bellNotification.targetId = key;
        bellNotification.message = details;
        bellNotification.type = 'follow_up_note';
        bellNotification.tableName = 'follow_up_billing';
        bellNotification.projectId = this.authService.getCurrentCompanyId();
        bellNotification.createdByUsr = this.authService.getCurrentLoggedInName();
        bellNotification.urlLink = '/app/payments/follow-up-note/' + followNoteId;
        await this.operatorService.addBellNotification(bellNotification).toPromise();
        // send email
        const emailData: any = {};
        emailData.employeeId = key;
        emailData.methodContact = followUpBilling.methodContact;
        emailData.collectionRepresentation = followUpBilling.collectionRepresentative;
        emailData.clientName = followUpBilling.clientName;
        emailData.invoiceNumber = followUpBilling.invoiceNumber;
        emailData.note = details.replace(/<[^>]*>/g, '');
        await this.followUpBillingInvoiceService.sendFollowUpTagEmail(emailData).toPromise();
      }
    }
  }

  stripHtml(html) {
    const tmp = document.createElement('tmp');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  }

  closeDialog() {
    this.isOpenCollectionNote = false;
    this.paymentInvoiceData = {};
    if (this.headerNote && this.headerNote === 'Collection Note - Update') {
      this.monitoringDetailsService.monitorAction(
        'Follow Up Note Viewed',
        this.timespent,
        {
          follow_up_note_viewed_by: this.authService.getCurrentLoggedInName()
        },
        'complete',
        'Follow Up Note Viewed',
        0
      );
    }
  }

  async downloadPdf(action?) {
    const doc = new jsPDF();
    this.exportFilter();

    const address = this.companyInfo.addressOne.split(',');
      const addressLine = address[0];
      let addressLine2 = '';
      if (address.length > 1) {
        for (let i = 1; i < address.length; i++) {
          addressLine2 += address[i].trim();
          if (i < address.length - 1) {
            addressLine2 += ', ';
          }
        }
      }
      autoTable(doc, {
        margin: { left: 100 },
        footStyles: {font: 'helvetica'},
        body: [
          [
            {
              content: this.companyInfo.name,
              styles: {
                halign: 'left',
                fontSize: 12,
                fontStyle: 'bold',
                textColor: [0, 180, 200],
                cellPadding: {top: 0, bottom: 0}
              }
            }, 
            {
              content: addressLine,
              styles: {
                halign: 'left',
                fontSize: 11,
                cellPadding: {top: 0, bottom: 0, left: 10}
              }
            }
          ],
          [
            {
              content: this.companyInfo.email + '\n' + this.companyInfo.phone,
              styles: {
                halign: 'left',
                fontSize: 11,
                cellPadding: {top: 0, bottom: 0}
              }
            },
            {
              content: addressLine2,
              styles: {
                halign: 'left',
                fontSize: 11,
                cellPadding: {top: 0, bottom: 0, left: 10}
              }
            }
          ],
        ],
        theme: 'plain'
      });

    if (this.selectedClient) {
      const clientResponse = await this.clientService.getClientById(this.selectedClient.key).toPromise();
      const clientInfo = clientResponse.data;
      autoTable(doc, {
        body: [
          [
            {
              content: 'STATEMENT',
              styles: {
                minCellWidth: 70,
                fontStyle: 'bold',
                textColor: [0, 180, 200],
                fontSize: 12
              }
            },
            {
              content: 'Client Address',
              styles: {
                fontStyle: 'bold',
                fontSize: 12
              }
            },
            {
              content: 'Property Address',
              styles: {
                minCellWidth: 60,
                fontStyle: 'bold',
                fontSize: 12
              }
            }
          ],
          [
            {
              content: 'Statement Date        ' + moment().format('MMM DD, YYYY') 
              + '\nBalance                    $' + (this.totalBalanceStatistics? this.totalBalanceStatistics.toFixed(2) : '0.00')
              + '\nClient Name                ' + clientInfo.name
            },
            {
              content: clientInfo.address + '\n' + clientInfo.email
            },
            {
              content: ''
            }
          ]
        ],
        styles: {
          halign: 'left',
          textColor: [33, 37, 41]
        },
        theme: 'plain',

      });
    }

    autoTable(doc, {
      footStyles: {font: 'helvetica'},
      body: [
        [
          {
            rowSpan: 2,
            content: 'Balance Due $' + (this.totalBalanceStatistics? this.totalBalanceStatistics.toFixed(2) : '0.00'),
            styles: {
              halign: 'center',
              valign: 'middle',
              fontSize: 13,
              fontStyle: 'bold',
              textColor: [255, 0, 0],
            }
          },
          {
            rowSpan: 1,
            content: '0 - 30',
            styles: {
              halign: 'center',
              valign: 'middle',
              fontSize: 13,
              fontStyle: 'bold',
              textColor: [102, 102, 102]
            }
          },
          {
            rowSpan: 1,
            content: '31 - 60',
            styles: {
              halign: 'center',
              valign: 'middle',
              fontSize: 13,
              fontStyle: 'bold',
              textColor: [102, 102, 102]
            }
          },
          {
            rowSpan: 1,
            content: '61 - 90',
            styles: {
              halign: 'center',
              valign: 'middle',
              fontSize: 13,
              fontStyle: 'bold',
              textColor: [102, 102, 102],
            }
          },
          {
            rowSpan: 1,
            content: '91 - 120',
            styles: {
              halign: 'center',
              valign: 'middle',
              fontSize: 13,
              fontStyle: 'bold',
              textColor: [102, 102, 102],
            }
          },
          {
            rowSpan: 1,
            content: '120+',
            styles: {
              halign: 'center',
              valign: 'middle',
              fontSize: 13,
              fontStyle: 'bold',
              textColor: [102, 102, 102],
            }
          }
        ],
        [
          {
            rowSpan: 1,
            content: '$' + (this.statementBalanceStatistics[0] && this.statementBalanceStatistics[0].first30? this.statementBalanceStatistics[0].first30.toFixed(2) : '0.00'),
            styles: {
              halign: 'center',
              valign: 'middle',
              fontSize: 13,
              textColor: [33, 37, 41],
            }
          },
          {
            rowSpan: 1,
            content: '$' + (this.statementBalanceStatistics[0] && this.statementBalanceStatistics[0].first60? this.statementBalanceStatistics[0].first60.toFixed(2) : '0.00'),
            styles: {
              halign: 'center',
              valign: 'middle',
              fontSize: 13,
              textColor: [33, 37, 41],
            }
          },
          {
            rowSpan: 1,
            content: '$' + (this.statementBalanceStatistics[0] && this.statementBalanceStatistics[0].first90? this.statementBalanceStatistics[0].first90.toFixed(2): '0.00'),
            styles: {
              halign: 'center',
              valign: 'middle',
              fontSize: 13,
              textColor: [33, 37, 41],
            }
          },
          {
            rowSpan: 1,
            content: '$' + (this.statementBalanceStatistics[0] && this.statementBalanceStatistics[0].first120? this.statementBalanceStatistics[0].first120.toFixed(2): '0.00'),
            styles: {
              halign: 'center',
              valign: 'middle',
              fontSize: 13,
              textColor: [33, 37, 41],
            }
          },
          {
            rowSpan: 1,
            content: '$' + (this.statementBalanceStatistics[0] && this.statementBalanceStatistics[0].last120?this.statementBalanceStatistics[0].last120.toFixed(2): '0.00'),
            styles: {
              halign: 'center',
              valign: 'middle',
              fontSize: 13,
              textColor: [33, 37, 41],
            }
          }
        ],
      ],
      theme: 'grid'
    });
    const bodyContent = [];
    const cellStyle = {
      halign: 'left',
      valign: 'middle',
      textColor: [33, 37, 41],
    };
    for (let index = 0; index < this.exportRecords.length; index++) {
      const row = [];
      row.push({
        rowSpan: 1,
        content: index + 1,
        styles: cellStyle
      });
      row.push({
        rowSpan: 1,
        content: moment(this.exportRecords[index].invoiceDate).format('MM/DD/YYYY HH:mm'),
        styles: cellStyle
      });
      if (!this.selectedClient) {
        row.push({
          rowSpan: 1,
          content: this.exportRecords[index].clientName,
          styles: cellStyle
        });
      }
      row.push({
        rowSpan: 1,
        content: this.exportRecords[index].invoiceNumber,
        styles: {
          halign: 'left',
          valign: 'middle',
          textColor: [0, 0, 255],
        }
      });

      row.push({
        rowSpan: 1,
        content: moment(this.exportRecords[index].dueDate).format('MM/DD/YYYY'),
        styles: cellStyle
      });

      const paidFee = this.exportRecords[index].paidFee;
      row.push({
        rowSpan: 1,
        content: "$" + (paidFee ? paidFee.toFixed(2) : '0.00'),
        styles: {
          halign: 'left',
          valign: 'middle',
          textColor: [0, 128, 0],
        }
      });

      const balance = this.exportRecords[index].balance;
      row.push({
        rowSpan: 1,
        content: "$" + (balance ? balance.toFixed(2) : '0.00'),
        styles: {
          halign: 'left',
          valign: 'middle',
          textColor: [255, 0, 0],
        }
      });

      bodyContent.push(row);
    }

    let header;
    if (this.selectedClient) {
      header = [['#', 'Invoice Date', 'Invoice Number', 'Due Date', 'Service Fee Paid', 'Balance']];
    } else {
      header = [['#', 'Invoice Date', 'Client Name', 'Invoice Number', 'Due Date', 'Service Fee Paid', 'Balance']];
    }
    autoTable(doc, {
      head: header,
      pageBreak: 'auto',
      rowPageBreak: 'avoid',
      body: bodyContent,
      theme: 'striped',
      headStyles: {
        fillColor: '#343a40'
      }
    });

    doc.setProperties({
      title: "StatementBalanceReport"
    });
    const blobUrl = URL.createObjectURL(doc.output("blob"));

    if (action !== 'SEND_EMAIL_STATEMENT') {
      window.open(blobUrl, '_blank');
      this.monitoringDetailsService.monitorAction(
        `Exported Statement & Balance`,
        this.timespent,
        {
          exported_to_statement_balance_by: this.authService.getCurrentLoggedInName()
        },
        'complete',
        `Exported Statement & Balance`,
        0
      );
    }

    if (action === 'SEND_EMAIL_STATEMENT') {
      const pdfFile = new File([doc.output("blob")], 'statementBalanceReport.pdf');
      this.documentServicev2.uploadFile(pdfFile, 'statement_balance', this.authService.getCurrentCompanyId(), 'Company').subscribe(resUpload => {
        if (resUpload.status === 'SUCCESS') {
          const option: any = {
            email: this.selectedClientEmail,
            fullName: this.selectedClient.value,
            clientId: this.selectedClient.key,
            companyId: this.authService.getCurrentCompanyId(),
            pdfLink: resUpload.data.fileUrl
          }
      //    console.log('SEND_EMAIL_STATEMENT option: ', option);
          this.paymentInvoiceService.sendEmailStatement(option).subscribe(resSendEmail => {
      //      console.log('sendEmailStatement resSendEmail: ', resSendEmail);
            if (resSendEmail.status === 'SUCCESS') {
              if (resSendEmail.data.code === '00') {
                this.showPopupEmailStatement = false;
                this.messageService.add({ severity: 'success', summary: 'success', detail: 'Send email statement success' });
              } else {
                this.messageService.add({ severity: 'error', summary: 'error', detail: 'An error occurred while sending email: ' + resSendEmail.data.message });
              }
            } else {
              this.messageService.add({ severity: 'error', summary: 'error', detail: 'An error occurred while sending email: ' + resSendEmail.message });
            }
          },(error) => {
            this.messageService.add({ severity: 'error', summary: 'error', detail: 'An error occurred while sending email: ' + error });
          });
        } else {
          this.messageService.add({ severity: 'error', summary: 'error', detail: 'An error occurred while sending email: ' + resUpload.message });
        }
      },(error) => {
        this.messageService.add({ severity: 'error', summary: 'error', detail: 'An error occurred while sending email: ' + error });
      });
    }
  }

  sortDataSelected(selectedSortOption) {
//    console.log('sortDataSelected selectedSortOption: ', selectedSortOption)
//    console.log('sortDataSelected selectedField: ', this.selectedField)
    this.sortField = this.selectedField;
    this.sortOrder = selectedSortOption === 'ASC' ? 1 : 0;
    this.search();
  }

  changeClient(event?) {
    this.searchData();
  }

  openPopupEmailStatement() {
 //   console.log('openPopupEmailStatement selectedClient: ', this.selectedClient);
    if (this.selectedClient) {
      if (this.selectedClient.email) {
        this.showPopupEmailStatement = true;
        this.selectedClientEmail = this.selectedClient.email;
      } else {
        const messageError = 'Client: ' + this.selectedClient.fullName + ' - have empty email';
        this.messageService.add({ severity: 'error', summary: 'Error', detail: messageError });
      }
    } else {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: `You have not selected client` });
    }
  }

  closePopupEmailStatement() {
    this.showPopupEmailStatement = false;
    this.selectedClientEmail = null;
  }

  actionEmailStatement() {
    this.downloadPdf('SEND_EMAIL_STATEMENT');
  }

  initStatementBalanceDays(type) {
    this.showStatementBalanceDays = true;
    if (type === '1') {
      this.statementBalanceDays = this.statementBalanceDaysTmp.first30;
      this.totalBalanceDays = (this.statementBalanceDaysTmp.first30 || []).length;
    } else if (type === '2') {
      this.statementBalanceDays = this.statementBalanceDaysTmp.first60;
      this.totalBalanceDays = (this.statementBalanceDaysTmp.first60 || []).length;
    } else if (type === '3') {
      this.statementBalanceDays = this.statementBalanceDaysTmp.first90;
      this.totalBalanceDays = (this.statementBalanceDaysTmp.first90 || []).length;
    } else if (type === '4') {
      this.statementBalanceDays = this.statementBalanceDaysTmp.first120;
      this.totalBalanceDays = (this.statementBalanceDaysTmp.first120 || []).length;
    } else {
      this.statementBalanceDays = this.statementBalanceDaysTmp.last120;
      this.totalBalanceDays = (this.statementBalanceDaysTmp.last120 || []).length;
    }
  }

  exportBalanceDays() {
    const doc = this.buildPdf();
    let output = doc.output('bloburl');
    window.open(output.toString(), '_blank');
  }

  buildPdf(): jsPDF {
    const pdfBody: any = this.getPdfBody();
    const doc = new jsPDF();

    autoTable(doc, {
      margin: 0,
      head: [
        [
          {
            content: '#',
            styles: {
              halign: 'left',
              fontSize: 11,
              fontStyle: 'bold',
              cellWidth: 35
            }
          },
          {
            content: 'Client',
            styles: {
              halign: 'left',
              fontSize: 11,
              fontStyle: 'bold',
              cellWidth: 25
            }
          },
          {
            content: 'Invoice',
            styles: {
              halign: 'left',
              fontSize: 11,
              fontStyle: 'bold',
              cellWidth: 20
            }
          },
          {
            content: 'Balance',
            styles: {
              halign: 'left',
              fontSize: 11,
              fontStyle: 'bold',
              cellWidth: 20
            }
          }
        ],
      ],
      theme: 'striped',
      columnStyles: {
        0: {cellWidth: 35},
        1: {cellWidth: 50},
        2: {cellWidth: 50},
        3: {cellWidth: 50}
      },
      styles: {
        lineWidth: 0.5,
      },
      bodyStyles: {
        fillColor: 255
      },
      alternateRowStyles: {
        fillColor: [245, 245, 245]
      },
      body: pdfBody,
    });
    return doc;
  }

  getPdfBody() {
    const cloneList = this.statementBalanceDays.map(x => Object.assign({}, x));
    return cloneList.map((row, index) => {
      return [
        {
          content: String(index + 1),
          styles: {
            halign: 'left'
          }
        },
        {
          content: row.clientName,
          styles: {
            halign: 'left'
          }
        },
        {
          content: row.invoiceNumber,
          styles: {
            halign: 'left'
          }
        },
        {
          content: '$' + (row.balance || 0).toFixed(2),
          styles: {
            halign: 'left'
          }
        }
      ]
    });
  }

  getInvoiceTypes() {
    this.typeList = [];
    this.generalInvoiceTypeService.getDropdown({status: 'Active'}).subscribe(res => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        resObj.data.forEach(type => {
          this.typeList.push({ label: type.value, value: type.value });
        });
      }
    });
  }

  customFilter() {
    this.filterSubject.debounceTime(1000).subscribe(() => {
      this.searchData()
    })
  }

  filterChange() {
    this.filterSubject.next(this.filterText)
  }

  ngOnDestroy(): void {
    this.filterSubject?.unsubscribe()
    if (this.routerEventsSubscribe) {
      this.routerEventsSubscribe.unsubscribe();
    }
  }

  exportFilter() {
    if (this.filterText) {
      this.exportRecords = this.allRecords.filter(t => {
        const invoiceDate = this.formatDate(t.invoiceDate);
        const dueDate = this.formatDate(t.dueDate);
        const updatedAt = this.formatDate(t.updatedAt);
        return ((t.invoiceNumber && t.invoiceNumber.toLowerCase().search(this.filterText.toLowerCase()) > -1) || (invoiceDate && invoiceDate.toLowerCase().search(this.filterText.toLowerCase()) > -1)
            || (t.clientName && t.clientName.toLowerCase().search(this.filterText.toLowerCase()) > -1) || (t.referenceNo && t.referenceNo.toLowerCase().search(this.filterText.toLowerCase()) > -1)
            || (dueDate && dueDate.toLowerCase().search(this.filterText.toLowerCase()) > -1) || (t.paidFee && String(t.paidFee).toLowerCase().search(this.filterText.toLowerCase()) > -1)
            || (t.balance && String(t.balance).toLowerCase().search(this.filterText.toLowerCase()) > -1) || (updatedAt && updatedAt.toLowerCase().search(this.filterText.toLowerCase()) > -1));
      });
    } else {
      this.exportRecords = this.allRecords.filter(i => __assign({}, i));
    }
  }
}
