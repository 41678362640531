import { Injectable } from '@angular/core';
import { AbstractService } from '../../shared/service/abstract.service';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../shared/model/constants';
import { Page } from '../../shared/model/page.model';
import { Position } from '../model/position.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AbstractServiceV2 } from '../../shared/service/abstract.v2.service';
import { PositionSearch } from '../../shared/model/search/position.search';


@Injectable({ providedIn: 'root' })
export class PositionService extends AbstractServiceV2<Position, PositionSearch> {

    constructor(private http: HttpClient) {
        super(http, Constants.ROUTES.OPERATOR + '/position', '');
    }

    getPositionSearch(params: any): Observable<Page<Position>> {
        return this._http.get<Page<Position>>(this.baseUrlV2 + '/search', { params: params }).pipe(map(resp => resp));
    }

    getPositionListByPage(params: any): Observable<Page<Position>> {
        return this._http.get<Page<Position>>(`${this.baseUrlV2}findAll`, { params: params }).pipe(map(resp => resp));
    }

    getPositionsByCompanyAndAgency(params: any): Observable<Page<Position>> {
        return this._http.get<Page<Position>>(
            `${this.baseUrlV2}/` + Constants.URI.POSITION.LISTALLBYCOMPANYANDAGENCY, { params: params }).pipe(map(resp => resp));
    }

    readAllForDropDownList(params: any): Observable<any> {
        return this._http.get<any>(`${this.baseUrlV2}/dropdown`, { params: params }).pipe(map(resp => resp));
    }

    findAllForSelection(params?: any): Observable<Position[]> {
        return this._http.get<any>(`${this.baseUrlV2}findAllSelection`, { params }).pipe(map(resp => resp));
    }

    getHistory(params: any): Observable<Page<any>> {
        return this._http.post<Page<any>>(`${this.baseUrlV2}/history`, params).pipe(map(resp => resp));
    }

    exportPdf(options: any): Observable<any> {
        return this.http.post<any>(`${this.baseUrlV2}/pdf`, options).pipe(map(resp => resp));
    }
}
