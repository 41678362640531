import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { CompanyService } from '../../../company/service/company.service';
import { AgencyService, AgencyHolidayService } from '../../../agency/service';
import { Company } from '../../../company/model/company.model';
import { Agency, AgencyHoliday } from '../../../agency/model';
import { Subject } from 'rxjs/Subject';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormControl } from '@angular/forms';
import { Constants } from '../../../shared/model/constants';
import * as _ from 'lodash';
import { AGENCY_PAGE, CMD_ACTION } from '../../../agency/agency.constant';
import { UtilsService } from '../../../shared/service/utils.service';
import { AuthService } from '../../../shared/service/auth/auth.service';
import { ConfirmationService } from 'primeng/api';
import { HolidayHistoryService } from '../../service/holiday-history.service';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { DATE_MONTH_FORMATS, DATE_FORMATS } from 'app/shared/data/config-common';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MessageService } from 'primeng/api';
import { CompanyServicev2 } from 'app/company/service/company.v2.service';
import { CompanyHolidayService } from './../../service/company-holiday.service';
import { CompanyHolidaySearchCriteria, CompanySearch } from 'app/shared/model/search/compnay.search';
import { CompanyHoliday } from 'app/company/model/v2/company-Holiday.model';
import * as MOMENT from 'moment';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import * as moment from "moment-timezone";
import {FileUtility} from "../../../shared/utility/file.utility";
import {Table} from 'primeng/table';
@Component({
  selector: 'app-company-holiday-manage',
  templateUrl: './company-holiday-manage.component.html',
  styleUrls: ['./company-holiday-manage.component.scss'],
  providers: [
    HolidayHistoryService,
    // {
    //   provide: DateAdapter,
    //   useClass: MomentDateAdapter,
    //   deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    // },
    // { provide: MAT_DATE_FORMATS, useValue: DATE_MONTH_FORMATS }
  ]
})
export class CompanyHolidayManageComponent implements OnInit, OnDestroy {

  @ViewChild('f') myNgForm;
  @ViewChild('table', { static: true }) table: Table;
  loading = true;
  size: number = 9999;
  page: number;
  companySelects: any;
  companyName: any;
  agencyName: any;
  agencyId: number;
  companyId: any;
  agencies: any[] = [];
  agency: Agency;
  searchText:any;
  company: Company;
  companySearchCriteria: CompanySearch;
  holidaySearchCriteria: CompanyHolidaySearchCriteria = new CompanyHolidaySearchCriteria();
  agencySelected: any;
  historyShow = false;
  searchStatus: any[] = [
    { label: 'active', value: 'ACTIVE' },
    { label: 'inactive', value: 'IN_ACTIVE' }
  ];

  optionLogic: any[] = [
    { label: 'Month & Day', value: 'Month & Day' },
    { label: 'Day', value: 'Day' }
  ];
  optionDayOfWeek: any[] = [
    { label: 'Mon', value: '1' },
    { label: 'Tue', value: '2' },
    { label: 'Wed', value: '3' },
    { label: 'Thu', value: '4' },
    { label: 'Fri', value: '5' },
    { label: 'Sat', value: '6' },
    { label: 'Sun', value: '7' },
  ];
  optionMonth: any[] = [
    { label: 'January', value: '1' },
    { label: 'February', value: '2' },
    { label: 'March', value: '3' },
    { label: 'April', value: '4' },
    { label: 'May', value: '5' },
    { label: 'June', value: '6' },
    { label: 'July', value: '7' },
    { label: 'August', value: '8' },
    { label: 'September', value: '9' },
    { label: 'October', value: '10' },
    { label: 'November', value: '11' },
    { label: 'December', value: '12' },
  ];
  optionEvery: any[] = [];
  selectedStatus: any;
  selectedSortOption = '';
  selectedField = '';
  textSortOptions = [
    { name: 'Sort A To Z', value: 'ASC', img: 'arrow-down-a-z' },
    { name: 'Sort Z To A', value: 'DESC', img: 'arrow-up-z-a' }
  ];
  numberSortOptions = [
    { name: 'Sort 0 To 9', value: 'ASC', img: 'arrow-down-1-9' },
    { name: 'Sort 9 To 0', value: 'DESC', img: 'arrow-up-9-1' }
  ];
  dateSortOptions = [
    { name: 'Sort 0 To 9', value: 'ASC', img: 'arrow-down-1-9' },
    { name: 'Sort 9 To 0', value: 'DESC', img: 'arrow-up-9-1' }
  ];
  cols: any[] = [
    { field: 'id', label: 'cols.id', sortOptions: '', sort: 'number' },
    { field: 'holidayName', label: 'Holiday', sortOptions: '', sort: 'text' },
    { field: 'holidayDate', label: 'cols.date', sortOptions: 'holidayDate', sort: 'number' },
    { field: 'every', label: 'Every', sortOptions: 'every', sort: 'number' },
    { field: 'createdAt', label: 'Created', sortOptions: 'createdAt', sort: 'number' },
    { field: 'status', label: 'cols.status', sortOptions: '', sort: 'text' },
    { field: 'actions', label: 'cols.action' }
  ];
  holidayDate: Date;
  companyList: any[];
  agencyList: Agency[];
  edittingHoliday: CompanyHoliday;
  formMode: string;

  private destroyed$ = new Subject();
  private dataSourceSubject = new BehaviorSubject(null);
  dataSource$ = this.dataSourceSubject.asObservable();
  holidayList = [];
  holidayListExport = [];

  searchForm: UntypedFormGroup;
  holidayForm: UntypedFormGroup;
  isPlatformAdmin = false;
  hide: boolean;
  sortField: string;
  sortOrder: any;
  msgs: any[];
  addHolidayModal = false;
  displayDialog: boolean = false;
  currentHoliday: AgencyHoliday;
  constructor(private router: Router,
    private formBuilder: UntypedFormBuilder,
    private holidayHistoryService: HolidayHistoryService,
    private confirmationService: ConfirmationService,
    private authService: AuthService,
    private messageService: MessageService,
    private companyService: CompanyServicev2,
    private companyHolidayService: CompanyHolidayService,
    private translate: TranslateService,
    private datePipe: DatePipe,
    private translatePipe: TranslatePipe) {
    this.translate.use(this.translate.currentLang ? this.translate.currentLang : 'en');
  }

  ngOnInit() {

    this.initHolidayForm();
    this.initSearchForm();
    this.initCompanyDropdown();

    this.hide = this.authService.isCompanyAd() || this.authService.isSubCompanyAd();
    this.company = this.authService.getCurrentCompany() ? this.authService.getCurrentCompany() : null;
    this.isPlatformAdmin = this.authService.isSuper() || this.authService.isSubSuper();
    if (!this.isPlatformAdmin) {
      this.companyId = this.authService.getCurrentCompany();
    } else {
      this.cols = [
        { field: 'id', label: 'cols.id', sortOptions: '', sort: 'number' },
        { field: 'companyName', label: 'Company', sortOptions: '', sort: 'text' },
        { field: 'holidayName', label: 'Holiday', sortOptions: '', sort: 'text' },
        { field: 'holidayDate', label: 'cols.date', sortOptions: 'holidayDate', sort: 'number' },
        { field: 'every', label: 'Every', sortOptions: 'every', sort: 'number' },
        { field: 'createdAt', label: 'Created', sortOptions: 'createdAt', sort: 'number' },
        { field: 'status', label: 'cols.status', sortOptions: '', sort: 'text' },
        { field: 'actions', label: 'cols.action' }
      ];
    }


    for (let i = 1; i <= 31; i++) {
      this.optionEvery.push({ label: i.toString(), value: i.toString() });
    }
    this.loadAllHolidayList();
  }

  initCompanyDropdown() {
    this.companyService.getDropdown(this.companySearchCriteria).subscribe((res: any) => {
      this.companyList = [];
      res.data.forEach(element => {
        this.companyList.push({ label: element.value, value: element.key });
      });
      this.companySelects = this.authService.getCurrentCompany();
      // if (!this.isPlatformAdmin) { this.companySelects = this.authService.getCurrentCompany(); }
      //this.loadAllHolidayList();
    });
  }

  changeCompany(event: any) {
    this.companyId = event.value;
    this.companySelects = this.companyId;
    this.loadAllHolidayList();

  }

  search(event?: any) {
    this.page = 0;
    setTimeout(() => {
      const wordSearch = event.target.value;

      if (wordSearch === event.target.value) {
        this.loadAllHolidayList();
        }
      }, 1000);

  }

  private initSearchForm() {
    this.searchForm = this.formBuilder.group({
      company: [null, Validators.required],
    });
   this.searchForm.patchValue({ company: this.authService.getCurrentCompany() });
  }

  getHistory() {
    this.historyShow = true;
  }
  private initHolidayForm(holiday?: CompanyHoliday) {
    if (holiday && holiday.id) {
      this.formMode = Constants.FORM_MODE.UPDATE;
    } else {
      this.formMode = Constants.FORM_MODE.CREATE;
      holiday = new CompanyHoliday();
    }
    this.holidayForm = this.formBuilder.group({
      holidayName: [holiday.holidayName, Validators.required],
      holidayDate: [holiday.holidayDate ? this.setDate(holiday.holidayDate) : null],
      status: [holiday.status, Validators.required],
      holidayLogic: [holiday.holidayLogic, Validators.required],
      day: [holiday.day],
      dayOfWeek: [holiday.dayOfWeek],
      month: [holiday.month]
    });
  }

  ngOnDestroy() {
    this.dataSourceSubject.next(null);
    this.dataSourceSubject.complete();
    this.destroyed$.next();
    this.destroyed$.complete();
    
  }

sortingClick(selectedSortOption: string) {
  this.selectedSortOption = selectedSortOption;
  this.page = 0; // Reset to the first page
  //if (this.selectedField !== 'every' && this.selectedField !== 'companyName') {
  //  this.loadAllHolidayList();
  //} else {
    const options = {
      field: this.selectedField === 'every' ? 'everyStr' : (this.selectedField === 'holidayDate'? 'holidayDateStr' : this.selectedField),
      order: selectedSortOption === 'ASC' ? 1 : -1,
      mode: 'single',
      data: this.holidayListExport
    };
    this.customSort(options);
  //}
}



  setSortOption(field, selectedSortOption?) {
    this.cols.forEach(item => {
      if (field === item.field) {
        item.sortOptions = selectedSortOption;
        this.selectedSortOption = selectedSortOption;
      }
    });
  }

  loadAllHolidayList(event?: any) {
    if (!this.isPlatformAdmin) {
      this.companyId = this.authService.getCurrentCompany();
    }
    this.holidayList = [];
    this.dataSourceSubject.next(null);
    this.loading = true;
    this.page = 0;
	this.holidaySearchCriteria.size = event && event.rows ? event.rows : (this.size ? this.size : 99999);
	this.holidaySearchCriteria.page = event && event.first && event.rows ? (event.first / event.rows) : (this.page ? this.page : 0);
    this.holidaySearchCriteria.sortField = this.selectedField ? this.selectedField : 'createdAt';
    this.holidaySearchCriteria.sortOrder = this.selectedSortOption ? this.selectedSortOption : 'DESC';
    this.holidaySearchCriteria.key = this.searchText ? this.searchText : null;
    this.holidaySearchCriteria.companyId = this.companyId;
    this.companyHolidayService.searchHoliday(this.holidaySearchCriteria).subscribe(
      (data: any) => {
        this.loading = false;
        this.dataSourceSubject.next(data.data);
        this.holidayList = data.data.content;
        const currentDate = new Date;
        this.holidayList.forEach(item => {
          if (item.holidayDate) {
            item.holidayDateStr = this.datePipe.transform(this.setDate(item.holidayDate), 'yyyy MMMM d');
            item.createdAtStr = this.datePipe.transform(item.createdAt, 'MM/dd/yyyy hh:mm a');
          }
          if (item.month && item.day) {
            item.everyStr = item.month + '/' + item.day + '/' + currentDate.getFullYear();
            item.every = this.datePipe.transform(this.setDate(item.everyStr), 'MMMM') + ' ' + this.getOrdinalSuffix(item.day);
          }
          if (this.isPlatformAdmin) {
            const companyName = this.companyList.find(obj => obj.value === item.companyId).label;
            item.companyName = companyName;
          }
        });
        this.holidayListExport = this.holidayList;
      },
      error => {
        console.error('Error fetching data:', error);
        this.loading = false;
      }
    );
  }

  getOrdinalSuffix(number) {
    const lastDigit = number % 10;
    const lastTwoDigits = number % 100;

    if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
      return number + "th";
    }

    switch (lastDigit) {
      case 1:
        return number + "st";
      case 2:
        return number + "nd";
      case 3:
        return number + "rd";
      default:
        return number + "th";
    }
  }

  hasError(fieldName: string, errorCode: string): boolean {
    return this.holidayForm.controls[fieldName] &&
      (this.holidayForm.controls[fieldName].dirty || this.holidayForm.controls[fieldName].touched) &&
      this.holidayForm.controls[fieldName].hasError(errorCode);
  }

  changeHistoryHandler(holidayId, mergedObject) {
    const admin = this.authService.getUserInfo();
    // console.log(mergedObject);
    const historyData = [];
    const fieldsName = ['holidayName', 'holidayDate', 'status', 'holidayLogic', 'day', 'dayOfWeek', 'month'];
    fieldsName.forEach(element => {
      if (mergedObject[element] !== null && mergedObject[element] !== '') {
        const dataObj: any = {};
        dataObj.fieldName = element;
        if (element === 'holidayDate') {
          dataObj.newValue = new Date(mergedObject[element]).getTime();
        }
        if(element === 'status') {
          dataObj.oldValue = '';
          dataObj.newValue = mergedObject[element] === 'ACTIVE' ? 'Active' : 'Inactive';
        } else if (element === 'dayOfWeek') {
            dataObj.oldValue = '';
            let dayOfWeek = this.optionDayOfWeek.find(item => item.value == mergedObject[element]);
            dataObj.newValue = dayOfWeek.label;
        } else if (element === 'month') {
            dataObj.oldValue = '';
            let month = this.optionMonth.find(item => item.value == mergedObject[element]);
            dataObj.newValue = month.label;
        } else {
          dataObj.oldValue = '';
          dataObj.newValue = mergedObject[element];
        }

        dataObj.action = 'Created';
        dataObj.userId = holidayId;
        dataObj.holidayId = holidayId;
        dataObj.userName = admin.username;
        historyData.push(dataObj);
      }
    });
    // console.log(historyData);
    this.holidayHistoryService.saveHistoryData(historyData).subscribe((item: any) => {
      // console.log(item);
    });
  }
  async changeHistoryHandlerEdit(newClientData) {
    const admin = this.authService.getUserInfo();
    await this.companyHolidayService.getHoliDayById(newClientData.id).subscribe((item: any) => {
      const oldData = item.data.data;
      // console.log(oldData);
      const historyData = [];
      const fieldsName = ['holidayName', 'holidayDate', 'status', 'holidayLogic', 'day', 'dayOfWeek', 'month'];
      fieldsName.forEach(element => {
        let newValue = newClientData[element];
        let oldValue = oldData[element];
        if (element === 'status') {
          newValue = newClientData[element] === 'ACTIVE' ? 'Active' : 'Inactive';
          oldValue = oldData[element] === 'ACTIVE' ? 'Active' : 'Inactive';
        }
        if (newValue !== oldValue) {
          const dataObj: any = {};
          dataObj.fieldName = element;
          if (element === 'holidayDate') {
            dataObj.oldValue = new Date(oldData[element]);
            if (newClientData[element]) {
              dataObj.newValue = new Date(newClientData[element]);
            }

          } else if (element === 'dayOfWeek') {
            if (oldValue) {
              let dayOfWeek = this.optionDayOfWeek.find(item => item.value == oldValue);
              dataObj.oldValue = dayOfWeek.label;
            }
            if (newValue) {
              let dayOfWeek = this.optionDayOfWeek.find(item => item.value == newValue);
              dataObj.newValue = dayOfWeek.label;
            }
          } else if (element === 'month') {
              if (oldValue) {
                  let month = this.optionMonth.find(item => item.value == oldValue);
                  dataObj.oldValue = month.label;
              }
              if (newValue) {
                  let month = this.optionMonth.find(item => item.value == newValue);
                  dataObj.newValue = month.label;
              }
          } else {
            dataObj.oldValue = oldValue;
            dataObj.newValue = newValue;
          }
          if (!dataObj.newValue) {
            dataObj.action = 'Deleted';
          } else {
            dataObj.action = 'Updated';
          }

          dataObj.holidayId = newClientData.id;
          dataObj.userName = admin.username;
          historyData.push(dataObj);
        }
      });
      // console.log(historyData);
      this.holidayHistoryService.saveHistoryData(historyData).subscribe((item: any) => {
       // console.log(item);
      });
    });
  }

  async onSave(event: Event) {
    const holidayRaw: CompanyHoliday = this.holidayForm.getRawValue();
    if (!this.companySelects === undefined) {
      this.messageService.add({ severity: 'error',
               summary: this.translatePipe.transform('error'), detail: this.translatePipe.transform('PleaseSelectSompany') });
      return;
    }
    if (this.formMode === Constants.FORM_MODE.UPDATE) {
      this.msgs = [];
      const historyName = this.edittingHoliday.holidayName;
      const historyDate = this.edittingHoliday.holidayDate;
      _.assign(this.edittingHoliday, holidayRaw);
        this.edittingHoliday.holidayDate = MOMENT(holidayRaw.holidayDate).startOf('day').add(MOMENT.duration(12, 'hours')).toDate();
        await this.changeHistoryHandlerEdit(this.edittingHoliday);


      await this.companyHolidayService.updateHoliday(this.edittingHoliday).subscribe((response: any) => {
        const resObj: any = response;
        if (resObj.status === 'SUCCESS') {
          this.messageService.add({ severity: 'info',
                      summary: this.translatePipe.transform('Updated'), detail: this.translatePipe.transform('HolidayUpdatedSuccessfully') });
          setTimeout(() => {
            // this.resetCompanyAgency();
            this.loadAllHolidayList();
            this.initHolidayForm();
            this.myNgForm.resetForm();
          }, 1000);
        } else if (resObj.status === 'COMPANY_HOLIDAY_NAME_EXIT') {
          this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('error'), detail: this.translatePipe.transform('COMPANY_HOLIDAY_NAME_EXIT') });
          //this.initHolidayForm();
          //this.loadAllHolidayList();
          //this.myNgForm.resetForm();
        } else {
          //this.edittingHoliday.holidayName = historyName;
          //this.edittingHoliday.holidayDate = historyDate;
          this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('error'), detail: this.translatePipe.transform('COMPANY_HOLIDAY_NAME_UNIQUE') });
          //this.initHolidayForm();
          //this.loadAllHolidayList();
          //this.myNgForm.resetForm();
        }
      }, (error: any) => {
        this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('error'), detail: this.translatePipe.transform('ErrorUpdating') });
      });
    } else {
      this.msgs = [];

      // holidayRaw.agency = { id: this.agency.id };
      holidayRaw.companyId = this.companySelects;
      holidayRaw.holidayDate = holidayRaw.holidayDate ? MOMENT(holidayRaw.holidayDate).startOf('day').add(MOMENT.duration(12, 'hours')).toDate() : null;
      await this.companyHolidayService.createHoliDay(holidayRaw).subscribe((response: any) => {
        const resObj: any = response;
        if (resObj.status === 'SUCCESS') {
          this.messageService.add({ severity: 'success', summary: this.translatePipe.transform('Created'), detail: this.translatePipe.transform('HolidayAddedSuccessfully') });
          setTimeout(() => {
            this.changeHistoryHandler(resObj.data.id, holidayRaw);
            this.resetCompanyAgency();
            this.loadAllHolidayList();
            this.initHolidayForm();
            this.myNgForm.resetForm();
          }, 1000);
        } else if (resObj.status === 'COMPANY_HOLIDAY_NAME_EXIT') {
          this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('error'), detail: this.translatePipe.transform('COMPANY_HOLIDAY_NAME_EXIT') });
          setTimeout(() => {
            //this.loadAllHolidayList();
            //this.initHolidayForm();
            //this.myNgForm.resetForm();
          }, 1000);
        } else {
          this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('error'), detail: this.translatePipe.transform('COMPANY_HOLIDAY_NAME_UNIQUE') });
          setTimeout(() => {
            //this.loadAllHolidayList();
            //this.initHolidayForm();
            //this.myNgForm.resetForm();
          }, 1000);
        }
      }, (error: any) => {
        this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('error'), detail: this.translatePipe.transform('COMPANY_HOLIDAY_NAME_EXIT') });
      });

    }
  }

  onCancel(event: Event) {
    this.initHolidayForm();

    if (this.isPlatformAdmin) {
      this.companyId = null;
      this.resetCompanyAgency();
      this.myNgForm.resetForm();
      this.initSearchForm();
    }

    this.table.reset();
    this.searchText = '';
    setTimeout(() => {
        this.loadAllHolidayList();
    }, 1000);
  }

  onDeleteHoliday(event: Event, holiday: AgencyHoliday) {
    this.currentHoliday = holiday; // set the current holiday
    this.displayDialog = true;
    this.msgs = [];
  
  }


  onEditHoliday(event: Event, holiday: CompanyHoliday) {
    this.formMode = 'update';

    this.edittingHoliday = holiday;
    if (this.isPlatformAdmin) {
      this.companySelects = holiday.companyId;
      this.companyName = this.companySelects.name;
      this.companyId = holiday.companyId;
      this.holidayDate = this.setDate(holiday.holidayDate);
    }
    this.initHolidayForm(holiday);
  }

  setDate(holidayDate: any) {
    if (holidayDate === undefined) {
      return new Date();
    }
    const date = MOMENT.utc(holidayDate).format('MM-DD-YYYY');
    return new Date(date);
  }

  resetCompanyAgency() {
    this.companySelects = this.companyId;
    // this.companyId = null;
    this.agency = null;
  }

  addHoliday() {
    this.addHolidayModal = true;
  }
  closeHoliday() {
    this.addHolidayModal = false;
  }

  
  onConfirm() {
    this.companyHolidayService.deleteHoliday(this.companyId, this.currentHoliday.id).subscribe((res: any) => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        // this.resetCompanyAgency();
        this.loadAllHolidayList();
        this.myNgForm.resetForm();
        this.messageService.add({ severity: 'info',
                        summary: this.translatePipe.transform('Deleted'), detail: this.translatePipe.transform('HolidayDeletedSuccessfully') });
      }
    }, error => {
      const resObj: any = error;
      // this.resetCompanyAgency();
      this.loadAllHolidayList();
      this.myNgForm.resetForm();
      this.messageService.add({ severity: 'error', summary: this.translatePipe.transform(error), detail: this.translatePipe.transform(resObj.message) });
    });
      this.closeDialog();
  }
  
  onReject() {
      // Your rejection logic here...
      this.closeDialog();
  }
  
  closeDialog() {
      this.displayDialog = false;
  }

  exportPdf() {
      // let searchCriteria = _.cloneDeep(this.holidaySearchCriteria);
      // searchCriteria.page = 0;
      // searchCriteria.size = 9999;
      // this.companyHolidayService.searchHoliday(searchCriteria).subscribe(
      //     (data: any) => {
      //     let planList = data.data.content;
      //     if (planList && planList.length) {
      //         const data: any = {
      //             contentList: planList.map(value => {
      //                 return {
      //                     id: value.id,
      //                     holidayName: value.holidayName,
      //                     holidayDate: this.datePipe.transform(this.setDate(value.holidayDate), 'yyyy MMMM d'),
      //                     addedDate: this.datePipe.transform(value.createdAt, 'MM/dd/yyyy hh:mm a'),
      //                     status: value.status,
      //                     every: value.every,
      //                 };
      //             })
      //         };
      //         data.companyId = this.authService.getCurrentCompany();
      //         data.username = this.authService.getCurrentUsername();
      //         data.reportDate = this.datePipe.transform(new Date(), 'MM/dd/yyyy EEE hh:mm a');
      //         this.companyHolidayService.exportPdf(data).subscribe(resExport => {
      //             if (resExport.data) {
      //                 const blob = FileUtility.b64toBlob(resExport.data.body, 'application/pdf');
      //                 const blobUrl = URL.createObjectURL(blob);
      //                 window.open(blobUrl, '_blank');
      //             } else {
      //                 this.messageService.add({
      //                     severity: 'error',
      //                     summary: this.translatePipe.transform('Error'),
      //                     detail: this.translatePipe.transform('Export pdf fail')
      //                 });
      //             }
      //         }, () => {
      //             this.messageService.add({
      //                 severity: 'error',
      //                 summary: this.translatePipe.transform('Error'),
      //                 detail: this.translatePipe.transform('Export pdf fail')
      //             });
      //         });
      //     }
      // }, () => {
      //     this.messageService.add({
      //         severity: 'error',
      //         summary: this.translatePipe.transform('Error'),
      //         detail: this.translatePipe.transform('Export pdf fail')
      //     });
      // });
    if (this.holidayListExport && this.holidayListExport.length) {
      const data: any = {
        contentList: this.holidayListExport.map(value => {
          return {
            id: value.id,
            companyName: (value.companyName || '').trim(),
            holidayName: value.holidayName,
            holidayDate: value.holidayDate && this.datePipe.transform(this.setDate(value.holidayDate), 'yyyy MMMM d'),
            addedDate: value.createdAt && this.datePipe.transform(value.createdAt, 'MM/dd/yyyy hh:mm a'),
            status: value.status,
            every: value.every,
          };
        })
      };
      data.companyId = !this.isPlatformAdmin? this.authService.getCurrentCompany() : this.companyId;
      data.username = this.authService.getCurrentUsername();
      data.reportDate = this.datePipe.transform(new Date(), 'MM/dd/yyyy EEE hh:mm a');
      data.hideCompanyCol = !this.isPlatformAdmin;
      this.companyHolidayService.exportPdf(data).subscribe(resExport => {
        if (resExport.data) {
          const blob = FileUtility.b64toBlob(resExport.data.body, 'application/pdf');
          const blobUrl = URL.createObjectURL(blob);
          window.open(blobUrl, '_blank');
        } else {
          this.messageService.add({
            severity: 'error',
            summary: this.translatePipe.transform('Error'),
            detail: this.translatePipe.transform('Export pdf fail')
          });
        }
      }, () => {
        this.messageService.add({
          severity: 'error',
          summary: this.translatePipe.transform('Error'),
          detail: this.translatePipe.transform('Export pdf fail')
        });
      });
    }
  }
  changeLogic(event) {
    if (event.value === 'Day') {
      this.holidayForm.controls['day'].setValidators([Validators.required]);
      this.holidayForm.controls['day'].updateValueAndValidity();

      this.holidayForm.controls['dayOfWeek'].setValidators([Validators.required]);
      this.holidayForm.controls['dayOfWeek'].updateValueAndValidity();

      this.holidayForm.controls['month'].setValidators([Validators.required]);
      this.holidayForm.controls['month'].updateValueAndValidity();

      this.holidayForm.controls['holidayDate'].patchValue(null);
      this.holidayForm.controls['holidayDate'].setValidators(null);
      this.holidayForm.controls['holidayDate'].updateValueAndValidity();

    } else if (event.value === 'Month & Day') {
      this.holidayForm.controls['holidayDate'].setValidators([Validators.required]);
      this.holidayForm.controls['holidayDate'].updateValueAndValidity();

      this.holidayForm.controls['day'].setValidators(null);
      this.holidayForm.controls['day'].updateValueAndValidity();

      this.holidayForm.controls['dayOfWeek'].setValidators(null);
      this.holidayForm.controls['dayOfWeek'].updateValueAndValidity();

      this.holidayForm.controls['month'].setValidators(null);
      this.holidayForm.controls['month'].updateValueAndValidity();

      this.holidayForm.controls['day'].patchValue(null);
      this.holidayForm.controls['dayOfWeek'].patchValue(null);
      this.holidayForm.controls['month'].patchValue(null);
    }
  }
  customSort(event) {
    if (event.data) {
      event.data.sort((data1, data2) => {
        let value1 = data1[event.field];
        let value2 = data2[event.field];
        let result = null;

        if (value1 == null && value2 != null) {
          result = -1;
        } else if (value1 != null && value2 == null) {
          result = 1;
        } else if (value1 == null && value2 == null) {
          result = 0;
        } else if (typeof value1 === 'string' && typeof value2 === 'string') {
          result = value1.localeCompare(value2);
        } else {
          result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
        return (event.order * result);
      });
    }
    this.holidayListExport = event.data;
  }

  onFilter(event, dt) {
    this.holidayListExport = event.filteredValue;
  }
}
