// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
export const environmentLocal = {
  api: 'https://voip1.operrtel.com/api/addExtensionPjsipWeb.php',
  dndApi: 'https://voip1.operrtel.com/api/dndWeb.php',
  callForwardApi: 'https://voip1.operrtel.com/api/setCallForwardWeb.php',
  phoneSettingsApi: 'https://voip1.operrtel.com/api/extensionStatusWeb.php',
  callLog: 'https://voip1.operrtel.com/api/callLogWeb.php',
  listAvailableDids: 'https://voip1.operrtel.com/api/listAvailableDidsWeb.php'
};

const server_ip = 'https://dev2-api.operrwork.com';
const v2_server_ip = 'https://dev2-api.operrwork.com';
const operrtell_ip = 'https://prodapi.operrtel.com';

export const environment = {
  production: false,
  server_backend: server_ip, // pls change this field when change the server ip without '/api'
  server_ip: `${server_ip}/api`,
  v2_server_backend: `${v2_server_ip}`,
  v2_server_ip: `${v2_server_ip}`,
  operrtell_server_ip: `${operrtell_ip}`,
  ip_checking_site: 'https://api.ipify.org/?format=json',
  time_to_display_clock: 15,
  sip: {
    server: 'voip1.operrtel.com',
    websocketProxyUrl: 'wss://ws.operrtel.com:8089/ws',
    iceServers: [{url: 'stun:stun.l.google.com:19302'}]
  },
  chat_url: 'https://chatbox-dev.operr.com/#/init?',
  chat_api_key: '697ce7aa546b5dd022fb7e09ef4cb5fb7a2cf2b8',
  // chat_api_key: '5c7e8a87dc0e820017593424',
  mail_url: 'https://mail.operr.com/',
  chat_api_url: 'https://chatbox-dev-api.operr.com/api/v1.0',
  // conference_api_url: 'http://192.168.120.39:8006/api/v2/meeting',ng s
  conference_api_url: 'https://dev2-api.operrwork.com/meeting/api/v2/meeting',
  v2_api_url: 'https://dev2-api.operrwork.com/company/api/v2',
  // v2_api_url: "http://localhost:8003/api/v2"
  payment_api_url: 'https://payment-gateway.operr.com/api/v1',
  payment_app_key: 'eaf8ee35d9f9c7335604437104afff4a',
  map_google_key: 'AIzaSyAAdRuq-hCMdR5W1591xLuHJUled9jlL_w',
  firebase: {
    apiKey: "AIzaSyCOkWVT_flJfsNuNoLm9e0JFvN7Ou_vuxM",
    authDomain: "operrwork-dev.firebaseapp.com",
    databaseURL: "https://operrwork-dev.firebaseio.com",
    projectId: "operrwork-dev",
    storageBucket: "operrwork-dev.appspot.com",
    messagingSenderId: "515079500672",
    appId: "1:515079500672:web:693d9f6aa913ba7b830765",
    measurementId: "G-798QD5Q0WD"
  },
  fustiontable: {
    apiUrl: 'https://www.googleapis.com/fusiontables/v2/query',
    apiKey: 'AIzaSyAAdRuq-hCMdR5W1591xLuHJUled9jlL_w',
    tableId: '1rMogw_5raP2ggDiK0S8k2-bDhQzf3yrmu4ObwcA_',
  },
  face_recognition_url: 'https://face-api.operrwork.com',
  face_recognition_system: 'Operrwork_DEV',
  dispatch: {
    url: 'https://dev3-api.operr.com'
  },
  fe_url: 'https://dev2.operrwork.com/#/app',
  operrtel_socket: 'https://devsocket.operrtel.com'
};
