<p-tabMenu [model]="employeeDashboardTabs" [activeItem]="activeItem">
	<ng-template pTemplate="item" let-item let-i="index">
		<span class="text-nowrap"><i class="fa-solid {{item.icon}} text-primary mr-2"></i> {{item.label | translate}}</span>
	</ng-template>
</p-tabMenu>
<div class="card add-shadow p-4 mb-0">

	<div class="row m-0">
		<div class="d-flex align-items-center justify-content-between pt-4 pb-2">
			<h1 class="m-0 ml-1">{{'My Dashboard' | translate}}</h1>
			<span>
				<!--
				<button class="btn btn-white mx-1" [routerLink]="'/app/employee/employee-job-report/list'"><i class="fa-solid fa-file-pen text-primary"></i></button>
				-->
				<button class="btn btn-white mx-1" (click)="triggerLogOut()" pTooltip="{{'Log Out' | translate}}" tooltipPosition="left"><i class="fa-solid fa-power-off text-danger"></i></button>
			</span>
		</div>
		<ng-container *ngFor="let rowData of absences">
			<ng-container *ngFor="let col of absenceCols">
				<div class="ui-xl-2 ui-md-4 ui-sm-6 p-3">
					<div class="card add-shadow text-left p-0 mb-0">
						<div class="card-header">
							<div class="d-flex align-items-center justify-content-between">
								<h5 class="mb-0 text-truncate">
									<i *ngIf="col.label === 'Type'" class="fa-solid fa-folder-tree text-primary mr-2"></i>
									<i *ngIf="col.label === 'PTO'" class="fa-solid fa-clock text-primary mr-2"></i>
									<i *ngIf="col.label === 'Period'" class="fa-solid fa-calendar text-primary mr-2"></i>
									<i *ngIf="col.label === 'Used'" class="fa-solid fa-clock text-orange mr-2"></i>
									<i *ngIf="col.label === 'Pending'" class="fa-solid fa-circle-info text-primary mr-2"></i>
									<i *ngIf="col.label === 'Available'" class="fa-solid fa-circle-check text-success mr-2"></i>
									{{ col.label | translate }}
								</h5>
								<i *ngIf="col.label === 'PTO'" class="fa-solid fa-circle-question text-muted ml-2" pTooltip="{{'Paid Time-Off' | translate}}" tooltipPosition="left" style="opacity: 0.5;font-size: 20px;"></i>
							</div>
						</div>
						<div class="card-body">
							<ng-container [ngSwitch]="col.field">
								<span *ngSwitchCase="'absenceType'">
									<h2 class="m-0" *ngIf="rowData.absenceType"  style="text-transform:capitalize">{{ rowData.absenceType }}</h2>
									<h2 *ngIf="!rowData.absenceType" class="text-muted m-0" style="opacity:0.6">{{ 'no data' | translate }}</h2>
								</span>
					
								<span *ngSwitchCase="'ptoAccrued'">
									<h2  class="m-0" *ngIf="rowData.ptoAccrued" >
										{{ getHoursFormat(rowData.ptoAccrued) }}
									</h2>
									<h2 *ngIf="!rowData.ptoAccrued" class="text-muted m-0" style="opacity:0.6">{{ '0:00' | translate }}</h2>
								</span>
					
								<span *ngSwitchCase="'toPeriod'">
									<h2  class="m-0" *ngIf="rowData.toPeriod">
										{{ rowData.toPeriod | date: 'M/d/y' }}
									</h2>
									<h2 *ngIf="!rowData.toPeriod" class="text-muted m-0" style="opacity:0.6">{{ 'no data' | translate }}</h2>
								</span>
					
								<span *ngSwitchCase="'pendingHours'">
									<h2 class="m-0" *ngIf="rowData.pendingHours" [ngClass]="{'text-success': checkHoursPositive(rowData.usedHours, rowData.pendingHours)}" >
										{{ getHHMMFormat(rowData.pendingHours) }}
									</h2>
									<h2 *ngIf="!rowData.pendingHours" class="text-muted m-0" style="opacity:0.6">{{ '0:00' | translate }}</h2>
								</span>
					
								<span *ngSwitchCase="'availablePtoHours'">
									<h2  class="m-0" *ngIf="rowData.availablePtoHours">
										{{ getHoursFormat(rowData.availablePtoHours) }}
									</h2>
									<h2 *ngIf="!rowData.availablePtoHours" class="text-muted m-0" style="opacity:0.6">{{ '0:00' | translate }}</h2>
								</span>
					
								<span *ngSwitchCase="'usedHours'">
									<h2 class="m-0" *ngIf="rowData.usedHours">
										{{ getHHMMFormat(rowData.usedHours) }}
									</h2>
									<h2 *ngIf="!rowData.usedHours" class="text-muted m-0" style="opacity:0.6">{{ '0:00' | translate }}</h2>
								</span>
							</ng-container>
						</div>
					</div>
				</div>
			</ng-container>
		</ng-container>


		<div class="ui-xl-4 ui-md-12 p-3">
			<div class="card add-shadow text-left p-0 mb-0">
				<div class="card-header">
					<div class="d-flex align-items-center justify-content-between">
						<h5 class="mb-0 text-truncate"><span class="badge mr-2" [ngClass]="totalRecords === 0 ? 'badge-muted' : 'badge-primary'">{{ totalRecords || 0 }}</span>{{'Punches & Hours' | translate}} ({{'Past week' | translate}})</h5>
					</div>
				</div>
	
				<p-table #dt id="punchesHours" header="Index" [value]="data" dataKey="id" [lazy]="true" (onLazyLoad)="loadComplianceReport($event)" [totalRecords]="totalRecords" [responsive]="true" [columns]="complianceReportColumns">
					<ng-template pTemplate="header" let-columns>
						<tr>
							<th pResizableColumn *ngFor="let col of columns" [ngStyle]="{'display': showPrevent ? col.preventDisplay:col.OTdisplay  }">
								<span class="d-flex align-items-center justify-content-center w-100">
									<span *ngIf="col.label === 'Date' || col.label === 'Sched.' || col.label === 'Actual'" class="one-liner" pTooltip="{{col.label | translate}}" tooltipPosition="left">{{col.label | translate}}</span>
									<i *ngIf="col.label === 'Good'" class="fa-solid fa-circle-check text-success" pTooltip="{{'On-Time' | translate}}" tooltipPosition="left"></i>
									<i *ngIf="col.label === 'Late'" class="fa-solid fa-clock text-warning" pTooltip="{{'Late' | translate}}" tooltipPosition="left"></i>
									<i *ngIf="col.label === 'Workers'" class="fa-solid fa-users" pTooltip="{{'Employees' | translate}}" tooltipPosition="left"></i>
									<i *ngIf="col.label === 'Miss'" class="fa-solid fa-triangle-exclamation text-orange" pTooltip="{{'Missed Punches' | translate}}" tooltipPosition="left"></i>
									<i *ngIf="col.label === 'No Break'" class="fa-solid fa-utensils text-danger" pTooltip="{{'Missed Break' | translate}}" tooltipPosition="left"></i>
								</span>
							</th>
						</tr>
					</ng-template>
					<ng-template pTemplate="body" let-rowData let-i="rowIndex">
						<tr [pSelectableRow]="rowData" id="id_punch_history_table_body">
							<td id="expected-column">
								<span class="one-liner">
							
									<span *ngIf="rowData.date" class="one-liner" pTooltip="{{rowData.date | date:'mediumDate'}}"><b>{{rowData.date | date : 'MM/dd'}}</b></span>
									<span *ngIf="!rowData.date" class="text-muted">{{'no data' | translate}}</span>
								</span>
							</td>
							<td (click)="detailClick(rowData, 'onTimeIds', getDate(rowData.date) + ' Employee On Time')" class="link">
								<span class="one-liner">
							
									<span *ngIf="rowData.onTimeStr" pTooltip="{{rowData.onTimeStr}}">{{rowData.onTimeStr}}</span>
									<span *ngIf="!rowData.onTimeStr" class="text-muted">0</span>
								</span>
							</td>
							<td (click)="detailClick(rowData, 'lateIds', getDate(rowData.date) + ' Employee Late')" class="link">
								<span class="one-liner">
								
									<span *ngIf="rowData.lateCount" pTooltip="{{rowData.lateCount}}">{{rowData.lateCount}}</span>
									<span *ngIf="!rowData.lateCount" class="text-muted">0</span>
								</span>
							</td>
							<td *ngIf="!isEmployee" (click)="detailClick(rowData, 'totalIds', getDate(rowData.date) + ' Employee Total')" class="link">
								<span class="one-liner">
								
									<span *ngIf="rowData.totalEmployee"
										pTooltip="{{rowData.totalEmployee}}">{{rowData.totalEmployee}}</span>
									<span *ngIf="!rowData.totalEmployee" class="text-muted">0</span>
								</span>
							</td>
							<td (click)="detailClick(rowData, 'missedclockIds', getDate(rowData.date) + ' Employee Miss Clock Count')" class="link">
								<span class="one-liner">
							
									<span *ngIf="rowData.missedclockCount" pTooltip="{{rowData.missedclockCount}}">{{rowData.missedclockCount}}</span>
									<span *ngIf="!rowData.missedclockCount" class="text-muted">0</span>
								</span>
							</td>
							<td id="without-lunch-column" (click)="detailClick(rowData, 'withoutLunchIds', getDate(rowData.date) + ' Employee Without Lunch')" class="link">
								<span class="one-liner">
								
									<span *ngIf="rowData.withoutLunchCount" pTooltip="{{rowData.withoutLunchCount}}">{{rowData.withoutLunchCount}}</span>
									<span *ngIf="!rowData.withoutLunchCount" class="text-muted">0</span>
								</span>
							</td>
							<td>
								<span class="one-liner">
			
									<span *ngIf="rowData.timeWorkDay && rowData.isHoliday" pTooltip="{{rowData.timeWorkDay}}">{{convertMinutesToHours(rowData.timeWorkDay)}}</span>
									<span *ngIf="!rowData.timeWorkDay && rowData.isHoliday" class="text-muted">0:00</span>
									<span *ngIf="!rowData.isHoliday" class="text-muted">0:00</span>
								</span>
							</td>
							<td>
								<span class="one-liner">
								
									<span *ngIf="rowData.regularTimeHHMM" pTooltip="{{rowData.regularTimeHHMM}}">{{rowData.regularTimeHHMM}}</span>
									<span *ngIf="!rowData.regularTimeHHMM" class="text-muted">0:00</span>
								</span>
							</td>
						</tr>
					</ng-template>
	
	
					<ng-template pTemplate="footer" let-columns>
						<ng-container>
							<tr id="table-data-row" style="height: 48px !important">
								<td class="text-right" colspan="1">
									<span class="desktop-table-label">{{'Totals' | translate}}: </span>
								</td>
								<td>
									<span class="one-liner">
									
										<span class="mobile-table-label align-items-center"><i class="fa-solid fa-circle-check text-success mr-2" pTooltip="{{'On-Time' | translate}}" tooltipPosition="left"></i>{{'On-Time' | translate}}: </span>{{complianceReport?.totalOntime}}
									</span>
								</td>
								<td>
									<span class="one-liner">
						
										<span class="mobile-table-label align-items-center"><i class="fa-solid fa-clock text-warning mr-2" pTooltip="{{'Late' | translate}}" tooltipPosition="left"></i>{{'Late' | translate}}: </span>{{complianceReport?.totalLate}}
									</span>
								</td>
								<td *ngIf="!isEmployee">
									<span class="one-liner">
										<span class="mobile-table-label align-items-center"><i class="fa-solid fa-users text-primary mr-2" pTooltip="{{'Employees' | translate}}" tooltipPosition="left"></i>{{'Employees' | translate}}: </span>{{complianceReport?.totalEmployee}}
									</span>
								</td>
								<td>
									<span class="one-liner">
								
										<span class="mobile-table-label align-items-center"><i class="fa-solid fa-triangle-exclamation text-orange mr-2" pTooltip="{{'Missed Punches' | translate}}" tooltipPosition="left"></i>{{'Missed Punch' | translate}}: </span>{{complianceReport?.totalMissed}}
									</span>
								</td>
								<td>
									<span class="one-liner">
						
										<span class="mobile-table-label align-items-center"><i class="fa-solid fa-utensils text-danger mr-2" pTooltip="{{'Missed Break' | translate}}" tooltipPosition="left"></i>{{'No Break' | translate}}: </span>{{complianceReport?.totalEmployeeWithoutLunch}}
									</span>
								</td>
								<td>
									<span class="one-liner">
								
										<span class="mobile-table-label align-items-center"><i  class="fa-solid fa-calendar text-secondary mr-2"></i>{{'Scheduled Hours' | translate}}: </span>{{complianceReport?.totalWorkTimeHHMM}}
									</span>
								</td>
								<td>
									<span class="one-liner">
									
										<span class="mobile-table-label align-items-center"><i  class="fa-solid fa-briefcase text-secondary mr-2"></i>{{'Hours Worked' | translate}}: </span>{{complianceReport?.totalWorkHourHHMM}}
									</span>
								</td>
							</tr>
						</ng-container>
					</ng-template>
	
					<ng-template pTemplate="emptymessage" let-columns>
						<tr *ngIf="data?.length == 0 && !loading">
							<td [attr.colspan]="columns.length " class="text-center py-5">
								<div class="text-muted my-5">{{'no data' | translate}}</div>
							</td>
						</tr>
						<tr *ngIf="loading" style="height: 277px">
							<td [attr.colspan]="columns.length" class="text-center py-5">
								<div class="text-muted my-5">
									<i class="fa-solid fa-spinner mr-2  fa-2x loadingHourglass"></i>
									<div>{{'Loading...' | translate}}</div>
								</div>
							</td>
						</tr>
					</ng-template>
	
				</p-table>	
			</div>
		</div>

		<div class="ui-xl-4 ui-md-6 ui-sm-12 p-3">
			<div class="card add-shadow text-left p-0 mb-0"  id="hoursGraph">
				<div class="card-header">
					<div class="d-flex align-items-center justify-content-between">
						<h5 class="mb-0 text-truncate"><i class="fa-solid fa-pie-chart text-primary mr-2"></i>{{'My Hours' | translate}}</h5>
						<span class="text-muted ml-2" style="font-size:18px">{{getDate(fromDate)?.slice(0, 5) || '--/--'}} - {{getDate(toDate)?.slice(0, 5) || '--/--'}}
							<div class="btn-group">
								<i class="fa-solid fa-circle-question text-muted ml-2" data-toggle="dropdown" aria-expanded="false" style="cursor:pointer;opacity:0.5"></i>
								<div class="dropdown-menu dropdown-menu-right">
									<div class="dropdown-item pl-2" type="button"><i class="fa-solid fa-circle text-muted mr-2"></i>{{'Scheduled Work Hours' | translate}}</div>
									<div class="dropdown-item pl-2" type="button"><i class="fa-solid fa-circle text-primary mr-2"></i>{{'Hours Actually Worked' | translate}}</div>
									<div class="dropdown-item pl-2" type="button"><i class="fa-solid fa-circle text-danger mr-2"></i>{{'Overtime Hours Used' | translate}}</div>		
								</div>
							</div>
						</span>
					</div>
				</div>
				<div class="p-3 d-flex align-items-center justify-content-center userActivitySection"  style="height: 372px;overflow-y: hidden;overflow-x: hidden;">
					<canvas id="payHourChart" width="340" height="340"></canvas>
				</div>
			</div>
		</div>
	
		<div class="ui-xl-4 ui-md-6 ui-sm-12 p-3">
			<div class="card add-shadow text-left p-0 mb-4">
				<div class="card-header">
					<div class="d-flex align-items-center justify-content-between">
						<h5 class="mb-0 text-truncate"><span class="badge mr-2" [ngClass]="(!absenceList || absenceList?.length === 0) ? 'badge-muted' : 'badge-primary'">{{ absenceList?.length || 0 }}</span>{{'Your Time-Off Requests' | translate}} </h5>
					</div>
				</div>
				<div class="p-0 bg-light userActivitySection"  style="height: 120px;overflow-y: auto;overflow-x: hidden;">
					<ng-container *ngIf="absenceList && absenceList.length > 0">
						<div *ngFor="let rowData of absenceList; let rowIndex = index" class="w-100 bg-white d-flex justify-content-between align-items-center border-bottom p-2" (click)="gotoRequestTimeOff()" id="concernsItem" style="cursor: pointer;height:60px">
							<img id="userIMG" [src]="photoURL" (error)="updateUrl($event)" class="border mr-2" style="width:45px;height:45px;border-radius: 50%;"/>
							<span class="text-nowrap" pTooltip="{{'From' | translate}}"><b>{{'OFF' | translate}}</b> - {{ rowData.from.slice(0, 5) }}</span>
							<i class="fa-solid fa-arrow-right text-muted mx-2"></i>
							<span pTooltip="{{'To' | translate}}">{{ rowData.to.slice(0, 5) }}</span>
							<div class="d-flex align-items-center flex-grow-1 justify-content-end">
								<span class="badge" [ngClass]="{'badge-primary' : rowData.status === 'Pending', 'badge-success' : rowData.status === 'Approved', 'badge-danger' : rowData.status === 'Rejected'}" pTooltip="{{ rowData.status }}" tooltipPosition="left" style="border-radius: 15px;height: 21px;width: 21px;display: flex;align-items: center;">
									<i class="fa-solid" [ngClass]="{'fa-clock' : rowData.status === 'Pending', 'fa-check' : rowData.status === 'Approved', 'fa-thumbs-down' : rowData.status === 'Rejected'}"></i>
								</span>
								<i class="fa-solid fa-chevron-right text-muted ml-2"></i>
							</div>
						</div>
					</ng-container>
					<div class="h-100 d-flex flex-column justify-content-center align-items-center ng-star-inserted" *ngIf="(!absenceList || absenceList.length === 0) && !requestsLoading">
						<i class="fa-solid fa-circle-check fa-3x text-muted"></i>
						<div class="text-muted mt-2">{{ 'No Requests' | translate }}</div>
					</div>
					<div class="h-100 d-flex flex-column justify-content-center align-items-center text-muted ng-star-inserted" *ngIf="requestsLoading">
						<i class="fa-solid fa-spinner fa-2x mr-2 loadingHourglass"></i>{{ 'Loading...' | translate }}
					</div>
				</div>
			</div>

			<div class="card add-shadow text-left p-0 mb-0">
				<div class="card-header">
					<div class="d-flex align-items-center justify-content-between">
						<h5 class="mb-0 text-truncate"><span class="badge mr-2" [ngClass]="holidays?.length + anniversaries?.length + birthdays?.length + absencesAprroved?.length + (isDelayedArrival ? 1 : 0) === 0 ? 'badge-muted' : 'badge-primary'">{{ holidays.length + anniversaries?.length + birthdays?.length + absencesAprroved?.length + (isDelayedArrival ? 1 : 0) }}</span>{{'Announcements' | translate}} </h5>
					</div>
				</div>
				<div class="p-0 bg-light userActivitySection"  style="height: 180px;overflow-y: auto;overflow-x: hidden;">			
					<ng-container *ngIf="holidays?.length + anniversaries?.length + birthdays?.length + absencesAprroved?.length + (isDelayedArrival ? 1 : 0) > 0" >
						<div *ngFor="let rowData of holidays" class="bg-white d-flex align-items-center border-bottom p-3" style="height:60px;">
							<span class="d-flex align-items-center text-truncate"><i class="fa-solid fa-calendar-xmark text-primary mr-1"></i><b>{{'Holiday Alert' | translate}}: </b></span><span>{{rowData?.holidayName}} - {{rowData.holidayDate | date : 'MMMM dd'}}</span>
						</div>
						<div *ngFor="let ann of anniversaries; let index = index" class="bg-white border-bottom d-flex align-items-center p-3" style="height:60px;">
							<ng-container *ngIf="employeeId == ann.id; else anCoworker">
								<span class="d-flex align-items-center text-truncate"><i class="fa-solid fa-thumbs-up text-info fa-2x mr-2"></i>{{ 'CONGRATS_MESSAGE' | translate:{year: ann.year} }}</span>
							</ng-container>
							<ng-template #anCoworker>
								<span class="d-flex align-items-center text-truncate"><i class="fa-solid fa-thumbs-up text-info fa-2x mr-2"></i><span [innerHTML]="'CONGRATS_MESSAGE2' | translate:{name: ann.fullName, title: ann.title, year: ann.year}"></span></span>
							</ng-template>
						</div>
						<div *ngFor="let bd of birthdays; let idx = index" class="bg-white d-flex align-items-center border-bottom p-3" style="height:60px;">
							<ng-container *ngIf="employeeId == bd.id; else birthdayCoworker">
								<span class="d-flex align-items-center text-truncate"><i class="fa-solid fa-birthday-cake text-purple fa-2x mr-2"></i><b>{{bd.fullName}}</b> - {{'Happy Birthday To YOU!' | translate}}</span>
							</ng-container>
							<ng-template #birthdayCoworker>
								<span class="d-flex align-items-center text-truncate"><i class="fa-solid fa-birthday-cake text-purple fa-2x mr-2"></i><span [innerHTML]="'BIRTHDAY_MESSAGE' | translate:{name: bd.fullName}"></span></span>
							</ng-template>
						</div>
						<div *ngFor="let message of absencesAprroved; let indx = index" class="bg-white d-flex align-items-center border-bottom text-truncate p-3" style="height:60px;"> 
							<span class="d-flex align-items-center text-truncate"><i class="fa-solid fa-calendar-xmark text-orange fa-2x mr-2"></i><span [innerHTML]="message"></span></span>
						</div>
						<div *ngIf="isDelayedArrival" class="bg-white d-flex align-items-center border-bottom p-3" style="height:60px;"> 
							<span class="d-flex align-items-center"><i class="fa-solid fa-triangle-exclamation fa-2x text-danger mr-2"></i><span [innerHTML]="'DELAYED_ARRIVAL_MESSAGE' | translate:{name: fullName, time: delayedTimeStr, startWork: startWork}"></span></span>
						</div>	
					</ng-container>
					<div class="h-100 d-flex flex-column justify-content-center align-items-center ng-star-inserted" *ngIf="holidays?.length + anniversaries?.length + birthdays?.length + absencesAprroved?.length + (isDelayedArrival ? 1 : 0) === 0" style="font-size: 16px;height: 186px;overflow-y: auto;overflow-x: hidden">
						<i class="fa-solid fa-circle-check fa-3x text-muted"></i>
						<div class="text-muted mt-2">{{ 'No Announcements' | translate }}</div>
					</div>
				</div>
			</div>
		</div>
		<div class="ui-g-12 py-0 px-3">
			<h2 class="m-0 m-0 mb-3 mt-2">{{'Work Activity' | translate}}</h2>
		</div>
		<div class="ui-xl-4 ui-md-6 ui-sm-12 p-3">
			<div class="card add-shadow text-left p-0 mb-0">
				<div class="card-header">
					<div class="d-flex align-items-center justify-content-between">
						<h5 class="mb-0 text-truncate"><span class="badge mr-2" [ngClass]="jobReports.length > 0 ? 'badge-primary' : 'badge-danger'">{{jobReports.length || 0 | number}}</span>{{'Latest Job Reports' | translate}} </h5>
					</div>
				</div>
				<div class="p-0 bg-light userActivitySection" id="jobReportsList" style="font-size: 16px;height: 372px;overflow-y: auto;overflow-x: hidden">
					<ng-container *ngIf="(jobReports && (jobReports.length > 0)) && !loadingJobReports" >
						<ng-container *ngFor="let rowData of jobReports">
							<div class="d-flex align-items-center justify-content-between bg-white px-3 py-2 border-bottom" (click)="gotoJobReport()" id="concernsItem" style="cursor:pointer;height:62px">
								<span class="d-flex align-items-center flex-grow-1 justify-content-start text-truncate">
									<i class="fa-solid fa-file-pen text-primary m-2" style="width:30px;font-size: 26px"></i>
									<div class="flex-grow-1 text-truncate pl-2" style="line-height: 1.2">
										<div class="m-0 text-truncate" id="jobReportItemText" [innerHTML]="rowData.latestNote" style="max-height: 1.15em"></div>
									</div>						
								</span>
								<div class="text-muted text-nowrap ml-2" style="font-size: 12px"><i class="fa-solid fa-circle-info text-primary ml-2" pTooltip="{{rowData.createdAt | date: 'M/d/yyy h:mm a'}}" tooltipPosition="left"></i><i class="fa-solid fa-chevron-right text-muted ml-2"></i></div>
							</div>						
						</ng-container>
					</ng-container>
					<div class="h-100 d-flex flex-column justify-content-center align-items-center ng-star-inserted" *ngIf="jobReports?.length === 0 && !loadingJobReports" style="font-size: 16px;height: 284px;overflow-y: auto;overflow-x: hidden">
						<i class="fa-solid fa-circle-exclamation fa-3x text-muted"></i>
						<div class="text-muted mt-2">{{ 'No Reports' | translate }}</div>
					</div>
					<div class="h-100 d-flex flex-column justify-content-center align-items-center text-muted ng-star-inserted" *ngIf="loadingJobReports" style="font-size: 16px;height: 284px;overflow-y: auto;overflow-x: hidden">
						<i class="fa-solid fa-spinner mr-2 fa-2x loadingHourglass"></i>{{ 'Loading...' | translate }}
					</div>
				</div>
			</div>
    	</div>
		<div class="ui-xl-4 ui-md-6 ui-sm-12 p-3">
			<div class="card add-shadow text-left p-0 mb-0">
				<div class="card-header">
					<div class="d-flex align-items-center justify-content-between">
						<h5 class="mb-0 text-truncate"><span class="badge mr-2" [ngClass]="sharedNotes?.length === 0 ? 'badge-muted' : 'badge-primary'">{{ sharedNotes?.length || 0 }}</span>{{'Sent Notebook Items' | translate}} </h5>
					</div>
				</div>
				<div class="p-0 bg-light userActivitySection"  style="height: 372px;overflow-y: auto;overflow-x: hidden;">
					<!-- Data Rows -->
					<ng-container>
						<div *ngFor="let rowData of sharedNotes; let rowIndex = index" class="w-100 bg-white d-flex justify-content-between align-items-center border-bottom py-2 px-3"  id="concernsItem"  (click)="clickViewDetailNote($event, rowData)" style="cursor: pointer;height: 62px">
							<span class="d-flex align-items-center">
								<i class="fa-solid fa-file-lines text-primary fa-2x mr-3"></i>
								<div style="line-height: 1.3">
									<div pTooltip="{{'Created' | translate}}" class="text-truncate"  style="font-size: 11px" class="text-muted">{{ rowData.createdAt }}</div>
									<div pTooltip="{{'Name' | translate}}" class="one-liner" style="font-size: 16px">{{ rowData.name }}</div>
								</div>		
							</span>
							<i class="fa-solid fa-chevron-right text-muted ml-2"></i>
						</div>
					</ng-container>
					<div class="h-100 d-flex flex-column justify-content-center align-items-center ng-star-inserted" *ngIf="sharedNotes.length === 0 && !sharedLoading">
						<i class="fa-solid fa-circle-exclamation fa-3x text-muted"></i>
						<div class="text-muted mt-2">{{ 'No Sent Items' | translate }}</div>
					</div>
					<div class="h-100 d-flex flex-column justify-content-center align-items-center ng-star-inserted" *ngIf="sharedLoading">
						<i class="fa-solid fa-spinner mr-2 loadingHourglass"></i>{{ 'Loading...' | translate }}
					</div>
				</div>
			</div>
		</div>
		<div class="ui-xl-4 ui-md-12 ui-sm-12 p-3">
			<div class="card add-shadow text-left p-0 mb-0">
				<div class="card-header">
					<div class="d-flex align-items-center justify-content-between">
						<h5 class="mb-0 text-truncate"><i class="fa-solid fa-bar-chart text-primary mr-2"></i>{{'Work Orders' | translate}} ({{'2 Weeks' | translate}})</h5>
					</div>
				</div>
				<div class="d-flex plan-assigment align-items-center justify-content-center userActivitySection px-3"  style="height: 372px;overflow-y: hidden;overflow-x: hidden;">
					<p-chart type="horizontalBar" id="assignmentChart" [data]="dataPlanAssignment" [responsive]="true" [options]="horizontalOptions" [style]="{'width':'100%', 'height':'360px'}"></p-chart>
				</div>
			</div>	
		</div>
	</div>
</div>




<p-dialog [(visible)]="showViewNoteDetailDialog" [modal]="true" [style]="{'width': '90%', 'max-height': '85vh', 'max-width':'600px'}">
	<p-header>
		<span><i class="fa-solid fa-image mr-2"></i>{{'Note Detail' | translate}}: {{noteDetail.name}}</span>
	</p-header>
    <div class="ui-g pb-3">
		<div class="ui-g-12 ui-sm-12">
			<label>{{'Recipients' | translate}}</label>
			<div style="font-size: 16px">{{getEmailByEmployeeId(noteDetail.userIds)}}</div>

		</div>
		<div class="ui-g-12 ui-sm-12">
			<label>{{'Content' | translate}}</label>
			<div [innerHTML]="noteDetail.content | safeHtml" class="ql-editor bg-light p-2" id="note_details" style="font-size: 16px !important"></div>
		</div>
	</div>
	<p-footer class="d-flex justify-content-end">
		<button class="btn btn-primary" (click)="showViewNoteDetailDialog = false">
			<i class="fa-solid fa-check mr-2"></i>{{'Done' | translate}}
		</button>
	</p-footer>
</p-dialog>
