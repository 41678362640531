import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { LazyLoadEvent, MenuItem, Message, SelectItem, MessageService } from 'primeng/api';
import {ActivatedRoute, NavigationEnd, NavigationExtras, Router} from '@angular/router';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Table } from 'primeng/table';
import { DepartmentServicev2 } from '../../../department/service/v2/department.v2.service';
import { Employee } from '../../../employee/model/employee.model';
import { AuthService } from '../../../shared/service/auth/auth.service';
import { EmployeeClockingService } from '../../service/employee-clocking.service';
import { EmployeeClocking } from '../../model/employee-clocking.model';
import { EmployeeClockingV2 } from '../../model/v2/employee.clocking';
import { WindowRefService } from '../../../shared/service/window-ref/window-ref.service';
import * as MOMENT from 'moment';
import * as moment from 'moment';
import { saveAs as importedSaveAs } from 'file-saver';
import { PayrollSettingService } from '../../../payroll-setting/service/payroll-setting.service';
import * as _ from 'lodash';
import { NoteHistoryService } from '../../../crm/service/note-history.service';
import { Constants } from '../../../shared/model/constants';
import { EditedClockingBeyondSettings } from '../../model/edited.clocking-beyond-settings.model';
import { EmployeeService } from '../../../employee/service/employee.service';
import { UtilsService } from '../../../shared/service/utils.service';
import { NotificationType, NotificationService } from '../../../crm/service/notification.service';
import { EmployeeClockingServiceV2 } from '../../service/v2/employee.clocking.v2.service';
import { EvvRegisterdService} from '../../../crm/service/evv-registered.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { TranslatePipe } from '@ngx-translate/core';
import { PayrollSetting } from 'app/payroll-setting/model/payroll-setting.model';
import { MonitoringDetailsService } from 'app/admin/process-monitor/service/monitoring-details.service';
import { ProcessMonitorEmailActivityService } from 'app/admin/process-monitor/service/process-monitor-email-activity.service';
import { IpAddressSettingServiceV2 } from 'app/company/service/ip-address-setting.v2.service';
import {LazyMapsAPILoader} from '@agm/core';
import { GeoService } from 'app/map/service/geo.service';
import {DatePipe} from '@angular/common';
import { PolygonUtils } from 'app/map/utils/polygon.utils';
declare let $: any;
declare var google: any;

@Component({
    selector: 'app-punch-in-out-history',
    templateUrl: './punch-in-out-history.component.html',
    styleUrls: ['./punch-in-out-history.component.scss'],
    providers: [EmployeeClockingService, EmployeeClockingServiceV2, WindowRefService, PayrollSettingService, MessageService, ProcessMonitorEmailActivityService, IpAddressSettingServiceV2]
})
export class PunchInOutHistoryComponent implements OnInit, OnDestroy {
    punchHistoryList: Array<any>;
    filteredPunchHistoryList: Array<any>;
    selectedPunchHistory: EmployeeClockingV2 = new EmployeeClockingV2();
    employeeClocking: EmployeeClockingV2 = new EmployeeClockingV2();
    totalRecords: Number = 0;
    totalRecords2: Number = 0;
    items: MenuItem[];
    msgs: Message[] = [];
    loading = true;
    historyData = [];
    payrollSetting: PayrollSetting;
    add = 'fa-solid fa-plus text-muted';
    size: number;
    page: number;
    first = 0;
    companyId: number;
    fromTimestamp: number;
    toTimestamp: number;
    rangeDates: any = {
        fromDate: null,
        toDate: null,
    };
    selectedItems;
    show = false;
    eventTemp: any;
    selectedFieldDisplay;
    fieldsName: any = [];
    companyWeekEnd = 0;
    employeeDepartment = '';
    employeeDepartmentShadow = '';
    showHistoryInfoDialog = false;
    types: Array<any> = [];
    payrollEndDate: Date;
    payrollStartDate: Date;
    employeeList: SelectItem[] = [];
    listSelectedEmployees: any = [];
    filteredClockingMissingList = <any>[];
    @ViewChild('dt', { static: true }) table: Table;
    @ViewChild('op1', { static: true }) op1: OverlayPanel;
    @ViewChild('op99', { static: true }) op99: OverlayPanel;
    sortField: string;
    sortOrder: number;
    reasonHeader: string;
    reasonNote: string;
    historyFilter: any = {
        employees: [],
        employeeIds: [],
        fromDate: null,
        toDate: null,
        ids: [],
        page: 0,
        size: 10,
        key: '',
        date: [new Date(), new Date()],
        lateEarlier: null,
        isMissing: false,
        withNoOvertime: false,
        type: 1,
        reportDate: null
    };
    rowData: any;
    totalOvertime: string;
    roleLevel: number;
    displayLastWeekData = false;
    missingOrInvalidDate = false;
    showNoteDetailsDialog = false;
    missingCheckInCount = 0;
    missingCheckOutCount = 0;
    selected: any;
    alwaysShowCalendars: boolean;
    currentTime = new Date();
    dataSourceSubject = new BehaviorSubject(null);
    dataSource$ = this.dataSourceSubject.asObservable();
    companies = [];
    allAgencies = [];
    agencies = [];
    selectedCompany: any;
    hideWithEmployee: Boolean = false;
    selectedAgency: any;
    selectedEmployee: any;
    selectedTimeClock: any;
    selectedWorkStatus: any;
    selectedDepartment: any;
    isEmployee: boolean;
    selectedLat = null;
    selectedLong = null;
    displayColumns: any[] = [
        { field: 'updateDate', label: 'Updated', sortable: false },
        { field: 'user', label: 'history.userName', sortable: false },
        { field: 'employee', label: 'Employee', sortable: false },
        { field: 'Date', label: 'form.date', sortable: false },
        { field: 'oldValue', label: 'history.oldValue', sortable: false },
        { field: 'newValue', label: 'history.newValue', sortable: false }
    ];
    uniqueDataForLunchCalculation = <any>[];
    dataForCheckOut = <any>[];
    employeeAndDateWiseLunchTime = <any>[];
    timeClock = [];
    workStatus = [];
    departments: any;
    adminData: any;
    disableSendEmployee = true;
    textSortOptionsClient = [
        { name: 'Sort A To Z', value: 'ASC', img: 'arrow-down-a-z' },
        { name: 'Sort Z To A', value: 'DESC', img: 'arrow-up-z-a' }
    ];
    numberSortOptionsClient = [
        { name: 'Sort 1 To 9', value: 'ASC', img: 'arrow-down-1-9' },
        { name: 'Sort 9 To 1', value: 'DESC', img: 'arrow-up-9-1' }
    ];
    dateSortOptions = [
        { name: 'Sort 1 To 12', value: 'ASC', img: 'arrow-down-1-9' },
        { name: 'Sort 12 To 1', value: 'DESC', img: 'arrow-up-9-1' }
      ];
    selectedSortOption = '';
    selectedField = '';
    isEditAble = false;
    totalHours = 0;
    companyDisabled = false;
    agencyDisabled = false;

    markerUrl = 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png';
    emplAbsent: string;
    emplClockedIn: string;
    emplOutForLunch: string;
    emplEndWork: string;
    isPopupOutForLunch = false;
    popupOutForLunchHeader = `${moment().format('MM/DD/YYYY')}`;
    popupOutForLunchCols = [
        { field: 'order', header: '#' },
        { field: 'fullName', header: 'Full Name' },
        { field: 'outForLunchTime', header: 'Break Start' }
    ];
    employeeClockingOutForLunchs: EmployeeClocking[] = [];
    employeeDisplayClockingOutForLunchs: { order: string, fullName: string, outForLunchTime: string }[] = [];
    isPopupEmployeeAbsent = false;
    popupEmployeeAbsentHeader = `${moment().format('MM/DD/YYYY')}`;
    popupOutForEmployeeAbsentCols = [
        { field: 'order', header: '#' },
        { field: 'fullName', header: 'FullName' },
        { field: 'employeeId', header: 'EmployeeID' }
    ];
    displayLunchColumns: any[] = [
        { field: 'id', label: '#', sortable: false, width: '10%' },
        { field: 'lunchStart', label: 'Start Break', sortable: false, width: '30%' },
        { field: 'lunchEnd', label: 'Break End', sortable: false, width: '30%' },
        { field: 'lunchTot', label: 'Break Tot', sortable: false, width: '30%' }
    ];
    employeeAbsents: any;
    totalClockedIn: any;
    employeeDisplayAbsents: { order: string, fullName: string, employeeId: string }[] = [];
    timeSpent = new Date();
    private routerSub = Subscription.EMPTY;
    fromDate: Date;
    toDate: Date;
    allLocationList = [];
    selectedLocation: any;
    loadingTrackData = false;
    trackingData = [];
    showTrackingDialog = false;
    firstLat;
    firstLong;
    trackingList = [];
    routePath = [];
    routePathMilestone = [];
    milestone_timestamp_markers = [];
    dialogTabs = [{'label': 'Full Records'}, {'label': 'By Milestone'}];
    activeItem = this.dialogTabs[0];
    fullRecordsTab = true;
    milestone_markers = [];
    milestone_groups = [];
    milestones = [];
    firstLatMilestone;
    firstLngMilestone;
    zoomMilestone;
    selectedMarker;
    options = {
        zoom: 14,
        navigationControl: false
    };
    selectedName;
    endDate;
    startTime;
    ranges: any = {
        today: [MOMENT(), MOMENT()],
        Yesterday: [MOMENT().subtract(1, 'days'), MOMENT().subtract(1, 'days')],
        Last7Days: [MOMENT().subtract(6, 'days'), MOMENT()],
        Last30Days: [MOMENT().subtract(29, 'days'), MOMENT()],
        ThisMonth: [MOMENT().startOf('month'), MOMENT().endOf('month')],
        LastMonth: [MOMENT().subtract(1, 'month').startOf('month'), MOMENT().subtract(1, 'month').endOf('month')],
    };
    selectedRowData: any = null;
    showDialog: Boolean = false;
    totalTime = '';
    invalidDates: MOMENT.Moment[] = [MOMENT().add(2, 'days'), MOMENT().add(3, 'days'), MOMENT().add(5, 'days')];
    punchDate;
    showPopupClockedIn = false;
    showPopupEndedWork = false;
    employeeClockingClockIn: any[] = [];
    employeeClockingEndWorks: any[] = [];
    employeeClockingDisplayClockIn: any[] = [];
    employeeClockingDisplayEndWorks: any[] = [];
    showProgressBarOnInitialLoad: Boolean = false;
    displayColumnDay = true;
    textSearch: any;
    selectedEmployeeId;
    exportPayrollConfirmModal = false;
    exportingPDF2 = false;
    employeeDisplayData: any = [];
    employeeNotLunchList: any = [];
    totalNotLunch = 0;
    showPopupNotLunch = false;
    isInvalidDate = (m: MOMENT.Moment) => {
        return this.invalidDates.some(d => d.isSame(m, 'day'));
    }

    showViewFaceImg;
    faceData: any = {};
    confirmingFaceImg = false;
    displayColumnTimeConfirmation = false
    absentFilter: any;
    redDotIcon = {
        path: google?.maps?.SymbolPath.CIRCLE,
        scale: 8,
        fillColor: '#FF0000',
        fillOpacity: 1,
        strokeWeight: 1,
        strokeColor: '#FF0000',
        labelOrigin: { x: 0, y: 2 }
    }

    actionTopbar: any;
    constructor(private router: Router, private route: ActivatedRoute,
        private employeeService: EmployeeService,
        private employeeClockingService: EmployeeClockingService,
        private payrollSettingService: PayrollSettingService,
        private noteHistoryService: NoteHistoryService,
        private windowRef: WindowRefService,
        private messageService: MessageService,
        private notificationService: NotificationService,
        private utilsService: UtilsService,
        private departmentServiceV2: DepartmentServicev2,
        private _employeeClockingService: EmployeeClockingServiceV2,
        private authService: AuthService,
        private translatePipe: TranslatePipe,
        private monitoringDetailsService: MonitoringDetailsService,
        private ipAddressSettingServiceV2: IpAddressSettingServiceV2,
        private evvRegisterdService: EvvRegisterdService,
        private mapsAPILoader: LazyMapsAPILoader,
        private geoService: GeoService,
        private datePipe: DatePipe,
    ) {
            this.routerSub = router.events.subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    if (event.url !== '/app/employee-clocking/punch-history') {
                        this.monitoringDetailsService.monitorAction(
                            `Navigated to Punch history `,
                            this.timeSpent,
                            {
                                navigated_to_punch_history_by: this.authService.getCurrentLoggedInName()
                            },
                            'complete',
                            `Navigated to Punch history `,
                            0
                        );
                    }
                }
            });
            this.route.queryParams.subscribe(params => {
                if (params['employeeId']) {
                    this.selectedEmployeeId = +params['employeeId'];
                }
                if (params['actionTopbar']) {
                    this.actionTopbar = params['actionTopbar'];
                }
            });
         }

    ngOnInit() {
        if (!this.isSuperAdmin()) {
            if (!this.selectedCompany) {
                this.selectedCompany = {};
            }
            this.displayLastWeekData = true;
            this.selectedCompany.key = this.authService.getCurrentCompanyId();
            this.companyId = this.authService.getCurrentCompanyId();
            this.loadPayrollSetting( this.companyId);
            // this.loadEmployeeList(this.companyId);
            // this.loadDepartment(this.companyId);
        }

        if (this.authService.isEmployeeRole()) {
            this.displayLastWeekData = false;
        }
        this.roleLevel = this.authService.getRoleLevel();
        this.adminData = JSON.parse(localStorage.getItem('_globals'));
        this.timeClock = [{ label: 'all', value: 'all' },
        { label: 'onTime', value: 'onTime' },
        { label: 'late', value: 'late' }];
        this.workStatus = [{ label: 'ALL', value: 'all' },
        { label: 'off', value: 'OFF' },
        { label: 'notOff', value: 'NO_OFF' },
        { label: 'sick', value: 'SICK' },
        { label: 'Holiday', value: 'Holiday' },
        { label: 'other', value: 'OTHER' },
        { label: 'vacation', value: 'VACATION' }];
        this.selectedWorkStatus = 'NO_OFF';
        this.historyFilter.typeAbsence = this.selectedWorkStatus;
        this.punchHistoryList = [];
        this.filteredPunchHistoryList = [];
        this.types = [
            { label: 'partTime', value: 'part_time' },
            { label: 'fullTime', value: 'full_time' },
            { label: 'consultant', value: 'consultant' },
            { label: 'volunteer', value: 'volunteer' }];


        this.isEmployee = this.authService.isEmployeeRole();

        if (this.authService.getCurrentEmployee() > 0) {
            this.historyFilter.employeeIds.push(this.authService.getCurrentEmployee());
            this.employeeDepartment = this.authService.getUserConfig().department.substring(0, 50) + '...';
            this.employeeDepartmentShadow = this.authService.getUserConfig().department;
        }

        this.loadCompanyAndAgencyDropdown();
        this.loadEmployeeList(null);
        this.loadDepartment(null);
        const employeeIdsStr = localStorage.getItem('overtime_alert');
        if (employeeIdsStr && employeeIdsStr.length > 0) {
            this.historyFilter.employeeIds.push(employeeIdsStr.split(','));
            localStorage.removeItem('overtime_alert');
        }

        this.isEditAble = this.authService.getLoggedInUserEditList().includes('23'); // get id from menu-list.ts
        this.emplAbsent = '0';
        this.emplClockedIn = '0';
        this.emplOutForLunch = '0';
        this.emplEndWork = '0';


        const punchHistoryOptions = JSON.parse(localStorage.getItem('SHOW_PUNCH_HISTORY'))
        if (punchHistoryOptions) {
            this.loadMissing(punchHistoryOptions)
        }  else {
            const today = new Date();
            // today.setMinutes(today.getMinutes() + today.getTimezoneOffset());
            this.fromDate = today;
            this.toDate = today;
        }

        this.loadAllLocation();
        //this.ngAfterViewInit();
    }

    // ngAfterViewInit() {
    //     this.reset(this.selectedEmployeeId);
    // }

    ngOnDestroy(): void {
        localStorage.removeItem('SHOW_PUNCH_HISTORY')
        setTimeout(() => {
            this.routerSub.unsubscribe();
        }, 1000);
    }

    changeCompany(company) {
        if (company) {
            this.loadEmployeeList(company.key);
            this.loadDepartment(company.key);
            this.loadPayrollSetting(company.key);
            this.historyFilter.companyId = company.key;
            this.displayLastWeekData = true;
        }
        this.loadStat({});
    }

    translateRange() {
        const object: any = {};
        object[this.translatePipe.transform('today')] = [MOMENT(), MOMENT()];
        object[this.translatePipe.transform('Yesterday')] = [MOMENT().subtract(1, 'days'), MOMENT().subtract(1, 'days')];
        object[this.translatePipe.transform('Last7Days')] = [MOMENT().subtract(6, 'days'), MOMENT()];
        object[this.translatePipe.transform('Last30Days')] = [MOMENT().subtract(29, 'days'), MOMENT()];
        object[this.translatePipe.transform('ThisMonth')] = [MOMENT().startOf('month'), MOMENT().endOf('month')];
        object[this.translatePipe.transform('LastMonth')] = [MOMENT().subtract(1, 'month').startOf('month'), MOMENT().subtract(1, 'month').endOf('month')];
        return object;
    }
    loadCompanyAndAgencyDropdown() {
        this.utilsService.loadCompanyAndAgencyDropdown().then((res) => {
            const companyAgencyDropDown: any = res;
            this.companyDisabled = companyAgencyDropDown.companyDisabled;
            if (companyAgencyDropDown.companyResponse) {
                if (companyAgencyDropDown.companyResponse.length === 1) {
                    this.companies = companyAgencyDropDown.companyResponse;
                    this.selectedCompany = {};
                    this.selectedCompany.key = companyAgencyDropDown.companyResponse[0].id;
                    this.selectedCompany.value = companyAgencyDropDown.companyResponse[0].name;
                } else {
                    this.companies = companyAgencyDropDown.companyResponse;
                }
            }
            if (companyAgencyDropDown.companyResponse) {
                if (companyAgencyDropDown.companyResponse.length === 1) {
                    this.loadEmployeeList(companyAgencyDropDown.companyResponse[0].id);
                }
            }
        });
    }

    loadStat(option?: any) {
        option = option ? option : this.historyFilter;
        if (this.selectedCompany === undefined || this.selectedCompany == null) {
            if (this.isSuperAdmin()) {
                option.companyId = 0;
            } else {
                option.companyId = this.authService.getCurrentCompanyId();
            }
        } else {
            option.companyId = this.selectedCompany.key;
        }
        // if (this.fromDate) {
        //     option.fromDate = moment(this.fromDate).startOf('day').toDate();
        // }

        // if (this.toDate) {
        //     option.toDate = moment(this.toDate).endOf('day').toDate();
        // }
        const punchStatisticNewOption: any = { ...option };
        punchStatisticNewOption.page = 0;
        punchStatisticNewOption.size = 9999;
        if (this.fromDate) {
            punchStatisticNewOption.fromDate = moment(this.fromDate).startOf('day').toDate();
        }
        if (this.toDate) {
            punchStatisticNewOption.toDate = moment(this.toDate).endOf('day').toDate();
        }
        this.employeeClockingService.punchStatisticNew(punchStatisticNewOption).subscribe(res => {
            if (res.data) {
                console.log('punchStatistic res.data: ', res.data);
                const statisticData: any = res.data;
                this.emplClockedIn = statisticData.totalClockedIn;
                this.emplAbsent = statisticData.totalAbsent > 0 ? statisticData.totalAbsent : 0;
                this.emplOutForLunch = statisticData.totalOutForLunch;
                this.emplEndWork = statisticData.totalEndWork;
                this.employeeClockingOutForLunchs = res.data.totalOutForLunchEmployeeList;
                this.employeeAbsents = res.data.employeeAbsent;
                this.totalClockedIn = res.data.totalClockedIn;
                this.employeeClockingClockIn = res.data.employeeClockIn;
                this.employeeClockingEndWorks = res.data.endWorks;
                this.totalNotLunch = statisticData.totalNotLunch;
                this.employeeNotLunchList = statisticData.employeeNotLunch;

                const employeeIdOutForLunchTmp = (this.employeeClockingOutForLunchs || []).map(i => Object.assign({}, i.employee?.employeeId));
                this.employeeNotLunchList = this.employeeNotLunchList.filter(i => !(employeeIdOutForLunchTmp || []).includes(i.employeeId)).map(i => Object.assign({}, i));
                this.totalNotLunch = this.employeeNotLunchList ? this.employeeNotLunchList.length : this.totalNotLunch;
            }
        }, (_error) => {
            console.log(_error);
        });
    }

    timeClockChange(event) {
        if (event.value === 'all') {
            this.historyFilter.lateEarlier = null;
            this.loadPage(this.historyFilter);
        } else {
            this.historyFilter.lateEarlier = event.value === 'onTime' ? '1' : '2';
            this.loadPage(this.historyFilter);
        }
    }

    workStatusChange(event) {
        if (event.value === 'all') {
            this.historyFilter.typeAbsence = null;
        } else {
            this.historyFilter.typeAbsence = event.value;
        }
    }


    searchByDepartment(event: any) {
        if (event.value) {
            this.historyFilter.department = event.value;
            // this.loadPage();
        }
    }

    async loadPunchHistoryLazy(event?: LazyLoadEvent) {
        console.log("lazy load");
        this.size = event && event.rows ? event.rows : (this.size ? this.size : 10);
        this.page = event && typeof event.first !== 'undefined' && event.rows ? (event.first / event.rows) : (this.page ? this.page : 0);
        this.sortField = this.sortField ? this.sortField : event && event.sortField ? event.sortField : 'createdAt';
        this.sortOrder = this.sortOrder ? this.sortOrder : event && event.sortOrder ? event.sortOrder : 1;
        const order = this.sortOrder === 1 ? 'ASC' : this.sortOrder;

        this.loading = true;
        this.historyFilter.page = this.page;
        this.historyFilter.size = this.size;
        this.historyFilter.sortField = this.sortField;
        this.historyFilter.sortOrder = order;
        this.route.queryParams.subscribe(params => {
            if (params && params.term === 'missing') {
                if (this.adminData.role.levelName === 'AGENCY_ADMIN') {
                    this.historyFilter.isMissing = true;
                    this.loadPage(this.historyFilter);
                } else {
                    this.loadPage(this.historyFilter);
                }
            } 
            else if (params && params.actionTopbar === 'click-missed-punch') {
                this.historyFilter.isMissing = true;
                this.loadPage(this.historyFilter);
            }
            else {
                if (params.employeeId > 0) {
                    this.historyFilter.employeeIds = [];
                    this.historyFilter.employeeIds.push(+params.employeeId);
                }
                if (params.startDate != null) {
                    this.fromDate = moment(parseInt(params.startDate)).toDate();
                }
                if (params.endDate != null) {
                    this.toDate = moment(parseInt(params.endDate)).toDate();
                } 

                if (this.historyFilter.date) {
                    if (this.historyFilter.date.startDate) {
                        this.historyFilter.fromDate = this.historyFilter.date.startDate;
                    } else {
                        this.historyFilter.fromDate = MOMENT();
                    }
                    if (this.historyFilter.date.endDate) {
                        this.historyFilter.toDate = MOMENT(this.historyFilter.date.endDate);
                    } else {
                        this.historyFilter.toDate = MOMENT();
                    }
                }
                this.historyFilter.isMissing = false;
                this.loadPage(this.historyFilter);
            }
        });
    }

    async loadPage(options?: any) {
        if (this.fromDate) {
            // this.historyFilter.fromDate = this.fromDate;
            this.historyFilter.fromDate = moment(this.fromDate).format('YYYY-MM-DD');
            //this.historyFilter.fromDate = moment(new Date()).startOf('day').toDate();
        }

        if (this.toDate) {
            // this.historyFilter.toDate = MOMENT(this.toDate).endOf('day').utc(true);
            this.historyFilter.toDate = moment(this.toDate).format('YYYY-MM-DD');
            //his.historyFilter.toDate = moment(new Date()).endOf('day').toDate();
        }
        if (!options) {
            options = this.historyFilter;
        }
        if (!options.isMissing) {
            options.ids = [];
        }
        this.isEmployee = this.authService.isEmployeeRole();
        if (this.isEmployee) {
            options.employeeIds = [];
            options.employeeIds.push(this.authService.getCurrentEmployee());
            options.companyId = this.authService.getUserInfo().companyId;
        }
        const empTypes = this.types;
        // check for support OT
        const start = this.fromDate;
        //start.setMinutes(start.getMinutes() + start.getTimezoneOffset());
        //start.setHours(12);
        const end = this.toDate;
        //end.setHours(12);
        const isWeekEndDate = end?.getDay() === this.companyWeekEnd;
        const isInWeek = end?.getDay()  - start?.getDay() === -1;
        options.isForOT = false;
        if (isWeekEndDate && isInWeek) {
            options.isForOT = true;
        }
        if (this.selectedLocation) {
            options.locationName = this.selectedLocation;
        }
        this.loadStat(options);
        const resObj: any = await this._employeeClockingService.search2(options).toPromise();
        if (resObj.status === 'SUCCESS') {
            this.loading = false;
            resObj.data.content = _.compact(resObj.data.content);
            resObj.data.content.forEach(obj => {
                if (obj && obj.clockInTime !== null && obj.clockOutTime !== null) {
                    if (obj.modifiedByUserName === 'Holiday') {
                        obj.disableEditAllowed = true;
                        obj.location = 'Holiday';
                    }

                    if (obj.modifiedByUserName === 'OFF') {
                        obj.location = 'OFF';
                    }
                }
            });
            resObj.data.content.forEach(function (obj) {
                empTypes.forEach(function (obj2) {
                    if (obj.employee.type === obj2.value) {
                        obj.employee.type = obj2.label;
                    }
                });

            });
            this.punchHistoryList = resObj.data.content;
            // reset value
            this.missingCheckOutCount = 0;
            this.missingOrInvalidDate = false;
            this.filteredClockingMissingList = [];
            if (this.punchHistoryList.length > 0) {
                this.punchHistoryList.forEach(item => {
                    if (item.clockInTime != null && item.clockOutTime == null) {
                        this.missingCheckOutCount = this.missingCheckOutCount + 1;
                        this.missingOrInvalidDate = true;
                        this.filteredClockingMissingList.push(item.id);
                    }
                });
            }
            this.totalRecords = resObj.data.totalElements;
            this.filterPunchHistory();
            this.historyFilter.isMissing = false;
        }
        this.monitoringDetailsService.monitorAction(
            `Searched For Punch history `,
            this.timeSpent,
            {
                searched_for_punch_history_by: this.authService.getCurrentLoggedInName()
            },
            'complete',
            `Searched For Punch history `,
            0
        );
    }

    getReasonTooltip(text: any) {
        if (text !== null && text.length > 0) {
            return text.substring(text.indexOf('-') + 2, text.length);
        }
        return '';
    }

    getHeaderReasonTooltip(text: any) {
        let data = '';
        if (text !== null && text.length > 0) {
            const type =  text.substring(0, text.indexOf('-')).trim();
            switch (type) {
                case '1':
                    data = 'User forgot or refused to clock out.';
                    break;
                case '2':
                    data = 'User unable to clock.';
                    break;
                case '3':
                    data = 'Do not have access on his/her location.';
                    break;
                case '5':
                    data = 'Short break';
                    break;
                default:
                    data = 'Other';
            }
        }
        return data;
    }


    setLunchTime() {
        const arrayObj = _.uniqWith(this.uniqueDataForLunchCalculation, _.isEqual);
        this.employeeClockingService.getLunchHoursForAllEmployees(arrayObj).subscribe((res) => {
            this.employeeAndDateWiseLunchTime = res.valueOf();
        });
    }

    checkUpdateClockInPunchHistory(rowData: any) {
        return !this.authService.isEmployeeRole() && rowData.isAdminUpdateCheckIn;
    }

    checkUpdateClockOutPunchHistory(rowData: any) {
        return !this.authService.isEmployeeRole() && rowData.isAdminUpdateCheckOut;
    }

    checkUpdateOutForLunchPunchHistory(rowData: any) {
        return !this.authService.isEmployeeRole() && rowData.isAdminUpdateLunchOut;
    }

    checkUpdateLunchInPunchHistory(rowData: any) {
        return !this.authService.isEmployeeRole() &&  rowData.isAdminUpdateLunchIn;
    }

    hhmm(minutes: number): string {
        const hours = Math.floor(minutes ? minutes / 60 : 0);
        const minute = minutes ? minutes % 60 : 0;
        if (!Number.isInteger(hours) || !Number.isInteger(minute)) {
            return minutes + '';
        }
        return _.padStart(hours, 1, '0') + ':' + _.padStart(minute, 2, '0');
    }


    getTotalDay(punchHistoryList: Array<any>) {
        if (punchHistoryList) {
            return punchHistoryList.length.toString() + this.translatePipe.transform('Record');
        }
        return '0 ' + this.translatePipe.transform('Record');
    }
    mappingEmployeeCloking(employee: Employee, employeeClockings: EmployeeClocking[]) {
        const clockings: EmployeeClocking[] = [];
        employee.employeeClockings = [];
        employeeClockings.forEach(cloking => {
            if (cloking.employee.id === employee.id) {
                clockings.push(cloking);
            }
        });
        employee.employeeClockings = clockings;
    }
    getTotalHour(punchHistoryList: Array<any>) {
        let totalHours = 0;
        let totalOvertime = 0;
        _.forEach(punchHistoryList, (x) => {
            totalHours += x.totalWorkTime;
            totalOvertime += x.overTime;
        });
        const result = totalHours / 60000 - totalOvertime;
        return this.hhmm(Math.round(result));
    }



    getTotalOverrtime(punchHistoryList: Array<any>) {
        let totalHours = 0;
        _.forEach(punchHistoryList, (x) => {
            totalHours += x.overTime;
        });
        return this.hhmm(Math.round(totalHours));
    }

    setList(punchHistoryList: Array<any>, rowData: EmployeeClocking) {
        const list: EmployeeClocking[] = [];
        this.punchHistoryList.forEach(function (obj) {
            if (obj.employee.id === rowData.employee.id) {
                list.push(obj);
            }
        });
        rowData.list = list;
    }

    search(event) {
        const wordSearch = event.target.value;
        setTimeout(() => {
            if (wordSearch === event.target.value) {
                this.eventTemp = event;
                this.loading = true;
                this.table.first = 0;
                this.historyFilter.key = event.target.value;
                this.historyFilter.page = 0;
                this.historyFilter.size = this.table.rows;
                if (this.historyFilter.key) {
                    this.loadPage(this.historyFilter);
                } else {
                    this.historyFilter.key = '';
                    this.loadPage(this.historyFilter);
                }
            }
        }, 1000);
    }

    filterMissing() {
        this.historyFilter.isMissing = true;
        this.historyFilter.page = 0;
        this.showNoteDetailsDialog = false;
        this.historyFilter.ids = this.filteredClockingMissingList;
        this.loadPage(this.historyFilter);
    }

    searchBySelectedDate(date) {
        if (date.startDate && date.endDate) {
            this.table.first = 0;
            this.historyFilter.page = 0;
            this.historyFilter.size = this.table.rows;
            this.historyFilter.fromDate = date.startDate;
            this.historyFilter.toDate = MOMENT(date.endDate).endOf('day').utc(true);

            const empIds: number[] = [];

        }
    }

    showHour(date: Date) {
        if (date === null) {
            return 'Missing';
        }
        return MOMENT.utc(date).format('HH:mm');
    }

    showCheckOutTime(data: EmployeeClocking) {
        const tempData = [];
        let firstDate;
        let secondDate;
        let returnTime;
        if (data.clockOutTime === null) {
            returnTime = 'Missing';
        } else if (data.clockInTime != null) {
            this.dataForCheckOut.forEach((i) => {
                if (MOMENT(data.clockInTime).format('YYYY-MM-DD') === MOMENT(i.clockInTime).format('YYYY-MM-DD')) {
                    tempData.push(i);
                }
            });
            if (tempData[0].clockOutTime != null && tempData[1]) {
                firstDate = moment(tempData[0].clockOutTime);
                secondDate = moment(tempData[1].clockOutTime);
                if (secondDate > firstDate) {
                    returnTime = moment(secondDate).format('HH:mm');
                }
            }

        }
        return returnTime;
    }

    showDate(date: Date) {
        return MOMENT.utc(date).format('D/MM/YYYY ddd');
    }

    exportLastWeekData() {
        this.exportingPDF2 = true;
        this.messageService.add({
            severity: 'info',
            summary: this.translatePipe.transform('Exporting XLS'),
            detail: this.translatePipe.transform('Export has started, please wait. This may take up to a minute to complete.')
        });
        this.historyFilter.fromDate = this.payrollStartDate;
        this.historyFilter.toDate = this.payrollEndDate;
        this.historyFilter.withNoOvertime = true;
        this.exportDetailAsExcel();
        this.sendLastWeekReportFileToEmployee();
    }

    getReportFile(type?: any) {
        if (this.filteredPunchHistoryList && this.filteredPunchHistoryList.length > 0) {
            this.messageService.add({
                severity: 'info',
                summary: this.translatePipe.transform('Exporting PDF'),
                detail: this.translatePipe.transform('Export has started, please wait. This may take up to a minute to complete.')
            });
            this.showProgressBarOnInitialLoad = true;
            const date = new Date();
            this.historyFilter.type = 1;
            this.historyFilter.reportDate = moment(new Date()).format('MM/DD/YYYY hh:mm A');
            if (!this.historyFilter.companyId || this.historyFilter.companyId === undefined) {
                this.historyFilter.companyId = this.authService.getCurrentCompanyId();
            }

            this.historyFilter.fromDateStr = moment(this.fromDate).format('MM/DD/YYYY');
            this.historyFilter.toDateStr = moment(this.toDate).format('MM/DD/YYYY');
            this._employeeClockingService.punchHistoryExportPDF(this.historyFilter).subscribe(res => {
                this.showProgressBarOnInitialLoad = false;
                this.messageService.add({
                    severity: 'success',
                    summary: this.translatePipe.transform('Export Complete'),
                    detail: this.translatePipe.transform('The export proccess is complete.')
                });
                const resObj: any = res;
                if (resObj.status === 'SUCCESS') {
                    const fileDate = new Date().getFullYear() + '-'
                        + new Date().getMonth() + '-' + new Date().getDate()
                        + '-' + new Date().getHours() + '-' + new Date().getMinutes() + '-' + new Date().getSeconds();
                    const filename = 'employee_punch_in_out_history_' + fileDate;
                    const binary = atob(resObj.data.replace(/\s/g, ''));
                    const len = binary.length;
                    const buffer = new ArrayBuffer(len);
                    const view = new Uint8Array(buffer);
                    for (let i = 0; i < len; i++) {
                        view[i] = binary.charCodeAt(i);
                    }
                    const blob = new Blob([view], {type: 'application/pdf'});
                    const linkElement = document.createElement('a');
                    const fileObjUrl = URL.createObjectURL(blob);
                    linkElement.setAttribute('href', fileObjUrl);
                    linkElement.setAttribute('download', filename);
                    const clickEvent = new MouseEvent('click', {
                        'view': this.windowRef.nativeWindow,
                        'bubbles': true,
                        'cancelable': false
                    });
                    linkElement.dispatchEvent(clickEvent);
                    (URL).revokeObjectURL(linkElement.href);
                    let args: any = {
                        exported_to_pdf_by: this.authService.getCurrentLoggedInName()
                    };
                    if (type) {
                        args = {
                            exported_last_week_payroll_by: this.authService.getCurrentLoggedInName()
                        };
                    }
                    const actions = type ? 'Exported last week payroll' : 'Exported To PDF';
                    this.monitoringDetailsService.monitorAction(
                        `${actions}`,
                        date,
                        args,
                        'complete',
                        `${actions}`,
                        0
                    );
                }
            }, (error) => {
                this.showProgressBarOnInitialLoad = false;
                this.messageService.add({
                    severity: 'error',
                    summary: this.translatePipe.transform('Export Error'),
                    detail: this.translatePipe.transform('Export has failed, please try again. If this problem persists, please contact support.')
                });
            });
        } else {
            this.messageService.add({
                severity: 'info',
                summary: this.translatePipe.transform('Exporting PDF'),
                detail: this.translatePipe.transform('There is no data to download, please check the date range.')
            });
        }
    }

    sendReportFileToEmployee() {
        this.historyFilter.type = 2;
        this._employeeClockingService.sendPunchHistoryDataToEmployee(this.historyFilter).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                this.notificationService.open({
                    type: NotificationType.SUCCESS,
                    title: 'Success',
                    body: 'The report was sent to Employee',
                });
            } else {
                this.notificationService.open({
                    type: NotificationType.ERROR,
                    title: 'Error',
                    body: 'Cannot send the report.',
                });
            }
        });
    }

    sendReportFileToFinance() {
        this.historyFilter.type = 3;
        this._employeeClockingService.sendPunchHistoryDataToFinance(this.historyFilter).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                this.notificationService.open({
                    type: NotificationType.SUCCESS,
                    title: 'Success',
                    body: 'The report was sent to Finance',
                });
            } else {
                this.notificationService.open({
                    type: NotificationType.ERROR,
                    title: 'Error',
                    body: 'Cannot send the report.',
                });
            }
        });
    }

    sendLastWeekReportFileToEmployee() {
        this.historyFilter.type = 3;
        this.historyFilter.fromDateStr = moment(this.historyFilter.fromDate).format('MM/DD/YYYY');
        this.historyFilter.toDateStr = moment(this.historyFilter.toDate).format('MM/DD/YYYY');
        this._employeeClockingService.sendLastWeekDataToEmployee(this.historyFilter).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                this.notificationService.open({
                    type: NotificationType.SUCCESS,
                    title: 'Success',
                    body: 'The Last Week Report was sent to Employee',
                });
            } else {
                this.notificationService.open({
                    type: NotificationType.ERROR,
                    title: 'Error',
                    body: 'Cannot send the last week report.',
                });
            }
        });
    }

    mapToDropdown(label, val) {
        return {
            label: label,
            value: val
        };
    }

    loadDepartment(companyId) {
        const departmentOptions = <any>{};
        if (companyId != null) {
            departmentOptions.companyId = companyId;
        } else {
            departmentOptions.companyId = this.authService.getCurrentCompanyId();
        }

        this.departments = [];
        this.departmentServiceV2.getDropdown(departmentOptions).subscribe(res => {
            const resObj: any = res;
            const tempDepList = [];
            if (resObj.status === 'SUCCESS' && resObj.data.length > 0) {
                resObj.data.forEach(function (dVal) {
                    tempDepList.push({ label: dVal.value, value: dVal.key });
                });
                this.departments = tempDepList;
            }
        });
    }

    loadPayrollSetting(companyId) {
        this.payrollSettingService.getbyId(companyId).subscribe(res1 => {
            const res1Obj: any = res1;
            this.payrollSetting = res1Obj.data;
            this.getPayrollDateByEndOfWeek(this.payrollSetting.payrollWeekEnding);
        });
    }

    loadEmployeeList(company) {
        this.employeeList = [];
        const searchOption = <any>{};
        if (company != null) {
            searchOption.companyId = company;
        } else {
            searchOption.companyId = this.authService.getCurrentCompanyId();
        }
        searchOption.status = 1;
        this.employeeService.getAllsDropdown(searchOption).subscribe(resEmp => {
            const empObj: any = resEmp;
            const tempList: any[] = [];
            if (empObj.status === 'SUCCESS') {
                empObj.data.forEach(element => {
                    tempList.push({ label: element.fullName, value: element.key });
                });
                tempList.sort((a, b) => a.label.localeCompare(b.label));
            }
            this.employeeList = tempList;
            this.filterPunchHistory();
        });
    }


    filterPunchHistory() {
        this.filteredPunchHistoryList = this.punchHistoryList.filter((punchHistory: EmployeeClocking) => {
            if (punchHistory.overTime > 0 &&  punchHistory.totalWorkTime === 0) {
                punchHistory.workHours = '00:00';
            } else if (punchHistory.overTime > 0 &&  punchHistory.totalWorkTime > 0) {
                punchHistory.workHours = punchHistory.totalWorkingStr;
            }
            punchHistory.workHourShadow = punchHistory.workHours;
            punchHistory.totalWorkTimeShadow = punchHistory.totalWorkTime;
            if (punchHistory.spreadHour && punchHistory.spreadHour > 0) {
                punchHistory.totalWorkTimeShadow = punchHistory.totalWorkTime - punchHistory.spreadHour;
                punchHistory.workHourShadow = this.hhmm(punchHistory.totalWorkTimeShadow / 60000);
                punchHistory.spreadHourText = 'Spread Hour';
            }
            return (!this.selectedCompany) || (this.selectedCompany && punchHistory.employee.companyId === this.selectedCompany.key);
        });
    }

    allowEditCheckIn(rowData) {
        if (!this.isEmployee && this.isEditAble && rowData.location !== 'Holiday' && !rowData.editAllowedCheckIn && !rowData.disableEditAllowed) {
            rowData.isAdminUpdateCheckIn = true;
            rowData.editAllowedCheckIn = this.checkUpdateClockInPunchHistory(rowData);
            if (rowData.clockInTime) {
                rowData.tempCheckInTime = MOMENT(rowData.clockInTime).format('HH:mm');
            }
        }
    }
    cancelEditCheckIn(rowData) {
        setTimeout(() => {
            rowData.editAllowedCheckIn = false;
        }, 0);
    }
    updateCheckInTime(rowData) {
        if (this.isEditAble) {
            if (rowData.tempCheckInTime.length < 4) {
                return;
            }
            try {
                // support OFF day
                if (rowData.location === 'OFF') {
                    rowData.location = 'Remote';
                    rowData.checkOutTime = null;
                    rowData.checkInTimeOriginal = null;
                    rowData.checkOutTimeOriginal = null;
                }
                if (this.isExceptionPunchInOut(rowData.locationName)) {
                    rowData.clockOutTime = null;
                }
                const tempTimeArr = rowData.tempCheckInTime.split(':');
                let tempDate = new Date();
                let temp = null;
                if (rowData.clockInTime) {
                    temp = rowData.clockInTime;
                    tempDate = new Date(rowData.clockInTime);
                    tempDate.setMinutes(tempDate.getMinutes() + tempDate.getTimezoneOffset());
                } else if (rowData.punchDate) {
                    temp = null;
                    tempDate = new Date(rowData.punchDate);
                    tempDate.setMinutes(tempDate.getMinutes() + tempDate.getTimezoneOffset());
                }
                tempDate.setHours(parseInt(tempTimeArr[0], 10));
                tempDate.setMinutes(parseInt(tempTimeArr[1], 10));
                if (tempDate > new Date()) {
                    alert('Invalid time. Work In time can not be greater than current time');
                    return;
                }
                if (rowData.clockOutTime && tempDate > new Date(rowData.clockOutTime)) {
                    alert('Invalid time. Work-in time can not be greater than Work End time');
                    return;
                }
                const valid = this.isUpdateTimeMoreThanNow(new Date(tempDate));
                if (valid) {
                    rowData.clockInTime = tempDate;
                    rowData.clockInTimeOriginal = temp;
                    if (rowData.lunchInTime == null && rowData.backFromLunch == null && rowData.checkOutTime == null) {
                        rowData.punchType = 'CLOCK_IN';
                        rowData.lastPunchType = 'IN';
                    }
                    rowData.updateType = 0;
                    this.updateTime(rowData, 0);
                }
            } catch (e) {
                alert('Something went wrong please try again');
            }
        } else {
            this.msgs.push({ severity: 'error', summary: this.translatePipe.transform('Error'), detail: this.translatePipe.transform('You don\'t have permissions!')});
        }
    }


    allowEditCheckOut(rowData) {
        if (!this.isEmployee && this.isEditAble && rowData.location !== 'Holiday' && !rowData.editAllowedCheckOut && !rowData.disableEditAllowed) {
            rowData.isAdminUpdateCheckOut = true;
            rowData.editAllowedCheckOut = this.checkUpdateClockOutPunchHistory(rowData);
            if (rowData.clockOutTime) {
                rowData.tempCheckOutTime = MOMENT(rowData.clockOutTime).format('HH:mm');
            }
        }
    }
    cancelEditCheckOut(rowData) {
        setTimeout(() => {
            rowData.editAllowedCheckOut = false;
        }, 0);
    }

    allowEditOutForLunch(rowData) {
        if (!this.isEmployee && this.isEditAble && rowData.location !== 'Holiday' && !rowData.editAllowedOutForLunch && !rowData.disableEditAllowed) {
            rowData.isAdminUpdateLunchOut = true;
            rowData.editAllowedOutForLunch = this.checkUpdateOutForLunchPunchHistory(rowData);
            if (rowData.lunchOutTime) {
                rowData.tempOutForLunch = MOMENT(rowData.lunchOutTime).format('HH:mm');
            }
        }
    }

    cancelEditOutForLunch(rowData) {
        setTimeout(() => {
            rowData.editAllowedOutForLunch = false;
        }, 0);
    }

    allowEditBackFromLunch(rowData) {
        if (!this.isEmployee && this.isEditAble && rowData.location !== 'Holiday' && !rowData.editAllowedBackFromLunch && !rowData.disableEditAllowed) {
            rowData.isAdminUpdateLunchIn = true;
            rowData.editAllowedBackFromLunch = this.checkUpdateLunchInPunchHistory(rowData);
            if (rowData.lunchInTime) {
                rowData.tempBackFromLunch = MOMENT(rowData.lunchInTime).format('HH:mm');
            }
        }
    }

    cancelEditBackFromLunch(rowData) {
        setTimeout(() => {
            rowData.editAllowedBackFromLunch = false;
        }, 0);
    }

    updateCheckOutTime(rowData) {
        if (rowData.tempCheckOutTime.length < 4) {
            return;
        }
        try {
            const tempTimeArr = rowData.tempCheckOutTime.split(':');
            let tempDate = null;
            let tempCheckOutTimeOriginal = null;
            if (rowData.clockOutTime) {
                tempCheckOutTimeOriginal = rowData.clockOutTime;
                tempDate = new Date(rowData.clockOutTime);
            } else {
                tempDate = new Date(rowData.clockInTime);
            }

            if (tempDate.getFullYear() !== new Date(rowData.clockInTime).getFullYear() ||
                tempDate.getMonth() !== new Date(rowData.clockInTime).getMonth() ||
                tempDate.getDate() !== new Date(rowData.clockInTime).getDate()) {
                tempDate = new Date(rowData.clockInTime);
            }

            tempDate.setHours(parseInt(tempTimeArr[0], 10));
            tempDate.setMinutes(parseInt(tempTimeArr[1], 10));
            if (this.isExceptionPunchInOut(rowData.locationName)) {
                alert('Invalid time. Work In time can not be null');
                return;
            }
            if (tempDate > new Date()) {
                alert('Invalid time. Work End time can not be later than the current time');
                return;
            }
            if (rowData.lunchInTime && tempDate < new Date(rowData.clockInTime)) {
                alert('Invalid time. Check-out time can not be smaller than Lunch in time');
                return;
            }

            if (rowData.lunchOutTime && tempDate < new Date(rowData.clockInTime)) {
                alert('Invalid time. Check-out time can not be smaller than Lunch out time');
                return;
            }

            if (rowData.clockInTime && tempDate < new Date(rowData.clockInTime)) {
                alert('Invalid time. Check-out time can not be smaller than check-in time');
                return;
            }
            const valid = this.isUpdateTimeMoreThanNow(new Date(tempDate));
            if (valid) {
                rowData.clockOutTime = tempDate;
                rowData.clockOutTimeOriginal = tempCheckOutTimeOriginal;
                rowData.punchType = 'CLOCK_OUT';
                rowData.lastPunchType = 'OUT';
                rowData.updateType = 3;
                this.updateTime(rowData, 3);
            }
        } catch (e) {
            alert('Something went wrong please try again');
        }
    }

    updateCheckOutForLunchTime(rowData) {
        if (rowData.tempOutForLunch.length < 4) {
            return;
        }
        try {
            const tempTimeArr = rowData.tempOutForLunch.split(':');
            let tempDate = null;
            let tempOutForLunchOriginal = null;
            if (rowData.lunchOutTime) {
                tempOutForLunchOriginal = rowData.lunchOutTime;
                tempDate = new Date(rowData.lunchOutTime);
            } else {
                tempDate = new Date(rowData.clockInTime);
            }

            if (tempDate.getFullYear() !== new Date(rowData.clockInTime).getFullYear() ||
                tempDate.getMonth() !== new Date(rowData.clockInTime).getMonth() ||
                tempDate.getDate() !== new Date(rowData.clockInTime).getDate()) {
                tempDate = new Date(rowData.clockInTime);
            }

            tempDate.setHours(parseInt(tempTimeArr[0], 10));
            tempDate.setMinutes(parseInt(tempTimeArr[1], 10));
            if (tempDate > new Date()) {
                alert('Invalid time. Lunch out time can not be greater than current time');
                return;
            }
            if (rowData.clockInTime && tempDate < new Date(rowData.clockInTime)) {
                alert('Invalid time. Lunch out time can not be smaller than check-in time');
                return;
            }
            const valid = this.isUpdateTimeMoreThanNow(new Date(tempDate));
            if (valid) {
                rowData.lunchOutTime = tempDate;
                rowData.lunchOutTimeOriginal = tempOutForLunchOriginal;
                if (rowData.lunchInTime == null && rowData.checkOutTime == null) {
                    rowData.punchType = 'LUNCH_OUT';
                    rowData.lastPunchType = 'OUT';
                }
                rowData.updateType = 1;
                this.updateTime(rowData, 1);
            }
        } catch (e) {
            alert('Something went wrong please try again');
        }
    }

    updateBackFromLunchTime(rowData) {
        if (rowData.tempBackFromLunch.length < 4) {
            return;
        }
        try {
            const tempTimeArr = rowData.tempBackFromLunch.split(':');
            let tempDate = null;
            let temp = null;
            if (rowData.lunchInTime) {
                temp = rowData.lunchInTime;
                tempDate = new Date(rowData.lunchInTime);
            } else {
                tempDate = new Date(rowData.clockInTime);
            }

            if (tempDate.getFullYear() !== new Date(rowData.clockInTime).getFullYear() ||
                tempDate.getMonth() !== new Date(rowData.clockInTime).getMonth() ||
                tempDate.getDate() !== new Date(rowData.clockInTime).getDate()) {
                tempDate = new Date(rowData.clockInTime);
            }

            tempDate.setHours(parseInt(tempTimeArr[0], 10));
            tempDate.setMinutes(parseInt(tempTimeArr[1], 10));
            if (tempDate > new Date()) {
                alert('Invalid time. End Break time can not be greater than current time');
                return;
            }
            if (rowData.lunchOutTime == null) {
                alert('Invalid time. Lunch out time cannot be empty');
                return;
            }

            if (rowData.clockInTime && tempDate < new Date(rowData.lunchOutTime) || rowData.lunchOutTime == null) {
                alert('Invalid time. End Break time can not be smaller than Lunch out time ');
                return;
            }

            const valid = this.isUpdateTimeMoreThanNow(new Date(tempDate));
            if (valid) {
                rowData.lunchInTime = tempDate;
                rowData.lunchInTimeOriginal = temp;
                if (rowData.clockOutTime == null) {
                    rowData.punchType = 'LUNCH_IN';
                    rowData.lastPunchType = 'IN';
                }
                rowData.updateType = 2;
                this.updateTime(rowData, 2);
            }
        } catch (e) {
            alert('Something went wrong please try again');
        }
    }

    updateTime(rowData, type: number) {
        this.employeeClocking = new EmployeeClockingV2();
        this.employeeClocking.id = rowData.id;
        this.employeeClocking.clockInTime = rowData.clockInTime;
        this.employeeClocking.lunchOutTime = rowData.lunchOutTime;
        this.employeeClocking.lunchInTime = rowData.lunchInTime;
        this.employeeClocking.clockInTimeOriginal = rowData.clockInTimeOriginal;
        this.employeeClocking.clockOutTimeOriginal = rowData.clockOutTimeOriginal;
        this.employeeClocking.lunchInTimeOriginal = rowData.lunchInTimeOriginal;
        this.employeeClocking.lunchOutTimeOriginal = rowData.lunchOutTimeOriginal;
        this.employeeClocking.clockOutTime = rowData.clockOutTime;
        this.employeeClocking.employee = rowData.employee;
        this.employeeClocking.lastPunchType = rowData.lastPunchType;
        this.employeeClocking.punchDate = rowData.punchDate;
        this.employeeClocking.createdAt = rowData.createdAt;
        this.employeeClocking.createdByUsr = rowData.createdByUsr;
        this.employeeClocking.isAdminUpdateCheckIn = rowData.isAdminUpdateCheckIn == null ? false : rowData.isAdminUpdateCheckIn;
        this.employeeClocking.isAdminUpdateCheckOut = rowData.isAdminUpdateCheckOut == null ? false : rowData.isAdminUpdateCheckOut;
        this.employeeClocking.isAdminUpdateLunchIn = rowData.isAdminUpdateLunchIn == null ? false : rowData.isAdminUpdateLunchIn;
        this.employeeClocking.isAdminUpdateLunchOut = rowData.isAdminUpdateLunchOut == null ? false : rowData.isAdminUpdateLunchOut;
        this.employeeClocking.updateType = type;
        let action;
        if (type === 0) {
            this.employeeClocking.isAdminUpdateCheckIn = false;
            action = 'Start Time updated';
        }

        if (type === 1) {
            this.employeeClocking.isAdminUpdateLunchOut = false;
            action = 'Break Time updated';
        }

        if (type === 2) {
            this.employeeClocking.isAdminUpdateLunchIn = false;
            action = 'Back From Lunch time updatedd';
        }

        if (type === 3) {
            this.employeeClocking.isAdminUpdateCheckOut = false;
            action = 'End Time updated';
        }

        // this.employeeClocking.location = rowData.location;
        this.employeeClocking.lastModifiedBy = this.authService.getCurrentUsername();
        this._employeeClockingService.update(this.employeeClocking, this.employeeClocking.id).subscribe(
            (data: any) => {
                if (data.status === 'SUCCESS') {
                    this.messageService.add({ severity: 'success',
                            summary: this.translatePipe.transform('ServiceMessage'), detail: this.translatePipe.transform('UpdatedSuccess') });
                    setTimeout(() => {
                        rowData.editAllowedCheckIn = false;
                        rowData.editAllowedCheckOut = false;
                        rowData.editAllowedOutForLunch = false;
                        rowData.editAllowedBackFromLunch = false;
                        if (type === 1) {
                            rowData.missedLunchOutTime = false;
                        }
                        if (type === 2) {
                            rowData.missedLunchInTime = false;
                        }
                        if (type === 3) {
                            rowData.missedEndWork = false;
                        }
                    }, 0);
                    this.monitoringDetailsService.monitorAction(
                        action,
                        new Date(),
                        {
                            employee_clocking_id: this.employeeClocking.id + '',
                            updated_by: this.authService.getCurrentLoggedInName()
                        },
                        'complete',
                        action,
                        0
                    );
                    this.chechEditedBeyondSettings();
                    this.loadPage();
                }
            }
        );
    }

    chechEditedBeyondSettings() {
        this.employeeClockingService.isEditedBeyondSettingsValue(this.employeeClocking.id).subscribe((data: any) => {
            const editedPunchInOutSettingsBeyond: EditedClockingBeyondSettings = data.data;
            if (editedPunchInOutSettingsBeyond) {
                //
            }
        });
    }

    notificationSave(notificationData: any) {
        notificationData.tableName = Constants.NOTE_DATA.SAVE_HISTORY; // this is static because from here only save the note notification
        notificationData.type = Constants.NOTE_DATA.SAVE_EDITED_PUNCH_IN_OUT_NOTE; // this is static because from here only save the note notification
        this.noteHistoryService.saveNotification(notificationData).subscribe((e) => {
        });
    }

    noteDetailsMouseEnter(event) {
        if (this.isEmployee || this.showNoteDetailsDialog) {
            return;
        } else {

            this.showNoteDetailsDialog = true;
            // setTimeout(() => {
            //     $('#id_note_detail_dialog > .ui-dialog').css('cssText', `top: ${event.clientY + 10}px !important; left: ${event.pageX + 10}px !important;`);
            // }, 0);
        }
    }
    noteDetailsMouseLeave(event) {
        this.showNoteDetailsDialog = false;

        localStorage.setItem('punchHistoryFilter', JSON.stringify(this.historyFilter));
        this.router.navigate(['app/employee/payment'], { queryParams: { missingClockOut: true } });
    }

    disableCompany(): boolean {
        return !(this.authService.isSuper() || this.authService.isSubSuper());
    }

    disableAgency(): boolean {
        return this.disableCompany() && !(this.authService.isCompanyAd() || this.authService.isSubCompanyAd());
    }

    disableEmployee(): boolean {
        return this.authService.isEmployeeRole();
    }
    dateToShow(date) {
        if (date) {
            return moment(date).format('HH:mm');
        } else {
            return '';
        }
    }
    dateToShowInTwelveHoursFormat(date) {
        if (date) {
            return moment(date).format('hh:mm A');
        } else {
            return '';
        }
    }
    getToolTip(locationName, text) {
        if (locationName === 'Holiday') {
            return 'Holiday: ' + text;
        } else {
            if (text && text !== null && text !== 'null') {
                return ' by ' + text;
            }
            return '';
        }
    }

    sortingClick(selectedSortOption) {
        const options = {
            sortOrder: selectedSortOption,
            sortField: this.selectedField
        };
        this.sortField = this.selectedField;
        this.sortOrder = selectedSortOption === 'ASC' ? 1 : 0;
        this.op1.hide();
        this.loadPunchHistoryLazy(options);
    }

    showPunchInOutReason(event: any, data) {
        this.reasonHeader = this.getHeaderReasonTooltip(data);
        this.reasonNote = this.getReasonTooltip(data);
        this.op99.show(event);
    }

    hidePunchInOutReason(event: any) {
        this.op99.hide();
    }

    search2() {
        this.page = 0;
        this.first = 0;
        this.sortField = null;
        this.sortOrder = null;
        localStorage.removeItem('SHOW_PUNCH_HISTORY')
        this.loadPunchHistoryLazy();
    }
    resetHistoryModal() {
        this.selectedField = null;
        this.fromDate = null;
        this.toDate = null;
        this.historyData = [];
    }
    reset(employeeId?) {
        this.page = 0;
        this.first = 0;
        const today = new Date();
        this.displayLastWeekData = false;
        this.textSearch = '';
        if (this.authService.isAgencyAd() || this.authService.isSubAgencyAd()) {
            this.selectedEmployee = null;
            this.selectedTimeClock = 'all';
            this.selectedWorkStatus = 'NO_OFF';
            this.selectedDepartment = null;
            this.fromDate = null;
            this.toDate = null;
            this.historyFilter = {
                employees: [],
                employeeIds: [],
                fromDate: null,
                toDate: null,
                ids: [],
                page: 0,
                size: 10,
                key: '',
                date: [moment().toDate(), moment().toDate()],
                lateEarlier: null,
                isMissing: false,
                type: 1
            };
        } else if (this.authService.isCompanyAd() || this.authService.isSubCompanyAd()) {
            this.selectedAgency = null;
            this.selectedEmployee = null;
            this.selectedTimeClock = 'all';
            this.selectedWorkStatus = 'NO_OFF';
            this.selectedDepartment = null;
            this.fromDate = null;
            this.toDate = null;
            this.historyFilter = {
                employees: [],
                employeeIds: [],
                fromDate: null,
                toDate: null,
                ids: [],
                page: 0,
                size: 10,
                key: '',
                date: [moment().toDate(), moment().toDate()],
                lateEarlier: null,
                isMissing: false,
                type: 1,
                typeAbsence: this.selectedWorkStatus
            };
        } else if (this.authService.isEmployeeRole()) {
            this.selectedCompany = null;
            this.selectedAgency = null;
            this.selectedTimeClock = 'all';
            this.selectedWorkStatus = 'NO_OFF';
            this.selectedDepartment = null;
            this.fromDate = null;
            this.toDate = null;
            this.historyFilter = {
                employees: [],
                employeeIds: [],
                fromDate: null,
                toDate: null,
                ids: [],
                page: 0,
                size: 10,
                key: '',
                date: [moment().toDate(), moment().toDate()],
                lateEarlier: null,
                isMissing: false,
                type: 1,
                typeAbsence: this.selectedWorkStatus
            };
            this.historyFilter.employeeIds.push(this.authService.getCurrentEmployee());

        } else {
            this.selectedCompany = null;
            this.selectedWorkStatus = 'NO_OFF';
            this.selectedAgency = null;
            this.selectedTimeClock = 'all';
            this.selectedDepartment = null;
            this.authService.getCurrentEmployee();
            this.fromDate = null;
            this.toDate = null;
            this.historyFilter = {
                employees: [],
                employeeIds: [],
                fromDate: null,
                toDate: null,
                ids: [],
                page: 0,
                size: 10,
                key: '',
                date: [moment().toDate(), moment().toDate()],
                lateEarlier: null,
                isMissing: false,
                type: 1,
                typeAbsence: this.selectedWorkStatus
            };
        }
        this.fromDate = today;
        this.toDate = today;
        this.historyFilter.fromDate = today;
        this.historyFilter.toDate = today;
        this.historyFilter.date.startDate = moment().toDate();
        this.historyFilter.date.endDate = moment().toDate();
        this.selectedLocation = 'All';
        if (this.selectedEmployeeId) this.historyFilter.employeeIds.push[this.selectedEmployeeId];
        this.loadPage(this.historyFilter);
    }

    openPopupOutForLunch() {
        this.employeeDisplayClockingOutForLunchs = [];
        this.isPopupOutForLunch = true;
        this.employeeClockingOutForLunchs.forEach((employeeClockingOutForLunch: EmployeeClocking, index: number) => {
            this.employeeDisplayClockingOutForLunchs.push(
                {
                    order: `${index + 1}.`,
                    fullName: `${employeeClockingOutForLunch.employee.fullName} `,
                    outForLunchTime: moment(employeeClockingOutForLunch.lunchOutTime).format('hh:mm:ss A')
                }
            );
        });
    }

    closePopupOutForLunchs() {
        this.isPopupOutForLunch = false;
        this.showNoteDetailsDialog = false;
    }


    closePopupEmployeeAbsent() {
        this.isPopupEmployeeAbsent = false;
    }

    checkIsSameDay(rowData) {
        let tempDate = null;
        tempDate = new Date();
        if (tempDate.getFullYear() === new Date(rowData.clockInTime).getFullYear() &&
            tempDate.getMonth() === new Date(rowData.clockInTime).getMonth() &&
            tempDate.getDate() === new Date(rowData.clockInTime).getDate()) {
            return true;
        }
        return false;
    }

    isSuperAdmin() {
        return this.authService.isSuper() || this.authService.isSubSuper();
    }

    isCompanyAdmin() {
        return this.authService.isCompanyAd() || this.authService.isSubCompanyAd();
    }

    isAgencyAdmin() {
        return this.authService.isAgencyAd() || this.authService.isSubAgencyAd();
    }

    isUpdateTimeMoreThanNow(tempDate: any) {
        const temp = moment.utc(tempDate, 'YYYY-MM-DD').startOf('day');
        const today = moment.utc(new Date(), 'YYYY-MM-DD').startOf('day');
        if (today.diff(temp) === 0 && tempDate > new Date()) {
            alert('Invalid time. Cannot be greater than current time');
            return false;
        }
        return true;
    }

    checkSelectedEmployee() {
        if (this.historyFilter.employeeIds.length > 0) {
            this.disableSendEmployee = false;
        } else {
            this.disableSendEmployee = true;
        }
    }
    rowClicked(event) {
        this.selectedRowData = event.data; // Assuming the row data is inside the 'data' property of the event object
        this.calCulateTotalhhmm();
        this.selectedRowData.employeeClockingLunchHourHistory.forEach(item => {
            item.editAllowedLunchStart = false;
            item.editAllowedLunchEnd = false;
        });
        this.showDialog = true;
    }
    expand(rowData: any) {
        console.log('expand rowData: ', rowData);
        this.selectedRowData = rowData;
        this.calCulateTotalhhmm();
        this.showDialog = true;
        this.punchDate = this.datePipe.transform(rowData.punchDate, 'MM/dd/yyyy');
        this.selectedRowData.employeeClockingLunchHourHistory.forEach(item => {
            item.editAllowedLunchStart = false;
            item.editAllowedLunchEnd = false;
            item.clockOutTime = rowData.clockOutTime;
            item.clockInTime = rowData.clockInTime;
        });
    }

    showHistory() {
        // this.rangeDates = {
        //     fromDate: null,
        //     toDate: null,
        // };
        this.selectedField = '';
        this.showHistoryInfoDialog = true;
        this.dataSource$ = this.dataSourceSubject.asObservable();
        this.fieldsName = [
            { value: 'checkInTime', label: 'Start Work' },
            { value: 'outForLunch', label: 'Start Break' },
            { value: 'backFromLunch', label: 'End Break' },
            { value: 'checkOutTime', label: 'End Work' }
        ];
    }

    showDisplayField() {
        const temp = this.fieldsName.filter(item => item.value === this.selectedField);
        this.selectedFieldDisplay = temp[0].label;
    }

    loadDataSource(event?: any) {
        this.loading = true;
        this.size = event && event.rows ? event.rows : (this.size ? this.size : 10);
        this.page = event && event.first && event.rows ? (event.first / event.rows) : (this.page ? this.page : 0);
        const options: any = {};
        options.size = this.size;
        options.page = this.page;
        options.fieldName = this.selectedField;
        // options.employeeId = this.authService.getCurrentLoggedInId();
        if (this.authService.isEmployeeRole()) { options.employeeId = this.authService.getCurrentLoggedInId(); }
        if (!this.isSuperAdmin()) { options.companyId = this.authService.getCurrentCompanyId(); }
        options.fromDate = new Date(this.fromDate).getTime();
        options.toDate = new Date(this.toDate).getTime();
            // if (this.rangeDates.toDate) {
            //     options.toDate = new Date(moment(this.toDate).add(1, 'days').toDate()).getTime();
            // } else {
            //     options.toDate = new Date().getTime();
            // }

        this.employeeClockingService.searchChanges(options).subscribe(
            (res: any) => {
                this.historyData = [];
                this.loading = false;
                if (res && res.data && res.data.content && res.data.content.length > 0) {
                    res.data.content.forEach((item) => {

                        this.historyData.push(
                            {
                                updateDate: `${item.updatedAt} `,
                                user: `${item.createdByUsr} `,
                                employee: `${item.employeeClocking.employee.fullName} `,
                                Date: `${item.employeeClocking.punchDate}`,
                                oldValue: `${item.oldValue} `,
                                newValue: `${item.newValue} `
                            }
                        );
                    });
                    this.totalRecords2 = res.data.content.length;
                }
            }
        );
    }

    isValidDate(dateString: string): boolean {
        if (!dateString || dateString.trim() === 'null') {
          return false;
        }
        const date = new Date(dateString.trim());
        return !isNaN(date.getTime());
      }


    closeHistoryInfoDialog() {
        this.rangeDates = {
            fromDate: null,
            toDate: null,
        };
        this.historyData = [];
        this.showHistoryInfoDialog = false;
    }

    getEndWorkLabel(data) {
        if (data.createdAt) {
            const cteateDate = moment(data.createdAt).format('YYYY-MM-DD');
            const currentDate = moment(new Date()).format('YYYY-MM-DD');
            return (cteateDate === currentDate) ? '--:--' : 'Missing';
        } else {
            return 'Missing';
        }
    }

    isExceptionPunchInOut(location: any) {
        return location === 'Holiday' || location === 'OFF' || location === 'VACATION' || location === 'SICK' || location === 'OTHER';
    }

    getPayrollDateByEndOfWeek(weekEndDate: string) {
        this.companyWeekEnd =  this.utilsService.convertWeekDaysToNumber(weekEndDate);
        const dayOfWeek = this.utilsService.convertWeekDaysToNumber(weekEndDate);
        const curr = new Date(); // get current date
        const endWeek: number = curr.getDate() - curr.getDay() + (dayOfWeek === 0 ? 7 : dayOfWeek);
        const date = new Date(curr.setDate(endWeek));
        this.payrollStartDate = new Date(date.setDate(date.getDate() - 6));
        this.payrollEndDate  = new Date(date.setDate(date.getDate() + 6));
        const today = new Date();
        this.payrollEndDate.setHours(0, 0, 0, 0);
        today.setHours(0, 0, 0, 0);
        if (this.payrollEndDate.getTime() - today.getTime() >= 0) {
            this.payrollStartDate = new Date(date.setDate(date.getDate() - 6 - 7));
            this.payrollEndDate  = new Date(this.payrollEndDate.setDate(this.payrollEndDate.getDate() - 7));
        }
        this.payrollStartDate.setHours(12);
        this.payrollEndDate.setHours(12);
    }

    exportDetailAsExcel() {
        this.exportingPDF2 = true;
        const current = new Date().getTime();
        this._employeeClockingService.dashBoardExportExcel(this.historyFilter).subscribe(res => {
            importedSaveAs(res, 'otDetails_' + current + '.xlsx');
            this.exportingPDF2 = false;
        }, (error) => {
            this.exportingPDF2 = false;
            this.messageService.add({severity: 'error', summary: this.translatePipe.transform('Export Error'), detail: this.translatePipe.transform('Something went wrong compiling file, try again later. If this error persists, contact support.')});
        });
    }

    loadAllLocation() {
        this.allLocationList = [
            {value: 'All', label: 'All'},
            {value: 'Remotely', label: 'Remote'}
        ];
        this.ipAddressSettingServiceV2.findAllByCompanyId(this.authService.getCurrentCompanyId()).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS' && resObj.data.length > 0) {
                resObj.data.forEach(i => {
                    const item = {value: i.locationName, label: i.locationName};
                    this.allLocationList.push(item);
                });
            }
        });
    }

    viewMap(rowData: any) {
        console.log("viewMap rowData: ", rowData);
        this.milestone_markers = [];
        this.milestone_groups = [];
        this.routePathMilestone = [];
        this.trackingData = [];
        this.trackingList = [];
        this.milestone_timestamp_markers = [];
        if (!rowData.employee || !rowData.employee.employeeId) { return; }
        this.loadingTrackData = true;
        this.showTrackingDialog = true;
        this.selectedLat = null;
        this.selectedLong = null;
        this.selectedName = rowData.employee.fullName;
        const options = <any>{};
        if (rowData.clockInTime != null) {
            options.fromDate = moment(rowData.clockInTime).toDate();
            this.startTime = rowData.clockInTime;
        } else {
            options.fromDate = moment(rowData.punchDate).startOf('day').toDate();
        }
        if (rowData.clockOutTime != null) {
            options.toDate = moment(rowData.clockOutTime).add(1, 'minute').toDate();
            this.endDate = rowData.clockOutTime;
        } else {
            options.toDate = moment(rowData.punchDate).endOf('day').toDate();
        }
        options.sortField = 'createdAt';
        options.sortOrder = 'ASC';
        options.page = 0;
        options.size = 9999;
        options.employeeId = rowData.employee.employeeId;
        this.evvRegisterdService.filter(options).subscribe(res => {
            const resObj: any = res;
            this.loadingTrackData = false;
            if (resObj.status === 'SUCCESS' && (resObj.data.content || []).length) {
                this.trackingData = [];
                const start = options.fromDate.getTime();
                const end = options.toDate.getTime();
                const filteredLocations = PolygonUtils.filterNoise(resObj.data.content);
                filteredLocations.forEach(item => {
                    if (!item.createdAt) {
                        return;
                    }
                    const time = moment(item.createdAt).toDate().getTime();
                    if(start <= time && time <= end) {
                        this.trackingData.push(item);
                    }
                });

                if (this.trackingData && this.trackingData.length) {
                    this.firstLat = this.trackingData[0].lat;
                    this.firstLong = this.trackingData[0].lng;
                    this.firstLatMilestone = this.trackingData[0].lat;
                    this.firstLngMilestone = this.trackingData[0].lng;
                    this.zoomMilestone = this.options.zoom;
                    this.trackingList = this.trackingData.map(data => {
                        return {
                            lat: data.lat,
                            lng: data.lng
                        };
                    });
                    let i = 0;
                    this.milestones = this.trackingData.filter(l => l.evvType !== 'Walker/questionnaire').map(data => {
                        let position = '';
                        if (i === 0 && data.evvType !== 'End Work') {
                            position = ' (Start)';
                        } else if (i === this.trackingData.length - 1) {
                            position = ' (End)';
                        }
                        i++;
                        return {
                            id: data.id,
                            lat: data.lat,
                            lng: data.lng,
                            draggable: false,
                            createdAt: data.createdAt,
                            zipCode: data.zipCode,
                            labelText: this.getLabelText(data.evvType),
                            evvTime: data.evvTime,
                            label: {
                                text: `${data.evvType}${position}`,
                                className: 'marker-label',
                                fontSize: '12px',
                            }
                        };
                    });
                    this.milestone_groups = this.chunkList(this.milestones);
                    if (this.milestone_groups && this.milestone_groups.length) {
                        this.milestone_groups.forEach(group => {
                            let inprogress = true;
                            group.data.forEach(item => {
                                if (item.labelText === 'End Work' || item.labelText === 'On Break') {
                                    inprogress = false;
                                }
                            });
                            group.inprogress = inprogress;
                        })
                    }
                    console.log('milestone_groups: ', this.milestone_groups);
                    
                }
            }
        }, () => {
            this.loadingTrackData = false;
            this.trackingData = [];
            this.trackingList = [];
        });

    }

    onActiveItemChange(event) {
        this.fullRecordsTab = !this.fullRecordsTab;
        this.activeItem = this.dialogTabs[this.fullRecordsTab ? 0 : 1];
    }

    closeTrackingDialog() {
        this.showTrackingDialog = false;
        this.trackingData = [];
        this.trackingList = [];
        this.firstLat = null;
        this.firstLong = null;
        this.fullRecordsTab = true;
        this.activeItem = this.dialogTabs[0];
    }
    changeSelectedLocation(rowData) {
        this.selectedLat = rowData.lat;
        this.selectedLong = rowData.lng;
    }

    toggleMarkers(rowData) {
        this.options.zoom = 14;
        this.endDate = null;
        this.milestone_markers = [];
        this.routePathMilestone = [];
        this.milestone_timestamp_markers = [];
        const allPositions = rowData.positions;
        if (allPositions.length > 1) {
            allPositions[0].label.className = 'marker-label-1';
            this.milestone_markers.push(allPositions[0]);
            allPositions[allPositions.length - 1].label.className = 'marker-label-2';
            this.milestone_markers.push(allPositions[allPositions.length - 1]);
        }
        let diff = moment(allPositions[allPositions.length - 1].createdAt).diff(moment(allPositions[0].createdAt), 'm');
        if (diff <= 1) {
          diff = 0;
        } else if (diff <= 5) {
          diff = 1
        } else if (diff <= 10) {
          diff = 5
        } else {
          diff = 10
        }
        if (diff > 0) {
            let curTimestamp;
            for (let i = 0; i < allPositions.length; i++) {
                const position = {
                    ...allPositions[i],
                    createdAtLabel: {
                        color: 'black',
                        fontSize: '15px',
                        fontWeight: 'bold',
                        text: moment(allPositions[i].createdAt).format('hh:mm A')
                    }, 
                    icon: this.redDotIcon
                }
                if (i === 0) {
                    this.milestone_timestamp_markers.push(position);
                    curTimestamp = position.createdAt;
                    continue;
                }
                if (moment(position.createdAt).diff(moment(curTimestamp), 'm') >= diff) {
                    this.milestone_timestamp_markers.push(position);
                    curTimestamp = position.createdAt;
                }
                if (i === allPositions.length - 1) {
                    this.milestone_timestamp_markers.push(position);
                }
            }
        }
    
        this.selectedMarker = rowData.positions[0];
        this.firstLatMilestone = this.selectedMarker.lat;
        this.firstLngMilestone = this.selectedMarker.lng;
        this.zoomMilestone = 20;
        if (rowData.routePathMilestone) {
            this.routePathMilestone = rowData.routePathMilestone;
            return;
        }
        this.routePathMilestone = allPositions;
        this.options.zoom = 16;
    }

    getDistanceFromLatLonInMeters(lat1, lon1, lat2, lon2) {
        const R = 6371;
        const dLat = this.deg2rad(lat2 - lat1);
        const dLon = this.deg2rad(lon2 - lon1);
        const a =
          Math.sin(dLat / 2) * Math.sin(dLat / 2) +
          Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad  (lat2)) *
          Math.sin(dLon / 2) * Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const distance = R * c * 1000; // result in meter
        return distance;
    }

    deg2rad(deg) {
        return deg * (Math.PI / 180);
    }

    chunkList(arr) {
        const tempArr = [];
        let currentPos = 'Start Work';
        let currArr = [];
        const starts = arr.filter(item => item.labelText === 'Start Work');
        let firstStart = starts && starts.length > 0 ? starts[0] : null;
        if (firstStart) {
            arr = arr.filter(item => item.evvTime >= firstStart.evvTime);
            currArr.push(firstStart);
        } else {
            if (arr && arr.length > 0 && this.startTime) {
                firstStart = _.cloneDeep(arr[0]);
                firstStart.createdAt = this.startTime;
                firstStart.labelText = 'Start Work';
                firstStart.evvTime = this.startTime;
                currArr.push(firstStart);
            }
        }
        for (let i = 0; i < arr.length; i++) {
            if (currentPos == null) {
                break;
            }
            const milestone = arr[i];
            if (milestone.labelText === 'LIVE UPDATE' && currentPos != 'On Break') {
                currArr.push(milestone);
                continue;
            }
            if (currentPos === 'Start Work') {
                if (milestone.labelText === 'On Break') {
                    currArr.push(milestone);
                    tempArr.push(_.cloneDeep(currArr));
                    currArr = [];
                    currentPos = 'On Break';
                } else if (milestone.labelText === 'End Work') {
                    currArr.push(milestone);
                    tempArr.push(_.cloneDeep(currArr));
                    currArr = [];
                    currentPos = 'End Work';
                    break;
                } 
                else if (milestone.labelText === 'Start Work' && arr.length === 1 ) {
                    currArr.push(milestone);
                    tempArr.push(_.cloneDeep(currArr));
                    currArr = [];
                    currentPos = 'Start Work';
                    break;
                }
            } else if (currentPos === 'On Break') {
                if (milestone.labelText === 'End work') {
                    const object = _.cloneDeep(milestone);
                    object.labelText = 'End Break';
                    currArr.push(object);
                    tempArr.push(_.cloneDeep(currArr));
                    currArr = [];
                    currArr.push(milestone);
                    currentPos = 'End Work';
                    break;
                } else if (milestone.labelText === 'End Break') {
                    currArr.push(milestone);
                    currentPos = 'End Break';
                }
            } else if (currentPos === 'End Break') {
                if (milestone.labelText === 'On Break') {
                    currArr.push(milestone);
                    tempArr.push(_.cloneDeep(currArr));
                    currArr = [];
                    currentPos = 'On Break';
                } else if (milestone.labelText === 'End Work') {
                    currArr.push(milestone);
                    tempArr.push(_.cloneDeep(currArr));
                    currArr = [];
                    currentPos = 'End Work';
                    break;
                }
            } else {
                currentPos = null;
            }
        }
        const result = [];
        for (let i = 0; i < tempArr.length; i++) {
            const arr = tempArr[i];
            if (arr && arr.length > 1) {
                const data = {
                    data: [arr[0], arr[arr.length - 1]],
                    positions: [...arr],
                    groupId: result.length
                };
                result.push(data);
            }
        }
        
        return result;
    }

    getSelectedFieldLabel(): string {
        const field = this.fieldsName.find(item => item.value === this.selectedField);
        return field ? field.label : this.selectedField;
    }


    calculateTotalhhmmForFirstLunch() {
        if (!(this.selectedRowData && this.selectedRowData.employeeClockingLunchHourHistory)) {
            return '';
        }
        let totalMinutes = 0;
        const clockings = this.selectedRowData.employeeClockingLunchHourHistory;
        for (let i = 0; i < clockings.length; i += 2) {
            if (clockings[i].lunchTime) { totalMinutes += clockings[i].lunchTime; }
        }
        const hours = Math.floor(totalMinutes / 60);
        const minute = totalMinutes % 60;
        return _.padStart(hours, 1, '0') + ':' + _.padStart(minute, 2, '0');
    }
    calculateTotalhhmmForSecondLunch() {
        if (!(this.selectedRowData && this.selectedRowData.employeeClockingLunchHourHistory)) {
            return '';
        }
        let totalMinutes = 0;
        const clockings = this.selectedRowData.employeeClockingLunchHourHistory;
        for (let i = 1; i < clockings.length; i += 2) {
            if (clockings[i].lunchTime) { totalMinutes += clockings[i].lunchTime; }
        }
        const hours = Math.floor(totalMinutes / 60);
        const minute = totalMinutes % 60;
        return _.padStart(hours, 1, '0') + ':' + _.padStart(minute, 2, '0');
    }

    calculateTotalhhmmForSecondLunchNew(rowData) {
        let totalMinutes = rowData.lunchTime;
        const hours = Math.floor(totalMinutes / 60);
        const minute = totalMinutes % 60;
        return _.padStart(hours, 1, '0') + ':' + _.padStart(minute, 2, '0');
    }

    calCulateTotalhhmm() {
        if (!(this.selectedRowData && this.selectedRowData.employeeClockingLunchHourHistory)) {
            return '';
        }
        let totalMinutes = 0;
        const clockings = this.selectedRowData.employeeClockingLunchHourHistory;
        for (const clocking of clockings) {
            if (clocking.lunchTime) { totalMinutes += clocking.lunchTime; }
        }
        const hours = Math.floor(totalMinutes / 60);
        const minute = totalMinutes % 60;
        return _.padStart(hours, 1, '0') + ':' + _.padStart(minute, 2, '0');
    }

    calCulateTotalLunchTothhmm(rowData) {
        if (!(rowData && rowData.employeeClockingLunchHourHistory)) {
            return '';
        }
        let totalMinutes = 0;
        const clockings = rowData.employeeClockingLunchHourHistory;
        for (const clocking of clockings) {
            if (clocking.lunchTime) { totalMinutes += clocking.lunchTime; }
        }
        const hours = Math.floor(totalMinutes / 60);
        const minute = totalMinutes % 60;
        return _.padStart(hours, 1, '0') + ':' + _.padStart(minute, 2, '0');
    }

    openPopupClockedIn() {
        this.employeeClockingDisplayClockIn = [];
        this.showPopupClockedIn = true;
        this.employeeClockingClockIn.forEach((item, index: number) => {
            this.employeeClockingDisplayClockIn.push(
                {
                    order: `${index + 1}.`,
                    fullName: `${item.fullName} `,
                    employeeId: `${item.employeeId} `
                }
            );
        });
    }

    openPopupEndedWork() {
        this.employeeClockingDisplayEndWorks = [];
        this.showPopupEndedWork = true;
        this.employeeClockingEndWorks.forEach((item, index: number) => {
            this.employeeClockingDisplayEndWorks.push(
                {
                    order: `${index + 1}.`,
                    fullName: `${item.fullName} `,
                    employeeId: `${item.employeeId} `
                }
            );
        });
    }

    closePopupClockedIn() {
        this.showPopupClockedIn = false;
    }

    closePopupNotLunch() {
        this.showPopupNotLunch = false;
    }

    closePopupEndedWork() {
        this.showPopupEndedWork = false;
    }

    gotoEmployeeList(searchText) {
        const userId = this.authService.getCurrentLoggedInId();
        const roleLevel = this.authService.getRoleLevel();
        switch (roleLevel) {
            case 1:
            case 3:
            case 5:
            case 4:
            case 6:
                const queryParams: NavigationExtras = {
                    queryParams: { searchText: searchText.split(' ')[0]}
                };
                this.router.navigate(['/app/employee/list'], queryParams);
                break;
            case 7:
                this.router.navigate(['app/employee/view/' + userId]);
                break;
            case 9:
                this.router.navigate(['app/crm/client/edit/' + userId]);
                break;
            case 10:
                this.router.navigate(['app/guest/view/' + userId]);
                break;
            case 13:
                this.router.navigate(['app/crm/freelancer/edit/' + userId]);
                break;
            default:
                break;
        }
    }

    openNotLunchDialog() {
        this.popupOutForLunchCols = [
            { field: 'order', header: '#' },
            { field: 'fullName', header: 'FullName' }
        ];
        this.showPopupNotLunch = true;
        this.employeeDisplayData = [];
        this.employeeNotLunchList.forEach((item: any, index: number) => {
            this.employeeDisplayData.push(
                {
                    order: `${index + 1}.`,
                    fullName: `${item.fullName} `,
                    employeeId: `${item.employeeId}`
                }
            );
        });
    }

    getLabelText(text) {
        if (text === 'Arrival Time' || text === 'Start work') {
            return 'Start Work';
        }

        if (text === 'End Work' || text === 'Leave Time') {
            return 'End Work';
        }

        if (text === 'Out for Lunch') {
            return 'On Break';
        }

        if (text === 'Back from Lunch') {
            return 'End Break';
        }
        return text;
    }

    updateLunchStartDetail(rowData) {
        if (rowData.tempLunchStartTimeDetail.length < 4) {
            return;
        }
        try {
            const tempTimeArr = rowData.tempLunchStartTimeDetail.split(':');
            let tempDate = null;
            let tempOutForLunchOriginal = null;
            if (rowData.lunchOutTime) {
                tempOutForLunchOriginal = rowData.lunchOutTime;
                tempDate = new Date(rowData.lunchOutTime);
            } else {
                tempDate = new Date(rowData.clockInTime);
            }

            if (tempDate.getFullYear() !== new Date(rowData.clockInTime).getFullYear() ||
                tempDate.getMonth() !== new Date(rowData.clockInTime).getMonth() ||
                tempDate.getDate() !== new Date(rowData.clockInTime).getDate()) {
                tempDate = new Date(rowData.clockInTime);
            }

            tempDate.setHours(parseInt(tempTimeArr[0], 10));
            tempDate.setMinutes(parseInt(tempTimeArr[1], 10));
            if (tempDate > new Date()) {
                alert('Invalid time. Lunch out time can not be greater than current time');
                return;
            }
            if (rowData.clockInTime && tempDate < new Date(rowData.clockInTime)) {
                alert('Invalid time. Lunch out time can not be smaller than check-in time');
                return;
            }
            const valid = this.isUpdateTimeMoreThanNow(new Date(tempDate));
            if (valid) {
                rowData.lunchOutTime = tempDate;
                rowData.lunchOutTimeOriginal = tempOutForLunchOriginal;
                this.updateLunchHourHistory(rowData);
            }
        } catch (e) {
            alert('Something went wrong please try again');
        }
    }

    updateLunchEndDetail(rowData) {
        if (rowData.tempEndLunchTimeDetail.length < 4) {
            return;
        }
        try {
            const tempTimeArr = rowData.tempEndLunchTimeDetail.split(':');
            let tempDate = null;
            let temp = null;
            if (rowData.lunchInTime) {
                temp = rowData.lunchInTime;
                tempDate = new Date(rowData.lunchInTime);
            } else {
                tempDate = new Date(rowData.clockInTime);
            }

            if (tempDate.getFullYear() !== new Date(rowData.clockInTime).getFullYear() ||
                tempDate.getMonth() !== new Date(rowData.clockInTime).getMonth() ||
                tempDate.getDate() !== new Date(rowData.clockInTime).getDate()) {
                tempDate = new Date(rowData.clockInTime);
            }

            tempDate.setHours(parseInt(tempTimeArr[0], 10));
            tempDate.setMinutes(parseInt(tempTimeArr[1], 10));
            if (tempDate > new Date()) {
                alert('Invalid time. End Break time can not be greater than current time');
                return;
            }
            if (rowData.lunchOutTime == null) {
                alert('Invalid time. Lunch out time cannot be empty');
                return;
            }

            if (rowData.clockInTime && tempDate < new Date(rowData.lunchOutTime) || rowData.lunchOutTime == null) {
                alert('Invalid time. End Break time can not be smaller than Lunch out time ');
                return;
            }

            const valid = this.isUpdateTimeMoreThanNow(new Date(tempDate));
            if (valid) {
                rowData.lunchInTime = tempDate;
                rowData.lunchInTimeOriginal = temp;
                this.updateLunchHourHistory(rowData);
            }
        } catch (e) {
            alert('Something went wrong please try again');
        }
    }


    allowEditLunchStartDetail(rowData) {
        if (!this.isEmployee && this.isEditAble && rowData.location !== 'Holiday' && !rowData.editAllowedCheckIn && !rowData.disableEditAllowed) {
            rowData.isAdminUpdateLunchOut = true;
            rowData.editAllowedLunchStart = this.checkUpdateOutForLunchPunchHistory(rowData);
            if (rowData.lunchOutTime) {
                rowData.tempLunchStartTimeDetail = MOMENT(rowData.lunchOutTime).format('HH:mm');
            }
        }
    }

    allowEditLunchEndDetail(rowData) {
        if (!this.isEmployee && this.isEditAble && rowData.location !== 'Holiday' && !rowData.editAllowedCheckIn && !rowData.disableEditAllowed) {
            rowData.isAdminUpdateLunchIn = true;
            rowData.editAllowedLunchEnd = this.checkUpdateLunchInPunchHistory(rowData);
            if (rowData.lunchInTime) {
                rowData.tempEndLunchTimeDetail = MOMENT(rowData.lunchInTime).format('HH:mm');
            }
        }
    }

    cancelEditLunchStartDetail(rowData) {
        setTimeout(() => {
            rowData.editAllowedLunchStart = false;
        }, 0);
    }
    cancelEditLunchEndDetail(rowData) {
        setTimeout(() => {
            rowData.editAllowedLunchEnd = false;
        }, 0);
    }

    updateLunchHourHistory(rowData) {
        this._employeeClockingService.updateLunchHourHistory(rowData).subscribe(
            (data: any) => {
                if (data.status === 'SUCCESS') {
                    // this.showDialog = false;
                    this.messageService.add({ severity: 'success',
                        summary: this.translatePipe.transform('ServiceMessage'), detail: this.translatePipe.transform('UpdatedSuccess') });
                    // this.loadPage();
                }
            }
        );
    }

    showFaceImage(event, id, type, date, name, img, status, lunchHistoryId?) {
        event.preventDefault();
        event.stopPropagation();
        this.faceData = {
            id: id,
            type: type,
            img: img,
            date: date,
            name: name,
            status: status,
            lunchHistoryId: lunchHistoryId
        };
        this.showViewFaceImg = true;
    }

    acceptFaceImg() {
        if (this.confirmingFaceImg) {
            return;
        }
        this.confirmingFaceImg = true;
        this._employeeClockingService.acceptFaceImg(this.faceData.id, this.faceData.type, this.faceData.lunchHistoryId).subscribe(
            (data: any) => {
                this.confirmingFaceImg = false;
                if (data.status === 'SUCCESS') {
                    this.showViewFaceImg = false;
                    this.messageService.add({ severity: 'success',
                        summary: this.translatePipe.transform('ServiceMessage'), detail: this.translatePipe.transform('UpdatedSuccess') });
                    this.search2();
                } else {
                    this.messageService.add({ severity: 'error',
                        summary: this.translatePipe.transform('ServiceMessage'), detail: this.translatePipe.transform('UpdatedFail') });
                }
            }, () => {
                this.messageService.add({ severity: 'error',
                    summary: this.translatePipe.transform('ServiceMessage'), detail: this.translatePipe.transform('UpdatedFail') });
                this.confirmingFaceImg = false;
            }
        );
    }

    rejectFaceImg() {
        if (this.confirmingFaceImg) {
            return;
        }
        this.confirmingFaceImg = true;
        this._employeeClockingService.rejectFaceImg(this.faceData.id, this.faceData.type, this.faceData.lunchHistoryId).subscribe(
            (data: any) => {
                this.confirmingFaceImg = false;
                if (data.status === 'SUCCESS') {
                    this.showViewFaceImg = false;
                    this.messageService.add({ severity: 'success',
                        summary: this.translatePipe.transform('ServiceMessage'), detail: this.translatePipe.transform('UpdatedSuccess') });
                    this.search2();
                } else {
                    this.messageService.add({ severity: 'error',
                        summary: this.translatePipe.transform('ServiceMessage'), detail: this.translatePipe.transform('UpdatedFail') });
                }
            }, () => {
                this.messageService.add({ severity: 'error',
                    summary: this.translatePipe.transform('ServiceMessage'), detail: this.translatePipe.transform('UpdatedFail') });
                this.confirmingFaceImg = false;
            }
        );  
    }

    async loadMissing(options: any) {
        this.fromDate = new Date(options.fromDate)
        this.toDate = new Date(options.toDate)

        const empTypes = this.types;
        const start = this.fromDate;
        const end = this.toDate;
        const isWeekEndDate = end.getDay() === this.companyWeekEnd;
        const isInWeek = end.getDay()  - start.getDay() === -1;
        options.isForOT = isWeekEndDate && isInWeek;

        if (this.selectedLocation) {
            options.locationName = this.selectedLocation;
        }

        this.loadStat(options);
        const resObj: any = await this._employeeClockingService.search2(options).toPromise();
        if (resObj.status === 'SUCCESS') {
            this.loading = false;
            resObj.data.content = _.compact(resObj.data.content);
            resObj.data.content.forEach(obj => {
                if (obj && obj.clockInTime !== null && obj.clockOutTime !== null) {
                    if (obj.modifiedByUserName === 'Holiday') {
                        obj.disableEditAllowed = true;
                        obj.location = 'Holiday';
                    }

                    if (obj.modifiedByUserName === 'OFF') {
                        obj.location = 'OFF';
                    }
                }
            });
            resObj.data.content.forEach(function (obj) {
                empTypes.forEach(function (obj2) {
                    if (obj.employee.type === obj2.value) {
                        obj.employee.type = obj2.label;
                    }
                });

            });
            this.punchHistoryList = resObj.data.content;
            // reset value
            this.missingCheckOutCount = 0;
            this.missingOrInvalidDate = false;
            this.filteredClockingMissingList = [];
            if (this.punchHistoryList.length > 0) {
                this.punchHistoryList.forEach(item => {
                    if (item.clockInTime != null && item.clockOutTime == null) {
                        this.missingCheckOutCount = this.missingCheckOutCount + 1;
                        this.missingOrInvalidDate = true;
                        this.filteredClockingMissingList.push(item.id);
                    }
                });
            }
            this.totalRecords = resObj.data.totalElements;
            this.filterPunchHistory();
            this.historyFilter.isMissing = false;
        }
        this.monitoringDetailsService.monitorAction(
            `Searched For Punch history `,
            this.timeSpent,
            {
                searched_for_punch_history_by: this.authService.getCurrentLoggedInName()
            },
            'complete',
            `Searched For Punch history `,
            0
        );
    }
    viewTimeRecollection () {
        this.router.navigate(['/app/compliance-setting/time-recollection']);
    }




    filteredEmployeeDisplayData: any[] = [];
    gotoEmployeePage(employeeId: number) {
        this.router.navigate([`/app/employee/edit/${employeeId}`]);
    }

    onSearchChange(searchValue: string) {
        this.textSearch = searchValue.toLowerCase();
        if (this.textSearch) {
          this.filteredEmployeeDisplayData = this.employeeDisplayAbsents.filter(rowData =>
            rowData.fullName.toLowerCase().includes(this.textSearch)
          );
        } else {
          this.filteredEmployeeDisplayData = [...this.employeeDisplayAbsents];
        }
      }
      
      openPopupEmployeeAbsent() {
        this.employeeDisplayAbsents = [];
        this.isPopupEmployeeAbsent = true;
        this.employeeAbsents.forEach((item, index: number) => {
          this.employeeDisplayAbsents.push({
            order: `${index + 1}.`,
            fullName: item.fullName,
            employeeId: item.employeeId
          });
        });
        this.filteredEmployeeDisplayData = [...this.employeeDisplayAbsents];
      }
      
}
