import { Injectable } from '@angular/core';
import { AbstractServiceV2 } from '../../../shared/service/abstract.v2.service';
import { environment, environmentLocal } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Employee } from '../../model/employee.model';
import { Constants } from '../../../shared/model/constants';
import { EmployeeSearch } from "../../,,/../../shared/model/search/employee.search"
import { from, Observable } from 'rxjs';
import { PaginatedResponse } from '../../../shared/model/PageResponse/paginated.response';
import { AbstractSearch } from '../../../shared/model/search/abstract.search';
import { ValidatePin } from 'app/employee/model/punch-in-out';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class EmployeeServiceV2 extends AbstractServiceV2<Employee, EmployeeSearch>{

    constructor(private http: HttpClient) {
        super(http, Constants.ROUTES.EMPLOYEE, '');
      }

  validatePin(employeeId, pin): Observable<ValidatePin> {
    const url = `${this.baseUrlV2}/${employeeId}/validate_pin`;
    return this._http.post<ValidatePin>(url, {
      pin: pin
    });
  }

  unlockPin(employeeId): Observable<ValidatePin> {
    const url = `${this.baseUrlV2}/${employeeId}/unlock`;
    return this._http.post<ValidatePin>(url, {});
  }

  getById(id: Number): Observable<any> {
    // return this._http.get<any>(`http://localhost:8004/api/v2/employee/${id}`).pipe(map(res => res));
    return this._http.get<any>(`${this.baseUrlV2}/${id}`).pipe(map(res => res));
  }
  
  getEmployeeById(id: Number): Observable<any> {
    // return this._http.get<any>(`http://localhost:8004/api/v2/employee/get-employee-by-id/${id}`).pipe(map(res => res));
    return this._http.get<any>(`${this.baseUrlV2}/get-employee-by-id/${id}`).pipe(map(res => res));
  }

}