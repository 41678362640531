<p-toast position="bottom-right">{{msgs}}</p-toast>
<h1 class="m-0 ml-2 my-4">{{'Aging Summary' | translate}}</h1>
<p-panel [toggleable]="true">
    <p-header class="flex-grow-1">
        <span><i class="fa-solid fa-magnifying-glass text-primary mr-2"></i> {{'Find Aging Summaries' | translate}}</span>
    </p-header>
    <div class="ui-g">
        <div class="ui-g-6 ui-sm-12" *ngIf="isPlatformAdmin">
            <label>{{'companyLabel' | translate}}</label>
            <div class="input-group w-100">
                <div class="input-group-prepend p-0">
                    <button class="btn btn-white" type="button" style="cursor:default !important">
                        <i class="fa-solid fa-building"></i>
                    </button>
                </div>
                <p-dropdown [options]="allCompanyList" optionLabel="value" dataKey="key" placeholder="{{'pleaseSelect' | translate}}" [(ngModel)]="selectedCompany" (onChange)="changeCompany()">
					<ng-template pTemplate="item" let-item>
						<span class="text-truncate">{{item.label | translate}}</span>
					</ng-template>
					<ng-template let-item pTemplate="selectedItem">
						<span class="one-liner">{{item.label | translate}}</span>
					</ng-template>
				</p-dropdown>
            </div>
        </div>
        <div class="ui-g-6 ui-sm-12">
            <label>{{'Client' | translate}}</label>
            <div class="input-group w-100">
                <div class="input-group-prepend p-0">
                    <button class="btn btn-white"  type="button" style="cursor:default !important">
                        <i class="fa-solid fa-briefcase"></i>
                    </button>
                </div>
                <p-dropdown [options]="clientList" filter="true" optionLabel="value"  placeholder="{{'pleaseSelect' | translate}}" [(ngModel)]="selectedClient" [showClear]="true" (onChange)="changeClient()">
					<ng-template pTemplate="item" let-item>
						<span class="text-truncate">{{item.label | translate}}</span>
					</ng-template>
					<ng-template let-item pTemplate="selectedItem">
						<span class="one-liner">{{item.label | translate}}</span>
					</ng-template>
                </p-dropdown>
            </div>
        </div>
        <div class="ui-g-6 ui-sm-12">
            <label>{{'Date Range' | translate}}</label>
            <div class="input-group w-100">
                <div class="input-group-prepend p-0">
                    <button class="btn btn-white"  type="button" style="cursor:default !important">
                        <i class="fa-solid fa-calendar-week"></i>
                    </button>
                </div>
                <mat-form-field class="w-100">
                    <mat-date-range-input [rangePicker]="picker">
                        <input matStartDate matInput placeholder="{{'StartDate' | translate}}" [(ngModel)]="fromDate" (focus)="picker.open()">
                        <input matEndDate matInput placeholder="{{'EndDate' | translate}}" [(ngModel)]="toDate" (focus)="picker.open()">
                    </mat-date-range-input>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
            </div>
        </div>
        <div class="ui-g-6 ui-sm-12">
            <label>{{'Show' | translate}}</label>
            <div class="input-group w-100">
                <div class="input-group-prepend p-0">
                    <button class="btn btn-white"  type="button" style="cursor:default !important">
                        <i class="fa-solid fa-filter"></i>
                    </button>
                </div>
                <p-dropdown [options]="showSelectedList" placeholder="{{'pleaseSelect' | translate}}" [(ngModel)]="selectedToShow">
					<ng-template pTemplate="item" let-item>
						<span class="text-truncate">{{item.label | translate}}</span>
					</ng-template>
					<ng-template let-item pTemplate="selectedItem">
						<span class="one-liner">{{item.label | translate}}</span>
					</ng-template>
                </p-dropdown>
            </div>
        </div>
		<div class="ui-g-6 ui-sm-12">
            <label>{{'Status' | translate}}</label>
            <div class="input-group w-100">
                <div class="input-group-prepend p-0">
                    <button class="btn btn-white"  type="button" style="cursor:default !important">
                        <i class="fa-solid fa-info"></i>
                    </button>
                </div>
                <p-multiSelect defaultLabel="{{'pleaseSelect' | translate}}" name="statusSearch" [options]="statusTypes" [(ngModel)]="selectedStatuses" id="id_invoice_status_search_label" class="w-100">
					<ng-template pTemplate="item" let-item>
						<span class="text-truncate">{{item.label | translate}}</span>
					</ng-template>
					<ng-template let-item pTemplate="selectedItem">
						<span class="one-liner" style="font-size: 14px !important">{{item.label | translate}}</span>
					</ng-template>
                </p-multiSelect>
            </div>
        </div>
        <div class="ui-g-12 text-center py-4">
            <button type="button" class="btn btn-danger mx-1" (click)="reset()"><i class="fa-solid fa-xmark mr-2"></i>{{'button.reset' | translate}}</button>
            <button type="button" class="btn btn-primary mx-1" (click)="search()"><i class="fa-solid fa-check mr-2"></i>{{'button.submit' | translate}}</button>
        </div>
    </div>
</p-panel>
<div class="d-flex align-items-center justify-content-start py-4">
	<h1 class="m-0 ml-2">{{'Browse Summary' | translate}}</h1>
</div>
<p-panel id="table-ppanel" [toggleable]="true">
    <p-header class="flex-grow-1">
        <span><i class="fa-solid fa-table text-primary mr-2"></i>{{'Summary as of' | translate}} {{getCurrentDateString()}} <span *ngIf="totalRecords">({{totalRecords}})</span></span>
    </p-header>
    <p-table #dt [value]="agingSummaries" selectionMode="single" [rows]="10" [responsive]="true" [rowsPerPageOptions]="[10,25,50]" [paginator]="true" [totalRecords]="totalRecords">
		<ng-template pTemplate="caption">
            <div class="d-flex align-items-center justify-content-start">
                <span class="d-flex">
					<button type="button" class="btn btn-primary mr-2" (click)="exportTable()">
						<span class="d-flex align-items-center" style="white-space: nowrap"><i class="fa-solid fa-print mr-2"></i>{{'Export PDF' | translate}}</span>
					</button>
				</span>
            </div>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th *ngFor="let col of cols">
                    <ng-container [ngSwitch]="col.field">
						<span *ngSwitchDefault>
							<div class="d-flex justify-content-between align-items-center w-100">
								<span class="one-liner" pTooltip="{{col.label | translate}}" tooltipPosition="left">{{col.label | translate}}</span>
								<span *ngIf="col.sort">
									<span (click)="col.sort == 'number'? op2.toggle($event) : op1.toggle($event); selectedField !== col.field ? selectedSortOption = '' : selectedSortOption = selectedSortOption; selectedField = col.field">
										<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
									</span>
								</span>
							</div>
						</span>
                    </ng-container>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-i="rowIndex">
            <tr *ngIf="!loading">
                <td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Customer' | translate}}: </span>
						<span *ngIf="rowData.clientName" pTooltip="{{ rowData.clientName }}">{{ rowData.clientName }}</span>
						<span *ngIf="!rowData.clientName" class="text-muted">{{'no data' | translate}}</span>
					</span>
                </td>
                <td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Current' | translate}}: </span>
						<span *ngIf="rowData.amount" (click)="clickInvoiceRef($event, rowData)" pTooltip="{{rowData.amount | currency : 'USD':'symbol':'1.2-2'}}" class="text-primary">{{rowData.amount | currency : 'USD':'symbol':'1.2-2'}}</span>
						<span *ngIf="!rowData.amount" class="text-muted">$0</span>
					</span>
                </td>
                <td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'1 - 30' | translate}}: </span> 
						<span class="mobile-table-label">{{'Customer' | translate}}: </span>
						<span *ngIf="rowData.section1" pTooltip="{{rowData.section1 | currency : 'USD':'symbol':'1.2-2'}}" class="text-primary" (click)="openDetail(rowData, '1')">
							{{rowData.section1 | currency : 'USD':'symbol':'1.2-2'}}
						</span>
						<span *ngIf="!rowData.section1" class="text-muted">$0</span>
					</span>
                </td>
                <td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'31 - 60' | translate}}: </span>
						<span *ngIf="rowData.section2" pTooltip="{{rowData.section2 | currency : 'USD':'symbol':'1.2-2'}}" class="text-primary" (click)="openDetail(rowData, '2')">
							{{rowData.section2 | currency : 'USD':'symbol':'1.2-2'}}
						</span>
						<span *ngIf="!rowData.section2" class="text-muted">$0</span>
					</span>
                </td>
                <td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'61 - 90' | translate}}: </span>
						<span *ngIf="rowData.section3" pTooltip="{{rowData.section3 | currency : 'USD':'symbol':'1.2-2'}}" class="text-primary" (click)="openDetail(rowData, '3')">
							{{rowData.section3 | currency : 'USD':'symbol':'1.2-2'}}
						</span>
						<span *ngIf="!rowData.section3" class="text-muted">$0</span>
					</span>
                </td>
                <td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'91 - 120' | translate}}: </span>
						<span *ngIf="rowData.section4" pTooltip="{{rowData.section4 | currency : 'USD':'symbol':'1.2-2'}}" class="text-primary" (click)="openDetail(rowData, '4')">
							{{rowData.section4 | currency : 'USD':'symbol':'1.2-2'}}
						</span>
						<span *ngIf="!rowData.section4" class="text-muted">$0</span>
					</span>
                </td>
                <td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'120+' | translate}}: </span>
						<span *ngIf="rowData.section5" pTooltip="{{rowData.section5 | currency : 'USD':'symbol':'1.2-2'}}" class="text-primary" (click)="openDetail(rowData, '5')">
							{{rowData.section5 | currency : 'USD':'symbol':'1.2-2'}}
						</span>
						<span *ngIf="!rowData.section5" class="text-muted">$0</span>
					</span>
                </td>
                <td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Total' | translate}}: </span>
						<span *ngIf="rowData.total" pTooltip="{{rowData.total | currency : 'USD':'symbol':'1.2-2'}}" class="text-primary">{{rowData.total | currency : 'USD':'symbol':'1.2-2'}}</span>
						<span *ngIf="!rowData.total" class="text-muted">$0</span>
					</span>
                </td>
                <td *ngIf="!isClientRole">
						<span class="one-liner">
							<span class="mobile-table-label">{{'Actions' | translate}}: </span> 	
							<i class="fa-solid fa-plus text-primary c-pointer mx-1" (click)="openDialog(rowData, true)" pTooltip="{{'Add Collections Note' | translate}}" tooltipPosition="left"></i>
							<span class="mx-1">
								<i *ngIf="rowData.followUpNoteDate" class="fa-solid fa-note-sticky text-warning c-pointer" (click)="openDialog(rowData, false)" pTooltip="{{'Follow-Ups' | translate}} {{rowData.followUpNoteDate | date: 'MM/dd/yyyy'}}"  tooltipPosition="left">
									<span *ngIf="rowData.numOfFollowUpNote > 0" class="translate-middle badge rounded-pill bg-danger" style="font-size: 7px">
										{{rowData.numOfFollowUpNote}}
									</span>
								</i>
								<i *ngIf="rowData.followUpNoteDate" class="fa-solid fa-inbox text-purple c-pointer mx-1" (click)="redirectToFollowUptUrl(rowData)" pTooltip="{{'View Follow-Ups' | translate}}" tooltipPosition="left"></i>
							</span>							
						</span>
                </td>
            </tr>
        </ng-template>
		<ng-template pTemplate="emptymessage" let-columns>
			<tr *ngIf="loading">
				<td [attr.colspan]="cols.length" class="text-center py-5">
					<div class="text-muted my-5"><i class="fa-solid fa-spinner mr-2 loadingHourglass"></i>{{'Loading...' | translate}}</div>
				</td>
			</tr>
			<tr *ngIf="!loading">
				<td [attr.colspan]="cols.length" class="text-center py-5">
					<div class="text-muted my-5">{{'no data' | translate}}</div>
				</td>
			</tr>
		</ng-template>
        <ng-template pTemplate="footer">
            <tr *ngIf="!loading">
                <td colspan="2">
					<span class="one-liner">
                        <span>{{'Totals' | translate}}: </span>
					</span>
                </td>
                <td>
					<span class="one-liner">
						<span class="mobile-table-label ">{{'1 - 30' | translate}}: </span> <span class="">{{(lastRow.section1 ? lastRow.section1 : 0)  | currency : 'USD':'symbol':'1.2-2'}}</span>
					</span>
                </td>
                <td>
					<span class="one-liner">
						<span class="mobile-table-label ">{{'31 - 60' | translate}}: </span> <span class="">{{(lastRow.section2 ? lastRow.section2 : 0)  | currency : 'USD':'symbol':'1.2-2'}}</span>
					</span>
                </td>
                <td>
					<span class="one-liner">
						<span class="mobile-table-label ">{{'61 - 90' | translate}}: </span> <span class="">{{(lastRow.section3 ? lastRow.section3 : 0)  | currency : 'USD':'symbol':'1.2-2'}}</span>
					</span>
                </td>
                <td>
					<span class="one-liner">
						<span class="mobile-table-label ">{{'91 - 120' | translate}}: </span> <span class="">{{(lastRow.section4 ? lastRow.section4 : 0)  | currency : 'USD':'symbol':'1.2-2'}}</span>
					</span>
                </td>
                <td>
					<span class="one-liner">
						<span class="mobile-table-label ">{{'120+' | translate}}: </span> <span class="">{{(lastRow.section5 ? lastRow.section5 : 0)  | currency : 'USD':'symbol':'1.2-2'}}</span>
					</span>
                </td>
                <td>
					<span class="one-liner">
						<span class="mobile-table-label ">{{'Total' | translate}}: </span> <span class="">{{(lastRow.total ? lastRow.total : 0) | currency : 'USD':'symbol':'1.2-2'}}</span>
					</span>
                </td>
            </tr>
        </ng-template>
    </p-table>
</p-panel>

<p-overlayPanel #op3 appendTo="body">
    <p-listbox [options]="exportOptions" [style]="{'width':'150px'}" [(ngModel)]="exportAs" (click)="invoiceRefAction(exportAs)">
        <ng-template let-col pTemplate="item">
            <div>
                <span>{{col.name | translate}}</span>
            </div>
        </ng-template>
    </p-listbox>
</p-overlayPanel>

<p-overlayPanel #op1 appendTo="body">
	<p-listbox [options]="textSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortDataSelected(selectedSortOption)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span>{{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>
<p-overlayPanel #op2 appendTo="body">
	<p-listbox [options]="numberSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortDataSelected(selectedSortOption)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span>{{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>

<p-dialog [(visible)]="isOpenCollectionNote" [responsive]="true" baseZIndex="99999" [modal]="true" [dismissableMask]="true" [style]="{'width':'100%', 'max-width':'900px'}">
	<p-header>
		<span><i class="fa-solid fa-circle-plus mr-2"></i>{{headerNote}}: {{followUpDate | date: 'MM/dd/yyyy hh:mm'}}</span>
	</p-header>
	<div class="ui-g">
		<div class="ui-g-6 ui-sm-12">
			<label>{{'Client' | translate}}:</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" type="button" style="cursor:default !important">
						<i class="fa-solid fa-briefcase"></i>
					</button>
				</div>
				<input *ngIf="paymentInvoiceData.clientName" pInputText [(ngModel)]="paymentInvoiceData.clientName" type="text" disabled>
				<div *ngIf="!paymentInvoiceData.clientName" class="form-control"><span class="one-liner"><i class="fa-solid fa-triangle-exclamation mr-1"></i>{{'no data' | translate}}</span></div>
			</div>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<label>{{'Collection Agent' | translate}}:</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" type="button" style="cursor:default !important">
						<i class="fa-solid fa-user-tie"></i>
					</button>
				</div>
				<p-dropdown class="w-100" [options]="assignedToEmployeeList" [filter]="true" [(ngModel)]="selectedCollectionPresentative"
							[showClear]="true" placeholder="{{'pleaseSelect' | translate}}" [disabled]="isOverTime || !isOwnerNote"></p-dropdown>
			</div>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<label>{{'Collection Type' | translate}}:</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" type="button" style="cursor:default !important">
						<i class="fa-solid fa-folder-tree"></i>
					</button>
				</div>
				<p-dropdown [options]="collectionTypeOptions" [(ngModel)]="collectionTypeSelected"  placeholder="Choose method" [disabled]="isOverTime || !isOwnerNote"></p-dropdown>
			</div>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<label>{{'Contact Method' | translate}}:<span class="text-danger">*</span></label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" type="button" style="cursor:default !important">
						<i class="fa-solid fa-message"></i>
					</button>
				</div>
				<p-dropdown [options]="contacts" [(ngModel)]="selectedContact" placeholder="Choose method" [disabled]="isOverTime || !isOwnerNote"></p-dropdown>
			</div>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<label>{{'Count' | translate}}:</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" type="button" style="cursor:default !important">
						<i class="fa-solid fa-hashtag"></i>
					</button>
				</div>
				<input type="text" pInputText [(ngModel)]="followUpCount" disabled/>
			</div>
		</div>
		<div class="ui-g-12 ui-sm-12">
			<label>{{'Note' | translate}}:<span class="text-danger">*</span> </label>
			<p-editor #notesEditor (onTextChange)="noteChange()" (onInit)="setEditor($event)" (closed)="boldMention()"
					  [mentionConfig]="{triggerChar:'@',maxItems:10,labelKey:'value'}" [mention]="assignedToEmployeeList"
					  [(ngModel)]="followUpNote" [style]="{'height':'170px'}" [readonly]="isOverTime || !isOwnerNote">
				<p-header>
						<span class="ql-formats">
							<button class="ql-bold" aria-label="Bold"></button>
							<button class="ql-italic" aria-label="Italic"></button>
							<button class="ql-underline" aria-label="Underline"></button>
							<button class="ql-strike" aria-label="Strike"></button>
						</span>
					<span class="ql-formats">
							<select title="Text Color" class="ql-color" defaultValue="rgb(0, 0, 0)">
								<option value="rgb(0, 0, 0)" label="rgb(0, 0, 0)"></option>
								<option value="rgb(104 104 104)" label="rgb(104 104 104)"></option>
								<option value="rgb(222 58 58)" label="rgb(222 58 58)"></option>
								<option value="rgb(255 99 0)" label="rgb(255 99 0)"></option>
								<option value="rgb(90 171 29)" label="rgb(90 171 29)"></option>
								<option value="rgb(0 123 255)" label="rgb(0 123 255)"></option>
							</select>
							<span class="ql-format-separator"></span>
						</span>
					<span class="ql-formats">
							<button class="ql-list" title="List"></button>
							<button class="ql-bullet" title="Bullet"></button>         
						</span>
				</p-header>
			</p-editor>
			<span *ngIf="MAX_LENGTH - charactersLength > 0" class="text-muted pull-right" style="font-size: 12px">{{MAX_LENGTH - charactersLength}} {{'characters left' | translate}}</span>
			<span *ngIf="MAX_LENGTH - charactersLength <= 0" class="text-danger pull-right" style="font-size: 12px">{{MAX_LENGTH - charactersLength}} {{'characters left' | translate}}</span>
		</div>
	</div>
	<p-footer class="d-flex align-items-center justify-content-between">
		<button class="btn btn-danger" (click)="closeDialog()"><i class="fa-solid fa-xmark mr-2"></i>{{'Cancel' | translate}}</button>
		<button class="btn btn-primary" [disabled]="followUpNote === '' || selectedCollectionPresentative === '' || selectedContact === '' || isOverTime || !isOwnerNote"
				(click)="saveAllAsync()"><i class="fa-solid fa-check mr-2"></i>{{'Submit' | translate}}</button>
	</p-footer>
</p-dialog>
<p-dialog [modal]="true" [(visible)]="showDetailPopup" [style]="{'width': '90%','max-width':'1000px','max-height':'85vh' }" appendTo="body" (onHide)="showDetailPopup = false">
	<p-header>
		<span><i class="fa-solid fa-circle-exclamation mr-2"></i>{{'Client Name' | translate}}&nbsp;{{agingDetails.name}}</span>
	</p-header>
	<div>
		<div class="row col-xs-12">
			<p-table id="inspectionId" [value]="agingDetails.data" [paginator]="false" [responsive]="true" [resizableColumns]="true">
				<ng-template pTemplate="header" let-columns>
					<tr>
						<th>
							<div class="d-flex align-items-center justify-content-between w-100">
								<span class="one-liner">{{'#' | translate}}</span>
							</div>
						</th>
						<th>
							<div class="d-flex align-items-center justify-content-between w-100">
								<span class="one-liner">{{'Invoice Number' | translate}}</span>
							</div>
						</th>
						<th>
							<div class="d-flex align-items-center justify-content-between w-100">
								<span class="one-liner">{{'Service Fee' | translate}}</span>
							</div>
						</th>
						<th>
							<div class="d-flex align-items-center justify-content-between w-100">
								<span class="one-liner">{{'Balance' | translate}}</span>
							</div>
						</th>
					</tr>
				</ng-template>
				<ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
					<tr [pSelectableRow]="rowData" [pContextMenuRow]="rowData">
						<td>
							<span class="one-liner">
								<span class="mobile-table-label">{{'#' | translate}}: </span> {{rowIndex + 1}}
							</span>
						</td>
						<td>
							<span class="one-liner">
								<span class="mobile-table-label">{{'Invoice Number' | translate}}: </span>
								<span *ngIf="rowData.invoiceNumber" pTooltip="{{rowData.invoiceNumber}}" style="cursor: pointer"
									  (click)="clickInvoiceRef($event, rowData)" class="text-primary">{{rowData.invoiceNumber}}</span>
								<span *ngIf="!rowData.invoiceNumber" class="text-muted">{{'no data' | translate}}</span>
							</span>
						</td>
						<td>
							<span class="one-liner">
								<span class="mobile-table-label">{{'Service Fee' | translate}}: </span>
								<span *ngIf="rowData.totalFee" pTooltip="{{rowData.totalFee | currency : 'USD':'symbol':'1.2-2'}}">{{rowData.totalFee | currency : 'USD':'symbol':'1.2-2'}}</span>
								<span *ngIf="!rowData.totalFee" class="text-muted">{{'no data' | translate}}</span>
							</span>
						</td>
						<td>
							<span class="one-liner">
								<span class="mobile-table-label">{{'Balance' | translate}}: </span>
								<span *ngIf="rowData.balance" pTooltip="{{rowData.balance | currency : 'USD':'symbol':'1.2-2'}}">{{rowData.balance | currency : 'USD':'symbol':'1.2-2'}}</span>
								<span *ngIf="!rowData.balance" class="text-muted">{{'no data' | translate}}</span>
							</span>
						</td>
					</tr>
				</ng-template>
			</p-table>
		</div>
	</div>
	<p-footer class="d-flex justify-content-end">
		<button type="button" (click)="showDetailPopup = false" class="btn btn-danger"><i class="fa-solid fa-xmark mr-2"></i>{{'Close' | translate}}</button>
	</p-footer>
</p-dialog>
