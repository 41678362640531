import { Injectable } from '@angular/core';
import { AbstractService } from '../../shared/service/abstract.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Constants } from '../../shared/model/constants';
import { FreelancerType } from '../model/freelancerType.model';
import { Observable } from 'rxjs';
import { Freelancer } from '../model/freelancer.model';
import { Page } from '../../shared/model/page.model';
import { FreelancerTicket } from '../model/freelancer-ticket.model';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { Response } from '../../shared/model/response';
import { Contact } from '../model/contact';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({ providedIn: 'root' })
export class FreelancerTicketService extends AbstractService<FreelancerTicket> {

  constructor(private http: HttpClient) {
    super(http, Constants.ROUTES.FREELANCERTICKET, '');
  }
  /*getDepartmentListByPage(params: any): Observable<Page<FreelancerTicket>> {
      return this._http.get<Page<Freelancer>>(this.baseUrl, {params: params}).pipe(map(resp => resp));
  }*/

  searchFreelancerTicket(data: any): Observable<Page<FreelancerTicket>> {
    return this._http.post<Page<FreelancerTicket>>(`${this.baseUrlV2}search`, data).pipe(map(resp => resp));
    // return this._http.post<Page<FreelancerTicket>>(`http://localhost:8013/api/v2/freelancerticket/search`, data).pipe(map(resp => resp));
  }

  searchFilterTickets(data: any): Observable<Page<FreelancerTicket>> {
    return this._http.post<Page<FreelancerTicket>>(`${this.baseUrlV2}searchFilterTickets`, data).pipe(map(resp => resp));
    // return this._http.post<Page<FreelancerTicket>>(`http://localhost:8013/api/v2/freelancerticket/searchFilterTickets`, data).pipe(map(resp => resp));
  }

  getCreatedByList(options: any): Observable<any> {
    let params = new HttpParams();
    params = params.set('companyId', options.companyId);
    return this._http.get<any>(`${this.baseUrlV2}find-created-by-user`, { params }).pipe(map(resp => resp));
  }

  exportPdf(data?: any): Observable<any> {
    return this._http.post<any>(`${this.baseUrlV2}exportPdf`, data).pipe(map(resp => resp));
    // return this._http.post<any>(`http://localhost:8013/api/v2/freelancerticket/exportPdf`, data).pipe(map(resp => resp));
  }

  getAllAssignedTickets(params: any): Observable<Page<FreelancerTicket>> {
    return this._http.get<Page<FreelancerTicket>>(`${this.baseUrlV2}`, { params: params }).pipe(map(resp => resp));
  }

  saveHistoryData(data) {
    return this._http.post(`${this.baseUrlV2}history`, data).pipe(map(resp => resp));
  }

  loadContractorList(data) {
    return this._http.post(`${this.baseUrlV2}search`, data).pipe(map(resp => resp));
  }

  createAll(entities: FreelancerTicket[]): Observable<Response<FreelancerTicket>> {
    return this._http.post<Response<FreelancerTicket>>(`${this.baseUrlV2}saveMultipleRows`, entities).pipe(map(res => res));
  }

  updateFreelancerTicket(freelancerTicket) {
    return this._http.put(`${this.baseUrlV2}`, freelancerTicket);
  }

  addCommentToTicket(payload) {
    return this._http.post(`${this.baseUrlV2}comment`, payload).pipe(map(resp => resp));
  }

  getTicketComment(options) {
    return this._http.post(`${this.baseUrlV2}comment/dropdown`, options).pipe(map(resp => resp));
  }

  deleteComment(id: number) {
    return this._http.delete(`${this.baseUrlV2}comment/${id}`);
  }

  getAllContractors(data: any): Observable<Page<FreelancerTicket>> {
    return this._http.post<Page<FreelancerTicket>>(`${this.baseUrlV2}all-contractors`, data).pipe(map(resp => resp));
  }

  getAllTeamIdByTask(data: any): Observable<Page<FreelancerTicket>> {
    return this._http.post<Page<FreelancerTicket>>(`${this.baseUrlV2}all-teams`, data).pipe(map(resp => resp));
  }

  takeTicketBack(data: any) {
    return this._http.post(`${this.baseUrlV2}taken-back`, data).pipe(map(resp => resp));
  }

  countToDoTaskOnCEOBoard(data: any) {
    return this._http.post(`${this.baseUrlV2}dashboard-count`, data).pipe(map(resp => resp));
  }

  exportAsExcelFile(json: any[], excelFileName: string, fileExtension?: any): void {

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const wscols = [{ wch: 12 }, { wch: 15 }, { wch: 10 }, { wch: 10 }, { wch: 10 }, { wch: 15 }];

    worksheet['!cols'] = wscols;
    const workbook: XLSX.WorkBook = { Sheets: { 'Sheet1': worksheet }, SheetNames: ['Sheet1'] };

    const excelBuffer: any = XLSX.write(workbook, { bookType: fileExtension, type: 'array' });
    if (fileExtension) {
      this.saveAsExcelFile(excelBuffer, excelFileName, fileExtension);
    } else {
      this.saveAsExcelFile(excelBuffer, excelFileName, '.xlsx');
    }
  }

  saveAsExcelFile(buffer: any, fileName: string, fileExtension?: string): void {
    let downloadFileExtension: string = EXCEL_EXTENSION;
    if (fileExtension) {
      downloadFileExtension = fileExtension;
    }
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + '.' + fileExtension);
  }

  searchFilterTicketsV2(data: any): Observable<Page<FreelancerTicket>> {
    // return this._http.post<Page<FreelancerTicket>>(`http://localhost:8013/api/v2/freelancerticket/search-filter-tickets-v2`, data).pipe(map(resp => resp));
    return this._http.post<Page<FreelancerTicket>>(`${this.baseUrlV2}search-filter-tickets-v2`, data).pipe(map(resp => resp));
  }

  exportPdfCard(data?: any): Observable<any> {
    // return this._http.post<any>(`http://localhost:8013/api/v2/freelancerticket/exportPdfCard`, data).pipe(map(resp => resp));
    return this._http.post<any>(`${this.baseUrlV2}exportPdfCard`, data).pipe(map(resp => resp));
  }
}
