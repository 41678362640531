import { AbstractService } from '../../shared/service/abstract.service';
import { BillingInvoice } from '../model/billinginvoice.model';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../shared/model/constants';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Page } from '../../shared/model/page.model';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class BillingInvoiceService extends AbstractService<BillingInvoice>{

    constructor(private http: HttpClient) {
        super(http, Constants.ROUTES.BILLING, '');
    }

    getAllBillingInvoice(): Observable<BillingInvoice[]> {
        return this._http.get<BillingInvoice[]>(this.baseUrlV2 + Constants.URI.BILLING.LISTALLBYPAGE).pipe(map(resp => resp));
    }

    getBillingInvoiceListByPage(params: any): Observable<Page<BillingInvoice>> {
        // return this._http.post<Page<BillingInvoice>>('http://localhost:8007/api/v2/operation/billing/search' , params).pipe(map(resp => resp));
        return this._http.post<Page<BillingInvoice>>(this.baseUrlV2 + 'search' , params).pipe(map(resp => resp));
    }

    exportPdf(data?: any): Observable<any> {
        //return this._http.post<any>('http://localhost:8007/api/v2/operation/billing/exportPdf', data).pipe(map(resp => resp));
        return this._http.post<any>(this.baseUrlV2 + 'exportPdf', data).pipe(map(resp => resp));
    }

    createBilling(entity: BillingInvoice, username: string): Observable<BillingInvoice> {
        const params = {
            userName: username
        };
        return this._http.post<BillingInvoice>(this.baseUrlV2, entity , { params: params }).pipe(map(res => res));
    }

    updateBilling(entity: BillingInvoice, id: Number, username: string): Observable<BillingInvoice> {
        const params = {
            userName: username
        };
        return this._http.put<BillingInvoice>(`${this.baseUrlV2}`, entity, { params: params }).pipe(map(res => res));
    }

    deleteBilling(id: Number, username: string) {
        const params = {
            userName: username
        };
        return this._http.delete(`${this.baseUrlV2}${id}`, { params: params }).pipe(map(data => data));
    }

    checkExists(data?: any): Observable<any> {
        return this._http.post<any>(`${this.baseUrlV2}check-record-exist`, data).pipe(map(resp => resp));
    }

    exportAr(data?: any): Observable<any> {
        return this._http.post<any>(this.baseUrlV2 + 'invoice/export-ar', data).pipe(map(resp => resp));
    }
    exportArPayment(data?: any): Observable<any> {
        return this._http.post<any>(this.baseUrlV2 + 'invoice/export-ar-payment', data).pipe(map(resp => resp));
    }
    exportPayment(data?: any): Observable<any> {
        return this._http.post<any>(this.baseUrlV2 + 'invoice/export-payment', data).pipe(map(resp => resp));
    }
}

