<div class="ui-g-12" class="main-panel">
	<p-table #dt [value]="availableAdminMenuList.menus" [columns]="displayedFields">
		<ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
			<tr *ngIf="rowIndex + 1 == 2">
				<td *ngFor="let col of columns; let i = index">
					<span *ngIf="col === 'name'">
						<span class="one-liner">
							<b>{{ 'Accounting' | translate }}</b>
						</span>
					</span>
				</td>
			</tr>
			<tr>
				<td *ngFor="let col of columns; let i = index" 
					[ngClass]="[rowData.type === 'parent' && col === 'name' ? 'bg-parent font-weight-bold text-center' : col !== 'name' ? 'text-center' : '']"
					style="width: 80px !important;">
					<span *ngIf="col === 'name' && (rowIndex + 1 != 1)">
						<span class="one-liner">
							<span class="mobile-table-label">{{'Feature' | translate}}: </span>{{ rowData[col] }}
						</span>
					</span>
					<div *ngIf="col !== 'name' && rowData.type === 'parent' && rowIndex === 0" class="d-flex flex-column align-items-center" id="plans">
						<p-radioButton *ngIf="col !== 'name'" name="planSection" [(ngModel)]="planId" (onClick)="addPlanId(displayedPlanIds[i])" [disabled]="isDisable" [value]="displayedPlanIds[i]" inputId="rnr_opt1"></p-radioButton>
						<span class="one-liner" pTooltip="{{ displayedHead[i] }}" tooltipPosition="left">{{ displayedHead[i] }}</span>
						<div class="badge text-dark" [ngStyle]="{ 'background-color': displayedSubHeadColor[i], 'color':'#fff' }">
							<span *ngIf="displayedSubHead[i]" class="one-liner" style="font-size: 12px">{{ displayedSubHead[i] | currency:'USD':'symbol':'1.2-2'}}</span>
							<span *ngIf="!displayedSubHead[i]" class="one-liner" style="font-size: 12px">{{ 'no data' | translate }}</span>
						</div>
					</div>
					<span *ngIf="col !== 'name' && rowData.type != 'parent'" [ngClass]="{'ng-checked': rowData[col], 'ng-not-checked': !rowData[col]}">
						<span class="one-liner">
							<span class="mobile-table-label">{{ displayedHead[i] }}: </span>
							<i class="fa-solid fa-check text-success" *ngIf="rowData[col]" pTooltip="{{'Included' | translate}}"></i>
							<i class="fa-solid fa-xmark text-danger" *ngIf="!rowData[col]" pTooltip="{{'Not Included' | translate}}"></i>
						</span>
					</span>
				</td>
			</tr>
		</ng-template>
	</p-table>	
</div>
