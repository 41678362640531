import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Constants } from '../../model/constants';
import { AbstractService } from '../abstract.service';
import { DocumentData } from '../../model/document-data.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NgxImageCompressService } from 'ngx-image-compress';
import { AuthService } from '../auth/auth.service';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';


@Injectable({ providedIn: 'root' })
export class DocumentsService extends AbstractService<DocumentData> {

    protected docApiUrl: string = `${environment.server_ip}/${Constants.ROUTES.FILEUPLOAD}`;

    constructor(protected _http: HttpClient, private imageCompress: NgxImageCompressService,
        private authService: AuthService) {
        super(_http, Constants.ROUTES.FILEUPLOAD, '');

    }

    save(document: DocumentData): Observable<DocumentData> {
        return this._http.post<DocumentData>(`${this.baseUrl}doc`, document).pipe(map(res => res));
    }

    update(document: DocumentData): Observable<DocumentData> {
        return this._http.post<DocumentData>(`${this.baseUrl}doc/${document.id}`, document).pipe(map(res => res));
    }


    uploadFile(file: File, entityName: string, entityId: any, fileclass: string, userUpload?): Observable<any> {
        let reqParams = { entity: entityName, entityId: entityId, fileClass: fileclass, userUpload };
        if (!reqParams.entityId) {
            reqParams.entityId = 0;
        }
        let payload = new FormData();
        payload.append('file', file);
        return this._http.post<any>(this.baseUrlV2, payload, { params: reqParams }).pipe(map(res => res));
    }

    uploadFileEntity(file: File, entityName: string, entityId: any, fileclass: string): Observable<any> {
        let reqParams = { entity: entityName, entityId: entityId, fileClass: fileclass };
        if (!reqParams.entityId) {
            reqParams.entityId = 0;
        }
        let payload = new FormData();
        payload.append('file', file);
        return this._http.post<any>(`${environment.v2_server_backend}/${Constants.ROUTES.FILEUPLOAD}`, payload, {params: reqParams}).pipe(map(res => res));
    }

    getUploadedFile(id): Observable<any> {
        return this._http.get<any>(`${environment.v2_server_backend}/${Constants.ROUTES.FILEUPLOAD}/${id}`).pipe(map(res => res));
    }

    updateFile(file: File, documentId: any, entityName: string, entityId: any, fileclass: string): Observable<any> {
        let reqParams = { id: documentId, entity: entityName, entityId: entityId, fileClass: fileclass };
        if (!reqParams.entityId) {
            reqParams.entityId = 0;
        }
        let payload = new FormData();
        payload.append('file', file);
        return this._http.post<any>(this.docApiUrl, file, { params: reqParams }).pipe(map(res => res));
    }

    getAvatarByEntityId(entityId: number): Observable<any> {
        const reqUrl = `${environment.v2_server_backend}/${Constants.ROUTES.FILEUPLOAD}/${entityId}`;
        return this._http.get<any>(reqUrl).pipe(
            map(res => {
                if (res.status === 'SUCCESS' && res.data?.fileUrl) {
                    return res.data.fileUrl;
                } else {
                    return 'assets/images/logo.png';
                }
            }),
            catchError(() => of('assets/images/logo.png'))
        );
    }

    
    getAvatarUrl(userInfo: any): Observable<any> {
        return this._http.get<any>(`${environment.v2_server_backend}/${Constants.ROUTES.FILEUPLOAD}/${userInfo.profilePhoto}`).pipe(
            map(res => {
                const resObj: any = res;
                if (resObj.status === 'SUCCESS') {
                    const avatar = resObj?.data?.fileUrl ? resObj?.data?.fileUrl : "assets/images/logo.png";
                    return { ...userInfo, avatar };
                } else {
                    return { ...userInfo, avatar: "assets/images/logo.png" };
                }
            }),
            catchError(error => {
                return of({ ...userInfo, avatar: "assets/images/logo.png" });
            })
        );
    }
    
    uploadFileWithFileName(file: File, entityName: string, entityId: any, fileclass: string, fileName: string): Observable<any> {
        let reqParams = { entity: entityName, entityId: entityId, fileClass: fileclass };
        if (!reqParams.entityId) {
            reqParams.entityId = 0;
        }
        let payload = new FormData();
        payload.append('file', file, fileName);
        return this._http.post<any>(this.baseUrlV2, payload, { params: reqParams }).pipe(map(res => res));
    }

    uploadFileWithThumbnail(file: File, thumbnail: File, entityName: string, entityId: any, fileclass: string, fileName: string): Observable<any> {
        let reqParams = { entity: entityName, entityId: entityId, fileClass: fileclass };
        if (!reqParams.entityId) {
            reqParams.entityId = 0;
        }
        let payload = new FormData();
        payload.append('file', file, fileName);
        if (thumbnail) { payload.append('thumbnail', thumbnail, new Date().getTime() + '_thumb_file.jpg'); }
        else { payload.append('thumbnail', null) }
        return this._http.post<any>(`${this.baseUrlV2}file-upload-with-thumbnail`, payload, { params: reqParams }).pipe(map(res => res));
    }

    uploadAnResize(image) {
        return this.imageCompress.compressFile(image, 1, 30, 30);
    }

    convertStreams(file: File, entityName: string, entityId: any, fileClass: string, fileName: string): Observable<any> {
        const reqParams = { entity: entityName, entityId: entityId, fileClass: fileClass };
        if (!reqParams.entityId) {
            reqParams.entityId = 0;
        }
        const payload = new FormData();
        payload.append('file', file, fileName);
        return this._http.post(`${environment.v2_server_backend}/${Constants.ROUTES.CONVERT_WEBM_MP4}`, payload, { params: reqParams, responseType: 'blob' }).pipe(map(resp => resp));
    }

    saveVideo(file: File, entityName: string, entityId: any, fileClass: string, fileName: string, title: string): Observable<any> {
        const reqParams: any = {
            entity: entityName, entityId: entityId, fileClass: fileClass, title: title,
            createdByUsr: this.authService.getCurrentUsername(), userType: this.authService.getUserType()
        };
        if (!reqParams.entityId) {
            reqParams.entityId = 0;
        }
        reqParams.userId = this.authService.getCurrentLoggedInId()
        if (this.authService.getCurrentCompanyId()) reqParams.companyId = this.authService.getCurrentCompanyId()
        const payload = new FormData();
        payload.append('file', file, fileName);
        return this._http.post(`${this.baseUrlV2}save-video`, payload, { params: reqParams }).pipe(map(resp => resp));
    }

    getScreenRecordingList(options: any): Observable<any> {
        return this._http.post<any>(`${this.baseUrlV2}video/search`, options).pipe(map(res => res));
    }

    deleteVideo(id: number): Observable<any> {
        return this._http.delete<any>(`${this.baseUrlV2}video/${id}`).pipe(map(res => res));
    }

    getCreatedBy(): Observable<any> {
        return this._http.get<any>(`${this.baseUrlV2}video/created-by-user`).pipe(map(res => res));
    }

    updateTitle(payload): Observable<any> {
        return this._http.post<any>(`${this.baseUrlV2}video/update`, payload).pipe(map(res => res));
    }

    getFileByEnitity(entity: string, entityId: any): Observable<any> {
        return this._http.get<any>(`${environment.v2_server_backend}/${Constants.ROUTES.FILEUPLOAD}/${entityId}/get-by-entity/${entity}`).pipe(map(res => res));
    }

    uploadFileEntityByUser(file: File, entityName: string, entityId: any, fileclass: string, userUpload: string): Observable<any> {
        let reqParams = { entity: entityName, entityId: entityId, fileClass: fileclass, userUpload: userUpload };
        if (!reqParams.entityId) {
            reqParams.entityId = 0;
        }
        let payload = new FormData();
        payload.append('file', file);
        return this._http.post<any>(`${environment.v2_server_backend}/${Constants.ROUTES.FILEUPLOAD}/`, payload, { params: reqParams }).pipe(map(res => res));
    }

    deleteFile(id: Number) {
        return this._http.delete(`${this.baseUrlV2}/${id}`).pipe(map(data => data));
    }

    uploadDocSignV2(data: any) {
        // return this._http.post('http://localhost:8009/api/v2/notification/document/upload-doc-sign-v2', data).pipe(map(res => res));
        return this._http.post(`${environment.v2_server_backend}/${Constants.ROUTES.FILEUPLOAD}/upload-doc-sign-v2`, data).pipe(map(res => res));
    }

    uploadDocSign(file: File, entityName: string, entityId: any, fileclass: string, signatureLeft: any, signatureRight: any): Observable<any> {
        let reqParams = { entity: entityName, entityId: entityId, fileClass: fileclass, signatureLeft: signatureLeft, signatureRight: signatureRight };
        if (!reqParams.entityId) {
            reqParams.entityId = 0;
        }
        let payload = new FormData();
        payload.append('file', file);
        //return this._http.post<any>(`http://localhost:8009/api/v2/notification/document/upload-doc-sign`, payload, {params: reqParams}).pipe(map(res => res));
        return this._http.post<any>(`${environment.v2_server_backend}/${Constants.ROUTES.FILEUPLOAD}/upload-doc-sign`, payload, { params: reqParams }).pipe(map(res => res));
    }

    downloadFile(id: number) {
        // return this._http.get(`http://localhost:8009/api/v2/notification/document/download/${id}`, {
        //     observe: 'response',
        //     responseType: 'blob' as 'json'
        // });
        return this._http.get(`${environment.v2_server_backend}/${Constants.ROUTES.FILEUPLOAD}/download/${id}`, {
            observe: 'response',
            responseType: 'blob' as 'json'
        });
    }
}
