import { MonitoringDetailsService } from './../../admin/process-monitor/service/monitoring-details.service';
import { AuthService } from './../../shared/service/auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs-compat';
import { Page } from '../../shared/model/page.model';
import { map } from 'rxjs/operators';
const IMAGE_LIST = ['https://livestore.operrwork.com/operrwork/adelin-grigorescu-yGCeV-8VeDw-unsplash.jpg', 'https://livestore.operrwork.com/operrwork/aden-lao-bHaDK74hWLY-unsplash.jpg', 'https://livestore.operrwork.com/operrwork/aden-lao-TCiPU0tiLeI-unsplash.jpg', 'https://livestore.operrwork.com/operrwork/ali-choubin-aO-Wq5CV0z8-unsplash.jpg', 'https://livestore.operrwork.com/operrwork/carnaby-gilany-u1EjAmywwI0-unsplash.jpg', 'https://livestore.operrwork.com/operrwork/dan-asaki-WfU13iQobg4-unsplash.jpg', 'https://livestore.operrwork.com/operrwork/hello-i-m-nik-ON9DwGUABIo-unsplash.jpg', 'https://livestore.operrwork.com/operrwork/joe-pohle-ow1J09OyNAM-unsplash.jpeg', 'https://livestore.operrwork.com/operrwork/john-hreniuc-cD9U2KveIO0-unsplash.jpg', 'https://livestore.operrwork.com/operrwork/marcreation-pWV8HjvHzk8-unsplash.jpg', 'https://livestore.operrwork.com/operrwork/marek-piwnicki-c9c0IWXrUkc-unsplash.jpg', 'https://livestore.operrwork.com/operrwork/marek-piwnicki-lcRJRJlZzgg-unsplash.jpg', 'https://livestore.operrwork.com/operrwork/ralph-ravi-kayden-j5xlNpgzqds-unsplash.jpg', 'https://livestore.operrwork.com/operrwork/ricardo-gomez-angel-7RuhOrZO-1g-unsplash.jpg', 'https://livestore.operrwork.com/operrwork/sergey-pesterev-llGiAyYthDw-unsplash.jpeg', 'https://livestore.operrwork.com/operrwork/solen-feyissa-gq7WBs1ejHw-unsplash.jpg', 'https://livestore.operrwork.com/operrwork/the-blowup-KEVgRFDWl34-unsplash.jpg'];
export const VISIBILITY_MODE = {
  ASSIGN_ONLY: 'ASSIGNED_ONLY',
  EVERYONE: 'EVERYONE',
  PERSONALIZE: 'PERSONALIZE'
};
export const SEARCH_TASK_DATE_TYPE = {
  NEXT_DAY: 1,
  NEXT_WEEK: 2,
  NEXT_MONTH: 3,
  OVERDUE: 4,
  NO_DUE: 5,
};
export const TICKET_TYPE = {
  STORY: 'STORY',
  EPIC: 'EPIC',
  BUG: 'BUG',
  NONE:'NONE'
};

import { Constants } from '../../shared/model/constants';
@Injectable({
  providedIn: 'root'
})
export class TaskManagementService {
  baseUrl: string;

  notificationUrl: string;

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    private monitoringDetailsService: MonitoringDetailsService) {
    this.baseUrl = `${environment.v2_server_backend}/${Constants.ROUTES.TASK_MANAGEMENT}`;
    // this.baseUrl = `http://8c1612c4caf5.ngrok.io/api/v2/crm/tasks-management`;
    this.notificationUrl = `${environment.v2_server_backend}/${Constants.ROUTES.NOTIFICATION}`;
  }
  getAllProject() {

    const userName = this.authService.getCurrentUsername();
    const userId = this.authService.isSuper() ? null : this.authService.getCurrentLoggedInId();
    return this.httpClient.post(`${this.baseUrl}/search`, {
      userId: userId,
      userType: this.authService.isSuper() ? null : this.getCurrentUserRole(), // 0
      companyId: (this.authService.isCompanyAd() || this.authService.isSubCompanyAd()) ? this.authService.getCurrentCompanyId() : null
    });
  }
  getProjectById(id) {
    const isAccess = this.authService.getLoggedInUserMenuList().includes('1015');
    if (isAccess && (this.authService.isCompanyAd() || this.authService.isSubCompanyAd())) {
      return this.getProjectListById(id);
    } else {
      return this.httpClient.get(`${this.baseUrl}/${id}`);
    }
  }
  addProject(name, companyId) {
    const userName = this.authService.getCurrentUsername();
    return this.httpClient.post(`${this.baseUrl}`, {
      name, createdByUsr: userName,
      companyId,
      userId: this.authService.getCurrentLoggedInId(),
      background: IMAGE_LIST[Math.floor(Math.random() * IMAGE_LIST.length)],
      backgroundType: 'IMAGE',
      userType: this.authService.isSuper() ? null : this.getCurrentUserRole(), // 0
    });
  }
  updateProject(request) {
    return this.httpClient.put(`${this.baseUrl}/${request.projectId}`, request);
  }
  deleteProject(projectId) {
    return this.httpClient.delete(`${this.baseUrl}/${projectId}`);
  }
  addColumn(request: { name: string, projectId: string }) {
    return this.httpClient.post(`${this.baseUrl}/buckets`, request);
  }
  updateColumn(request: { name: string, columnId: string }) {
    return this.httpClient.put(`${this.baseUrl}/buckets/${request.columnId}`, { name: request.name });
  }
  deleteColumn(id) {
    return this.httpClient.delete(`${this.baseUrl}/buckets/${id}`);
  }
  addCard(request: { name: string, content: string, status: string, bucketId: string }) {
    return this.httpClient.post(`${this.baseUrl}/buckets/tasks`, request);
  }
  updateCard(id: string,
    request: {
      name: string,
      content: string,
      status: string,
      deadline?,
      bucketId: number,
      background: string,
      assignedUsers: any[],
      viewMode?: string,
      accessibleUsers: any[],
      projectId?: number,
      lastModifiedBy?: string,
      watchCard?: any,
      position: number,
      startDate?: any,
      ticketType?: string,
    }) {

    return this.httpClient.put(`${this.baseUrl}/buckets/tasks/${id}`, request);
  }
  deleteCard(id) {
    return this.httpClient.delete(`${this.baseUrl}/buckets/tasks/${id}`);
  }

  deleteTask(payload) {
    return this.httpClient.post(`${this.baseUrl}/buckets/tasks/delete-task`, payload);
  }

  invite(request) {
    return this.httpClient.post(`${this.baseUrl}/invite`, request);
  }
  acceptInvitation(token) {
    const userId = this.authService.getCurrentLoggedInId();
    return this.httpClient.post(`${this.baseUrl}/accept-invitation`, {
      userId,
      token,
      userType: this.getCurrentUserRole()
    });
  }
  unassign(request: { userId: number, projectId: number, companyId: number, taskId: number, userType: any }) {
    return this.httpClient.post(`${this.baseUrl}/buckets/tasks/unassign`, request);
  }
  assign(request: { userId: number, projectId: number, companyId: number, taskId: number, userType: any, ticketLink:string }) {
    return this.httpClient.post(`${this.baseUrl}/buckets/tasks/assign`, request);
  }
  createLabel(name: string, color: string, projectId: number) {
    const request = {
      name,
      color,
      projectId
    };
    return this.httpClient.post(`${this.baseUrl}/labels`, request);
  }
  updateLabel(id, name: string, color: string, projectId: number) {
    const request = {
      name,
      color,
      projectId
    };
    return this.httpClient.put(`${this.baseUrl}/labels/${id}`, request);
  }
  deleteLabel(id) {
    return this.httpClient.delete(`${this.baseUrl}/labels/${id}`);
  }
  assignLabelToTask(labelId, taskId) {
    const url = `${this.baseUrl}/buckets/tasks/${taskId}/labels/assign/${labelId}`;
    return this.httpClient.post(url, {});
  }
  unassignLabel(labelId, taskId) {
    const url = `${this.baseUrl}/buckets/tasks/${taskId}/labels/unassign/${labelId}`;
    return this.httpClient.post(url, {});
  }
  addComment(data, taskId) {
    const url = `${this.baseUrl}/buckets/tasks/${taskId}/comments`;
    return this.httpClient.post(url, data);
  }
  updateComment(data, taskId) {
    const url = `${this.baseUrl}/buckets/tasks/${taskId}/comments/${data.id}`;
    return this.httpClient.put(url, data);
  }
  deleteComment(id, taskId, fileName?, commentType?) {
    const url = `${this.baseUrl}/buckets/tasks/${taskId}/comments/${id}/delete`;
    const request = {
      'userId': this.authService.getCurrentLoggedInId(),
      userName: this.authService.getCurrentLoggedInName(),
      fileType:commentType,
      fileName
    };
    return this.httpClient.post(url, request);
  }
  getListComment(taskId) {
    const url = `${this.baseUrl}/buckets/tasks/${taskId}/comments`;
    return this.httpClient.get(url);
  }
  updateTaskOrder(bucketId, request: { ids: any[], updatedBy: string , taskId?:number, moveTicketToOther?:boolean, newBoardId?:number, newBucketId?:number, newInternalId?:number}) {
    const url = `${this.baseUrl}/buckets/${bucketId}/task_orders`;
    return this.httpClient.post(url, request);
  }
  updateBucketsOrder(projectId, request: { ids: any[], updatedBy: string }) {
    const url = `${this.baseUrl}/${projectId}/bucket_orders`;
    return this.httpClient.post(url, request);
  }
  getMemberAvatar(employeeIds, adminIds, guestIds, freelancerIds) {
    const url = `${this.baseUrl}/user-avatars`;
    return this.httpClient.post(url, { employeeIds, adminIds, guestIds, freelancerIds });
  }
  updateProjectBackground(request: {
    background: string,
    backgroundType: string,
    name: string,
  }, projectId) {
    return this.httpClient.put(`${this.baseUrl}/${projectId}`, request);
  }
  encodeId(value) {
    return btoa(value);
  }
  decodeId(value) {
    return atob(value);
  }
  getCurrentUserRole() {
    // admin : 0, employee:1
    return this.authService.adminRole() ? 0 : this.authService.isEmployeeRole() ? 1 : this.authService.isGuestRole() ? 3 : 2;
  }
  removeUserBoard(request) {
    return this.httpClient.post(`${this.baseUrl}/unassign`, request);
  }

  fetchAllUsers(params) {
    return this.httpClient.get(`${this.baseUrl}/all-users`,{params:params});
  }

  changeUserRole(params) {
    return this.httpClient.get(`${this.baseUrl}/change-user-role`, { params: params });
  }

  duplicateTask(id, params) {
    // return this.httpClient.post(`http://localhost:8015/api/v2/task/tasks-management/buckets/tasks/${id}/duplicate`, params);
    return this.httpClient.post(`${this.baseUrl}/buckets/tasks/${id}/duplicate`, params);
  }

  getListCommentOfProject(projectId) {
    const url = `${this.baseUrl}/buckets/tasks/${projectId}/comments-project`;
    return this.httpClient.get(url);
  }

  getTask(taskId) {
    const url = `${this.baseUrl}/buckets/tasks/${taskId}`;
    return this.httpClient.get(url);
  }

  removeBoardMember(id) {
    return this.httpClient.delete(`${this.baseUrl}/unassign/${id}`);
  }

  getAllTask(options: any): Observable<Page<any>> {
    const reqUrl = `${this.baseUrl}/buckets/tasks/search`;
    return this.httpClient.post<any>(reqUrl, options).pipe(map(resp => resp));
  }

  setWatchCard(options: any): Observable<Page<any>> {
    const reqUrl = `${this.baseUrl}/buckets/tasks/watch-card`;
    return this.httpClient.post<any>(reqUrl, options).pipe(map(resp => resp));
  }

  unWatchCard(id) {
    return this.httpClient.delete(`${this.baseUrl}/buckets/tasks/un-watch-card/${id}`);
  }

  getUserType() {
    return this.authService.adminRole() ? 'ADMIN' : this.authService.isEmployeeRole() ? 'EMPLOYEE' : this.authService.isGuestRole() ? 'GUEST' : 'FREELANCER';
  }

  assignToProject(option: any) {
    return this.httpClient.post(`${this.baseUrl}/assign-to-project`, option);
  }

  getTaskName(params) {
    const url = `${this.baseUrl}/buckets/tasks/find-task-name`;
    return this.httpClient.get(url, { params: params });
  }

  getBucket(id) {
    return this.httpClient.get(`${this.baseUrl}/buckets/${id}`);
  }

  removeInvoiceNumberLabel(option: any) {
    return this.httpClient.post(`${this.baseUrl}/buckets/tasks/remove-number-label`, option);
  }
  getTasksByFreelancer(params: any) {
    return this.httpClient.get(`${this.baseUrl}/buckets/tasks/find-task-according-to-user`, { params: params });
  }

  getAllProjectByGuest() {
    const userId = this.authService.getCurrentLoggedInId();
    return this.httpClient.post(`${this.baseUrl}/search`, {
      userId: userId,
      userTypes: [1, 2, 3]
    });
  }

  getProjectListById(id) {
    const params = {
    id: id,
    userId: this.authService.getCurrentLoggedInId().toString(),
    userType: this.getUserType(),
  };
    return this.httpClient.get(`${this.baseUrl}/get-project`, {params: params});
  }

  getUserDetails(type) {
    const params = {
    requestType : type,
    userId: this.authService.getCurrentLoggedInId().toString(),
    userType: this.getUserType(),
  };
    return this.httpClient.get(`${this.baseUrl}/find-user-details`, {params: params});
  }

  removeUser(ids) {
    const params = {
      ids: ids
    };
    return this.httpClient.get(`${this.baseUrl}/user-unassign`, {params: params});
  }

  clearCache(projectId: any) {
    return this.httpClient.get(`${this.baseUrl}/clear-cache/${projectId}`);
  }

  getBoardNameDropDown(companyId: any) {
    return this.httpClient.get(`${this.baseUrl}/drop-down/${companyId}`).pipe(map(resp => resp));
  }

  saveChecklist(payload?:any){
    return this.httpClient.post(`${this.baseUrl}/buckets/tasks/check-list`, payload)
  }

  saveChecklistItem(payload?:any){
    return this.httpClient.post(`${this.baseUrl}/buckets/tasks/check-list/save-task-list-item`, payload)
  }

  getCheckListByTask(data:any){
    return this.httpClient.post(`${this.baseUrl}/buckets/tasks/check-list/dropdown`, data)
  }

  deleteCheckList(id:number){
    return this.httpClient.delete(`${this.baseUrl}/buckets/tasks/check-list/${id}`)
  }

  
  getTaskHistory(options:any){
    return this.httpClient.post(`${this.baseUrl}/task-history/search`, options);
  }

  updateChecklist(payload?:any){
    return this.httpClient.put(`${this.baseUrl}/buckets/tasks/check-list/${payload.id}`, payload)
  }

  gettaskDetails(id) {
    return this.httpClient.get(`${this.baseUrl}/buckets/tasks/task-details/${id}`)
  }

  getNewProjectList() {
      const userName = this.authService.getCurrentUsername();
      const userId = this.authService.isSuper() ? null : this.authService.getCurrentLoggedInId();
      return this.httpClient.post(`${this.baseUrl}/project-list`, {
        userId: userId,
        userType: this.authService.isSuper() ? null : this.getCurrentUserRole(), // 0
        companyId: (this.authService.isCompanyAd() || this.authService.isSubCompanyAd()) ? this.authService.getCurrentCompanyId() : null
      });
  }

  updateHideBucket(payload) {
    return this.httpClient.put(`${this.baseUrl}/buckets/hide-status/update`, payload)
  }

  saveFirebaseNotification(rootPath: string, data: any) {
    const payload = {
      rootPath: rootPath,
      data: data
    }
    return this.httpClient.post(`${this.notificationUrl}/firebase/save`, payload);
  }

  searchTaskHistory(payload) {
    return this.httpClient.post(`${this.baseUrl}/task-history/search`, payload);
  }

  getProjectListNew(body) {
    const userId = this.authService.isSuper() ? null : this.authService.getCurrentLoggedInId();
    return this.httpClient.post(`${this.baseUrl}/project-list`, body);
  }

  exportPdfCardActivity(data?: any): Observable<any> {
    // return this.httpClient.post<any>(`http://localhost:8015/api/v2/task/tasks-management/task-history/exportPdfCardActivity`, data).pipe(map(resp => resp));
    return this.httpClient.post<any>(`${this.baseUrl}task-history/exportPdfCardActivity`, data).pipe(map(resp => resp));
  }
}
