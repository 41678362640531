import { NgModule } from '@angular/core';
import {Routes, RouterModule, PreloadAllModules} from '@angular/router';
import { AppComponent } from './app.component';
import { AdminGuard } from './authenticate/admin.guard';
import { AuthGuard } from './authenticate/auth.guard';
import { DispatchPotentialClientComponent } from './crm/campaign/component/dispatch-system/dispatch-potential-client.component';
import { FidelisPotentialClientComponent } from './crm/campaign/component/fidelis-billing/fidelis-potential-client.component';
import { FidelisLookbackPotentialClientComponent } from './crm/campaign/component/fidelis-lookback/fidelis-lookback-potential-client.component';
import { MasPotentialClientComponent } from './crm/campaign/component/mas-billing/mas-potential-client.component';
import { DispatchSystemComponent } from './crm/component/add-product-dialog/dispatch-system/dispatch-system.component';
import { FidelisBillingComponent } from './crm/component/add-product-dialog/fidelis-billing/fidelis-billing.component';
import { FidelisLookbackComponent } from './crm/component/add-product-dialog/fidelis-lookback/fidelis-lookback.component';
import { MasBillingComponent } from './crm/component/add-product-dialog/mas-billing/mas-billing.component';
import { ContributeFormComponent } from './crm/fundraisers/component/contribute-form/contribute-form.component';
import { ContributeThankyouComponent } from './crm/fundraisers/component/contribute-thankyou/contribute-thankyou.component';
import { DocSignRecipientSigningComponent } from './doc-sign/component/doc-sign-recipient-signing/doc-sign-recipient-signing.component';
import { EmployeeDashboardComponent } from './employee/component/employee-dashboard/employee-dashboard.component';
import { DashboardComponent } from './layout/component/dashboard/dashboard.component';
import { OneTimePaymentThankyouComponent } from './payment/component/one-time-payment/one-time-payment-thankyou/one-time-payment-thankyou.component';
import { OneTimePaymentComponent } from './payment/component/one-time-payment/one-time-payment.component';
import { PayrollJournalReportPreViewComponent } from './payroll-journal-report/component/payroll-journal-report-preview/payroll-journal-report-preview.component';
import { QuotePreviewComponent } from './quote/component/quote-preview/quote-preview.component';
import {SmsMmsBillingModule} from './sms-mms-billing/sms-mms-billing.module';
import {LawFirmModule} from './law-firm/law-firm.module';
import {RecollectionDetailsModule} from './recollection-details/recollection-details.module';
import {OnboardingRequirementsModule} from './onboarding-requirements/onboarding-requirements.module';

export const routes: Routes = [
    {
        path: '',
        redirectTo: 'app',
        pathMatch: 'full'
    },
    {
        path: 'app',
        component: AppComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'contacts-interest',
        loadChildren: () => import('./contacts-interest/contacts-interest.module').then(m => m.ContactsInterestModule),
    },
    {
        path: 'payroll-journal-report-preview/:id/:detailId',
        component: PayrollJournalReportPreViewComponent,
        canLoad: [AuthGuard], canActivateChild: [AuthGuard]
    },
    {
        path: 'quote-preview/:id',
        component: QuotePreviewComponent,
        canLoad: [AuthGuard], canActivateChild: [AuthGuard]
    },
    {
        path: 'dispatch-client/:id',
        component: DispatchSystemComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard]
    },
    {
        path: 'fidelis-client/:id',
        component: FidelisBillingComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard]
    },
    {
        path: 'fidelis-lookback-client/:id',
        component: FidelisLookbackComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard]
    },
    {
        path: 'mas-client/:id',
        component: MasBillingComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard]
    },
    {
        path: 'dispatch-potential-client/:id',
        component: DispatchPotentialClientComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard]
    },
    {
        path: 'fidelis-potential-client/:id',
        component: FidelisPotentialClientComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard]
    },
    {
        path: 'fidelis-lookback-potential-client/:id',
        component: FidelisLookbackPotentialClientComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard]
    },
    {
        path: 'mas-potential-client/:id',
        component: MasPotentialClientComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard]
    },
    {
        path: 'app/dashboard',
        component: DashboardComponent,
        canLoad: [AdminGuard], canActivateChild: [AdminGuard]
    },
    {
        path: 'app/employee-dashboard',
        component: EmployeeDashboardComponent,
        canLoad: [AdminGuard], canActivateChild: [AdminGuard]
    },
    {
        path: 'app/one-time-payment/:id',
        component: OneTimePaymentComponent,
        canLoad: [AuthGuard], canActivateChild: [AuthGuard]
    },
    {
        path: 'app/one-time-payment-thankyou',
        component: OneTimePaymentThankyouComponent,
        canLoad: [AuthGuard], canActivateChild: [AuthGuard]
    },
    {
        path: 'signing/emails/:id', component: DocSignRecipientSigningComponent
    },
    {
        path: 'app/absence',
        loadChildren: () => import('./employee/absence/absence.module').then(m => m.AbsenceModule),
    },
    {
        path: 'app/account',
        loadChildren: () => import('./account/account.module').then(m => m.AccountModule),
    },
    {
        path: 'app/billing-location',
        loadChildren: () => import('./accounting/accounting.module').then(m => m.AccountingModule),
    },
    {
        path: 'app/sms-mms-billing',
        loadChildren: () => import('./sms-mms-billing/sms-mms-billing.module').then(m => m.SmsMmsBillingModule),
    },
    {
        path: 'app/admin',
        loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    },
    {
        path: 'app/agency',
        loadChildren: () => import('./agency/agency.module').then(m => m.AgencyModule),
    },
    {
        path: 'app/location/asset-management',
        loadChildren: () => import('./asset/asset.module').then(m => m.AssetModule),
    },
    {
        path: 'app/billings',
        loadChildren: () => import('./billing/billing.module').then(m => m.BillingModule),
    },
    {
        path: 'app/ceo-dashboard',
        loadChildren: () => import('./ceo-dashboard/ceo-dashboard.module').then(m => m.CEODashboardModule),
    },
    {
        path: 'app/chat-box',
        loadChildren: () => import('./chat-box/chat-box.module').then(m => m.ChatBoxModule),
    },
    {
        path: 'app/client',
        loadChildren: () => import('./client-dashboard/client-dashboard.module').then(m => m.ClientDashboardModule),
    },
    {
        path: 'app/client-account',
        loadChildren: () => import('./client-account/client-account.module').then(m => m.ClientAccountModule),
    },
    {
        path: 'app/client-info-account',
        loadChildren: () => import('./client-info-account/client-info.module').then(m => m.ClientInfoAccountModule),
    },
    {
        path: 'app/company',
        loadChildren: () => import('./company/company.module').then(m => m.CompanyModule),
    },
    {
        path: 'app/conference',
        loadChildren: () => import('./conference/conference.module').then(m => m.ConferenceModule),
    },
    {
        path: 'app/conference-room',
        loadChildren: () => import('./conference-room/conference-room.module').then(m => m.ConferenceRoomModule),
    },
    {
        path: 'app/content',
        loadChildren: () => import('./content/content.module').then(m => m.ContentModule),
    },
    {
        path: 'app/contractors',
        loadChildren: () => import('./contractor/contractor.module').then(m => m.ContractorModule),
    },
    {
        path: 'app/contribution',
        loadChildren: () => import('./contribution/contribution.module').then(m => m.ContributionModule),
    },
    {
        path: 'app/crm',
        loadChildren: () => import('./crm/crm.module').then(m => m.CrmModule),
    },
    {
        path: 'app/crm/campaign',
        loadChildren: () => import('./crm/campaign/campaign.module').then(m => m.CampaignModule),
    },
    {
        path: 'app/crm/potential',
        loadChildren: () => import('./crm/lead/lead.module').then(m => m.LeadModule),
    },
    {
        path: 'app/crm/candidate',
        loadChildren: () => import('./crm/candidate/candidate.module').then(m => m.CandidateModule),
    },
    {
        path: 'app/crm/activity-log',
        loadChildren: () => import('./crm/activity-log/activity-log.module').then(m => m.ActivityLogModule),
    },
    {
        path: 'app/department',
        loadChildren: () => import('./department/department.module').then(m => m.DepartmentModule),
    },
    {
        path: 'app/doc-sign',
        loadChildren: () => import('./doc-sign/doc-sign.module').then(m => m.DocSignModule),
    },
    {
        path: 'app/document',
        loadChildren: () => import('./document/document.module').then(m => m.DocumentModule),
    },
    {
        path: 'app/ele-campaign',
        loadChildren: () => import('./electoral-campaign/electoral-campaign.module').then(m => m.ElectoralCampaignModule),
    },
    {
        path: 'app/ele-campaign/visit-history',
        loadChildren: () => import('./electoral-campaign/visit-history/visit-history.module').then(m => m.VisitHistoryModule),
    },
    {
        path: 'app/ele-campaign/voter',
        loadChildren: () => import('./electoral-campaign/voter-list/voter-list.module').then(m => m.VoterListModule),
    },
    {
        path: 'app/ele-campaign/vrm-group',
        loadChildren: () => import('./electoral-campaign/vrm-group/vrm-group.module').then(m => m.VrmGroupModule),
    },
    {
        path: 'app/ele-campaign/voter-upload-history',
        loadChildren: () => import('./electoral-campaign/voter-upload-history/voter-upload-history.module').then(m => m.VoterUploadHistoryModule),
    },
    {
        path: 'app/intern',
        loadChildren: () => import('./employee/intern.module').then(m => m.InternModule),
    },
    {
        path: 'app/volunteer',
        loadChildren: () => import('./employee/volunteer.module').then(m => m.VolunteerModule),
    },
    {
        path: 'app/employee',
        loadChildren: () => import('./employee/employee.module').then(m => m.EmployeeModule),
    },
    {
        path: 'app/employee-bill',
        loadChildren: () => import('./employee-bill/employee-bill.module').then(m => m.EmployeeBillModule),
    },
    {
        path: 'app/employee-clocking',
        loadChildren: () => import('./employee-clocking/employee-clocking.module').then(m => m.EmployeeClockingModule),
    },
    {
        path: 'app/equipment',
        loadChildren: () => import('./equipment/equipment.module').then(m => m.EquipmentModule),
    },
    {
        path: 'app/excursion',
        loadChildren: () => import('./excursion/excursion.module').then(m => m.ExcursionModule),
    },
    {
        path: 'app/expense',
        loadChildren: () => import('./expense/expense.module').then(m => m.ExpenseModule),
    },
    {
        path: 'app/faq',
        loadChildren: () => import('./faq/faq.module').then(m => m.FaqModule),
    },
    {
        path: 'app/fidelis-billing-report',
        loadChildren: () => import('./fidelis-billing-report/fidelis-billing-report.module').then(m => m.FidelisBillingReportModule),
    },
    {
        path: 'app/file-examination',
        loadChildren: () => import('./file-examination/file-examination.module').then(m => m.FileExaminationModule),
    },
    {
        path: 'app/general-invoice',
        loadChildren: () => import('./general-invoice/general-invoice.module').then(m => m.GeneralInvoiceModule),
    },
    {
        path: 'app/generic-compliance',
        loadChildren: () => import('./generic-compliance/generic-compliance.module').then(m => m.GenericComplianceModule),
    },
    {
        path: 'app/google-api-setting',
        loadChildren: () => import('./google-api-setting/google-api-setting.module').then(m => m.GoogleApiSettingModule),
    },
    {
        path: 'app/guest',
        loadChildren: () => import('./guest/guest.module').then(m => m.GuestModule),
    },
    {
        path: 'app/in-service',
        loadChildren: () => import('./in-service/in-service.module').then(m => m.InServiceModule),
    },
    {
        path: 'app/training-topic',
        loadChildren: () => import('./training-topic/training-topic.module').then(m => m.TrainingTopicModule),
    },
    {
        path: 'app/insurance',
        loadChildren: () => import('./insurance/insurance.module').then(m => m.InsuranceModule),
    },
    {
        path: 'app/invoice',
        loadChildren: () => import('./invoice-dashboard/invoice-dashboard.module').then(m => m.InvoiceDashboardModule),
    },
    {
        path: 'app/invoice-tracking',
        loadChildren: () => import('./invoice-tracking/invoice-tracking.module').then(m => m.InvoiceTrackingModule),
    },
    {
        path: 'app/lander',
        loadChildren: () => import('./lander/lander.module').then(m => m.LanderModule),
    },
    {
        path: 'app/lettermail',
        loadChildren: () => import('./lettermail/lettermail.module').then(m => m.LettermailModule),
    },
    {
        path: 'app/loan',
        loadChildren: () => import('./loan/loan.module').then(m => m.LoanModule),
    },
    {
        path: 'app/location-management',
        loadChildren: () => import('./location-management/location-management.module').then(m => m.LocationManagementModule),
    },
    {
        path: 'app/mananger-calendar',
        loadChildren: () => import('./mananger-calendar/mananger-calendar.module').then(m => m.ManangerCalendarModule),
    },
    {
        path: 'app/membership',
        loadChildren: () => import('./membership/membership.module').then(m => m.MembershipModule),
    },
    {
        path: 'app/monthly-fee',
        loadChildren: () => import('./monthly-fee/monthly-fee.module').then(m => m.MonthlyFeeModule),
    },
    {
        path: 'app/note',
        loadChildren: () => import('./note/note.module').then(m => m.NoteModule),
    },
    {
        path: 'app/notification',
        loadChildren: () => import('./notification/notification.module').then(m => m.NotificationModule),
    },
    {
        path: 'app/operrtel-pool',
        loadChildren: () => import('./operrtel-pool/operrtel-pool.module').then(m => m.OperrtelPoolModule),
    },
    {
        path: 'app/operrtel',
        loadChildren: () => import('./oppertel-dashboard/oppertel-dashboard.module').then(m => m.OppertelDashboardModule),
    },
    {
        path: 'app/overtime',
        loadChildren: () => import('./overrtime-dashboard/overtime-dashboard.module').then(m => m.OvertimeDashboardModule),
    },
    {
        path: 'app/package-service-plan',
        loadChildren: () => import('./package-service-plan/package-service-plan.module').then(m => m.PackageServicePlanModule),
    },
    {
        path: 'app/payments',
        loadChildren: () => import('./payment/payment.module').then(m => m.PaymentModule),
    },
    {
        path: 'app/payment-info',
        loadChildren: () => import('./payment-info/payment-info.module').then(m => m.PaymentInfoModule),
    },
    {
        path: 'app/payment-method',
        loadChildren: () => import('./payment-method/payment-method.module').then(m => m.PaymentMethodModule),
    },
    {
        path: 'app/payment-tracking',
        loadChildren: () => import('./payment-tracking/payment-tracking.module').then(m => m.PaymentTrackingModule),
    },
    {
        path: 'app/payroll-journal-report',
        loadChildren: () => import('./payroll-journal-report/payroll-journal-report.module').then(m => m.PayrollJournalReportModule),
    },
    {
        path: 'app/phonesystem',
        loadChildren: () => import('./phonesystem/phonesystem.module').then(m => m.PhonesystemModule),
    },
    {
        path: 'app/plan',
        loadChildren: () => import('./plan/plan.module').then(m => m.PlanModule),
    },
    {
        path: 'app/position',
        loadChildren: () => import('./position/position.module').then(m => m.PositionModule),
    },
    {
        path: 'app/spread-sheet',
        loadChildren: () => import('./preadsheet/spreadsheet.module').then(m => m.SpreadsheetModule),
    },
    {
        path: 'app/product',
        loadChildren: () => import('./product/product.module').then(m => m.ProductModule),
    },
    {
        path: 'app/project',
        loadChildren: () => import('./project/project.module').then(m => m.ProjectModule),
    },
    {
        path: 'app/performance',
        loadChildren: () => import('./employee/performance/performance.module').then(m => m.PerformanceModule),
    },
    {
        path: 'app/pwdmng',
        loadChildren: () => import('./pwdmng/pwdmng.module').then(m => m.PwdmngModule),
    },
    {
        path: 'app/quote',
        loadChildren: () => import('./quote/quote.module').then(m => m.QuoteModule),
    },
    {
        path: 'app/reminder',
        loadChildren: () => import('./reminder-alert/reminder-alert.module').then(m => m.ReminderAlertModule),
    },
    {
        path: 'app/reports',
        loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule),
    },
    {
        path: 'app/resident',
        loadChildren: () => import('./resident/resident.module').then(m => m.ResidentModule),
    },
    {
        path: 'app/resume',
        loadChildren: () => import('./resume/resume.module').then(m => m.ResumeModule),
    },
    {
        path: 'app/screen-recorder',
        loadChildren: () => import('./screen-recorder/screen-recorder.module').then(m => m.ScreenRecorderModule),
    },
    {
        path: 'app/recollection-details',
        loadChildren: () => import('./recollection-details/recollection-details.module').then(m => m.RecollectionDetailsModule),
    },
    {
        path: 'app/law-firm',
        loadChildren: () => import('./law-firm/law-firm.module').then(m => m.LawFirmModule),
    },
    {
        path: 'app/onboarding-requirements',
        loadChildren: () => import('./onboarding-requirements/onboarding-requirements.module').then(m => m.OnboardingRequirementsModule),
    },
    {
        path: 'app/service-provider',
        loadChildren: () => import('./service-provider/service-provider.module').then(m => m.ServiceProviderModule),
    },
    {
        path: 'app/service-rate',
        loadChildren: () => import('./service-rate/service-rate.module').then(m => m.ServiceRateModule),
    },
    {
        path: 'app/share-holder',
        loadChildren: () => import('./shared-holder/shared-holder.module').then(m => m.SharedHolderModule),
    },
    {
        path: 'app/stock',
        loadChildren: () => import('./stock/stock.module').then(m => m.StockModule),
    },
    {
        path: 'app/survey',
        loadChildren: () => import('./survey-dashboard/survey-dashboard.module').then(m => m.SurveyDashboardModule),
    },
    {
        path: 'app/tax-info',
        loadChildren: () => import('./tax-detail/tax-detail.module').then(m => m.TaxDetailModule),
    },
    {
        path: 'app/workorder',
        loadChildren: () => import('./todo/todo.module').then(m => m.ToDoModule),
    },
    // {
    //     path: 'app/task-management',
    //     loadChildren: () => import('./task-management/task-management.module').then(m => m.TaskManagementModule),
    // },
    // {
    //     path: 'app/tm',
    //     loadChildren: () => import('./task-management/task-management.module').then(m => m.TaskManagementModule),
    // },
    {
        path: 'app/user',
        loadChildren: () => import('./user/user.module').then(m => m.UserModule),
    },
    {
        path: 'app/vehicle',
        loadChildren: () => import('./vehicle/vehicle.module').then(m => m.VehicleModule),
    },
    {
        path: 'app/voter',
        loadChildren: () => import('./voter-dashboard/voter-dashboard.module').then(m => m.VoterDashboardModule),
    },
    {
        path: 'app/vrm/customize-questions',
        loadChildren: () => import('./crm/customize-questions/customize-questions.module').then(m => m.CustomizeQuestionsModule),
    },
    {
        path: 'app/vrm/fundraisers',
        loadChildren: () => import('./crm/fundraisers/fundraisers.module').then(m => m.FundraisersModule),
    },
    {
        path: 'app/vrm/contribute/:id',
        component: ContributeFormComponent
    },
    {
        path: 'app/vrm/contribute-thankyou',
        component: ContributeThankyouComponent
    },
    {
        path: 'app/zone-management',
        loadChildren: () => import('./zone-management/zone-management.module').then(m => m.ZoneManagementModule),
    },
    {
        path: 'app/maintenance',
        loadChildren: () => import('./maintenance/maintenance.module').then(m => m.MaintenanceModule),
    },
    {
        path: 'app/compliance-setting',
        loadChildren: () => import('./compliance-setting/compliance-setting.module').then(m => m.ComplianceSettingModule),
    },
    {
        path: 'app/working-time-calculator',
        loadChildren: () => import('./working-time-calculator/working-time-calculator.module').then(m => m.WorkingTimeCalculatorModule),
    },
    {
        path: 'app/paystub-pdf-processer',
        loadChildren: () => import('./paystub-pdf-processer/paystub-pdf-processer.module').then(m => m.PaystubPdfProcesserModule),
    },

];

@NgModule({
    imports: [RouterModule.forRoot(routes,
        {
            preloadingStrategy: PreloadAllModules,
        })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
